import styled from 'styled-components';
import {atMostLG} from "@Styles/breakpoints";

const ReviewOfTheDaySection = styled.div`
  max-width: 1500px;
  flex: 0 1 0px;
  display: flex;
  flex-direction: column;
  align-self: center;
  img {
    align-self: center;
  }
  margin-left: 12px;
  margin-right: 12px;
`;

const ReviewOfTheDayHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-top: 25px;
  margin-bottom: 35px;
  span {
    flex: 1;
    text-align: center;
    line-height: 50px;
  }
`;

const ReviewOfTheDayLightHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  margin-bottom: 35px;
  span {
    flex: 1;
    text-align: left;
    font-weight: 900;
    line-height: 50px;
  }
`;

const ReviewOfTheDaySuperLightHeader = styled.div`
  max-height: 12px;
  flex: 0 0 12px;
  display: flex;
  border-top: 1px solid black;
`;

const ReviewOfTheDaySuperLightHeaderXL = styled.div`
  max-height: 24px;
  flex: 0 0 24px;
  display: flex;
  border-top: 1px solid black;
`;

const FeatureLightHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 1px solid black;
  padding-top: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
  span {
    flex: 1;
    text-align: left;
    font-weight: 900;
    line-height: 50px;
  }
`;

const FeatureSuperLightHeader = styled.div`
  max-height: 12px;
  flex: 0 0 12px;
  display: flex;
  border-top: 1px solid black;
  padding-top: 5px;  
  margin-top: 12px;
`;

const FeatureSuperLightHeaderXL = styled.div`
  max-height: 24px;
  flex: 0 0 24px;
  display: flex;
  border-top: 1px solid black;
  padding-top: 11px;  
  margin-top: 24px;
`;

const ReviewOfTheDayRenderer = styled.div`
  max-width: 1000px;
  flex: 0 1 0px;
  display: flex;
  align-self: flex-start;
  img {
    height: fit-content;
    max-width: 300px;
    margin-right: 12px;
  }
`;

const ReviewOfTheDayMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 15px;
  span {
    margin-bottom: 5px;
  }
`;

const ReviewOfTheDaySide = styled.div`
  flex: 0 0 200px;
  border-left: 1px solid black;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  div:last-child {
    border-bottom: 0;
  }
`;

const ReviewOfTheDaySideLink = styled.div`
  flex: 1;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  gap: 10px;
`;

export {
  ReviewOfTheDaySection,
  ReviewOfTheDayHeader,
  ReviewOfTheDayRenderer,
  ReviewOfTheDayMain,
  ReviewOfTheDaySide,
  ReviewOfTheDaySideLink,
  ReviewOfTheDayLightHeader,
  ReviewOfTheDaySuperLightHeader,
  FeatureLightHeader,
  FeatureSuperLightHeader,
  FeatureSuperLightHeaderXL,
  ReviewOfTheDaySuperLightHeaderXL
};
