import styled from "styled-components";

const LoginFormRenderer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  input {
    margin-bottom: 8px;
    width: 300px;

    font-size: 16px;
    padding: 3px 6px;
    border-color: darkblue;
    border-width: 1px;
    border-style: double;

    &::placeholder {
      color: #8f8f8f;
      font-size: inherit;
    }
  }
  button {
    margin-top: 70px;
  }
`;

const ResetPassword = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  span {
    font-family: freight-sans-pro, serif;
    margin-top: 20px;
    color: #8f8f8f;
  }
`;

const ResetMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  span {
    font-family: freight-sans-pro, serif;
    color: #8f8f8f;
    margin-bottom: 20px;
  }
`;

const ValidationMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  span {
    font-family: freight-sans-pro, serif;
    color: red;
  }
`;

const LoginHeader = styled.span`
  font-family: freight-sans-pro,serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 28px;
  width: 336px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
`;

export { LoginFormRenderer, ResetPassword, ValidationMessage, LoginHeader, ResetMessage }
