import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentAnnouncement: null,
};

const announcementSlice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        setCurrentAnnouncement: (state, action) => {
            const { payload } = action;
            state.currentAnnouncement = payload;
        },
        clearData: () => initialState,
    },
});

const announcementSelectors = {
    getCurrentAnnouncement: (state) => state.currentAnnouncement,
};
const announcementActions = announcementSlice.actions;

export default announcementSlice;
export { announcementSelectors, announcementActions };