import React from 'react';
import propTypes from 'prop-types';
import ContainerRenderer from './ContainerRenderer';

/* Hello! I'm Container. My only job is to contain things and be somewhat flexy.
 * In future I may provide some minor utilities, like generic padding or something similar.
 * But this depends on the designs. For now, I'm just containing things.
 */
const Container = (props) => {
  return <ContainerRenderer {...props} />;
};

Container.defaultProps = {
  flexy: true,
};

Container.propTypes = {
  children: propTypes.any,
  flex: propTypes.bool,
  flexy: propTypes.bool,
  scrollable: propTypes.bool,
  column: propTypes.bool,
  justify: propTypes.string,
  align: propTypes.string,
};

export default Container;
