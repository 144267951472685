import styled from 'styled-components';

const BooklistReaderRenderer = styled.div`
  max-width: 1000px;
  flex: 0 1 0px;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const BooklistReaderHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-top: 25px;
  margin-bottom: 35px;
  span {
    flex: 1;
    text-align: center;
    line-height: 50px;
  }
`;

const BooklistReaderColumnsWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
`;

const BooklistReaderItemsWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const BooklistReaderItem = styled.div`
  max-width: 220px;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 200px;
  }
  span {
    margin-top: 15px;
  }
`;

const BooklistReaderFooter = styled.div`
  margin-top: 30px;
  flex: 1 1 0px;
  img {
    width: 100%;
  }
`;

export {
  BooklistReaderRenderer,
  BooklistReaderHeader,
  BooklistReaderColumnsWrapper,
  BooklistReaderItemsWrapper,
  BooklistReaderItem,
  BooklistReaderFooter,
};
