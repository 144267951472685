import http from '@Utils/network';

const GET_SITEINFO_ENDPOINT = (siteInfoId) => `api/Eusservice/GetSiteInfo?siteInfoId=${siteInfoId}`;

const getSiteInfo = async (searchOptions) => {
  const { siteInfoId } = searchOptions;

  const { data } = await http.get(GET_SITEINFO_ENDPOINT(siteInfoId));
  return data;
};

export { getSiteInfo };
