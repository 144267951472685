import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { siteInfoLoginInterface } from '@Store';
import { getSiteInfo } from '@Api/siteInfo';

export const useHandleLoadSiteInfoLogin = (siteInfoId) => {
    const currentSiteInfo = useSelector(siteInfoLoginInterface.getCurrentSiteInfo);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(
        async ({ siteInfoId }) => {
            setDataLoading(true);
            const retData = await getSiteInfo({
                siteInfoId,
            });
            const { siteInfo, accordionData } = retData;
            siteInfoLoginInterface.dispatch(siteInfoLoginInterface.setCurrentSiteInfo({ currentSiteInfo: siteInfo }));
            siteInfoLoginInterface.dispatch(siteInfoLoginInterface.setCurrentAccordionData({ currentAccordionData: accordionData }));
            setDataLoading(false);
        },
        [siteInfoId]
    );

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        siteInfoLoginInterface.dispatch(siteInfoLoginInterface.clearData());
    }, [siteInfoId]);

    useEffect(() => {
        if (!currentSiteInfo) loadData({ siteInfoId });
    }, [currentSiteInfo, loadData, siteInfoId]);

    return [loadData, isLoading];
};
