import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

const GlobalBodyStyle = createGlobalStyle`
  ${reset}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html, body, #root {
    font-family: "roboto", sans-serif;
    font-size: 16px;
  }
  
  em {
    font-style: italic;
  }
  
  strong {
    font-weight: 600;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
    outline: none;
  }

  input {
    font-family: "roboto", sans-serif;
    outline: none;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }  
  .blogItemHeader {
    font-weight: 700;
  }


  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  
  .richContent {
    a {
      color: black;
    }
  }

  .announcementRichContent {
    a {
      color: black;
    }
    h1,h2,h3,h4,h5,h6 {
      font-family: "freight-sans-pro";      
    }
    h2 {
      font-weight: 400;
      font-size: 28px;
      textTransform: 'capitalize'
    }
    p {
      font-family: "garamond-premier-pro";
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 12px;
    }
  }

  .richContentRODFeatures {
    a {
      color: black;
    }   
    h1,h2,h3,h4,h5,h6 {
      font-family: "freight-sans-pro";
    }
    h2 {
      font-weight: 400;
      font-size: 20px;
      textTransform: 'capitalize'
    }
    p {
      font-family: "garamond-premier-pro";
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
    }
    p:last-child {      
      margin-bottom: 0px;
    }
  }

  .topRichContent {
    p {
      font-family: "garamond-premier-pro";
      font-weight: 500; 
      font-size: 18px;
    }
    p:not(:first-child) {
      display: none;
    }
    a {
      color: black;
      font-family: "freight-sans-pro";
      font-weight: 400;
      font-size: 18px;
      text-decoration: none;
    }
  }

  .BSbutton {
    background-color: #de2454;
    border: 0;
    border-radius: 999px;
    color: white;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    width: 110px;
    font-size: 12px;
    padding: 5px;
  }
  ul.sidebar-links {
    padding: 0;
    margin: 0;
    list-style: none;
    clear: both;
    width: 100%;
  }

  ul.sidebar-links li {
    float: left;
    clear: both;
    width: 100%;
    margin-bottom: 24px
  }

  ul.sidebar-links a {
    color: black;
    font: 17px/17px freight-sans-pro;
    padding: 0;
    text-indent: -6px;
    text-decoration: underline;
  }
  
  .productRelations{
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      img {
        margin-top: -10px;
        margin-right: 8px;
      }
    }
    .style22 {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
    }
    p {
      margin: 0px 0px 8px 0px !important;
      padding: 0px !important;
    }
  }
  
  #printContent {
    br {
      display: none;
    }
  }

  .ant-modal .ant-modal-content {    
    border: 1px solid black;
    border-radius: 0px;

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child th:last-child {
      border-start-end-radius: 0px;
    }

    .ant-pagination .ant-pagination-item-active {
      background-color: #ffffff;
      border-color: black;
      a {
        color: black;
      }
    }
    .ant-pagination .ant-pagination-item-active:hover {
      background-color: #ffffff;
      border-color: black;
      a {
        color: black;
      }
    }
  }

  .ant-btn-primary {
    color: black;
    background-color: white;
    box-shadow: none;
    border: 2px solid black;
    border-radius: 0px;
  }

  .ant-btn-primary:not(:disabled):hover {
    color: black;
    background-color: white;    
  }

  .ant-btn-default {
    color: black;
    background-color: white;
    box-shadow: none;
    border: 2px solid black;
    border-radius: 0px;
  }

  .ant-btn-default:not(:disabled):hover {
    color: black;
    background-color: white;
  }

  .ant-modal .ant-modal-title {    
    color: black;
    font-weight: 600;
    font-size: 18px;    
  }
  
  #indieBn1 {
    display: none;
  }
`;

export default GlobalBodyStyle;
