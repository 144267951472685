import React, {useEffect, useState} from 'react';
import TopAdAreaRenderer from '@Components/sections/TopAdArea/Renderers';
import images from '@Images';
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

const TopAdArea = () => {
    const location = useLocation();

    useEffect(()=>{
        if(window.broadstreet) window.broadstreet.refreshAll();
    }, [location]);
  return (
      <TopAdAreaRenderer>
          <broadstreet-zone zone-id="72502"></broadstreet-zone>
      </TopAdAreaRenderer>
  );
};

export default TopAdArea;
