import React, { useCallback } from 'react';
import { SideMenueRenderer } from '@Components/sections/SideMenu/Renderers';
import { useSelector } from 'react-redux';
import { appInterface } from '@Store';
import styled, { css } from 'styled-components';
import Button from '@Components/controls/Button';
import { atMostMD, atMostSM } from '@Utils/themes';
import images from '@Images';
import MainLogoArea from '@Components/sections/TopHeader/MainLogoArea';
import NavigationArea from '@Components/sections/SideMenu/NavigationArea';
import ToolBar from '@Components/sections/SideMenu/ToolBar';

const MenuButton = styled.button`
  position: absolute;
  top: 132px;
  left: 14px;
  ${atMostSM`
    top: 105px;
    left: 22px;
  `}
`;

const CloseButton = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 12px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: -1px;
    top: calc(50% - 1px);
    display: block;
    width: calc(100% + 4px);
    height: 2px;
    background-color: black;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const NavigationWrapper = styled.div`
  overflow-y: auto;
  padding-left: 42px;
  ${atMostSM`
    padding-left: 54px;
  `}
`;

const SideMenu = () => {
  const isOpen = useSelector(appInterface.getSideMenuOpen);
  const handleMenuOpenClick = useCallback(() => {
    appInterface.dispatch(appInterface.setSideMenuOpen({ sideMenuOpen: false }));
  }, []);

  return (
    <SideMenueRenderer isOpen={isOpen} onMouseLeave={isOpen ? handleMenuOpenClick : undefined}>
      <CloseButton onClick={handleMenuOpenClick} />
      <MainLogoArea variant="SideMenu" />
      <NavigationWrapper>
        <NavigationArea />
        <ToolBar />
      </NavigationWrapper>
    </SideMenueRenderer>
  );
};

export default SideMenu;
