import styled, { css } from 'styled-components';

const QuickSearchRenderer = styled.div`
  opacity: 0;
  visibility: hidden;
  width: 100%;
  background-color: rgb(232, 240, 254);
  display: flex;
  align-items: center;
  padding: 10px 12px;
  flex-wrap: wrap;
  gap: 7px;
  flex: 1;
  max-height: 0;
  ${({ mode }) =>
    mode === 1 &&
    css`
      position: sticky;
      z-index: 444;
      top: 51px;
    `}
  transition-property: max-height, height, opacity, visibility;
  transition-duration: 0.5s;

  input {
    border: 2px solid darkblue;
    background-color: rgb(232, 240, 254);
    color: black;
    font-size: 16px;
    padding: 3px 6px;

    &::placeholder {
      color: inherit;
      font-size: inherit;
    }
  }

  .keywordInput {
    min-width: 300px;
  }
  .deweyInput {
    max-width: 120px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 2800px;
    `}
`;

const QuickSearchRow = styled.div`
  display: flex;
  gap: 7px;
  flex: 1;
  flex-wrap: wrap;
  
  button {
    margin-left: 15px;
  }
`;

const QuickSearchSubTitleRenderer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;
  align-items: flex-end;
  align-self: baseline;
  min-width: auto;
  cursor: pointer;
`

const QuickSearchTitleRenderer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 190px;
  align-items: flex-start;
  align-self: flex-start;
  min-width: auto;
  border-right: ${({ mode }) => {
    return mode === 1 ? '0' : '2px dotted black;';
  }};

  span:first-child {
    margin-right: 12px;
    color: black;
    font-weight: 700;
    position: relative;
    padding-left: 16px;
    transition-property: border-bottom;
    transition-duration: 0.4s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform-origin: right;
      display: block;
      width: 8px;
      height: 2px;
      background-color: black;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  span:last-child {
    color: black;
    font-weight: 700;
    position: relative;
    margin-top: 5px;
    text-align: left;
    cursor: pointer;
    transition-property: border-bottom;
    transition-duration: 0.4s;
  }

  span:last-child:hover {
    border-bottom: 3px dotted black;
  }
`;

const AdvancedSearchHeaderRow = styled.div`
  display: flex;
  gap: 7px;
  flex: 1;
  flex-wrap: wrap;
  min-width: 80%;
  margin-left: 0px;
`;

const AdvancedSearchColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 400px;
  align-items: flex-start;
  align-self: flex-start;
  padding-left:12px; 
  padding-right:12px; 
  padding-top:24px;
  label {
    color: black;
  }
`;

const AdvancedSearchRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 370px;
  align-items: flex-start;
  align-self: flex-start;
`;

const AdvancedSearchRowShortColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: flex-start;
  align-self: flex-start;
`;

const AdvancedSearchRow = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  gap: 5px;
  > span {
    color: black;
    margin-bottom: 10px;
  }
  label {
    color: black;
  }
`;

export {
  QuickSearchRenderer,
  QuickSearchTitleRenderer,
  AdvancedSearchColumn,
  AdvancedSearchRow,
  AdvancedSearchRowColumn,
  AdvancedSearchRowShortColumn,
  AdvancedSearchHeaderRow,
  QuickSearchRow,
  QuickSearchSubTitleRenderer
};
