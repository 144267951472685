import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchResults: [],
  searchResultsPIDs: [],
  totalCount: null,
  page: 0,
  pageSize: 10,
  sortOrder: '0',
  selectedIDs: [],
  currentViewIndex: -1,
  searchOptions: {},
  searchType: 0,
  QSCriteria: {
    Keyword: '',
    SearchBy: 23,
    SearchFor: -1,
    SearchIn: -1
  },
  ASCriteria: {
    SearchIn: '1',

    Keyword1: '',
    SearchBy1: "23",
    SearchAnd1: "1",

    Keyword2: '',
    SearchBy2: "15",
    SearchAnd2: "1",

    Keyword3: '',
    SearchBy3: "10",
    SearchAnd3: "1",

    Keyword4: '',
    SearchBy4: "7",
    SearchAnd4: "1",

    Keyword5: '',
    SearchBy5: "81",

    DeweyFrom: '',
    DeweyTo: '',

    Taxonomy: '',
    Audience: '0',
    Awards: '',

    IssueMonthFrom: -1,
    IssueMonthTo: -1,
    IssueYearFrom: -1,
    IssueYearTo: -1,

    PublicationMonthFrom: -1,
    PublicationMonthTo: -1,
    PublicationYearFrom: -1,
    PublicationYearTo: -1,

    GradeFrom: -2,
    GradeTo: -2,

    IsStarredReview: false,
    IsOnlineOnly: false,
    IsYaRecommended: false,
    IsFirstNovel: false,
    IsPictureBook: false,
    IsEasyReader: false,
    IsEbook: false,
    IsClassics: false,
    IsPodcast: false,
    NumOfResultsReturned: 0
  },
};

const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setQSKeyword: (state, action) => {
      const { payload } = action;
      state.QSCriteria.Keyword = payload;
    },
    setQSSearchBy: (state, action) => {
      const { payload } = action;
      state.QSCriteria.SearchBy = payload;
    },
    setQSSearchFor: (state, action) => {
      const { payload } = action;
      state.QSCriteria.SearchFor = payload;
    },
    setQSSearchIn: (state, action) => {
      const { payload } = action;
      state.QSCriteria.SearchIn = payload;
    },
    setASCriteria: (state, action) => {
      const { payload } = action;
      state.ASCriteria = payload;
    },

    setASSearchIn: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchIn = payload;
    },

    setASKeyword1: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Keyword1 = payload;
    },
    setASSearchBy1: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchBy1 = payload;
    },
    setASSearchAnd1: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchAnd1 = payload;
    },

    setASKeyword2: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Keyword2 = payload;
    },
    setASSearchBy2: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchBy2 = payload;
    },
    setASSearchAnd2: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchAnd2 = payload;
    },

    setASKeyword3: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Keyword3 = payload;
    },
    setASSearchBy3: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchBy3 = payload;
    },
    setASSearchAnd3: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchAnd3 = payload;
    },

    setASKeyword4: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Keyword4 = payload;
    },
    setASSearchBy4: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchBy4 = payload;
    },
    setASSearchAnd4: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchAnd4 = payload;
    },

    setASKeyword5: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Keyword5 = payload;
    },
    setASSearchBy5: (state, action) => {
      const { payload } = action;
      state.ASCriteria.SearchBy5 = payload;
    },

    setASDeweyFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.DeweyFrom = payload;
    },
    setASDeweyTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.DeweyTo = payload;
    },

    setASTaxonomy: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Taxonomy = payload;
    },
    setASAudience: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Audience = payload;
    },
    setASAwards: (state, action) => {
      const { payload } = action;
      state.ASCriteria.Awards = payload;
    },

    setASIssueMonthFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IssueMonthFrom = payload;
    },
    setASIssueMonthTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IssueMonthTo = payload;
    },
    setASIssueYearFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IssueYearFrom = payload;
    },
    setASIssueYearTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IssueYearTo = payload;
    },

    setASPublicationMonthFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.PublicationMonthFrom = payload;
    },
    setASPublicationMonthTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.PublicationMonthTo = payload;
    },
    setASPublicationYearFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.PublicationYearFrom = payload;
    },
    setASPublicationYearTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.PublicationYearTo = payload;
    },

    setASGradeFrom: (state, action) => {
      const { payload } = action;
      state.ASCriteria.GradeFrom = payload;
    },
    setASGradeTo: (state, action) => {
      const { payload } = action;
      state.ASCriteria.GradeTo = payload;
    },

    setASIsStarredReview: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsStarredReview = payload;
    },
    setASIsOnlineOnly: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsOnlineOnly = payload;
    },
    setASIsYaRecommended: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsYaRecommended = payload;
    },
    setASIsFirstNovel: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsFirstNovel = payload;
    },
    setASIsPictureBook: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsPictureBook = payload;
    },
    setASIsEasyReader: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsEasyReader = payload;
    },
    setASIsEbook: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsEbook = payload;
    },
    setASIsClassics: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsClassics = payload;
    },
    setASIsPodcast: (state, action) => {
      const { payload } = action;
      state.ASCriteria.IsPodcast = payload;
    },
    setASClear: (state, action) => {
      const { payload } = action;
      state.ASCriteria = initialState.ASCriteria;
    },
    setSearchOptions: (state, action) => {
      const { payload } = action;
      state.page = payload.page || 0;
      state.pageSize = payload.pageSize || 10;
      state.sortOrder = payload.sortOrder || '0';
      state.selectedIDs = payload.selectedIDs || [];
      state.searchOptions = payload.searchOptions;
      state.searchType = payload.searchType;
      state.totalCount = payload.totalCount;
      state.currentViewIndex = -1;
    },
    setSearchSize: (state, action) => {
      const { payload } = action;
      state.pageSize = payload.pageSize || 100;
    },
    setNextPage: (state, action) => {
      const { payload } = action;
      state.page = state.page + 1;
    },
    setSortOrder: (state, action) => {
      const { payload } = action;
      state.sortOrder = payload;
    },
    setSearchResults: (state, action) => {
      const { payload } = action;
      state.totalCount = payload.totalCount;
      state.searchResults = payload.searchResults.map((x,i)=> ({...x, fullReview: payload.revs[i]}));
      state.searchResultsPIDs = payload.searchResultsPIDs;
    },
    appendSearchResults: (state, action) => {
      const { payload } = action;
      state.totalCount = payload.totalCount;
      state.searchResults = state.searchResults.concat(payload.searchResults.map((x,i)=> ({...x, fullReview: payload.revs[i]})));
    },
    setSelectAll: (state, action) => {
      const { payload } = action;
      state.selectedIDs = state.searchResultsPIDs;
    },
    setClearSelected: (state, action) => {
      const { payload } = action;
      state.selectedIDs = [];
    },
    setSelectedPID: (state, action) => {
      const { payload } = action;
      const index = state.selectedIDs.indexOf(payload.pid);
      if (index > -1) {
        state.selectedIDs.splice(index, 1);
      }
      else state.selectedIDs.push(payload.pid);
    },
    setFirstViewIndex: (state, action) => {
      const { payload } = action;
      if(state.selectedIDs.length)
        state.currentViewIndex = 0;
      else
        state.currentViewIndex = -1;
    },
    setNextViewIndex: (state, action) => {
      const { payload } = action;
      state.currentViewIndex = state.currentViewIndex === state.selectedIDs.length-1 ? state.selectedIDs.length-1 : state.currentViewIndex + 1;
    },
    setPreviousViewIndex: (state, action) => {
      const { payload } = action;
      state.currentViewIndex = state.currentViewIndex === 0 ? 0 : state.currentViewIndex - 1;
    },
    clearData: () => initialState,
    clearSearchResults: (state, action) => {
      state.searchResults = [];
      state.totalCount = 0;
    },
  },
});

const searchResultsSelectors = {
  getSearchResults: (state) => state.searchResults,
  getPage: (state) => state.page,
  getPageSize: (state) => state.pageSize,
  getSortOrder: (state) => state.sortOrder,
  getSelectedIDs: (state) => state.selectedIDs,
  getCurrentViewPID: (state) => {
    if(state.currentViewIndex !== -1)
      return state.selectedIDs[state.currentViewIndex];
    else
      return undefined;
  },
  getSearchOptions: (state) => state.searchOptions,
  getTotalCount: (state) => state.totalCount,
  getSearchType: (state) => state.searchType,

  getQSKeyword: (state) => state.QSCriteria.Keyword,
  getQSSearchBy: (state) => state.QSCriteria.SearchBy,
  getQSSearchFor: (state) => state.QSCriteria.SearchFor,
  getQSSearchIn: (state) => state.QSCriteria.SearchIn,

  getASSearchCriteria: (state) => state.ASCriteria,

  getASSearchIn: (state) => state.ASCriteria.SearchIn,

  getASKeyword1: (state) => state.ASCriteria.Keyword1,
  getASSearchBy1: (state) => state.ASCriteria.SearchBy1,
  getASSearchAnd1: (state) => state.ASCriteria.SearchAnd1,

  getASKeyword2: (state) => state.ASCriteria.Keyword2,
  getASSearchBy2: (state) => state.ASCriteria.SearchBy2,
  getASSearchAnd2: (state) => state.ASCriteria.SearchAnd2,

  getASKeyword3: (state) => state.ASCriteria.Keyword3,
  getASSearchBy3: (state) => state.ASCriteria.SearchBy3,
  getASSearchAnd3: (state) => state.ASCriteria.SearchAnd3,

  getASKeyword4: (state) => state.ASCriteria.Keyword4,
  getASSearchBy4: (state) => state.ASCriteria.SearchBy4,
  getASSearchAnd4: (state) => state.ASCriteria.SearchAnd4,

  getASKeyword5: (state) => state.ASCriteria.Keyword5,
  getASSearchBy5: (state) => state.ASCriteria.SearchBy5,

  getASDeweyFrom: (state) => state.ASCriteria.DeweyFrom,
  getASDeweyTo: (state) => state.ASCriteria.DeweyTo,

  getASTaxonomy: (state) => state.ASCriteria.Taxonomy,
  getASAudience: (state) => state.ASCriteria.Audience,
  getASAwards: (state) => state.ASCriteria.Awards,


  getASIssueMonthFrom: (state) => state.ASCriteria.IssueMonthFrom,
  getASIssueMonthTo: (state) => state.ASCriteria.IssueMonthTo,
  getASIssueYearFrom: (state) => state.ASCriteria.IssueYearFrom,
  getASIssueYearTo: (state) => state.ASCriteria.IssueYearTo,

  getASPublicationMonthFrom: (state) => state.ASCriteria.PublicationMonthFrom,
  getASPublicationMonthTo: (state) => state.ASCriteria.PublicationMonthTo,
  getASPublicationYearFrom: (state) => state.ASCriteria.PublicationYearFrom,
  getASPublicationYearTo: (state) => state.ASCriteria.PublicationYearTo,

  getASGradeFrom: (state) => state.ASCriteria.GradeFrom,
  getASGradeTo: (state) => state.ASCriteria.GradeTo,

  getASIsStarredReview: (state) => state.ASCriteria.IsStarredReview,
  getASIsOnlineOnly: (state) => state.ASCriteria.IsOnlineOnly,
  getASIsYaRecommended: (state) => state.ASCriteria.IsYaRecommended,
  getASIsFirstNovel: (state) => state.ASCriteria.IsFirstNovel,
  getASIsPictureBook: (state) => state.ASCriteria.IsPictureBook,
  getASIsEasyReader: (state) => state.ASCriteria.IsEasyReader,
  getASIsEbook: (state) => state.ASCriteria.IsEbook,
  getASIsClassics: (state) => state.ASCriteria.IsClassics,
  getASIsPodcast: (state) => state.ASCriteria.IsPodcast,

};
const searchResultsActions = searchResultsSlice.actions;

export default searchResultsSlice;
export { searchResultsSelectors, searchResultsActions };
