import http from '@Utils/network';

const GET_HIGHDEMANDS_ENDPOINT = () => `api/EusAdmin/HighDemands`;
const PUT_HIGHDEMANDS_SORT_ENDPOINT = () => `api/EusAdmin/UpdateHighDemandSorting`;
const POST_HIGHDEMANDS_ENDPOINT = () => `api/EusAdmin/CreateHighDemand`;
const PUT_HIGHDEMANDS_ENDPOINT = () => `api/EusAdmin/UpdateHighDemand`;
const DEL_HIGHDEMANDS_ENDPOINT = (Id) => `api/EusAdmin/DeleteHighDemand/${Id}`;

const getHighDemands = async () => {
    const curIssues = await http.get(GET_HIGHDEMANDS_ENDPOINT());
    return curIssues;
};

const putHighDemandsSorting = async (data) => {
    const retVal = await http.put(PUT_HIGHDEMANDS_SORT_ENDPOINT(), data);
    return retVal;
};

const postHighDemands = async () => {
    const retVal = await http.post(POST_HIGHDEMANDS_ENDPOINT());
    return retVal;
};

const putHighDemands = async (data) => {
    const retVal = await http.put(PUT_HIGHDEMANDS_ENDPOINT(), data);
    return retVal;
};

const delHighDemands = async (Id) => {
    const retVal = await http.delete(DEL_HIGHDEMANDS_ENDPOINT(Id));
    return retVal;
};

export { getHighDemands, putHighDemandsSorting, postHighDemands, putHighDemands, delHighDemands };