import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import {
  RecentOnlineReviewsHeader,
  RecentOnlineReviewsRenderer,
  RecentTopImage,
  RecentTopItem,
  RecentTopLink,
  RecentTopMain,
  RecentTopRenderer,
  RecentTopSide,
  RecentTopSideLink, RecentTopSub, RecentTopSubImage, RecentTopSubItem,
  SliderContainerRenderer,
} from '@Components/Pages/Home/RecentOnlineReviews/Renderers';
import 'swiper/css/bundle';

import Typography from '@Components/controls/Typography';
import Slider from "react-slick";
import { Visible } from 'react-grid-system';
import {useHandleLoadHighDemands} from "@Api/hooks/highDemands";
import {useSelector} from "react-redux";
import {highDemandsInterface, searchResultsInterface, topFeaturesInterface} from "@Store";
import {Skeleton} from "antd";
import {useHandleLoadTopFeatures} from "@Api/hooks/topFeatures";

import {groupBy, orderBy} from 'lodash';
import {Link, useNavigate} from "react-router-dom";
import SignUpArea from "@Components/Pages/Home/SignUpArea";
import {
  ADContainerRenderer,
  FullPageContainerRenderer,
  SiteInfoPageContainerRenderer
} from "@Components/Pages/SiteInfoPage/Renderers";
import SideAD2 from "@Images/Samples/SideAD2.png";
import SideAD1 from "@Images/Samples/SideAD1.png";
import {SignUpAreaRenderer} from "@Components/Pages/Home/SignUpArea/Renderers";
import {ROUTES} from "@Utils/routing";

const RecentOnlineReviews = () => {
  const CoverImage = 'http://teststore.coetruman.com/assets/images/835fb481ba278cbd6e7cca6c16284f0f-Cover.png';
  const CoverImage2 = 'images/BOL_JacketTemporarilyUnavailable.png';
  const [loadHDData, isHDLoading] = useHandleLoadHighDemands(false);
  const highDemands = useSelector(highDemandsInterface.getCurrentHighDemands);
  const navigate = useNavigate();

  const [loadTFData, isTFLoading] = useHandleLoadTopFeatures(false);
  const topFeatures = useSelector(topFeaturesInterface.getCurrentTopFeatures);
  const topFeaturesRL = useSelector(topFeaturesInterface.getCurrentTopFeaturesWithRowLayout);
  const topFeaturesCL = useSelector(topFeaturesInterface.getCurrentTopFeaturesWithColumnLayout);

  const groupedBySessionRL = groupBy(topFeaturesRL, (i) => i.ItemGroup);
  const groupedBySessionCL = groupBy(topFeaturesCL, (i) => i.ItemGroup);

  const maxItems = highDemands ? highDemands.length : 0;

  const slideSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 300,
    slidesToShow: maxItems > 6 ? 6 : maxItems-1,
    slidesToScroll: maxItems > 6 ? 6 : maxItems-1,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: maxItems > 8 ? 8 : maxItems-1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: maxItems > 8 ? 8 : maxItems-1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: maxItems > 7 ? 7 : maxItems-1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: maxItems > 6 ? 6 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: maxItems > 5 ? 5 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: maxItems > 4 ? 4 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: maxItems > 4 ? 4 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: maxItems > 4 ? 4 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: maxItems > 3 ? 3 : maxItems-1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxItems > 2 ? 2 : maxItems-1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');

    if(targetLink && targetLink.pathname.startsWith('/PreProgrammedSearch')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { preprogram: searchParams[1] }, searchType: 5, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.href.indexOf('/products/') === -1 && targetLink.href.indexOf('pid=') > -1) {
      e.preventDefault();
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    }

    if(targetLink && targetLink.host === 'www.booklistonline.com') {
      e.preventDefault();
      navigate(targetLink.pathname + targetLink.search);
    }

    if(!targetLink || targetLink.host !== window.location.host) return;
    e.preventDefault();

    if(targetLink.href.indexOf('pid=') > -1) {
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    } else {
      navigate(targetLink.href.replace(/^(?:\/\/|[^/]+)*\//, '/'));
    }
  };

  return (
    <>
      {isHDLoading && (<Skeleton active />)}
      {(!isHDLoading && highDemands && highDemands.length > 0) && (
          <RecentOnlineReviewsRenderer>
            <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro" textAlign="center">
              New High-Demand Titles
            </Typography>
            <SliderContainerRenderer>
              <Slider {...slideSettings}>
                {highDemands.map(issue => {
                  return (
                      <div>
                        <Link to={issue.ItemLink && issue.ItemLink.startsWith('http') ? issue.ItemLink : `/products/${issue.ItemPID}`} >
                        <img height={200} src={issue.ItemImageUrl === CoverImage && issue.ItemHeroShot && issue.ItemHeroShot !== CoverImage2 ? issue.ItemHeroShot : issue.ItemImageUrl} />
                        </Link>
                      </div>
                  )
                })}
              </Slider>
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div style={{
                  width: 'fit-content'
                }} className="swiper-custom-pagination"/>
              </div>
            </SliderContainerRenderer>
          </RecentOnlineReviewsRenderer>
      )}
      <SignUpArea />
      {isTFLoading && (<Skeleton active />)}
      {(!isTFLoading && topFeatures && topFeatures.length > 0) && (
          <FullPageContainerRenderer>
            <SiteInfoPageContainerRenderer>
              <RecentTopRenderer>
                {(Object.keys(groupedBySessionRL) && Object.keys(groupedBySessionRL).length > 0) && (
                    <RecentTopMain>
                      {Object.keys(groupedBySessionRL).map(key => {
                        return (
                            <>
                              <RecentOnlineReviewsHeader>
                                <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro">
                                  {key}
                                </Typography>
                              </RecentOnlineReviewsHeader>
                              {topFeatures.filter(x=>x.ItemGroup === key).map(issue=>{
                                return (
                                    <RecentTopItem>
                                      <RecentTopLink>
                                        <RecentTopImage>
                                          <Link to={`/products/${issue.ItemPID}`} >
                                            <img height={200} src={issue.ItemImageUrl === CoverImage && issue.Heroshot && issue.Heroshot !== CoverImage2 ? issue.Heroshot : issue.ItemImageUrl} />
                                          </Link>
                                        </RecentTopImage>
                                        <div className={'topRichContent'} onClick={contentClickHandler}  dangerouslySetInnerHTML={{ __html: issue.Imprint.replace(/<img[^>]*>/g,"") }} />
                                      </RecentTopLink>
                                    </RecentTopItem>
                                );
                              })}
                              {(topFeatures && topFeatures.filter(x=>x.ItemGroup === key).length > 0 && topFeatures.filter(x=>x.ItemGroup === key)[0].AdBelow === true) && (
                                  <Visible lg md sm xs>
                                    <SignUpAreaRenderer>
                                      <broadstreet-zone zone-id="72503"></broadstreet-zone>
                                    </SignUpAreaRenderer>
                                  </Visible>
                              )}
                            </>
                        )
                      })}
                    </RecentTopMain>
                )}
                {(Object.keys(groupedBySessionCL) && Object.keys(groupedBySessionCL).length > 0) && (
                    <RecentTopMain>
                      {Object.keys(groupedBySessionCL).map(key => {
                        return (
                            <>
                              <RecentOnlineReviewsHeader>
                                <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro">
                                  {key}
                                </Typography>
                              </RecentOnlineReviewsHeader>
                              <RecentTopSub>
                                {topFeatures.filter(x=>x.ItemGroup === key).map(issue=>{
                                  return (
                                      <RecentTopSubItem>
                                        <RecentTopLink>
                                          <RecentTopSubImage>
                                            <Link to={`/products/${issue.ItemPID}`} >
                                              <img src={issue.ItemImageUrl === CoverImage && issue.Heroshot && issue.Heroshot !== CoverImage2 ? issue.Heroshot : issue.ItemImageUrl} />
                                            </Link>
                                          </RecentTopSubImage>
                                          <div className={'topRichContent'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: issue.Imprint.replace(/<img[^>]*>/g,"") }} />
                                        </RecentTopLink>
                                      </RecentTopSubItem>
                                  );
                                })}
                              </RecentTopSub>
                              {(topFeatures && topFeatures.filter(x=>x.ItemGroup === key).length > 0 && topFeatures.filter(x=>x.ItemGroup === key)[0].AdBelow === true) && (
                                  <Visible lg md sm xs>
                                    <SignUpAreaRenderer>
                                      <broadstreet-zone zone-id="72503"></broadstreet-zone>
                                    </SignUpAreaRenderer>
                                  </Visible>
                              )}
                            </>
                        )
                      })}
                    </RecentTopMain>
                )}
              </RecentTopRenderer>
            </SiteInfoPageContainerRenderer>
            <Visible xxl>
              <ADContainerRenderer>
                <broadstreet-zone></broadstreet-zone>
                <broadstreet-zone></broadstreet-zone>
                <broadstreet-zone></broadstreet-zone>
              </ADContainerRenderer>
            </Visible>
          </FullPageContainerRenderer>

      )}
    </>
  );
};

export default RecentOnlineReviews;
