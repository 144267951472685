import isArray from 'lodash/isArray';

const mergeTaggedLiteral = (strings, ...elements) => {
  let outString = strings;

  if (isArray(outString)) {
    const hangingStringItems = outString.slice(1);
    const accumulatedElements = hangingStringItems.reduce(
      (acc, pathString, index) => {
        return [...acc, elements[index] || '', hangingStringItems[index]];
      },
      [outString[0]]
    );
    outString = accumulatedElements.join('');
  }

  return outString;
};

export { mergeTaggedLiteral };
