import styled from 'styled-components';
import { atMostMD } from '@Utils/themes';

const TopHeaderDesktopRenderer = styled.div`
  flex: 0 1 150px;
  ${atMostMD`
    flex: 0 1 100px;
  `}
  justify-content: space-between;
  display: flex;
`;

export default TopHeaderDesktopRenderer;
