import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { userInterface } from '@Store';
import { GetUserProfile } from '@Api/user';
import {useLocalStorage} from "@Utils/localStorage";

export const useHandleLoadUserProfile = (clearSelf) => {
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const currentUserProfile = useSelector(userInterface.getUserProfile);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const data = await GetUserProfile(lsUserId);
        userInterface.dispatch(userInterface.setUserProfile({userProfile: data}));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) userInterface.dispatch(userInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentUserProfile && lsUserId) loadData();
    }, [currentUserProfile, loadData, lsUserId]);

    return [loadData, isLoading];
};
