import styled, { css } from 'styled-components';

const HGroup = styled.span`
  ${({ overflowStyle }) =>
    overflowStyle &&
    overflowStyle === 'column' &&
    css`
      display: flex;
    `}

  & > * {
    vertical-align: middle;
    ${({ overflowStyle }) =>
      !overflowStyle &&
      css`
        display: inline-flex;
      `}
    margin-left: 12px;
    :first-child {
      margin-left: 0px;
    }
  }
`;

export default HGroup;
