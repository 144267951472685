import styled, { css } from 'styled-components';
import { atMostSM } from '@Utils/themes';

const NavigationItemRenderer = styled.li`
    position: relative;
    transition-property: background-color, color;
    transition-duration: 0.4s;
    text-align: center;

    & > a {
        font-family: 'freight-sans-pro';
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        display: inline-block;
        margin: 16px 16px 0;
        border-bottom: 2px solid white;
        padding: 0 0 3px;
    }
`;

const NavigationSublistRenderer = styled.ul`
  padding: 16px;
  transform-origin: top;
  transition-property: opacity, visibility, transform, max-height, padding;
  transition-duration: 0.4s;
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  background-color: transparent;
  color: white;
`;

const NavigationSubitemRenderer = styled.li`
  margin-bottom: 16px;
  white-space: normal;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    line-height: 130%;
    transition-property: border-bottom;
    transition-duration: 0.4s;
    font-size: 14px;
  }

  &:hover {
    a {
      border-bottom: 3px dotted white;
    }
  }
`;

export { NavigationItemRenderer, NavigationSublistRenderer, NavigationSubitemRenderer };
