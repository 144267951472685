import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  devMode: 0,
  userActive: false,
  userProfile: null,
  readerToken: null,
  ipaddress: null,
  ipauthenticated: false,
  userAccount: null,
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    flipDevMode: (state) => {
      state.devMode ^= 1;
    },
    setUserProfile: (state, action) => {
      const { userProfile } = action.payload;

      state.userProfile = userProfile;
    },
    setReaderToken: (state, action) => {
      const { readerToken } = action.payload;

      state.readerToken = readerToken;
    },
    setUserIPAddress: (state, action) => {
      state.ipaddress = action.payload;
    },
    setUserIPAuthenticated: (state, action) => {
      const { ipAuthenticated } = action.payload;

      state.ipauthenticated = ipAuthenticated;
    },
    setUserAccount: (state, action) => {
      const { account } = action.payload;

      state.userAccount = account;
    },
    clearData: () => initialState,
  },
});
const userSelectors = {
  getDevMode: (state) => !!state.devMode,
  getUserProfile: (state) => state.userProfile,
  getReaderToken: (state) => state.readerToken,
  getUserIP: (state) => state.ipaddress,
  getIPAuthenticated: (state) => state.ipauthenticated,
  getUserAccount: (state) => state.userAccount,
};
const userActions = userSlice.actions;

export default userSlice;
export { userSelectors, userActions };
