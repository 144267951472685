import React from 'react';
import {
    ReviewOfTheDaySide,
    ReviewOfTheDayMain,
    ReviewOfTheDayRenderer,
    ReviewOfTheDaySideLink,
    ReviewOfTheDaySection,
    ReviewOfTheDayHeader,
    ReviewOfTheDayLightHeader,
    FeatureLightHeader,
    ReviewOfTheDaySuperLightHeader,
    FeatureSuperLightHeader, ReviewOfTheDaySuperLightHeaderXL, FeatureSuperLightHeaderXL,
} from '@Components/Pages/Home/ReviewOfTheDay/Renderers';
import Typography from '@Components/controls/Typography';
import Review1 from '@Images/Samples/Review1.png';
import { Visible } from '@Components/containers/Grid';
import { AnnouncementHeader, AnnouncementWrapper } from '@Components/Pages/Home/Announcement/Renderers';
import styled, {css} from "styled-components";
import {useHandleLoadAnnouncement} from "@Api/hooks/announcement";
import {useSelector} from "react-redux";
import {announcementInterface, searchResultsInterface} from "@Store";
import {Skeleton} from "antd";
import {networkBaseUrl} from "@Utils/network";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "@Utils/routing";

const RODContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
  gap: 20px;
`;

const RODColumn = styled.div`
  ${({ flexPerc }) =>
          css`
      flex: ${flexPerc};      
    `}
  display: flex;
  flex-direction: column;
`;

const RODColumnNarrow = styled.div`
  ${({ flexPerc }) =>
          css`
      flex: ${flexPerc};      
    `}
  display: flex;
  flex-direction: column;
  border-left: 1px solid black;
  padding-left: 20px;
`;

const ReviewOfTheDay = () => {
  const [loadData, isLoading] = useHandleLoadAnnouncement(false);
  const dataSource = useSelector(announcementInterface.getCurrentAnnouncement);
    const navigate = useNavigate();

    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');

        if(targetLink && targetLink.pathname.startsWith('/PreProgrammedSearch')) {
            e.preventDefault();

            const searchParams = targetLink.pathname.split("$");
            searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
            searchResultsInterface.dispatch(
                searchResultsInterface.setSearchOptions({ searchOptions: { preprogram: searchParams[1] }, searchType: 5, totalCount: null })
            );
            navigate(ROUTES.searchResutsLazy);
            return;
        }

        if(targetLink && targetLink.href.indexOf('/products/') === -1 && targetLink.href.indexOf('pid=') > -1) {
            e.preventDefault();
            navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
        }

        if(targetLink && targetLink.host === 'www.booklistonline.com') {
            e.preventDefault();
            navigate(targetLink.pathname + targetLink.search);
        }

        if(!targetLink || targetLink.host !== window.location.host) return;
        e.preventDefault();

        if(targetLink.href.indexOf('pid=') > -1) {
            navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
        } else {
            navigate(targetLink.href.replace(/^(?:\/\/|[^/]+)*\//, '/'));
        }
    };

  return (
    <ReviewOfTheDaySection>
      <Visible xl xxl>
        <RODContainer>
          {isLoading && (<Skeleton active />)}
          {(!isLoading && dataSource && dataSource.Announcement && dataSource.Announcement.ItemStatus) && (
              <RODColumn flexPerc={dataSource.Announcement.ColumnWidth}>
                <>
                    {(!isLoading && dataSource && dataSource.Announcement && dataSource.Announcement.Header) && (
                        <AnnouncementHeader>
                            <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro">
                                {dataSource.Announcement.Header}
                            </Typography>
                        </AnnouncementHeader>
                    )}
                  <AnnouncementWrapper>
                    {(dataSource.Announcement.ItemBody) && (
                        <div className={'announcementRichContent'} onClick={contentClickHandler}   dangerouslySetInnerHTML={{ __html: dataSource.Announcement.ItemBody }} />
                    )}
                  </AnnouncementWrapper>
                </>
              </RODColumn>
          )}
          {(!isLoading && dataSource && dataSource.ReviewOfTheDay && dataSource.ReviewOfTheDay.ItemStatus) && (
              <RODColumnNarrow flexPerc={100 - dataSource.Announcement.ColumnWidth}>
                  {(!isLoading && dataSource && dataSource.ReviewOfTheDay && dataSource.ReviewOfTheDay.Header) && (
                      <ReviewOfTheDayLightHeader>
                          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                              {dataSource.ReviewOfTheDay.Header}
                          </Typography>
                      </ReviewOfTheDayLightHeader>
                  )}
                  {(!isLoading && dataSource && dataSource.ReviewOfTheDay && !dataSource.ReviewOfTheDay.Header) && (
                      <ReviewOfTheDaySuperLightHeaderXL />
                  )}
                {(dataSource.rodDetails.Image) && (
                    <Visible xs sm>
                      <img src={networkBaseUrl + 'Content/Images/' + dataSource.rodDetails.Image} />
                    </Visible>
                )}
                <ReviewOfTheDayRenderer>
                  {(dataSource.rodDetails.Image) && (
                      <Visible md lg xl xxl>
                        <img src={networkBaseUrl + 'Content/Images/' + dataSource.rodDetails.Image} />
                      </Visible>
                  )}
                  <ReviewOfTheDayMain>
                    {(dataSource.ReviewOfTheDay.ItemBody) && (
                        <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.ReviewOfTheDay.ItemBody }} />
                    )}
                    {(!dataSource.ReviewOfTheDay.ItemBody && dataSource.rodDetails && dataSource.rodDetails.Review ) && (
                        <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.rodDetails.Review }} />
                    )}
                  </ReviewOfTheDayMain>
                </ReviewOfTheDayRenderer>


                  {(!isLoading && dataSource && dataSource.Feature1 && dataSource.Feature1.Header) && (
                      <FeatureLightHeader>
                          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                              {dataSource.Feature1.Header}
                          </Typography>
                      </FeatureLightHeader>
                  )}
                  {(!isLoading && dataSource && dataSource.Feature1 && !dataSource.Feature1.Header) && (
                      <FeatureSuperLightHeaderXL />
                  )}
                  {(dataSource.Feature1Details.Image) && (
                      <Visible xs sm>
                          <img src={networkBaseUrl + 'Content/Images/' + dataSource.Feature1Details.Image} />
                      </Visible>
                  )}
                  <ReviewOfTheDayRenderer>
                      <ReviewOfTheDayMain>
                          {(dataSource.Feature1.ItemBody) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature1.ItemBody }} />
                          )}
                          {(!dataSource.Feature1.ItemBody && dataSource.Feature1Details && dataSource.Feature1Details.Review ) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature1Details.Review }} />
                          )}
                      </ReviewOfTheDayMain>
                  </ReviewOfTheDayRenderer>

                  {(!isLoading && dataSource && dataSource.Feature2 && dataSource.Feature2.Header) && (
                      <FeatureLightHeader>
                          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                              {dataSource.Feature2.Header}
                          </Typography>
                      </FeatureLightHeader>
                  )}
                  {(!isLoading && dataSource && dataSource.Feature2 && !dataSource.Feature2.Header) && (
                      <FeatureSuperLightHeaderXL />
                  )}
                  {(dataSource.Feature2Details.Image) && (
                      <Visible xs sm>
                          <img src={networkBaseUrl + 'Content/Images/' + dataSource.Feature2Details.Image} />
                      </Visible>
                  )}
                  <ReviewOfTheDayRenderer>
                      <ReviewOfTheDayMain>
                          {(dataSource.Feature2.ItemBody) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature2.ItemBody }} />
                          )}
                          {(!dataSource.Feature2.ItemBody && dataSource.Feature1Details && dataSource.Feature1Details.Review ) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature2Details.Review }} />
                          )}
                      </ReviewOfTheDayMain>
                  </ReviewOfTheDayRenderer>
              </RODColumnNarrow>
          )}
        </RODContainer>
      </Visible>
      <Visible xs sm md lg>
        {(!isLoading && dataSource && dataSource.Announcement && dataSource.Announcement.ItemStatus) && (
            <>
                {(!isLoading && dataSource && dataSource.Announcement && dataSource.Announcement.Header) && (
                    <AnnouncementHeader>
                        <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                            {dataSource.Announcement.Header}
                        </Typography>
                    </AnnouncementHeader>
                )}
              <AnnouncementWrapper>
                {(dataSource.Announcement.ItemBody) && (
                    <div className={'announcementRichContent'} onClick={contentClickHandler}  dangerouslySetInnerHTML={{ __html: dataSource.Announcement.ItemBody }} />
                )}
              </AnnouncementWrapper>
            </>
        )}
        {(!isLoading && dataSource && dataSource.ReviewOfTheDay && dataSource.ReviewOfTheDay.ItemStatus) && (
            <>
                {(!isLoading && dataSource && dataSource.ReviewOfTheDay && dataSource.ReviewOfTheDay.Header) && (
                    <ReviewOfTheDayHeader>
                        <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                            {dataSource.ReviewOfTheDay.Header}
                        </Typography>
                    </ReviewOfTheDayHeader>
                )}
                {(!isLoading && dataSource && dataSource.ReviewOfTheDay && !dataSource.ReviewOfTheDay.Header) && (
                    <ReviewOfTheDaySuperLightHeader />
                )}
              <ReviewOfTheDayRenderer>
                {(dataSource.rodDetails.Image) && (
                    <Visible xs sm md lg xl xxl>
                      <img src={networkBaseUrl + 'Content/Images/' + dataSource.rodDetails.Image} />
                    </Visible>
                )}
                <ReviewOfTheDayMain>
                  {(dataSource.ReviewOfTheDay.ItemBody) && (
                      <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.ReviewOfTheDay.ItemBody }} />
                  )}
                  {(!dataSource.ReviewOfTheDay.ItemBody && dataSource.rodDetails && dataSource.rodDetails.Review ) && (
                      <div className={'richContentRODFeatures'} onClick={contentClickHandler}  dangerouslySetInnerHTML={{ __html: dataSource.rodDetails.Review }} />
                  )}
                </ReviewOfTheDayMain>
              </ReviewOfTheDayRenderer>
            </>
        )}
          {(!isLoading && dataSource && dataSource.Feature1 && dataSource.Feature1.ItemStatus) && (
              <>
                  {(!isLoading && dataSource && dataSource.Feature1 && dataSource.Feature1.Header) && (
                      <ReviewOfTheDayHeader>
                          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                              {dataSource.Feature1.Header}
                          </Typography>
                      </ReviewOfTheDayHeader>
                  )}
                  {(!isLoading && dataSource && dataSource.Feature1 && !dataSource.Feature1.Header) && (
                      <FeatureSuperLightHeader />
                  )}
                  <ReviewOfTheDayRenderer>
                      <ReviewOfTheDayMain>
                          {(dataSource.Feature1.ItemBody) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature1.ItemBody }} />
                          )}
                          {(!dataSource.Feature1.ItemBody && dataSource.Feature1Details && dataSource.Feature1Details.Review ) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler}  dangerouslySetInnerHTML={{ __html: dataSource.Feature1Details.Review }} />
                          )}
                      </ReviewOfTheDayMain>
                  </ReviewOfTheDayRenderer>
              </>
          )}
          {(!isLoading && dataSource && dataSource.Feature2 && dataSource.Feature2.ItemStatus) && (
              <>
                  {(!isLoading && dataSource && dataSource.Feature2 && dataSource.Feature2.Header) && (
                      <ReviewOfTheDayHeader>
                          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                              {dataSource.Feature2.Header}
                          </Typography>
                      </ReviewOfTheDayHeader>
                  )}
                  {(!isLoading && dataSource && dataSource.Feature2 && !dataSource.Feature2.Header) && (
                      <FeatureSuperLightHeader />
                  )}
                  <ReviewOfTheDayRenderer>
                      <ReviewOfTheDayMain>
                          {(dataSource.Feature2.ItemBody) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: dataSource.Feature2.ItemBody }} />
                          )}
                          {(!dataSource.Feature2.ItemBody && dataSource.Feature2Details && dataSource.Feature2Details.Review ) && (
                              <div className={'richContentRODFeatures'} onClick={contentClickHandler}  dangerouslySetInnerHTML={{ __html: dataSource.Feature2Details.Review }} />
                          )}
                      </ReviewOfTheDayMain>
                  </ReviewOfTheDayRenderer>
              </>
          )}
      </Visible>
    </ReviewOfTheDaySection>
  );
};

export default ReviewOfTheDay;
