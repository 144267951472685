import axios from 'axios';

// const networkBaseUrl = 'http://pubng.coetruman.com/';
// const networkBaseUrl = 'http://localhost:49995/';
const networkBaseUrl = 'https://pub.booklistonline.com/';

// const samlSsoUrl = 'https://localhost:44319/Saml/InitiateSingleSignOn';
const samlSsoUrl = 'https://bolapi.booklistonline.com/Saml/InitiateSingleSignOn';

const fileUploadUrl = networkBaseUrl + 'api/EusAdmin/UploadFile';

const pubhttp = axios.create({
  // baseURL: 'http://pubng.coetruman.com/',
  baseURL: 'http://localhost:5154/',
  // baseURL: 'https://pub.booklistonline.com/',
  headers: { 'Content-Type': 'application/json' },
  timeout: 50000,
});

const http = axios.create({
  // baseURL: 'http://pubng.coetruman.com/',
  // baseURL: 'http://localhost:49995/',
  baseURL: 'https://pub.booklistonline.com/',
  headers: { 'Content-Type': 'application/json' },
  timeout: 50000,
});

export { networkBaseUrl, fileUploadUrl, samlSsoUrl, pubhttp }

export default http;
