import { ISvgComponents } from './types';
// @ts-ignore
import Logo, { ReactComponent as LogoSvgComponent } from './logo.svg';
// @ts-ignore
import TestAD from './TestAD2.jpg';
// @ts-ignore
import Menu, { ReactComponent as MenuSvgComponent } from './Menu.svg';
// @ts-ignore
import Search, { ReactComponent as SearchSvgComponent } from './Search.svg';
// @ts-ignore
import SMFacebook, { ReactComponent as SMFacebookSvgComponent } from './SMFacebook.svg';
// @ts-ignore
import SMInstagram, { ReactComponent as SMInstagramSvgComponent } from './SMInstagram.svg';
// @ts-ignore
import SMLinkedIn, { ReactComponent as SMLinkedInSvgComponent } from './SMLinkedIn.svg';
// @ts-ignore
import SMTwitter, { ReactComponent as SMTwitterSvgComponent } from './SMTwitter.svg';
// @ts-ignore
import SMBluesky, { ReactComponent as SMBlueskySvgComponent } from './SMBluesky.svg';
// @ts-ignore
import SMYoutube, { ReactComponent as SMYoutubeSvgComponent } from './SMYoutube.svg';
// @ts-ignore
import Login, { ReactComponent as LoginSvgComponent } from './Login.svg';

const images = {
  Logo,
  TestAD,
  Menu,
  Search,
  SMFacebook,
  SMLinkedIn,
  SMTwitter,
  SMBluesky,
  SMYoutube,
  SMInstagram,
  Login,
};

export const svgComponents = {
  LogoSvgComponent,
  MenuSvgComponent,
  SearchSvgComponent,
  SMFacebookSvgComponent,
  SMLinkedInSvgComponent,
  SMTwitterSvgComponent,
  SMBlueskySvgComponent,
  SMYoutubeSvgComponent,
  SMInstagramSvgComponent,
  LoginSvgComponent,
};

export default images;
