import React from 'react';
import * as propTypes from 'prop-types';
import styled from 'styled-components';

const PageMessageRenderer = styled.div`
  background: red;
  margin: 20px -88px 0 -88px;
  padding: 18px;
  /* This is to increase specificity for the flex:0 rule for our component */
  &&& {
    flex: 0;
  }
`;

const PageMessage = ({ message = ' message comes here ' }) => {
  return <PageMessageRenderer>{message}</PageMessageRenderer>;
};

PageMessage.propTypes = {
  message: propTypes.string,
};

export default PageMessage;
