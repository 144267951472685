import http from '@Utils/network';

const PRINTSUBSCRIBE_ENDPOINT = `api/EusSubscr/RegisterPrintSubscriber`;
const TRIALREG_ENDPOINT = `api/EusSubscr/TrialReg`;
const RESETPWD_ENDPOINT = `api/EusSubscr/ResetPassword`;
const SAVEPROFILE_ENDPOINT = `api/EusSubscr/UpdateProfile`;
const AUTHENTICATE_ENDPOINT = `api/Eusservice/Authenticate`;
const AUTHENTICATEIP_ENDPOINT = `api/Eusservice/AuthenticateIP`;
const AUTHENTICATEREFURL_ENDPOINT = `api/Eusservice/AuthenticateRefUrl`;
const AUTHENTICATELIBCARD_ENDPOINT = `api/Eusservice/AuthenticateCard`;
const GET_USERPROFILE_ENDPOINT = (userId) => `api/Eusservice/GetUserProfile?userId=${userId}`;
const GET_READERTOKEN_ENDPOINT = (accountId) => `api/Eusservice/GetReaderAccessLink?accountId=${accountId}`;


const SaveProfile = async (loginOptions) => {
    const {
        firstName,
        lastName,
        password,
        passwordRepeat,
        email,
        zipCode,
        profession,
        userName,
        printNumber,
        AlertsFirstName1,
        AlertsLastName1,
        AlertsFirstName2,
        AlertsLastName2,
        AlertsFirstName3,
        AlertsLastName3,
        AlertsFirstName4,
        AlertsLastName4,
        AlertsFirstName5,
        AlertsLastName5,
        PreferredQuickSearchKeywordType,
        PreferredQuickSearchScope,
        PreferredQuickSearchSections,
        PreferredQuickSearchSortOrder,
        lsUserId,
        lsAccountId
    } = loginOptions;

    const postData = {
        userId: lsUserId,
        accountId: lsAccountId,
        firstName: firstName,
        lastName: lastName,
        profession: profession,
        email: email,
        zip: zipCode,
        username: userName,
        password: password,
        printSubscriptionNum: printNumber,
        passwordRepeat: password,
        alertsFname1: AlertsFirstName1,
        alertsLname1: AlertsLastName1,
        alertsFname2: AlertsFirstName2,
        alertsLname2: AlertsLastName2,
        alertsFname3: AlertsFirstName3,
        alertsLname3: AlertsLastName3,
        alertsFname4: AlertsFirstName4,
        alertsLname4: AlertsLastName4,
        alertsFname5: AlertsFirstName5,
        alertsLname5: AlertsLastName5,
        searchKeywordType: PreferredQuickSearchKeywordType,
        searchScope: PreferredQuickSearchScope,
        searchSections: PreferredQuickSearchSections,
        searchSort: PreferredQuickSearchSortOrder
    }
    const { data } = await http.post(SAVEPROFILE_ENDPOINT, postData);
    return data;
};

const PrintSubscription = async (loginOptions) => {
    const { data } = await http.post(PRINTSUBSCRIBE_ENDPOINT, loginOptions);
    return data;
};

const ResetUserPassword = async (loginOptions) => {
    const { data } = await http.post(RESETPWD_ENDPOINT, loginOptions);
    return data;
};

const TrialReg = async (loginOptions) => {
    const { data } = await http.post(TRIALREG_ENDPOINT, loginOptions);
    return data;
};

const GetUserProfile = async (userId) => {
    const { data } = await http.get(GET_USERPROFILE_ENDPOINT(userId));
    return data;
};

const GetUserIP = async () => {
    const { data } = await http.get("https://api.ipify.org?format=json");
    return data.ip;
};

const GetReaderToken = async (accountId) => {
    const { data } = await http.get(GET_READERTOKEN_ENDPOINT(accountId));
    return data;
};

const AuthenticateBOL = async (loginOptions) => {
    const { userName, password } = loginOptions;
    const requestParams = {
        userName,
        password
    };

    const { data } = await http.post(AUTHENTICATE_ENDPOINT, requestParams);
    return data;
};

const AuthenticateIP = async (loginOptions) => {
    const { ipaddress } = loginOptions;
    const requestParams = {
        userip: ipaddress,
    };

    const { data } = await http.post(AUTHENTICATEIP_ENDPOINT, requestParams);
    return data;
};

const AuthenticateRefUrl = async (loginOptions) => {
    const { useraccid, userrefurl } = loginOptions;
    const requestParams = {
        useraccid: useraccid,
        userrefurl: userrefurl
    };

    const { data } = await http.post(AUTHENTICATEREFURL_ENDPOINT, requestParams);
    return data;
};

const AuthenticateLibCard = async (loginOptions) => {
    const { useraccid, usercard } = loginOptions;
    const requestParams = {
        useraccid: useraccid,
        usercard: usercard
    };

    const { data } = await http.post(AUTHENTICATELIBCARD_ENDPOINT, requestParams);
    return data;
};

export { AuthenticateBOL, GetUserProfile, GetReaderToken, GetUserIP, AuthenticateIP, AuthenticateRefUrl, AuthenticateLibCard, PrintSubscription, SaveProfile,
    TrialReg, ResetUserPassword};
