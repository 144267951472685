import React from 'react';
import { useSelector } from 'react-redux';
import { appInterface } from '@Store';
import { OverlayRenderer } from '@Components/controls/Overlay/Renderers';

const Overlay = () => {
  const isOpen = useSelector(appInterface.getSideMenuOpen);

  return <OverlayRenderer isOpen={isOpen} />;
};

export default Overlay;
