import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { searchResultsInterface } from '@Store';
import {
    advancedSearchV2,
    authorSearchV2, bcpiSearchV2,
    browseSearchV2,
    listSearchV2,
    preprogrammedSearchV2,
    quickSearchV2,
    issueSearchV2, readerSearchV2
} from '@Api/search';

export const useHandleLoadSearchResultsV2 = () => {
    const items = useSelector(searchResultsInterface.getSearchResults);
    const totalCount = useSelector(searchResultsInterface.getTotalCount);
    const page = useSelector(searchResultsInterface.getPage);
    const pageSize = useSelector(searchResultsInterface.getPageSize);
    const sortOrder = useSelector(searchResultsInterface.getSortOrder);
    const selectedIDs = useSelector(searchResultsInterface.getSelectedIDs);
    const searchOptions = useSelector(searchResultsInterface.getSearchOptions);
    const asCriteria = useSelector(searchResultsInterface.getASSearchCriteria);
    const searchType = useSelector(searchResultsInterface.getSearchType);

    const loadedCount = items.length;

    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        let retData = [];
        if(searchType === 1) {
            retData = await quickSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 2) {
            retData = await browseSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 3) {
            retData = await advancedSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...asCriteria,
            });
        }

        if(searchType === 4) {
            retData = await listSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 5) {
            let mgid = 0;
            let mgName = '';
            if(searchOptions.preprogram === '6') {
                mgid = "102";
                mgName = " ";
            }
            if(searchOptions.preprogram === '7') {
                mgid = "144";
                mgName = " ";
            }
            if(searchOptions.preprogram === '8') {
                mgid = "162";
                mgName = " ";
            }
            if(searchOptions.preprogram === '9') {
                mgid = "194";
                mgName = " ";
            }
            if(searchOptions.preprogram === '10') {
                mgid = "206,233";
                mgName = " ";
            }
            if(searchOptions.preprogram === '11') {
                mgid = "249,273";
                mgName = "";
            }
            retData = await preprogrammedSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                mgid,
                mgName
            });
        }

        if(searchType === 6) {
            retData = await authorSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 7) {
            retData = await bcpiSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 8) {
            retData = await issueSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 9) {
            retData = await readerSearchV2({
                skip: loadedCount,
                take: pageSize,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        const { totalCount, searchResults } = retData;
        if(page > 0)
            searchResultsInterface.dispatch(searchResultsInterface.appendSearchResults({ totalCount, searchResults: searchResults.SearchResults, revs:searchResults.Revs }));
        else
            searchResultsInterface.dispatch(searchResultsInterface.setSearchResults({ totalCount, searchResults: searchResults.SearchResults, searchResultsPIDs: searchResults.SearchResultsPIDs, revs:searchResults.Revs }));

        setDataLoading(false);
    }, [page, pageSize, sortOrder, searchOptions, loadedCount, searchType]);

    const loadAllData = useCallback(async () => {
        setDataLoading(true);
        let retData = [];
        if(searchType === 1) {
            retData = await quickSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 2) {
            retData = await browseSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 3) {
            retData = await advancedSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...asCriteria,
            });
        }

        if(searchType === 4) {
            retData = await listSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 5) {
            let mgid = 0;
            let mgName = '';
            if(searchOptions.preprogram === '6') {
                mgid = "102";
                mgName = " ";
            }
            if(searchOptions.preprogram === '7') {
                mgid = "144";
                mgName = " ";
            }
            if(searchOptions.preprogram === '8') {
                mgid = "162";
                mgName = " ";
            }
            if(searchOptions.preprogram === '9') {
                mgid = "194";
                mgName = " ";
            }
            if(searchOptions.preprogram === '10') {
                mgid = "206,233";
                mgName = " ";
            }
            if(searchOptions.preprogram === '11') {
                mgid = "249,273";
                mgName = "";
            }
            retData = await preprogrammedSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                mgid,
                mgName
            });
        }

        if(searchType === 6) {
            retData = await authorSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 7) {
            retData = await bcpiSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 8) {
            retData = await issueSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        if(searchType === 9) {
            retData = await readerSearchV2({
                skip: 0,
                take: 9999,
                sort: sortOrder,
                ...searchOptions,
            });
        }

        const { totalCount, searchResults } = retData;
        // searchResultsInterface.dispatch(searchResultsInterface.setSearchResults({ totalCount, searchResults: searchResults.SearchResults, searchResultsPIDs: searchResults.SearchResultsPIDs }));

        setDataLoading(false);
        return searchResults.SearchResults || [];
    }, [page, pageSize, sortOrder, searchOptions, loadedCount, searchType]);


    useEffect(() => {
        if (totalCount === null || (loadedCount < totalCount && (loadedCount <= (page*pageSize)))) loadData();
    }, [loadedCount, loadData, totalCount, page, pageSize, searchType]);

    return [loadData, isLoading, loadAllData];
};
