import styled from 'styled-components';
import {atMostXL} from "@Utils/themes";
import {atMostLG} from "@Styles/breakpoints";

const AnnouncementRenderer = styled.div`
  max-width: 1000px;
  flex: 0 1 0px;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const AnnouncementHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 35px;
  span {
    flex: 1;
    text-align: center;
    line-height: 50px;
  }
`;

const AnnouncementWrapper = styled.div`
  flex: 1 1 0px;
    ${atMostLG`
    margin-bottom: 24px;
  `}  
  strong {
    font-weight: 600;
  }
`;

export { AnnouncementRenderer, AnnouncementHeader, AnnouncementWrapper };
