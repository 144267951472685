import React, { useCallback, useState } from 'react';
import {
  NavigationItemRenderer,
  NavigationSublistRenderer,
} from '@Components/sections/SideMenu/NavigationArea/NavigationItem/Renderers';
import {Link} from "react-router-dom";
import {appInterface} from "@Store";

const NavigationItem = ({ title, children, itemId, link }) => {
  const [selectedMenu, setSelectedMenu] = useState(false);
  const handleMenuOpenClick = useCallback(() => {
    setSelectedMenu(!selectedMenu);
  }, [selectedMenu, setSelectedMenu]);

  const handleMenuNavClick = useCallback(() => {
    appInterface.dispatch(appInterface.setSideMenuOpen({ sideMenuOpen: false }));
  }, []);

  return (
    <NavigationItemRenderer isSelected={selectedMenu}>
      {link && (
          <Link onClick={handleMenuNavClick} to={link}>{title}</Link>
      )}
      {!link && (
          <a onClick={handleMenuOpenClick}>{title}</a>
      )}
      {children && (
          <NavigationSublistRenderer>{children}</NavigationSublistRenderer>
      )}
    </NavigationItemRenderer>
  );
};

export default NavigationItem;
