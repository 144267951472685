import styled from 'styled-components';

const LoginSearchAreaWrapper = styled.div`
  flex: 0 1 387px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;  
`;

const LoginSearchAreaRenderer = styled.div`
  flex: 0;
  display: flex;
  margin-top: 16px;
  gap: 10px;
  margin-right: 16px;
  align-items: center;
  position: relative;
  &.selected {
    button {
      background-color: black;
      color: white;
    }
    ul {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
`;

const LoginMenuRenderer = styled.ul`
  z-index: 560;
  padding: 16px;
  background-color: white;
  color: black;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top;
  transition-property: opacity, visibility, transform, max-height, padding;
  transition-duration: 0.4s;
  position: absolute;
  right: 0;
  top: 100%;
  width: 210px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid black;
`;

const LoginMenuSubitemRenderer = styled.li`
  margin-bottom: 16px;
  z-index: 555;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    line-height: 130%;
    transition-property: border-bottom;
    transition-duration: 0.4s;
  }

  &:hover {
    a {
      border-bottom: 3px dotted black;
    }
  }
`;

export { LoginSearchAreaRenderer, LoginSearchAreaWrapper, LoginMenuRenderer, LoginMenuSubitemRenderer };
