import React, {useEffect} from 'react';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import {
  EmptyResultsWrapper,
  ResultItem,
  ResultItemBody,
  ResultItemCitation,
  ResultItemHeroshot,
  ResultItemMGPath,
  ResultItemSelect,
  ResultsWrapper,
  SearchResultsPageRenderer,
  SearchSort,
  SearchSummary,
  SearchToolsSelect,
  SearchToolsSend,
} from '@Components/Pages/SearchResults/Renderers';
import { useSelector } from 'react-redux';
import { searchResultsInterface } from '@Store';
import { useHandleLoadSearchResults } from '@Api/hooks/search';
import Loader from '@Components/controls/Loader';
import DOMPurify from 'dompurify';
import Typography from '@Components/controls/Typography';
import Select from 'react-select';
import SelectIcon from '@Images/Select.svg';
import { ReactComponent as ViewSelectedIcon } from '@Images/ViewSelected.svg';
import { ReactComponent as AddToListIcon } from '@Images/AddToList.svg';
import { ReactComponent as DownloadIcon } from '@Images/Download.svg';
import { ReactComponent as PrintIcon } from '@Images/print.svg';
import { ReactComponent as EmailIcon } from '@Images/Email.svg';
import CloseIcon from '@Images/Close.svg';
import SaveIcon from '@Images/Save.svg';
import LoadIcon from '@Images/Load.svg';
import Cover from '@Images/Cover.png';
import { Link } from 'react-router-dom';

import Button from '@Components/controls/Button';

const purifyConfig = {
  FORBID_TAGS: ['br', '{{', 'p', '&nbsp;'],
  SAFE_FOR_TEMPLATES: true,
  RETURN_TRUSTED_TYPE: true,
  ALLOW_DATA_ATTR: false,
  KEEP_CONTENT: true,
};

const citationOptions = [
  { value: 'short', label: 'Citation' },
  { value: 'long', label: 'Citation + Partial text' },
];

const sortOptions = [
  { value: '0', label: 'Date' },
  { value: '1', label: 'Title' },
  { value: '2', label: 'Author/Editor' },
];

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  spacing: {
    baseUnit: 2,
    controlHeight: 20,
    menuGutter: 4,
  },
  colors: {
    ...theme.colors,
    neutral10: 'black',
    neutral20: 'black',
    neutral30: 'black',
    neutral40: 'black',
    primary25: 'lightgrey',
    primary: 'black',
  },
});

const SearchResultsPage = () => {
  const searchResults = useSelector(searchResultsInterface.getSearchResults);
  const totalCount = useSelector(searchResultsInterface.getTotalCount);
  const page = useSelector(searchResultsInterface.getPage);
  const pageSize = useSelector(searchResultsInterface.getPageSize);
  const sortOrder = useSelector(searchResultsInterface.getSortOrder);
  const selectedIDs = useSelector(searchResultsInterface.getSelectedIDs);
  const searchOptions = useSelector(searchResultsInterface.getSearchOptions);
  const loadedCount = searchResults.length;

  const [loadData, isLoading] = useHandleLoadSearchResults();

  useEffect(() => {
    document.title = "Booklist Online: Leading Book Discovery";
  }, []);

  return (
    <ResponsiveLayout>
      <SearchResultsPageRenderer>
        {!isLoading && loadedCount === 0 && (
          <EmptyResultsWrapper>
            <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
              Results are empty
            </Typography>
          </EmptyResultsWrapper>
        )}
        {isLoading && <Loader centered />}
        {!isLoading && loadedCount > 0 && (
          <>
            <SearchSummary>
              <Typography themeColor="lightBlue" font="XXXXL" fontWeight="600">
                {totalCount} Results
              </Typography>
              <Typography themeColor="lightBlue" font="LG">
                Keyword: {searchOptions.keyword}, Keyword in: Title, All Content, All sections
              </Typography>
            </SearchSummary>
            <SearchSort>
              <Typography font="LG" fontWeight="600">
                Display:
              </Typography>
              <Select
                defaultValue={citationOptions.filter((option) => option.value === 'long')}
                theme={selectTheme}
                options={citationOptions}
              />
              <Typography font="LG" fontWeight="600">
                Sort:
              </Typography>
              <Select
                defaultValue={sortOptions.filter((option) => option.value === '0')}
                width="100px"
                theme={selectTheme}
                options={sortOptions}
              />
            </SearchSort>
            <SearchToolsSelect>
              <Button variant="narrow">
                <img width={21} src={SelectIcon} alt="Select" />
                Select All titles
              </Button>
              <Button variant="narrow">
                <img width={21} src={SelectIcon} alt="Select" />
                Select All titles on this Page
              </Button>
              <Button variant="narrow">
                <img width={21} src={CloseIcon} alt="Select" />
                Clear Selected
              </Button>
              <Button variant="narrow">
                <img width={21} src={SaveIcon} alt="Select" />
                Save Search
              </Button>
              <Button variant="narrow">
                <img width={21} src={LoadIcon} alt="Select" />
                Load Search
              </Button>
            </SearchToolsSelect>
            <SearchToolsSend>
              <Button themeColor="white" backgroundColor="black" variant="narrow">
                <ViewSelectedIcon />
                View selected items
              </Button>
              <Button themeColor="white" backgroundColor="black" variant="narrow">
                <AddToListIcon />
                Add To List
              </Button>
              <Button themeColor="white" backgroundColor="black" variant="narrow">
                <DownloadIcon />
                Download
              </Button>
              <Button themeColor="white" backgroundColor="black" variant="narrow">
                <PrintIcon />
                Print
              </Button>
              <Button themeColor="white" backgroundColor="black" variant="narrow">
                <EmailIcon />
                Email
              </Button>
            </SearchToolsSend>
            <ResultsWrapper>
              {searchResults.map((item) => (
                <ResultItem>
                  <ResultItemMGPath>
                    <Typography font="SM" themeColor="grey1" fontWeight="400">
                      {item.TaxonomyPathFormattedNew}
                    </Typography>
                  </ResultItemMGPath>
                  <ResultItemBody>
                    <ResultItemSelect>
                      <input type="checkbox" />
                    </ResultItemSelect>
                    <ResultItemHeroshot>
                      <img src={item.BookImage && item.BookImage.indexOf('http') > -1 ? item.BookImage : Cover} />
                    </ResultItemHeroshot>
                    <ResultItemCitation>
                      <Link to={`/products/${item.ProductId}`}>
                        <Typography font="XL" themeColor="lightBlue" fontWeight="600">
                          {item.Title}
                        </Typography>
                      </Link>
                      <Typography font="SM" themeColor="black" fontWeight="600">
                        {DOMPurify.sanitize(item.PublishedInfo, purifyConfig).toString()}
                      </Typography>
                      <Typography font="SM" themeColor="lightBlue" fontWeight="600">
                        {item._mediaclass}
                      </Typography>
                      <Typography font="SM" themeColor="black" fontWeight="600">
                        {item._issue}
                      </Typography>
                      <Typography font="SM" themeColor="black" fontWeight="400">
                        {DOMPurify.sanitize(item.ReviewInfo, purifyConfig).toString()}
                      </Typography>
                    </ResultItemCitation>
                  </ResultItemBody>
                </ResultItem>
              ))}
            </ResultsWrapper>
          </>
        )}
      </SearchResultsPageRenderer>
    </ResponsiveLayout>
  );
};

export default SearchResultsPage;
