import React, {useCallback} from 'react';
import {
    PrintSubscriber,
    RegisterForFreeRenderer,
    RegisterHeader,
    RegisterMobileHeader,
    RegisterMobileSeparator,
    RegisterMobileSeparatorLines,
    RegisterMobileSubHeader,
    RegisterSubHeader
} from "@Components/Pages/Login/RegisterForFree/Renderers";
import Button from "@Components/controls/Button";
import {Visible} from "react-grid-system";
import {ROUTES} from "@Utils/routing";
import {useNavigate} from "react-router-dom";

const RegisterForFree = () => {

    const navigate = useNavigate();
    const handleRegisterClick = useCallback(() => {
        navigate(ROUTES.trialreg);
    }, []);

    const handlePrintregClick = useCallback(() => {
        navigate(ROUTES.printreg);
    }, []);

    return (
        <RegisterForFreeRenderer>
            <Visible xs sm md lg>
                <RegisterMobileSeparatorLines/>
                <RegisterMobileSeparator>OR</RegisterMobileSeparator>
                <RegisterMobileHeader>Create a Profile</RegisterMobileHeader>
                <RegisterMobileSubHeader>What do I get if I register?</RegisterMobileSubHeader>
            </Visible>
            <Visible xl xxl>
                <RegisterHeader>What do I get if I register?</RegisterHeader>
            </Visible>
            <ul>
                <RegisterSubHeader>Access to additional content</RegisterSubHeader>
                <RegisterSubHeader>Save Searches and Lists</RegisterSubHeader>
                <RegisterSubHeader>Access 30+ Years Reviews and Features</RegisterSubHeader>
            </ul>
            <Button themeColor='darkblue' onClick={handlePrintregClick}>
                Link Print Subscription
            </Button>
            <PrintSubscriber onClick={handleRegisterClick}><span>TAKE A FREE TRIAL &gt;&gt;</span></PrintSubscriber>
        </RegisterForFreeRenderer>
    )
}

export default RegisterForFree