const sliceToInterfaceBuilder = store => (slice, selectors) => ({
    ...slice.actions,
    ...Object.keys(selectors).reduce(
        (acc, selector) => ({
            ...acc,
            [selector]: () => {
                const state = store.getState()
                return selectors[selector](state[slice.name], state)
            }
        }),
        {}
    ),
    dispatch: store.dispatch
});

export { sliceToInterfaceBuilder };
