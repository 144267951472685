import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { savedSearchesInterface } from '@Store';
import {getSavedSearches} from "@Api/savedSearches";


export const useSavedSearches = (clearSelf) => {
    const currentSavedSearches = useSelector(savedSearchesInterface.getSavedSearches);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            savedSearchesInterface.dispatch(savedSearchesInterface.setSavedSearches([]));
        } else {
            const {data} = await getSavedSearches(JSON.parse(uid));
            savedSearchesInterface.dispatch(savedSearchesInterface.setSavedSearches(data));
        }
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) savedSearchesInterface.dispatch(savedSearchesInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentSavedSearches) loadData();
    }, [currentSavedSearches, loadData]);

    return [loadData, isLoading];
};