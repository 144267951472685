import React, {useEffect} from 'react';
import {
    LoginContentBlock, LoginFormBlock, LoginFormColumn,
    LoginHeader,
    LoginHeaderLabel,
    LoginHeaderSep,
    LoginPageRenderer
} from "@Components/Pages/Login/Renderers";
import ResponsiveLayout from "@Components/controls/ResponsiveLayout";
import LoginForm from "@Components/Pages/Login/LoginForm";
import RegisterForFree from "@Components/Pages/Login/RegisterForFree";
import {Visible} from "react-grid-system";

const LoginPage = () => {
    useEffect(() => {
        window.scrollTo(0, 50);
    }, []);

    return (
        <ResponsiveLayout>
            <LoginPageRenderer>
                <LoginContentBlock>
                    <Visible xl xxl>
                        <LoginHeader>
                            <LoginHeaderLabel>Log In</LoginHeaderLabel>
                            <LoginHeaderSep>OR</LoginHeaderSep>
                            <LoginHeaderLabel>Create a Profile</LoginHeaderLabel>
                        </LoginHeader>
                        <LoginFormBlock>
                            <LoginFormColumn>
                                <LoginForm />
                            </LoginFormColumn>
                            <LoginFormColumn>
                                <RegisterForFree />
                            </LoginFormColumn>
                        </LoginFormBlock>
                    </Visible>
                    <Visible xs sm md lg>
                        <LoginFormColumn>
                            <LoginForm />
                        </LoginFormColumn>
                        <LoginFormColumn>
                            <RegisterForFree />
                        </LoginFormColumn>
                        <br />
                        <br />
                    </Visible>
                </LoginContentBlock>
            </LoginPageRenderer>
        </ResponsiveLayout>
    )
}

export default LoginPage;