import React from 'react';
import {
  SignUpAreaRenderer,
  SignUpFooter,
  SignUpFooterLeft,
  SignUpFooterRight,
} from '@Components/Pages/Home/SignUpArea/Renderers';
import Background2 from '@Images/Samples/Background2.png';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';

const SignUpArea = () => {
  return (
    <SignUpAreaRenderer>
      <broadstreet-zone zone-id="72503"></broadstreet-zone>
    </SignUpAreaRenderer>
  );
};

export default SignUpArea;
