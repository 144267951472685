import styled from 'styled-components';

const EmptyResultsWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    flex: 0;
    line-height: 10;
  }
`;

const SearchResultsPageRenderer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1000px;
    align-self: center;
    margin: 32px 12px;
`;

const SearchSummary = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const SearchSort = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchToolsSelect = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchToolsSend = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  flex-wrap: wrap;
`;

const ResultsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ResultItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const ResultItemMGPath = styled.div`
  flex: 1;
  display: flex;
  padding-left: 30px;
  padding-bottom: 5px;
`;
const ResultItemBody = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`;
const ResultItemSelect = styled.div`
    flex: 1;
    display: flex;
    max-width: 30px;
    flex-direction: column;
    align-items: baseline;
`;
const ResultItemHeroshot = styled.div`
  flex: 0 0 130px;
  margin-top: 3px;
  display: flex;
  max-width: 130px;
  img {
    width: 100%;
  }
`;
const ResultItemHeroshotSmall = styled.div`
  flex: 0 0 100px;
  margin-top: 3px;
  display: flex;
  max-width: 100px;
  img {
    width: 100%;
  }
`;

const ResultItemHeroshotPlaceholder = styled.div`
    flex: 1;
    display: flex;
    max-width: 100px;
    border: solid 1px #e6eaf1;
    border-radius: 10px;
    height: 90px;
    justify-content: center;
    align-items: center;
    img {
        width: 50px;
        height: 50px;
    }
`;

const ResultItemCitation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
  a {
    text-decoration: none;
  }
  em {
    font-style: italic;
  }
  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
`;

export {
    SearchResultsPageRenderer,
    EmptyResultsWrapper,
    SearchSummary,
    SearchSort,
    SearchToolsSelect,
    SearchToolsSend,
    ResultsWrapper,
    ResultItem,
    ResultItemMGPath,
    ResultItemBody,
    ResultItemSelect,
    ResultItemHeroshot,
    ResultItemCitation,
    ResultItemHeroshotSmall,
    ResultItemHeroshotPlaceholder
};
