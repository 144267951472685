import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentHighDemands: null,
};

const highDemandsSlice = createSlice({
    name: 'highDemands',
    initialState,
    reducers: {
        setCurrentHighDemands: (state, action) => {
            const { payload } = action;
            state.currentHighDemands = payload;
        },
        clearData: () => initialState,
    },
});

const highDemandsSelectors = {
    getCurrentHighDemands: (state) => state.currentHighDemands,
};
const highDemandsActions = highDemandsSlice.actions;

export default highDemandsSlice;
export { highDemandsSelectors, highDemandsActions };