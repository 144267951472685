import React, {useEffect, useState} from 'react';
import HomePageRenderer from '@Components/Pages/Home/Renderers';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import { useTheme } from '@mui/material/styles';
import {Box, useMediaQuery} from "@mui/material";
import Header from "@Components/Pages/AdminHome/Header";
import {useSelector} from "react-redux";
import {appInterface} from "@Store";
import MainDrawer from "@Components/Pages/AdminHome/Drawer";
import ThemeCustomization from "@Styles/Admin";
import AnnouncementEditor from "@Components/Pages/AdminHome/AnnouncementEditor";
import CurrentIssueEditor from "@Components/Pages/AdminHome/CurrentIssuesEditor";
import HighDemandEditor from "@Components/Pages/AdminHome/HighDemandEditor";
import TopCategoriesEditor from "@Components/Pages/AdminHome/TopCategoriesEditor";
import HighDemandsTableEditor from "@Components/Pages/AdminHome/HighDemandTableEditor";
import TopFeaturesEditor from "@Components/Pages/AdminHome/TopFeaturesEditor";
import {useLocalStorage} from "@Utils/localStorage";
import {ROUTES} from "@Utils/routing";
import {useNavigate} from "react-router-dom";

const AdminHomePage = ({editor}) => {
    const theme = useTheme();
    const drawerOpen = useSelector(appInterface.getOpenDrawer);
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const navigate = useNavigate();

    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        appInterface.dispatch(appInterface.openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if(!lsUserId || lsUserId !== '9e6c1b73-e1cd-4943-be13-edd9e121ca8f') {
            navigate(ROUTES.root);
        }
    }, [lsUserId]);

    useEffect(() => {
        setOpen(!matchDownLG);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        appInterface.dispatch(appInterface.openDrawer({ drawerOpen: !matchDownLG }));
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        <ThemeCustomization>
            <ResponsiveLayout isAdmin>
                <HomePageRenderer>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                        <MainDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
                        <Box sx={{ width: '100%', flexGrow: 1, pr: 2, pl: { sm: 2 }, pt: 7 }}>
                            {editor === 'Announcement' && (
                                <AnnouncementEditor />
                            )}
                            {editor === 'CurrentIssue' && (
                                <CurrentIssueEditor />
                            )}
                            {editor === 'HighDemand' && (
                                <HighDemandsTableEditor />
                            )}
                            {editor === 'TopCategories' && (
                                <TopFeaturesEditor />
                            )}
                        </Box>
                    </Box>
                </HomePageRenderer>
            </ResponsiveLayout>
        </ThemeCustomization>
    );
};

export default AdminHomePage;