import styled, { keyframes } from 'styled-components';
import propTypes from 'prop-types';

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const PlainLoaderSVGRenderer = styled.svg.attrs(({ width = 31, height = 31 }) => ({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 31 31',
  width,
  height,
}))`
  animation: ${animation} 0.7s linear infinite;

  path {
    fill: ${({ fill, theme }) => (!fill ? theme.colors.plainLoader.fillColor : fill)};
  }
`;

PlainLoaderSVGRenderer.propTypes = {
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  height: propTypes.oneOfType([propTypes.string, propTypes.number]),
  fill: propTypes.string,
};

export default PlainLoaderSVGRenderer;
