import React from 'react';
import styled from 'styled-components';
import { atMostLG, atMostSM } from '@Utils/themes';
import NavigationArea from '@Components/sections/Footer/NavigationArea';
import SocialMediaArea from '@Components/sections/Footer/SocialMediaArea';
import MainLogoArea from '@Components/sections/TopHeader/MainLogoArea';
import Button from '@Components/controls/Button';
import {Link} from "react-router-dom";

const FooterWrapper = styled.div`
  padding-top: 24px;
  background-color: black;
`;

const NavigationWrapper = styled.div`
  margin: 0 auto;
  display: block;
  color: white;
  max-width: 1279px;

  ${atMostLG`
    max-width: 768px;
  `}

  ${atMostSM`
    display: none;
  `}
`;

const FooterBottomWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    max-width: 1279px;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 24px;
    justify-content: space-between;

    ${atMostLG`
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    max-width: 768px;
  `}
`;

const FooterSubmenuWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    max-width: 1279px;
    margin: 0 auto;
    padding: 24px 0;
    justify-content: space-between;

    ${atMostLG`
    flex-direction: column;
    padding-top: 56px;
    padding-bottom: 40px;
    max-width: 768px;
  `}
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: calc(100vw - 2 * 18px);
        height: 1px;
        background-color: white;
    }

    a {
        font-family: 'freight-sans-pro';
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        display: inline-block;
        margin: 16px 16px 0;
        padding: 0 0 3px;
    }
`;

const FooterALAWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 24px;

    span:first-child {
        margin-right: 4px;
        font-family: 'Times New Roman', serif;
        font-size: 55px;
    }

    span:not(:nth-child(1)) {
        max-width: min-content;
        font-size: 15px;
    }

    ${atMostLG`
    margin-bottom: 38px;
  `}
`;
const FooterCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
    span {
        font-family: 'freight-sans-pro';
        font-weight: 500;
        font-size: 18px;
    }
  ${atMostLG`
    margin-right: 0px;
  `}
`;

const SubscribeWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  gap: 15px;
  justify-content: center;
  max-width: 1279px;

  ${atMostSM`
    
  `}
`;

const FooterSubscribeButton = styled(Button)``;

const Footer = () => {
  return (
    <FooterWrapper>
      <NavigationWrapper>
        <NavigationArea />
      </NavigationWrapper>
      <SocialMediaArea />
        <FooterSubmenuWrapper>
            <Link to='/contact-us'>Contact</Link>
            <a href='https://www.ala.org/privacypolicy'>Privacy Policy</a>
            <Link to={`/get-reviewed`}>Review Policy</Link>
        </FooterSubmenuWrapper>
      <FooterBottomWrapper>
        <FooterALAWrapper>
          <span>ALA</span>
          <span>American Library Association</span>
        </FooterALAWrapper>

        <FooterCopyrightWrapper>
          <span>&copy;1993-2023 Booklist Publictions.</span>
          <span>All rights reserved.</span>
        </FooterCopyrightWrapper>
      </FooterBottomWrapper>
    </FooterWrapper>
  );
};

export default Footer;
