import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import AppRoutes from './AppRoutes';
import './App.css';
import {AppContext} from './utils/context';
import {userInterface} from './store';
import {useScreenClass} from './Components/containers/Grid';
import jwt from 'jwt-decode'
import {useLocalStorage} from "@Utils/localStorage";
import {GTMProvider} from "@elgorditosalsero/react-gtm-hook";
import {useHandleLoadUserIp} from "@Api/hooks/ipauth";
import ReactGA from 'react-ga4';
import {useCookies} from "react-cookie";
import {v4 as uuidv4} from "uuid";


const getCookieByName = (name: string) => {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(";")
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}

function App() {
    const screenClass = useScreenClass();
    const devMode: boolean = useSelector(userInterface.getDevMode);
    const [sidePanelVisible, setSidePanelVisible] = useState(false);
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '');
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '');
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '');
    const [cookies, setCookie, removeCookie] = useCookies(['ASP.NET_SessionId']);

    // TODO: activate IP auth
    const [loadData, isLoading, shouldRefresh] = useHandleLoadUserIp(false);

    const currentDate = new Date()
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate()+1);

    useEffect(() => {
        if (shouldRefresh) {
            setCookie('ASP.NET_SessionId', uuidv4(), {
                expires: tomorrow
            });
            window.location.reload();
        }
    }, [shouldRefresh]);

    const togglePanelVisibility = useCallback(
        () => setSidePanelVisible(!sidePanelVisible),
        [sidePanelVisible, setSidePanelVisible]
    );
    const gtmParams = {id: 'G-K2GR85VY14'}

    useEffect(() => {
        ReactGA.initialize('G-K2GR85VY14');
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/home", title: "Landing Page" });
    }, [])

    const jwttok = getCookieByName("betaJWT");

    if (jwttok) {
        // Decode the token, assign full name property
        const decodedJwt = jwt(jwttok);

        if (decodedJwt && (!lsAccountId || (lsAuthModeId !== '2' && lsAuthModeId !== '1'))) {
            // @ts-ignore
            setLSAccountId(decodedJwt.jti);
            setLSAuthModeId('2');
            setCookie('ASP.NET_SessionId', uuidv4(), {
                expires: tomorrow
            });
        }
        // @ts-ignore
        // const fullName = `${decodedJwt.given_name} ${decodedJwt.family_name}`;
        const jwtString = JSON.stringify(decodedJwt);

        // For info/debug
        console.log(`JWT:\n${jwttok}\nDecoded JWT:\n${jwtString}`);

    }

    return (
        <GTMProvider state={gtmParams}>
            <AppContext.Provider
                value={{
                    screenClass,
                    sidePanelVisible,
                    setSidePanelVisible,
                    togglePanelVisibility,
                }}
            >
                <AppRoutes devMode={devMode}/>
            </AppContext.Provider>
        </GTMProvider>
    );
}

export default App;
