import styled, { css } from 'styled-components';
import { SCREEN_SIZE } from '@Styles/breakpoints';

const PageContainerRenderer = styled.main`
  background-color: ${({ bgColor, theme }) => {
    return bgColor && theme.colors[bgColor];
  }};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > * {
    flex: 1;
    display: flex;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.MD}px) {
    & > * {
      padding: 0px;
    }
  }
`;

export default PageContainerRenderer;
