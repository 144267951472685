import styled from 'styled-components';
import { atMostLG, atMostSM } from '@Utils/themes';

const SocialMediaLinksRenderer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  gap: 15px;
  justify-content: center;
  
  ${atMostSM`
    display: none;
  `}
`;

const SocialMediaButtonRenderer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 24px;
  
  ${atMostSM`
    display: none;
  `}
`;

export { SocialMediaLinksRenderer, SocialMediaButtonRenderer };
