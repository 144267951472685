import styled from 'styled-components';
import {atMostLG, atMostMD} from '@Utils/themes';

const EmptyPageWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;  
  span {
    flex: 0;
    line-height: 10;
  }
`;

const NotFoundPageWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  div > * {
    color: inherit;
    font: inherit;
    font-family: "freight-sans-pro";
  }
  span {
    flex: 0;
    line-height: 2;
    align-self: center;
  }
  h1 {
    font-weight: bold;
  }
  ul {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style-type: circle;
  }
`;

const ProductInfoPageRenderer = styled.div`
  flex: 1;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1000px;
  align-self: center;
  ${atMostLG`
    margin-left: 30px;
    margin-right: 30px;
  `}
`;

const ProductItemMGPath = styled.div`
  flex: 0;
  display: flex;
  padding-bottom: 15px;
`;

const ProductItemWrapper = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 15px;
  justify-items: center;
  align-items: flex-start;
`;

const ProductItemHeroshot = styled.div`
  flex: 1;
  margin-top: 3px;
  display: flex;
  max-width: 280px;
  justify-content: end;
  ${atMostMD`
    align-self: center;
    margin-bottom: 15px;
  `}
  img {
    max-width: 80%;
    margin-right: 5px;
  }
`;

const FeatureItemHeroshot = styled.div`
  flex: 1;
  margin-top: 3px;
  display: flex;
  max-width: 100%;
  justify-content: start;
`;

const ProductItemCitation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;  
  margin-bottom: 40px;
`;

const ProductItemCitationBody = styled.div`
  * {
    color: inherit;
    font: inherit;
    font-family: "freight-sans-pro";
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 18px;
  }

  strong {
    font-weight: 600;
  }

  em, i {
    font-style: italic;  
  }
  
  font > b {
    font-family: "freight-sans-pro";
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 14px;
    display: inline-block;
  }

  h1 {
    font-family: "freight-sans-pro";
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;    
  }
      
  h2,
  h3,
  h4 {
    font-family: "freight-sans-pro";
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const ProductItemToolbox = styled.div`
  flex: 0 0 220px;
  display: flex;
  flex-direction: column;
  max-width: 220px;
  gap: 10px;
  position: sticky;
  top: 7.5rem;
`;

const ProductItemToolboxMobile = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export {
  ProductInfoPageRenderer,
  EmptyPageWrapper,
  ProductItemMGPath,
  ProductItemWrapper,
  ProductItemHeroshot,
  FeatureItemHeroshot,
  ProductItemCitation,
  ProductItemToolbox,
  ProductItemToolboxMobile,
  ProductItemCitationBody,
  NotFoundPageWrapper
};
