import styled from 'styled-components';

const SocialMediaLinksRenderer = styled.div`
  flex: 0 1 0%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

export default SocialMediaLinksRenderer;
