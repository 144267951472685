import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import {
    FormControl,
    FormControlLabel,
    FormHelperText, Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {ReCAPTCHA} from "react-google-recaptcha";
import Reaptcha from "reaptcha";
import {UserProfilePageRenderer} from "@Components/Pages/UserProfile/Renderers";
import {
    announcementInterface,
    curIssuesInterface,
    searchResultsInterface,
    siteInfoInterface,
    userInterface
} from "@Store";
import shareBooklistReader from '@Images/shareBooklistReader.svg';
import AccountNumGuide from '@Images/AccountNumGuide.png';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHandleLoadReaderToken} from "@Api/hooks/readerToken";
import {useHandleLoadSiteInfo} from "@Api/hooks/siteInfo";
import {useLocalStorage} from "@Utils/localStorage";
import {useSearchParams} from "react-router-dom";
import {ShareReaderPageRenderer} from "@Components/Pages/ShareReader/Renderer";
import {Tabs} from "antd";
import {CheckLibraryCardPageRenderer} from "@Components/Pages/CheckLibraryCard/Renderers";
import {AuthenticateLibCard, AuthenticateRefUrl} from "@Api/user";


const CheckLibraryCardPage = () => {
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const [searchParams, setSearchParams] = useSearchParams()

    const [libraryCard, setLibraryCard] = useState('');
    const [libraryId, setLibraryId] = useState(searchParams.get('libraryId'));

    const handleLibCardChange = useCallback((e) => {
        setLibraryCard(e.target.value);
    }, []);

    const handleLibCardCheckClick = useCallback(async (e) => {
        const { Authenticated } = await AuthenticateLibCard({useraccid: libraryId, usercard: libraryCard});

        if(Authenticated && !lsAccountId) {
            setLSAccountId(libraryId);
        }
        if(Authenticated && (!lsAuthModeId || lsAuthModeId === '5')) {
            setLSAuthModeId('8');
        }

        window.location.href='/';
    }, [libraryId, libraryCard]);

    return (
        <ResponsiveLayout>
            <CheckLibraryCardPageRenderer>
                <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                    Check Library Card Access
                </Typography>
                <Typography font="SM" fontStyle="italic">
                    To use Booklist
                    Online, please enter your library card number (no dashes or spaces):
                </Typography>
                <TextField value={libraryCard} onChange={handleLibCardChange} label="Library card #" variant="standard"
                           sx={{paddingBottom: 1}} required/>

                <Button style={{ marginTop: 25 }} onClick={handleLibCardCheckClick} >Check card</Button>
            </CheckLibraryCardPageRenderer>
        </ResponsiveLayout>
    )
}

export default CheckLibraryCardPage;
