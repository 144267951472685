import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Divider, Form, Input, message, Popconfirm, Table, Upload} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import {announcementInterface, appInterface, curIssuesInterface} from "@Store";
import { UploadOutlined } from '@ant-design/icons';

const EditableContext = React.createContext(null);

import {useHandleLoadCurrentIssues} from "@Api/hooks/curIssues";
import {useSelector} from "react-redux";
import {delCurIssue, postCurIssue, putCurIssue, putCurIssuesSorting} from "@Api/curIssues";
import {fileUploadUrl, networkBaseUrl} from "@Utils/network";
import {useHandleLoadAnnouncement} from "@Api/hooks/announcement";
import {putAnnouncement} from "@Api/announcement";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if(!values?.cover?.file)
                handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    },[form, toggleEdit, handleSave]);

    const handleChange = useCallback((info) => {
        if (info.file.status === 'uploading') {
            const blaBla = info.file.status;
            return;
        }
        if (info.file.status === 'done') {
            const cover = networkBaseUrl + 'Content/Images/' + info.file.response.result;
            handleSave({...record, IssueImageUrl: cover});
            return;
        }
    },[handleSave]);

    let childNode = children;

    let editor;
    if(title === 'cover')
        editor = (
            <Upload
                action={fileUploadUrl}
                listType="picture"
                maxCount={1}
                onRemove={save}
                onChange={handleChange}
            >
                <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
        )
    else
        editor = (<Input ref={inputRef} name={dataIndex} onPressEnter={save} onBlur={save}/>)

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                valuePropName={title === 'cover' ? "file" : undefined}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {editor}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {title === 'cover' && (
                    <img height={80} src={children[1]} />
                )}
                {title !== 'cover' && (
                    <>{children}</>
                )}

            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const CurrentIssueEditor = () => {
    const [loadData, isLoading] = useHandleLoadCurrentIssues(true);
    const dataSource = useSelector(curIssuesInterface.getCurrentCurIssues);

    const [loadData2, isLoading2] = useHandleLoadAnnouncement(true);
    const dataSource2 = useSelector(announcementInterface.getCurrentAnnouncement);

    const [currIssueLink, setCurrIssueLink] = useState('');
    useEffect(() => {
        if(dataSource2 && dataSource2.CurrIssue) {
            setCurrIssueLink(dataSource2.CurrIssue.ItemBody);
        }
    }, [dataSource2]);


    useEffect(() => {
        appInterface.dispatch(appInterface.activeItem({ openItem: ['util-cur-issue'] }));
        // eslint-disable-next-line
    }, []);

    const onSortEnd = useCallback(async ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );

            await putCurIssuesSorting(newData.map(x=>x.Id));
            await loadData();
        }
    },[putCurIssuesSorting, loadData, dataSource]);

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();

        if(!dataSource) return (<></>);
        // function findIndex base on Table rowKey props and should always be a right array index
        const ind = dataSource.findIndex((x) => x.Id === props['data-row-key']);

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <SortableItem index={ind} {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const handleDelete = useCallback(async (Id) => {
        await delCurIssue(Id);
        await loadData();
    },[delCurIssue, loadData]);

    const defaultColumns = [
        {
            title: 'Sort',
            dataIndex: 'SortOrder',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
            editable: false,
        },
        {
            title: 'cover',
            dataIndex: 'IssueImageUrl',
            width: '10%',
            editable: true,
            className: 'drag-visible',
        },
        {
            title: 'cover link',
            dataIndex: 'CoverLink',
            editable: true,
        },
        {
            title: 'title',
            dataIndex: 'IssueTitle',
            editable: true,
        },
        {
            title: 'subtitle',
            dataIndex: 'IssueSubtitle',
            editable: true,
        },
        {
            title: 'link',
            dataIndex: 'IssueLink',
            editable: true,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.Id)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleAdd = useCallback( async () => {
        await postCurIssue();
        await loadData();
    }, [loadData, postCurIssue]);

    const handleSave = useCallback(async (row) => {
        await putCurIssue(row);
        await loadData();
    },[putCurIssue, loadData]);

    const handleSaveCurrIssueLink = useCallback( async () => {
        await putAnnouncement({...dataSource2?.CurrIssue, ItemBody: currIssueLink});
        await loadData();
        message.open({
            type: 'success',
            content: 'Entity has been saved successfully!',
            style: {
                marginTop: '65px',
            },
        });
    }, [loadData2, putAnnouncement, dataSource2, currIssueLink]);

    const components = {
        body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Divider orientation="left">Current Issue Menu</Divider>
            <Input placeholder="Menu Link" value={currIssueLink} onChange={(e)=> {setCurrIssueLink(e.target.value); }} onBlur={(e)=> {setCurrIssueLink(e.target.value); }} />

            <Button
                onClick={handleSaveCurrIssueLink}
                type="primary"
                style={{
                    marginTop: 16,
                    marginBottom: 16,
                }}
            >
                Save Link
            </Button>


            <Divider orientation="left">Current Issue Landing Page</Divider>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Add a row
            </Button>
            <Table
                size={'small'}
                pagination={{
                    pageSize: 50,
                }}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                rowKey="Id"
            />
        </div>
    );
};

export default CurrentIssueEditor;
