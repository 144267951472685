import styled from 'styled-components';

const TypographyRenderer = styled.span`
  color: ${({ themeColor, theme }) => {
    return themeColor && theme.colors[themeColor];
  }};
  font-size: ${({ font, theme }) => {
    return font && theme.fontSizes[font];
  }};
  font-weight: ${({ fontWeight }) => {
    return fontWeight || '400';
  }};
  font-family: ${({ fontFamily }) => {
    return fontFamily || 'Roboto';
  }};
  font-style: ${({ fontStyle }) => {
    return fontStyle || 'normal';
  }};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export default TypographyRenderer;
