import React, {useCallback, useState} from 'react';
import {
    LoginFormRenderer,
    LoginHeader, ResetMessage,
    ResetPassword,
    ValidationMessage
} from "@Components/Pages/Login/LoginForm/Renderers";
import {Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import {PermIdentity} from "@mui/icons-material";
import Button from "@Components/controls/Button";
import {ROUTES} from "@Utils/routing";
import {AuthenticateBOL, ResetUserPassword} from "@Api/user";
import {useLocalStorage} from "@Utils/localStorage";
import {useNavigate} from "react-router-dom";
import {Visible} from "react-grid-system";
import {samlSsoUrl} from "@Utils/network";
import {userInterface} from "@Store";
import { v4 as uuidv4 } from 'uuid';
import {useCookies} from "react-cookie";


const StyledFC = styled(FormControlLabel)`
  span.MuiFormControlLabel-label {
    color: #8f8f8f !important;
  }
`;

const LoginForm = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['ASP.NET_SessionId']);

    const handleLoginClick = useCallback(async () => {
        if(userName === 'admin' && password === 'b00kl1st0n1ine') {
            setLSUserId('9e6c1b73-e1cd-4943-be13-edd9e121ca8f');
            setLSAuthModeId('4');
            // 6678932890
            navigate(ROUTES.admin);
        }

        try {
            const authResponse = await AuthenticateBOL({userName, password});

            if(authResponse && authResponse.authorized === true) {
                setAuthFailed(false);
                setLSUserId(authResponse.userId);
                setLSAccountId(authResponse.accountId);
                setLSAuthModeId('1');
                setLSAccountName(authResponse.account.AccountName);

                const currentDate = new Date()
                const tomorrow = new Date();
                tomorrow.setDate(currentDate.getDate()+1);

                setCookie('ASP.NET_SessionId', uuidv4(), {
                    expires: tomorrow
                });
                navigate(ROUTES.root);
            } else {
                setAuthFailed(true);
            }
        }
        catch {
            setAuthFailed(true);
        }
    }, [userName, password]);

    const handleEnterPressed = useCallback((e) => {
        if(e.keyCode === 13) {
            handleLoginClick();
        }
    }, [handleLoginClick]);

    function useInput({ type, placeholder, className, valueSelector, handleChange }) {

        const input = (
            <input
                onKeyDown={(placeholder === "Username" || placeholder === "Password") ? handleEnterPressed : null}
                className={className}
                placeholder={placeholder}
                value={valueSelector}
                onChange={(e) => handleChange(e.target.value)}
                type={type}
            />
        );
        return [input];
    }

    const [usernameInput] = useInput({ type: 'text', placeholder: 'Username', valueSelector: userName, handleChange: setUserName });
    const [passwordInput] = useInput({ type: 'password', placeholder: 'Password', valueSelector: password, handleChange: setPassword });
    const [emailInput] = useInput({ type: 'text', placeholder: 'Email address', valueSelector: email, handleChange: setEmail });

    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const [lsAccountName, setLSAccountName] = useLocalStorage("LSANM", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')

    const [authFailed, setAuthFailed] = useState(false);
    const [isResetPwd, setIsResetPwd] = useState(false);
    const [resetPwdSent, setResetPwdSent] = useState(false);

    const navigate = useNavigate();

    const handleResetClick = useCallback(async () => {
        setIsResetPwd(!isResetPwd);
        setResetPwdSent(false);
    }, [isResetPwd, setIsResetPwd, setResetPwdSent]);

    const handleResetPwdClick = useCallback(async () => {
        await ResetUserPassword({username: userName, email: email});
        setResetPwdSent(true);
    }, [userName, email, resetPwdSent, setResetPwdSent]);

    return (
        <LoginFormRenderer>
            {isResetPwd && (
                <>
                    {resetPwdSent && (
                        <>
                            <Visible xs sm md lg>
                                <LoginHeader>
                                    Reset&nbsp;password
                                </LoginHeader>
                            </Visible>
                            <ResetMessage><span>Thank you!<br />Your login information has been sent to you.<br />For further assistance, please contact us at: info@booklistonline.com </span></ResetMessage>

                            <ResetPassword onClick={handleResetClick}>
                                <span>Log in &gt;&gt;</span>
                            </ResetPassword>
                        </>
                    )}
                    {!resetPwdSent && (
                        <>
                            <Visible xs sm md lg>
                                <LoginHeader>
                                    Reset&nbsp;password
                                </LoginHeader>
                            </Visible>
                            <ResetMessage><span>Please provide the following information:</span></ResetMessage>
                            {usernameInput}
                            <span style={{fontSize: 10, marginBottom:7}}>OR</span>
                            {emailInput}
                            <Button themeColor='darkblue' onClick={handleResetPwdClick}>
                                Reset&nbsp;password
                            </Button>
                            <ResetPassword onClick={handleResetClick}>
                                <span>Log in &gt;&gt;</span>
                            </ResetPassword>
                        </>
                    )}
                </>
            )}
            {!isResetPwd && (
                <>
                    <Visible xs sm md lg>
                        <LoginHeader>
                            Log&nbsp;In
                        </LoginHeader>
                    </Visible>
                    {usernameInput}
                    {passwordInput}
                    <StyledFC control={<Checkbox  />} label="Remember me" />
                    <div>
                        <Button themeColor='darkblue' onClick={handleLoginClick}>
                            Sign&nbsp;In
                        </Button>
                        <a style={{marginLeft: 20}} href={samlSsoUrl + `?returnurl=${encodeURIComponent(document.location.origin)}`}>
                            <Button themeColor='darkblue'>
                                Sign&nbsp;In via ALA
                            </Button>
                        </a>
                    </div>
                    {authFailed && (<ValidationMessage><span>* Invalid username or password</span></ValidationMessage>)}
                    <ResetPassword  onClick={handleResetClick}>
                        <span>RESET PASSWORD &gt;&gt;</span>
                    </ResetPassword>
                </>
            )}

        </LoginFormRenderer>
    )
}

export default LoginForm
