import http from '@Utils/network';

const GET_TOPFEATURES_ENDPOINT = () => `api/EusAdmin/TopFeatures`;
const PUT_TOPFEATURES_SORT_ENDPOINT = () => `api/EusAdmin/UpdateTopFeaturesSorting`;
const POST_TOPFEATURES_ENDPOINT = () => `api/EusAdmin/CreateTopFeature`;
const PUT_TOPFEATURES_ENDPOINT = () => `api/EusAdmin/UpdateTopFeature`;
const DEL_TOPFEATURES_ENDPOINT = (Id) => `api/EusAdmin/DeleteTopFeature/${Id}`;

const getTopFeatures = async () => {
    const curIssues = await http.get(GET_TOPFEATURES_ENDPOINT());
    return curIssues;
};

const putTopFeaturesSorting = async (data) => {
    const retVal = await http.put(PUT_TOPFEATURES_SORT_ENDPOINT(), data);
    return retVal;
};

const postTopFeatures = async () => {
    const retVal = await http.post(POST_TOPFEATURES_ENDPOINT());
    return retVal;
};

const putTopFeatures = async (data) => {
    const retVal = await http.put(PUT_TOPFEATURES_ENDPOINT(), data);
    return retVal;
};

const delTopFeatures = async (Id) => {
    const retVal = await http.delete(DEL_TOPFEATURES_ENDPOINT(Id));
    return retVal;
};

export { getTopFeatures, putTopFeaturesSorting, postTopFeatures, putTopFeatures, delTopFeatures };