import http from '@Utils/network';

const GET_PRODUCT_ENDPOINT = (productId) => `api/Eusservice/GetProduct?ProductId=${productId}`;

const getProduct = async (searchOptions) => {
  const { productId } = searchOptions;

  const { data } = await http.get(GET_PRODUCT_ENDPOINT(productId));
  return data;
};

export { getProduct };
