import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerOpen: false,
  openItem: ['adm_announcement'],
  sideMenuOpen: false,
  quickSearchOpen: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    setSideMenuOpen: (state, action) => {
      const { payload } = action;
      state.sideMenuOpen = payload.sideMenuOpen;
    },
    setQuickSearchOpen: (state, action) => {
      const { payload } = action;
      state.quickSearchOpen = payload.quickSearchOpen;
    },
    clearData: () => initialState,
  },
});

const appSelectors = {
  getOpenItem: (state) => state.openItem,
  getOpenDrawer: (state) => state.drawerOpen,
  getSideMenuOpen: (state) => state.sideMenuOpen,
  getQuickSearchOpen: (state) => state.quickSearchOpen,
};
const appActions = appSlice.actions;

export default appSlice;
export { appSelectors, appActions };
