import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LoaderSVGRenderer from './LoaderSVGRenderer';
import PlainLoaderSVGRenderer from './PlainLoaderSVGRenderer';

const LoaderContainer = styled.div`
  ${({ centered }) =>
    centered &&
    css`
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    `}
`;

const PlainLoader = (props) => (
  <PlainLoaderSVGRenderer {...props}>
    <path
      d="M14.8227 0.0136719C6.57653 0.368213 0 7.16566 0 15.4991C0 24.0596 6.93959 30.9991 15.5 30.9991C24.0604 30.9991 31 24.0596 31 15.4991C31 15.2728 30.9951 15.0476 30.9855 14.8237H26.9805C26.9934 15.0472 27 15.2724 27 15.4991C27 21.8504 21.8513 26.9991 15.5 26.9991C9.14873 26.9991 4 21.8504 4 15.4991C4 9.37523 8.78669 4.36931 14.8227 4.01875V0.0136719Z"
      fill="currentColor"
    />
    <path
      d="M16.8442 2.02174C16.8442 3.13831 15.939 4.04348 14.8224 4.04348C13.7059 4.04348 13.4746 3.13831 13.4746 2.02174C13.4746 0.905163 13.7059 0 14.8224 0C15.939 0 16.8442 0.905163 16.8442 2.02174Z"
      fill="currentColor"
    />
    <path
      d="M30.9986 14.8264C30.9986 15.943 30.0934 16.1743 28.9768 16.1743C27.8602 16.1743 26.9551 15.943 26.9551 14.8264C26.9551 13.7099 27.8602 12.8047 28.9768 12.8047C30.0934 12.8047 30.9986 13.7099 30.9986 14.8264Z"
      fill="currentColor"
    />
  </PlainLoaderSVGRenderer>
);
const ComapssLoader = (props) => (
  <LoaderSVGRenderer {...props}>
    <g>
      <path d="M20 3.33301V9.99967" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.0664 12.9335L31.7831 8.2168" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30 20H36.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.0664 27.0664L31.7831 31.7831" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 30V36.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.2168 31.7831L12.9335 27.0664" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33398 20H10.0007" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.2168 8.2168L12.9335 12.9335" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </LoaderSVGRenderer>
);

const Loader = ({ type = 'compass', centered, stroke, strokeWidth, fill, ...props }) => {
  return (
    <LoaderContainer centered={centered}>
      {type === 'compass' && <ComapssLoader stroke={stroke} strokeWidth={strokeWidth} {...props} />}
      {type === 'plain' && <PlainLoader fill={fill} {...props} />}
    </LoaderContainer>
  );
};

Loader.propTypes = {
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  height: propTypes.oneOfType([propTypes.string, propTypes.number]),
  fill: propTypes.string,
  stroke: propTypes.string,
  strokeWidth: propTypes.number,
  centered: propTypes.bool,
  type: propTypes.oneOf(['compass', 'plain']),
};

export default Loader;
