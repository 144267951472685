import styled from 'styled-components';
import { atMostSM } from '@Utils/themes';

const ToolBarRenderer = styled.div`
  justify-self: end;
  display: grid;
  margin-bottom: 32px;
  justify-content: start;

  ${atMostSM`
    margin-bottom: 24px;
  `}
`;

const SocialMediaLinksRenderer = styled.div`
  flex: 0 1 0%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export { ToolBarRenderer, SocialMediaLinksRenderer };
