import React, { useCallback, useState } from 'react';
import identity from 'lodash/identity';
import HGroup from '@Components/containers/HGroup';
import Typography from '@Components/controls/Typography';
import { LinkButtonRenderer } from '@Components/controls/LinkButton/Renderers';
import propTypes from 'prop-types';

const LinkButton = (props) => {
  const { onClick = identity, font = 'XS', variant = 'static', themeColor = 'black', title, text, icon } = props;

  const [isActing, setIsActing] = useState(false);
  const onClickHandler = useCallback(
    (...stuff) => {
      if (!onClick) return;
      const result = onClick(...stuff);
      if (result instanceof Promise) {
        setIsActing(true);
        result.then(() => setIsActing(false));
      }
      return result;
    },
    [onClick, setIsActing]
  );

  return (
    <LinkButtonRenderer
      variant={variant}
      themeColor={themeColor}
      acting={isActing}
      onClick={onClickHandler}
      title={title || text}
      {...props}
    >
      <HGroup>
        {icon && icon}
        <Typography font={font} themeColor={themeColor} title={title}>
          {text}
        </Typography>
      </HGroup>
    </LinkButtonRenderer>
  );
};

LinkButton.propTypes = {
  variant: propTypes.string,
  onClick: propTypes.func,
  text: propTypes.node.isRequired,
  icon: propTypes.node,
  font: propTypes.string,
  themeColor: propTypes.string,
  title: propTypes.string,
};

export default LinkButton;
