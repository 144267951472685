import styled from 'styled-components';

export const AccessRestrictedWrapper = styled.div`
  span {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  li {
    list-style: square;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  a {
    color: black;
  }
`;