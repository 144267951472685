import styled, { css } from 'styled-components';

const TopAdAreaRenderer = styled.div`
  max-height: 230px;
  flex: 0 1 0%;
  background-color: #999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    flex: 0 0 auto;
    max-width: 100%;
  }
`;

export default TopAdAreaRenderer;
