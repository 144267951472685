import React from 'react';
import Typography from '@Components/controls/Typography';
import Sample2 from '@Images/Samples/Sample2.png';
import Sample3 from '@Images/Samples/Sample3.png';
import Sample26 from '@Images/Samples/Sample26.png';
import Sample27 from '@Images/Samples/Sample27.png';
import {Visible} from '@Components/containers/Grid';
import {
    CurrentIssueColumnRenderer,
    CurrentIssueColumnsWrapper,
    CurrentIssueHeader, CurrentIssueHeaderXL,
    CurrentIssueItemRenderer,
    CurrentIssueRenderer, FullPageCurIssueRenderer,
} from '@Components/Pages/Home/CurrentIssue/Renderers';
import {Skeleton} from 'antd';
// import 'antd/dist/antd.css';
import {useSelector} from "react-redux";
import {curIssuesInterface} from "@Store";
import {useHandleLoadCurrentIssues} from "@Api/hooks/curIssues";


const CurrentIssue = () => {
    const [loadData, isLoading] = useHandleLoadCurrentIssues();
    const currentCurIssues = useSelector(curIssuesInterface.getCurrentCurIssues);

    return (
        <>
            {isLoading && (<Skeleton active/>)}
            <FullPageCurIssueRenderer>
                <CurrentIssueRenderer>
                    {(!isLoading && currentCurIssues && currentCurIssues.length > 0) && (
                        <>
                            <Visible xxl>
                                <CurrentIssueHeaderXL>
                                    <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro">
                                        Current Issues
                                    </Typography>
                                </CurrentIssueHeaderXL>
                            </Visible>
                            <Visible xs sm md lg xl>
                                <CurrentIssueHeader>
                                    <Typography font="XXXXXXL" fontWeight="600" fontFamily="garamond-premier-pro">
                                        Current Issues
                                    </Typography>
                                </CurrentIssueHeader>
                            </Visible>
                            <CurrentIssueColumnsWrapper>
                                {currentCurIssues.map(issue => {
                                    return (
                                        <CurrentIssueColumnRenderer>
                                            <a href={issue.CoverLink}>
                                                <img src={issue.IssueImageUrl}/>
                                            </a>
                                            <CurrentIssueItemRenderer>
                                                <a style={{textDecoration: 'none'}} href={issue.IssueLink}>
                                                    <Typography font="LG" fontWeight="500"
                                                                fontFamily="garamond-premier-pro" textAlign="center">
                                                        {issue.IssueTitle}
                                                    </Typography>
                                                </a>
                                                <div>
                                                    <a style={{textDecoration: 'none'}} href={issue.IssueLink}>
                                                        <Typography font="MMD" fontWeight="300"
                                                                    fontFamily="freight-sans-pro" textAlign="center">
                                                            {issue.IssueSubtitle}
                                                        </Typography>
                                                    </a>
                                                </div>
                                            </CurrentIssueItemRenderer>
                                        </CurrentIssueColumnRenderer>
                                    )
                                })}
                            </CurrentIssueColumnsWrapper>
                        </>
                    )}
                </CurrentIssueRenderer>
            </FullPageCurIssueRenderer>
        </>
    );
};

export default CurrentIssue;
