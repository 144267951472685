import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentCurIssues: null,
};

const curIssuesSlice = createSlice({
    name: 'curIssues',
    initialState,
    reducers: {
        setCurrentCurIssues: (state, action) => {
            const { payload } = action;
            state.currentCurIssues = payload;
        },
        clearData: () => initialState,
    },
});

const curIssuesSelectors = {
    getCurrentCurIssues: (state) => state.currentCurIssues,
};
const curIssuesActions = curIssuesSlice.actions;

export default curIssuesSlice;
export { curIssuesSelectors, curIssuesActions };