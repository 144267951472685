import React, {useCallback, useEffect, useState} from 'react';
import {
  EmptyPageWrapper, FeatureItemHeroshot,
  ProductInfoPageRenderer,
  ProductItemCitation, ProductItemCitationBody,
  ProductItemHeroshot,
  ProductItemMGPath,
  ProductItemToolbox,
  ProductItemToolboxMobile,
  ProductItemWrapper,
} from '@Components/Pages/ProductInfo/Renderers';
import { LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {productInterface, searchResultsInterface} from '@Store';
import Loader from '@Components/controls/Loader';
import Typography from '@Components/controls/Typography';
import Cover from '@Images/Cover.png';
import DOMPurify from 'dompurify';
import { useHandleLoadProductInfo } from '@Api/hooks/product';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import Button from '@Components/controls/Button';
import { ReactComponent as ViewSelectedIcon } from '@Images/ViewSelected.svg';
import { ReactComponent as AddToListIcon } from '@Images/AddToList.svg';
import { ReactComponent as DownloadIcon } from '@Images/Download.svg';
import { ReactComponent as PrintIcon } from '@Images/print.svg';
import { ReactComponent as EmailIcon } from '@Images/Email.svg';
import { Visible } from 'react-grid-system';
import {ROUTES} from "@Utils/routing";
import {Modal} from "antd";
import UserListsEditor from "@Components/Pages/SearchResults/UserLists";
import AccessRestricted from "@Components/sections/Common/AccessRestricted";
import {useLocalStorage} from "@Utils/localStorage";
import {networkBaseUrl} from "@Utils/network";
import {
  ADContainerRenderer,
  FullPageContainerRenderer,
  SiteInfoPageContainerRenderer
} from "@Components/Pages/SiteInfoPage/Renderers";
import SideAD2 from "@Images/Samples/SideAD2.png";
import SideAD1 from "@Images/Samples/SideAD1.png";
import {Chip} from "@mui/material";

const purifyConfig = {
  FORBID_TAGS: [],
  SAFE_FOR_TEMPLATES: true,
  RETURN_TRUSTED_TYPE: true,
  ALLOW_DATA_ATTR: false,
  KEEP_CONTENT: true,
};

const getProductImage = function (product) {
  if(product.BookImage && product.BookImage.indexOf('http') > -1) {
    return product.BookImage;
  }

  if(product.BookHeroshot) {
    if (product.BookHeroshot.indexOf('userupload') === 0) {
      return networkBaseUrl + 'Content/Images/' + product.BookHeroshot;
    }

    if (product.BookHeroshot.indexOf('http') > -1) {
      return product.BookHeroshot;
    }
  }

  return Cover;
}

const ProductInfoPage = () => {
  const params = useParams();
  const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
  const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')

  const navigate = useNavigate();

  const currentViewPID = useSelector(searchResultsInterface.getCurrentViewPID);

  const [loadData, isLoading, currentPID, setCurrentPID] = useHandleLoadProductInfo(+params.productId || currentViewPID);
  const [isAddListOpen, setIsAddListOpen] = useState(false);

  const currentProductInfo = useSelector(productInterface.getCurrentProductInfo);

  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if(params.productId) {
      if(params.productId.startsWith("pid="))
        setCurrentPID(+(params.productId.replace("pid=", '')));
      else
        setCurrentPID(+params.productId);
    }
    if(!params.productId && currentViewPID) {
      setCurrentPID(currentViewPID);
    }
  }, [setCurrentPID, currentViewPID, params])

  useEffect(() => {
    if(!isLoading && currentProductInfo) {
      setTimeout( () => {
        window.addthis = undefined;
        const addthisScript = document.createElement('script');
        addthisScript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f23e1e209c71461')
        if (document.body) document.body.appendChild(addthisScript)
      }, 1);

    }
  }, [isLoading, currentProductInfo])

  useEffect(() => {
    if(!isLoading && currentProductInfo && currentProductInfo.catalogProd && currentProductInfo.catalogProd.TitleSubtitle) {
      document.title = currentProductInfo.catalogProd.TitleSubtitle;
    }
  }, [isLoading, currentProductInfo])

  const handlePrevious = useCallback(() => {
    searchResultsInterface.dispatch(searchResultsInterface.setPreviousViewIndex({ }));
  }, []);

  const handleNext = useCallback(() => {
    searchResultsInterface.dispatch(searchResultsInterface.setNextViewIndex({ }));
  }, []);

  const handleBackToSR = useCallback(() => {
    navigate(ROUTES.searchResutsLazy);
  }, []);

  const handleAddList = useCallback(() => {
    const uid = window.localStorage.getItem("LSUID");
    if(!uid) {
      Modal.error({
        title: 'Log In',
        content: 'Please log in to use user lists',
      });
      return;
    }

    setIsAddListOpen(!isAddListOpen);
  }, [isAddListOpen, setIsAddListOpen]);

  const handleAuthorsSearch = useCallback((Author) =>  ()=> {
    searchResultsInterface.dispatch(
        searchResultsInterface.setSearchOptions({ searchOptions: { author: Author }, searchType: 6, totalCount: null })
    );
    navigate(ROUTES.searchResutsLazy);
  },[navigate]);

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');

    if(targetLink && targetLink.pathname.startsWith('/issueSearch')) {
      e.preventDefault();

      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { pid: currentPID }, searchType: 8, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/PreProgrammedSearch')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { preprogram: searchParams[1] }, searchType: 5, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.href.indexOf('/products/') === -1 && targetLink.href.indexOf('pid=') > -1) {
      e.preventDefault();
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    }

    if(targetLink && targetLink.host === 'www.booklistonline.com') {
      e.preventDefault();
      navigate(targetLink.pathname + targetLink.search);
    }

    if(!targetLink || targetLink.host !== window.location.host) return;
    e.preventDefault();

    if(targetLink.href.indexOf('pid=') > -1) {
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    } else {
      navigate(targetLink.href.replace(/^(?:\/\/|[^/]+)*\//, '/'));
    }
  }

  const [isPrintOpen, setIsPrintOpen] = useState(false);

  const handlePrint = useCallback(() => {
    setIsPrintOpen(!isPrintOpen);
  }, [isPrintOpen, setIsPrintOpen]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(usersData.users),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }

  const exportToCsv = useCallback(async (e) => {
    e.preventDefault()


    const res = [currentProductInfo.catalogProd];

    // Headers for each column
    const headers = ['Title,PubDate,Format,Dewey,Grade,Issue']

    const reviewsCsv = res.reduce((acc, review) => {
      const { TitleSubtitle, PubDate, Format, Dewey, Grade, Issue } = review
      acc.push([`"${TitleSubtitle}"`, `"${PubDate}"`, `"${Format}"`, `"${Dewey}"`, `"${Grade}"`, `"${Issue}"`].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...reviewsCsv].join('\n'),
      fileName: 'ALA Booklist Reviews.csv',
      fileType: 'text/csv',
    })

  }, [currentProductInfo]);

  let authenticatedToView = false;
  if((currentProductInfo._mediaclass !== 'FEATURE' && !!lsUserId)
      || (currentProductInfo._mediaclass === 'FEATURE')
      || (currentProductInfo._mediaclass === 'BLOG' && lsAuthModeId === '5')
      || (lsAuthModeId === '6')
      || (lsAuthModeId === '7')
      || (currentProductInfo.IsPublic === true)
  )
    authenticatedToView = true;

  return (
    <ResponsiveLayout>
      <Modal
          closable={false}
          open={isAddListOpen}
          title="Add to list"
          okText="Close"
          width={600}
          onOk={handleAddList}
          cancelButtonProps={{ style: { display: 'none' } }}
      >
        <UserListsEditor viewMode={1} handleClose={handleAddList} pid={+params.productId || currentViewPID} />
      </Modal>
      <Modal
          open={isPrintOpen}
          title="Print content"
          okText="Print"
          cancelText="Cancel"
          width={600}
          bodyStyle={{height: 400, overflowY:'scroll'}}
          onCancel={handlePrint}
          onOk={() => {
            const mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>' + document.title  + '</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<h1>' + document.title  + '</h1>');
            mywindow.document.write(document.getElementById('printContent').innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
          }}
      >
        <ProductInfoPageRenderer id='printContent'>
          <div className="addthis_inline_share_toolbox"></div>
          {!isLoading && !currentProductInfo && (
              <EmptyPageWrapper>
                <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                  Product Not Found
                </Typography>
              </EmptyPageWrapper>
          )}
          {isLoading && <Loader centered />}
          {!isLoading && currentProductInfo && (
              <>
                {!params.productId && (
                    <>
                      <ProductItemMGPath>
                        <Button style={{marginRight:10}} themeColor="white" backgroundColor="black" variant="narrow" onClick={handlePrevious}>
                          <LeftOutlined />
                          Previous
                        </Button>
                        <Button style={{marginRight:10}} variant="narrow" onClick={handleBackToSR}>
                          <UpOutlined />
                          Back to Search Results
                        </Button>
                        <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={handleNext}>
                          Next
                          <RightOutlined />
                        </Button>
                      </ProductItemMGPath>
                    </>
                )}
                <ProductItemMGPath>
                  <Typography font="SM" themeColor="grey1" fontWeight="400">
                    {currentProductInfo.TaxonomyPathFormattedNew}
                  </Typography>
                </ProductItemMGPath>
                {((currentProductInfo.BookImage && currentProductInfo.BookImage.indexOf('http') > -1) || currentProductInfo.BookHeroshot) && (
                    <Visible xs sm md>
                      <ProductItemHeroshot>
                        <img
                            src={getProductImage(currentProductInfo)}
                        />
                        <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                              <img
                                  alt="Book"
                                  title="Book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/book_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                              <img
                                  alt="Star review"
                                  title="Star review"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/starred_review.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                              <img
                                  alt="e-book"
                                  title="e-book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/internet_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                              <img
                                  alt="Audiobook"
                                  title="Audiobook"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/headphones_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                              <img
                                  alt="Video"
                                  title="Video"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/camcorder_image.gif'
                              />
                          )}
                        </div>
                      </ProductItemHeroshot>
                    </Visible>
                )}
                <ProductItemWrapper>
                  {((currentProductInfo.BookImage && currentProductInfo.BookImage.indexOf('http') > -1) || currentProductInfo.BookHeroshot) && (
                      <Visible lg xl xxl>
                        <ProductItemHeroshot>
                          <img
                              src={getProductImage(currentProductInfo)}
                          />
                          <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                                <img
                                    alt="Book"
                                    title="Book"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/book_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                                <img
                                    alt="Star review"
                                    title="Star review"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/starred_review.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                                <img
                                    alt="e-book"
                                    title="e-book"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/internet_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                                <img
                                    alt="Audiobook"
                                    title="Audiobook"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/headphones_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                                <img
                                    alt="Video"
                                    title="Video"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/camcorder_image.gif'
                                />
                            )}
                          </div>
                        </ProductItemHeroshot>
                      </Visible>
                  )}
                  {(!currentProductInfo.BookImage && !currentProductInfo.BookHeroshot) && (
                      <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                        {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                            <img
                                alt="Book"
                                title="Book"
                                style={{width: 20}}
                                src='https://www.booklistonline.com/images/book_image.gif'
                            />
                        )}
                        {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                            <img
                                alt="Star review"
                                title="Star review"
                                style={{width: 20}}
                                src='https://www.booklistonline.com/images/starred_review.gif'
                            />
                        )}
                        {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                            <img
                                alt="e-book"
                                title="e-book"
                                style={{width: 20}}
                                src='https://www.booklistonline.com/images/internet_image.gif'
                            />
                        )}
                        {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                            <img
                                alt="Audiobook"
                                title="Audiobook"
                                style={{width: 20}}
                                src='https://www.booklistonline.com/images/headphones_image.gif'
                            />
                        )}
                        {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                            <img
                                alt="Video"
                                title="Video"
                                style={{width: 20}}
                                src='https://www.booklistonline.com/images/camcorder_image.gif'
                            />
                        )}
                      </div>
                  )}
                  <ProductItemCitation>
                    {!authenticatedToView && (
                        <Typography font="SM" themeColor="black" fontWeight="400">
                          {
                            <div
                                onClick={contentClickHandler}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(currentProductInfo.LongImprintProcessed.substring(0, 600).substring(0, currentProductInfo.LongImprintProcessed.substring(0, 600).lastIndexOf(' ')) + "...", purifyConfig),
                                }}
                            />
                          }
                        </Typography>
                    )}
                    {authenticatedToView && (
                        <Typography font="SM" themeColor="black" fontWeight="400">
                          {
                            <div
                                onClick={contentClickHandler}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(currentProductInfo.LongImprintProcessed, purifyConfig),
                                }}
                            />
                          }
                        </Typography>
                    )}

                    {!authenticatedToView && (<AccessRestricted />)}
                  </ProductItemCitation>
                </ProductItemWrapper>
              </>
          )}
        </ProductInfoPageRenderer>
      </Modal>
      <FullPageContainerRenderer>
        <Visible xxl>
          <ADContainerRenderer></ADContainerRenderer>
        </Visible>
        <SiteInfoPageContainerRenderer>
          <ProductInfoPageRenderer>
            <div className="addthis_inline_share_toolbox"></div>
            {!isLoading && !currentProductInfo && (
                <EmptyPageWrapper>
                  <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                    Product Not Found
                  </Typography>
                </EmptyPageWrapper>
            )}
            {isLoading && <Loader centered />}
            {!isLoading && currentProductInfo && (
                <>
                  {location.state && location.state.fromSearchResults && (
                      <>
                        <ProductItemMGPath>
                          <Button style={{marginRight:10}} variant="narrow" onClick={()=>{navigate(-1);}}>
                            <UpOutlined />
                            Back to Search Results
                          </Button>
                        </ProductItemMGPath>
                      </>
                  )}
                  {!params.productId && (
                      <>
                        <ProductItemMGPath>
                          <Button style={{marginRight:10}} themeColor="white" backgroundColor="black" variant="narrow" onClick={handlePrevious}>
                            <LeftOutlined />
                            Previous
                          </Button>
                          <Button style={{marginRight:10}} variant="narrow" onClick={handleBackToSR}>
                            <UpOutlined />
                            Back to Search Results
                          </Button>
                          <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={handleNext}>
                            Next
                            <RightOutlined />
                          </Button>
                        </ProductItemMGPath>
                      </>
                  )}
                  <ProductItemMGPath>
                    <Typography font="SM" themeColor="grey1" fontWeight="400">
                      {currentProductInfo.TaxonomyPathFormattedNew}
                    </Typography>
                  </ProductItemMGPath>
                  {(((currentProductInfo.BookImage && currentProductInfo.BookImage.indexOf('http') > -1) || currentProductInfo.BookHeroshot) && currentProductInfo._mediaclass !== 'FEATURE') && (
                      <Visible xs sm md>
                        <ProductItemHeroshot>
                          <img
                              src={getProductImage(currentProductInfo)}
                          />
                          <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                                <img
                                    alt="Book"
                                    title="Book"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/book_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                                <img
                                    alt="Star review"
                                    title="Star review"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/starred_review.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                                <img
                                    alt="e-book"
                                    title="e-book"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/internet_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                                <img
                                    alt="Audiobook"
                                    title="Audiobook"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/headphones_image.gif'
                                />
                            )}
                            {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                                <img
                                    alt="Video"
                                    title="Video"
                                    style={{width: 20}}
                                    src='https://www.booklistonline.com/images/camcorder_image.gif'
                                />
                            )}
                          </div>
                        </ProductItemHeroshot>
                      </Visible>
                  )}
                  {(((currentProductInfo.BookImage && currentProductInfo.BookImage.indexOf('http') > -1) || currentProductInfo.BookHeroshot)  && currentProductInfo._mediaclass === 'FEATURE') && (
                      <FeatureItemHeroshot>
                        <img
                            src={getProductImage(currentProductInfo)}
                        />
                        <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                              <img
                                  alt="Book"
                                  title="Book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/book_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                              <img
                                  alt="Star review"
                                  title="Star review"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/starred_review.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                              <img
                                  alt="e-book"
                                  title="e-book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/internet_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                              <img
                                  alt="Audiobook"
                                  title="Audiobook"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/headphones_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                              <img
                                  alt="Video"
                                  title="Video"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/camcorder_image.gif'
                              />
                          )}
                        </div>
                      </FeatureItemHeroshot>
                  )}
                  <ProductItemWrapper>
                    {(((currentProductInfo.BookImage && currentProductInfo.BookImage.indexOf('http') > -1) || currentProductInfo.BookHeroshot)  && currentProductInfo._mediaclass !== 'FEATURE') && (
                        <Visible lg xl xxl>
                          <ProductItemHeroshot>
                            <img
                                src={getProductImage(currentProductInfo)}
                            />
                            <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                              {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                                  <img
                                      alt="Book"
                                      title="Book"
                                      style={{width: 20}}
                                      src='https://www.booklistonline.com/images/book_image.gif'
                                  />
                              )}
                              {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                                  <img
                                      alt="Star review"
                                      title="Star review"
                                      style={{width: 20}}
                                      src='https://www.booklistonline.com/images/starred_review.gif'
                                  />
                              )}
                              {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                                  <img
                                      alt="e-book"
                                      title="e-book"
                                      style={{width: 20}}
                                      src='https://www.booklistonline.com/images/internet_image.gif'
                                  />
                              )}
                              {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                                  <img
                                      alt="Audiobook"
                                      title="Audiobook"
                                      style={{width: 20}}
                                      src='https://www.booklistonline.com/images/headphones_image.gif'
                                  />
                              )}
                              {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                                  <img
                                      alt="Video"
                                      title="Video"
                                      style={{width: 20}}
                                      src='https://www.booklistonline.com/images/camcorder_image.gif'
                                  />
                              )}
                            </div>
                          </ProductItemHeroshot>
                        </Visible>
                    )}
                    {(!currentProductInfo.BookImage && !currentProductInfo.BookHeroshot) && (
                        <div style={{display: "flex", flexDirection: "column", flex: 1, maxWidth: 26, minWidth: 26}}>
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{BOOKIMAGE}}') && (
                              <img
                                  alt="Book"
                                  title="Book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/book_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{STAR}}') && (
                              <img
                                  alt="Star review"
                                  title="Star review"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/starred_review.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{INTERNETIMAGE}}') && (
                              <img
                                  alt="e-book"
                                  title="e-book"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/internet_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{AUDIOIMAGE}}') && (
                              <img
                                  alt="Audiobook"
                                  title="Audiobook"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/headphones_image.gif'
                              />
                          )}
                          {currentProductInfo.ShortWebImprint && currentProductInfo.ShortWebImprint.includes('{{VIDEOIMAGE}}') && (
                              <img
                                  alt="Video"
                                  title="Video"
                                  style={{width: 20}}
                                  src='https://www.booklistonline.com/images/camcorder_image.gif'
                              />
                          )}
                        </div>
                    )}
                    <ProductItemCitation>
                      <ProductItemCitationBody>
                        {!authenticatedToView && (
                            <Typography font="SM" themeColor="black" fontWeight="400">
                              {
                                <div
                                    onClick={contentClickHandler}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(currentProductInfo.LongImprintProcessed.substring(0, 600).substring(0, currentProductInfo.LongImprintProcessed.substring(0, 600).lastIndexOf(' ')) + "...", purifyConfig),
                                    }}
                                />
                              }
                            </Typography>
                        )}
                        {authenticatedToView && (
                            <Typography font="SM" themeColor="black" fontWeight="400">
                              {
                                <div
                                    onClick={contentClickHandler}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(currentProductInfo.LongImprintProcessed, purifyConfig),
                                    }}
                                />
                              }
                            </Typography>
                        )}
                      </ProductItemCitationBody>

                      {(authenticatedToView && currentProductInfo.Authors && currentProductInfo.Authors.length > 0) && (
                          <div style={{marginTop: 20}}>
                            {currentProductInfo.Authors.map((value) => (
                                <>
                                  {value && (
                                      <>
                                        <a href="#" onClick={handleAuthorsSearch(value)}>
                                          <Typography font="SM" themeColor="black" fontWeight="400">
                                            More titles by {value}
                                          </Typography>
                                        </a>
                                        <br/>
                                      </>
                                  )}
                                </>
                            ))}

                          </div>
                      )}
                      <Visible lg xs sm md>
                        <div
                            style={{display: 'flex', gap: 15, marginTop: 15}}
                            onClick={contentClickHandler}
                            dangerouslySetInnerHTML={{
                              __html: currentProductInfo.catalogProd?.BuyButtons,
                            }}
                        />
                        <div
                            className={'productRelations'}
                            style={{marginBottom: 15}}
                            onClick={contentClickHandler}
                            dangerouslySetInnerHTML={{
                              __html: currentProductInfo.catalogProd?.Relations,
                            }}
                        />
                        <ProductItemToolboxMobile>
                          {lsUserId && (
                              <>
                                <Button themeColor="white" onClick={handleAddList} backgroundColor="black" variant="narrow">
                                  <AddToListIcon />
                                  Add To List
                                </Button>
                                <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={exportToCsv}>
                                  <DownloadIcon />
                                  Download
                                </Button>
                                <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={handlePrint} >
                                  <PrintIcon />
                                  Print
                                </Button>
                                {/* <Button themeColor="white" backgroundColor="black" variant="narrow">
                              <EmailIcon />
                              Email
                            </Button> */}
                              </>
                          )}
                        </ProductItemToolboxMobile>
                      </Visible>
                      {!authenticatedToView && (<AccessRestricted />)}
                    </ProductItemCitation>
                  </ProductItemWrapper>

                </>
            )}
          </ProductInfoPageRenderer>
        </SiteInfoPageContainerRenderer>
        <Visible xl xxl>
          <ADContainerRenderer>
            <Visible xl xxl>
              <ProductItemToolbox>
                {lsUserId && (<>
                  <Button themeColor="white" onClick={handleAddList} backgroundColor="black" variant="narrow">
                    <AddToListIcon />
                    Add To List
                  </Button>
                  <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={exportToCsv}>
                    <DownloadIcon />
                    Download
                  </Button>
                  <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={handlePrint} >
                    <PrintIcon />
                    Print
                  </Button>
                  {/* <Button themeColor="white" backgroundColor="black" variant="narrow">
                            <EmailIcon />
                            Email
                          </Button> */}
                </>)}
                <div
                    style={{marginBottom: 18, marginTop: 18, display: 'flex', flexDirection: 'column',gap: 10}}
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                      __html: currentProductInfo.catalogProd?.BuyButtons,
                    }}
                />
                <div
                    style={{marginBottom: 10}}
                    className={'productRelations'}
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                      __html: currentProductInfo.catalogProd?.Relations,
                    }}
                />
              </ProductItemToolbox>
            </Visible>
          </ADContainerRenderer>
        </Visible>
      </FullPageContainerRenderer>
    </ResponsiveLayout>
  );
};

export default ProductInfoPage;
