import styled from 'styled-components';

const RecentOnlineReviewsRenderer = styled.div`
  margin-top: 24px;  
  flex: 1 1 0px;
  flex-direction: column;
  display: flex;
  background-color: lightgrey;
  margin-bottom: 0px;
  span {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const RecentOnlineReviewsHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 12px;
  span {
    flex: 1;
    text-align: left;
    line-height: 50px;
  }
`;

const SliderContainerRenderer = styled.div`
  height: 250px;
  width: 100%;
  max-width: calc(100vw - var(--scrollbar-width));
  min-width: 0;
  .slick-slider {
    margin-left: 50px;
    margin-right: 50px;
  }
  .slick-list {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const RecentTopRenderer = styled.div`
  margin-top: 0px;
  max-width: 1000px;
  flex: 0 1 0px;
  display: flex;
  align-self: center;
  flex-direction: column;
`;

const RecentTopMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;  
  img {
    width: 100%;
  }
  span:not(:first-child) {
    margin-top: 7px;
  }
  div:last-child {
    border-bottom: 0;
  }
`;

const RecentTopSub = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;  
  gap: 20px;
  img {
    width: 100%;
  }
  span:not(:first-child) {
    margin-top: 7px;
  }  
`;

const RecentTopSubItem = styled.div`
  flex: 1;
  min-width: 200px;
  display: flex;
  padding-top: 0px;
  padding-bottom: 12px;
  gap: 10px;
`;

const RecentTopItem = styled.div`
  flex: 1;  
  display: flex;
  padding-top: 0px;
  padding-bottom: 12px;
  gap: 10px;
`;

const RecentTopImage = styled.div`
  flex: 1;
  margin-bottom: 12px;
  min-width: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;

const RecentTopSubImage = styled.div`
  flex: 0;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: auto;
  }
`;


const RecentTopLink = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RecentTopSide = styled.div`
  flex: 0 0 200px;
  border-left: 2px solid lightgrey;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
`;

const RecentTopSideLink = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export {
  RecentOnlineReviewsRenderer,
  RecentOnlineReviewsHeader,
  SliderContainerRenderer,
  RecentTopRenderer,
  RecentTopMain,
  RecentTopItem,
  RecentTopImage,
  RecentTopLink,
  RecentTopSide,
  RecentTopSideLink,
  RecentTopSub,
  RecentTopSubItem,
  RecentTopSubImage
};
