import React from 'react';
import Typography from '@Components/controls/Typography';
import {AccessRestrictedWrapper} from "@Components/sections/Common/AccessRestricted/Renderers";

const AccessRestricted = () => {
    return (
        <AccessRestrictedWrapper>
            <Typography font="XS" themeColor="red" fontWeight="400">
                This page is for subscribers only. Want to see more of the premier readers’ advisory and collection development resource?
            </Typography>
            <ul>
                <li>
                    <Typography  font="SM"  fontWeight="400">
                        Please LOG IN at the upper right to access this content.
                    </Typography>
                </li>
                <li>
                    <Typography  font="SM"  fontWeight="400">
                        Booklist subscribers without a profile, click to <a href="https://www.booklistonline.com/profile" title="PRINT REGISTRATION">CREATE A PROFILE.</a>
                    </Typography>
                </li>
                <li>
                    <Typography font="SM" fontWeight="400">
                        <a href="https://bls.pcdfusion.com/pcd/Order?iKey=I**NEW" title="SUBSCRIBE">SUBSCRIBE</a> to <i>Booklist</i>  print + single-user online access.
                    </Typography>
                </li>
                <li>
                    <Typography  font="SM"  fontWeight="400">
                        <a href="https://www.booklistonline.com/UserSubscription.aspx" title="PURCHASE">PURCHASE</a>  an unlimited-user online account for your building(s).
                    </Typography>
                </li>
                <li>
                    <Typography  font="SM"  fontWeight="400">
                        <a href="/trialreg" title="REGISTER">REGISTER</a>  for 14-days of FREE access to Booklist Online.
                    </Typography>
                </li>
                <li>
                    <Typography  font="SM"  fontWeight="400">
                        Questions? Need help? <a href="mailto:info@booklistonline.com" title="CONTACT US">CONTACT US.</a>
                    </Typography>
                </li>
            </ul>
            <Typography font="XS" themeColor="red" fontWeight="400">
                When you subscribe to Booklist, you’ll receive full access to the most trusted book and audio reviews and articles from the experts at the American Library Association.
            </Typography>
        </AccessRestrictedWrapper>
    )
}

export default AccessRestricted