import React from 'react';
import {NavigationAreaRenderer} from '@Components/sections/Footer/NavigationArea/Renderers';
import NavigationItem from '@Components/sections/Footer/NavigationArea/NavigationItem';
import {NavigationSubitemRenderer} from '@Components/sections/Footer/NavigationArea/NavigationItem/Renderers';
import {Link} from "react-router-dom";

const NavigationArea = () => {
    return (
        <NavigationAreaRenderer>
            <NavigationItem itemId="1" title="Newsletters" link={'/newsletters'} />
            <NavigationItem itemId="2" title="Reviewers" link={'/reviewers'} />
            <NavigationItem itemId="3" title="Advertise" link={'/advertise'} />
            <NavigationItem itemId="4" title="Webinars" link={'/upcomingWebinars'} />
            <NavigationItem itemId="5" title="Advisory Board" link={'/staff'} />
            <NavigationItem itemId="6" title="Activate Profile" link={'/printreg'} />
            <NavigationItem itemId="7" title="Blog" link={'/booklistBlog'} />
            <NavigationItem itemId="8" title="Staff" link={'/staff'} />
            <NavigationItem itemId="9" title="Subscribe" link={'/subscribe'} />
        </NavigationAreaRenderer>
    );
};

export default NavigationArea;
