import React, {useCallback, useEffect} from 'react';
import HomePageRenderer from '@Components/Pages/Home/Renderers';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import ReviewOfTheDay from '@Components/Pages/Home/ReviewOfTheDay';
import CurrentIssue from '@Components/Pages/Home/CurrentIssue';
import RecentOnlineReviews from '@Components/Pages/Home/RecentOnlineReviews';
import SignUpArea from '@Components/Pages/Home/SignUpArea';
import BooklistReader from '@Components/Pages/Home/BooklistReader';
import Announcement from '@Components/Pages/Home/Announcement';
import {appInterface, searchResultsInterface, userInterface} from "@Store";
import useWindowDimensions from "@Utils/dimensions";
import {useLocalStorage} from "@Utils/localStorage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthenticateRefUrl} from "@Api/user";
import {ROUTES} from "@Utils/routing";

const HomePage = () => {
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        document.title = "Booklist Online: Leading Book Discovery";
    }, []);

    const authenticateReferrer = useCallback(async (refer, accid) => {

        const { Authenticated, account } = await AuthenticateRefUrl({useraccid: accid, userrefurl: refer});

        if(Authenticated && !lsAccountId) {
            setLSAccountId(accid);
        }
        if(Authenticated && account) {
            userInterface.dispatch(userInterface.setUserAccount({account: account}));
        }
        if(Authenticated && (!lsAuthModeId || lsAuthModeId === '5')) {
            setLSAuthModeId('7');
        }
        window.location.href='/';
    }, [setLSAccountId, setLSAuthModeId]);

    const [searchParams, setSearchParams] = useSearchParams()
    const refrr = document.referrer;
    // const refrr = 'https://www.booklistonline.com/';

    useEffect(() => {
        if(refrr && searchParams.get('libraryId')){
            authenticateReferrer(refrr,searchParams.get('libraryId'))
            console.log("!!!");
            console.log(searchParams.get('libraryId'));
        }
    }, [refrr, searchParams]);

    useEffect(() => {
        if(setLSUserId && lsUserId && lsUserId === '6678932890') {
            setLSUserId('9e6c1b73-e1cd-4943-be13-edd9e121ca8f');
            setLSAuthModeId('3')
        }
    }, [lsUserId, setLSUserId]);

    useEffect(() => {
       if(width < 800) {
           appInterface.dispatch(appInterface.setQuickSearchOpen({ quickSearchOpen: false }));
       }
    }, [width]);

  return (
    <ResponsiveLayout>
      <HomePageRenderer>
        <ReviewOfTheDay />
        <CurrentIssue />
        <RecentOnlineReviews />
      </HomePageRenderer>
    </ResponsiveLayout>
  );
};

export default HomePage;
