import { createGlobalStyle, css } from 'styled-components';

const DevModeStyleSwitch = createGlobalStyle`
[data-future="true"] {
  ${({ devMode }) => css`
    ${devMode ? 'opacity: 0.7' : 'display: none'};
  `}
}
`;
export default DevModeStyleSwitch;
