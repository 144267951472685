import styled from "styled-components";

const RegisterForFreeRenderer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ul {
    list-style-type: disc;
    margin-left: 40px;
  }
  button {
    margin-top: 30px;
  }
`;

const RegisterHeader = styled.span`
  font-family: freight-sans-pro, serif;
  font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  width: 336px;
  margin-top: 20px;
`;

const RegisterSubHeader = styled.li`
  font-family: freight-sans-pro, serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  width: 290px;
  margin-top: 10px;
`;

const PrintSubscriber = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  span {
    font-family: freight-sans-pro, serif;
    margin-top: 20px;
    color: #8f8f8f;
  }
`;

const RegisterMobileSeparator = styled.span`
  display: block;
  background-color: black;
  border-radius: 50%;
  font-family: freight-sans-pro,serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  color: white;
  padding: 15px;
  align-self: center;
  margin-top: 30px;
  z-index: 2;
  border-left: 30px solid lightgrey;
  border-right: 30px solid lightgrey;
`;

const RegisterMobileSeparatorLines = styled.hr`
  border-top: 1px solid black;
  height: 2px;
  width: 100%;
  position: relative;
  top: 75px;
  z-index: 1;
`;

const RegisterMobileHeader = styled.span`
  font-family: freight-sans-pro,serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 28px;
  width: 336px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
`;

const RegisterMobileSubHeader  = styled.span`
  font-family: freight-sans-pro, serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  width: 336px;
  margin-top: 20px;
`;

export { RegisterForFreeRenderer, RegisterHeader, RegisterSubHeader, PrintSubscriber, RegisterMobileSeparator, RegisterMobileHeader, RegisterMobileSubHeader, RegisterMobileSeparatorLines }
