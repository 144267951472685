import http from '@Utils/network';

const GET_ANNOUNCEMENT_ENDPOINT = () => `api/EusAdmin/Announcement`;
const PUT_ANNOUNCEMENT_ENDPOINT = () => `api/EusAdmin/UpdateAnnouncement`;

const getAnnouncement = async () => {
    const curIssues = await http.get(GET_ANNOUNCEMENT_ENDPOINT());
    return curIssues;
};

const putAnnouncement = async (data) => {
    const retVal = await http.put(PUT_ANNOUNCEMENT_ENDPOINT(), data);
    return retVal;
};


export { getAnnouncement, putAnnouncement };