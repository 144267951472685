import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { announcementInterface } from '@Store';
import { getAnnouncement } from '@Api/announcement';

export const useHandleLoadAnnouncement = (clearSelf) => {
    const currentAnnouncement = useSelector(announcementInterface.getCurrentAnnouncement);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const {data} = await getAnnouncement();
        announcementInterface.dispatch(announcementInterface.setCurrentAnnouncement(data));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) announcementInterface.dispatch(announcementInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentAnnouncement) loadData();
    }, [currentAnnouncement, loadData]);

    return [loadData, isLoading];
};