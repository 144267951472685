import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { searchResultsInterface } from '@Store';
import { quickSearch } from '@Api/search';

export const useHandleLoadSearchResults = () => {
  const items = useSelector(searchResultsInterface.getSearchResults);
  const totalCount = useSelector(searchResultsInterface.getTotalCount);
  const page = useSelector(searchResultsInterface.getPage);
  const pageSize = useSelector(searchResultsInterface.getPageSize);
  const sortOrder = useSelector(searchResultsInterface.getSortOrder);
  const selectedIDs = useSelector(searchResultsInterface.getSelectedIDs);
  const searchOptions = useSelector(searchResultsInterface.getSearchOptions);

  const loadedCount = items.length;

  const [isLoading, setDataLoading] = useState(false);

  const loadData = useCallback(async () => {
    setDataLoading(true);
    const retData = await quickSearch({
      page,
      pageSize,
      sortOrder,
      ...searchOptions,
    });
    const { totalCount, searchResults } = retData;
    searchResultsInterface.dispatch(searchResultsInterface.setSearchResults({ totalCount, searchResults }));
    setDataLoading(false);
  }, [page, pageSize, sortOrder, searchOptions]);

  useEffect(() => {
    /** Here we clear store, otherwise we append new data to already loaded data */
    /** This also sets loadedCount to 0 and fires loadData effect */
    searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
  }, [page, pageSize, sortOrder, searchOptions]);

  useEffect(() => {
    if (!loadedCount) loadData();
  }, [loadedCount, loadData]);

  return [loadData, isLoading];
};
