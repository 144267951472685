import styled from 'styled-components';

const NavigationItemRenderer = styled.li`
  position: relative;
  transition-property: background-color, color;
  transition-duration: 0.4s;

  &:hover {
    background-color: black;
    color: white;
    ul {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
  &:active {
    background-color: black;
    color: white;
  }

  & > a {
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    padding: 16px;
  }

    &:last-child > ul {
        left: auto;
        right: 0;
    }
`;

const NavigationSublistRenderer = styled.ul`
  padding: 16px;
  background-color: white;
  color: black;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top;
  transition-property: opacity, visibility, transform, max-height, padding;
  transition-duration: 0.4s;
  position: absolute;
  left: 0;
  top: 100%;
  width: 210px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid black;    
`;

const NavigationSubitemRenderer = styled.li`
  margin-bottom: 16px;
  z-index: 555;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    line-height: 130%;
    transition-property: border-bottom;
    transition-duration: 0.4s;
  }

  &:hover {
    a {
      border-bottom: 3px dotted black;
    }
  }
`;

export { NavigationItemRenderer, NavigationSublistRenderer, NavigationSubitemRenderer };
