import React from 'react';
import * as propTypes from 'prop-types';
import TypographyRenderer from './Renderers';

const Typography = (props) => {
  const {
    font = 'XS',
    fontWeight = '400',
    fontFamily,
    fontStyle,
    children,
    themeColor = 'black',
    textAlign,
    title,
    onClick,
      style
  } = props;
  return (
    <TypographyRenderer
      font={font}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      themeColor={themeColor}
      textAlign={textAlign}
      title={title}
      onClick={onClick}
      style={style}
    >
      {children}
    </TypographyRenderer>
  );
};

Typography.propTypes = {
  font: propTypes.string,
  themeColor: propTypes.string,
  children: propTypes.node,
  textAlign: propTypes.string,
  title: propTypes.string,
  fontWeight: propTypes.string,
  fontFamily: propTypes.string,
  fontStyle: propTypes.string,
};

export default Typography;
