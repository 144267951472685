import React, {useCallback, useState} from 'react';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import { Visible } from 'react-grid-system';
import { TrialRegistrationPageRenderer } from '@Components/Pages/TrialRegistration/Renderers';
import {
    Autocomplete,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {ReCAPTCHA} from "react-google-recaptcha";
import Reaptcha from "reaptcha";
import {notification} from "antd";
import {PrintSubscription, TrialReg} from "@Api/user";

const TrialRegistrationPage = () => {
    const [values, setValues] = React.useState({
        accountNumber: '',
        firstName: '',
        lastName: '',
        profession: '',
        institution: '',
        institutionName: '',
        job: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        username: '',
        password: '',
        passwordRepeat: '',
        recap:false,
        showPassword: false,
        allfilled: false
    });

    const [api, contextHolder] = notification.useNotification();
    const notifySuccess = (placement, message) => {
        api.success({
            message: "Success",
            description: (<div dangerouslySetInnerHTML={{__html: message}}/>),
            placement,
            duration: 10,
            style: {
                width: 600,
            },
        });
    };
    const notifyFail = (placement, message) => {
        api.error({
            message: message,
            placement,
        });
    };

    const onSubscribe = useCallback(async (e) => {
        const resp = await TrialReg(values);
        if(resp.Success) {
            notifySuccess('top', resp.Message)
        }
        else
        {
            notifyFail('top', resp.ErrorMessage)
        }
    }, [values, notifySuccess, notifyFail]);

    const validate = function(obj) {
        return obj && obj.firstName && obj.lastName && obj.profession && obj.institution && obj.institutionName && obj.job && obj.address1 && obj.city && obj.country && obj.state && obj.zip && obj.phone && obj.email && obj.username && obj.password && obj.passwordRepeat && (obj.passwordRepeat === obj.password) && obj.recap;
    }

    const onRecaptchaVerify = useCallback((e) => {
        setValues({
            ...values,
            recap: true,
            allfilled: validate({
                ...values,
                recap: true,
            })
        });

    }, [values]);

    const handleFNameChange = useCallback((e) => {
        setValues({
            ...values,
            firstName: e.target.value,
            allfilled: validate({
                ...values,
                firstName: e.target.value,
            })
        });
    }, [values]);
    const handleLNameChange = useCallback((e) => {
        setValues({
            ...values,
            lastName: e.target.value,
            allfilled: validate({
                ...values,
                lastName: e.target.value,
            })
        });
    }, [values]);
    const handleProfChange = useCallback((e) => {
        setValues({
            ...values,
            profession: e.target.value,
            allfilled: validate({
                ...values,
                profession: e.target.value,
            })
        });
    }, [values]);
    const handleInstChange = useCallback((e) => {
        setValues({
            ...values,
            institution: e.target.value,
            allfilled: validate({
                ...values,
                institution: e.target.value,
            })
        });
    }, [values]);
    const handleInstNameChange = useCallback((e) => {
        setValues({
            ...values,
            institutionName: e.target.value,
            allfilled: validate({
                ...values,
                institutionName: e.target.value,
            })
        });
    }, [values]);
    const handleJobChange = useCallback((e) => {
        setValues({
            ...values,
            job: e.target.value,
            allfilled: validate({
                ...values,
                job: e.target.value,
            })
        });
    }, [values]);
    const handleAddr1Change = useCallback((e) => {
        setValues({
            ...values,
            address1: e.target.value,
            allfilled: validate({
                ...values,
                address1: e.target.value,
            })
        });
    }, [values]);
    const handleAddr2Change = useCallback((e) => {
        setValues({
            ...values,
            address2: e.target.value,
            allfilled: validate({
                ...values,
                address2: e.target.value,
            })
        });
    }, [values]);
    const handleCityChange = useCallback((e) => {
        setValues({
            ...values,
            city: e.target.value,
            allfilled: validate({
                ...values,
                city: e.target.value,
            })
        });
    }, [values]);
    const handleStateChange = useCallback((e) => {
        setValues({
            ...values,
            state: e.target.value,
            allfilled: validate({
                ...values,
                state: e.target.value,
            })
        });
    }, [values]);
    const handleZipChange = useCallback((e) => {
        setValues({
            ...values,
            zip: e.target.value,
            allfilled: validate({
                ...values,
                zip: e.target.value,
            })
        });
    }, [values]);
    const handlePhoneChange = useCallback((e) => {
        setValues({
            ...values,
            phone: e.target.value,
            allfilled: validate({
                ...values,
                phone: e.target.value,
            })
        });
    }, [values]);
    const handleEmailChange = useCallback((e) => {
        setValues({
            ...values,
            email: e.target.value,
            allfilled: validate({
                ...values,
                email: e.target.value,
            })
        });
    }, [values]);
    const handleUnameChange = useCallback((e) => {
        setValues({
            ...values,
            username: e.target.value,
            allfilled: validate({
                ...values,
                username: e.target.value,
            })
        });
    }, [values]);
    const handlePasswChange = useCallback((e) => {
        setValues({
            ...values,
            password: e.target.value,
            allfilled: validate({
                ...values,
                password: e.target.value,
            })
        });
    }, [values]);
    const handlePasswRepChange = useCallback((e) => {
        setValues({
            ...values,
            passwordRepeat: e.target.value,
            allfilled: validate({
                ...values,
                passwordRepeat: e.target.value,
            })
        });
    }, [values]);

    const handleClickShowPassword = useCallback((e) => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
            allfilled: validate({
                ...values,
                accountNumber: e.target.value,
            })
        });
    }, [values]);

    const handleCountryChange = useCallback((e) => {
        setValues({
            ...values,
            country: e.target.value,
            allfilled: validate({
                ...values,
                country: e.target.value,
            })
        });
    }, [values]);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function onCaptchaChange(value) {
        console.log("Captcha value:", value);
    }

    return (
    <ResponsiveLayout>
        {contextHolder}
      <TrialRegistrationPageRenderer>
          <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
              Free Trial Subscription
          </Typography>
          <Typography font="SM" fontStyle="italic">
              We encourage you to to try Booklist Online free for 14 days.
              (Each user is limited to one free trial.)
          </Typography>
          <Typography font="SM" fontWeight="600">
              Please tell us:
          </Typography>
          <TextField id="fName" onChange={handleFNameChange} label="First name" variant="standard" required sx={{ paddingBottom: 1 }} />
          <TextField id="lName" onChange={handleLNameChange} label="Last name" variant="standard" required sx={{ paddingBottom: 1 }} />
          <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
              <InputLabel id="pProfessionL">Your profession</InputLabel>
              <Select onChange={handleProfChange} labelId="pProfessionL" id="pProfession" label="Your profession">
                  <MenuItem value={10}>Academic Librarian</MenuItem>
                  <MenuItem value={20}>Public Librarian</MenuItem>
                  <MenuItem value={30}>School Librarian</MenuItem>
                  <MenuItem value={40}>Publisher</MenuItem>
                  <MenuItem value={40}>Individual Reader</MenuItem>
              </Select>
          </FormControl>
          <TextField id="fJob" onChange={handleJobChange} label="Job title" variant="standard" required sx={{ paddingBottom: 1 }} />
          <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
              <InputLabel id="pInstitutionL">Your profession</InputLabel>
              <Select onChange={handleInstChange} labelId="pInstitutionL" id="pInstitution" label="Institution">
                  <MenuItem value={1}>Other</MenuItem>
                  <MenuItem value={2}>Elementary School</MenuItem>
                  <MenuItem value={3}>Middle School</MenuItem>
                  <MenuItem value={4}>High School</MenuItem>
                  <MenuItem value={5}>Combined Middle and High School</MenuItem>
                  <MenuItem value={6}>School District</MenuItem>
                  <MenuItem value={7}>Public Library</MenuItem>
                  <MenuItem value={8}>Library System</MenuItem>
                  <MenuItem value={9}>Statewide Department</MenuItem>
                  <MenuItem value={10}>Community College</MenuItem>
                  <MenuItem value={11}>College/University</MenuItem>
                  <MenuItem value={12}>Business</MenuItem>
                  <MenuItem value={13}>Nonprofit</MenuItem>
                  <MenuItem value={14}>Individual</MenuItem>
                  <MenuItem value={15}>Combined Middle and High School</MenuItem>
                  <MenuItem value={16}>Middle School</MenuItem>
                  <MenuItem value={17}>Combined K-12 School</MenuItem>
              </Select>
          </FormControl>
          <TextField id="fInstitution" onChange={handleInstNameChange} label="Institution name" variant="standard" required sx={{ paddingBottom: 1 }} />
          <TextField id="fAddress1" onChange={handleAddr1Change} label="Address 1" variant="standard" required sx={{ paddingBottom: 1 }} />
          <TextField id="fAddress2" onChange={handleAddr2Change} label="Address 2" variant="standard" required sx={{ paddingBottom: 1 }} />
          <TextField id="fCity" onChange={handleCityChange} label="City" variant="standard" required sx={{ paddingBottom: 1 }} />
          <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
              <InputLabel id="pCountryL">Country</InputLabel>
              <Select onChange={handleCountryChange} labelId="pCountryL" onChange={handleCountryChange} id="pCountry" label="Country">
                  <MenuItem value="-1" >NONE</MenuItem>
                  <MenuItem value="1" >Afghanistan</MenuItem>
                  <MenuItem value="2" >Albania</MenuItem>
                  <MenuItem value="3" >Algeria</MenuItem>
                  <MenuItem value="4" >American Samoa</MenuItem>
                  <MenuItem value="5" >Andorra</MenuItem>
                  <MenuItem value="6" >Angola</MenuItem>
                  <MenuItem value="7" >Anguilla</MenuItem>
                  <MenuItem value="8" >Antarctica</MenuItem>
                  <MenuItem value="9" >Antigua and Barbuda</MenuItem>
                  <MenuItem value="10" >Argentina</MenuItem>
                  <MenuItem value="11" >Armenia</MenuItem>
                  <MenuItem value="12" >Aruba</MenuItem>
                  <MenuItem value="13" >Austria</MenuItem>
                  <MenuItem value="14" >Azerbaijan</MenuItem>
                  <MenuItem value="15" >Bahamas</MenuItem>
                  <MenuItem value="16" >Bahrain</MenuItem>
                  <MenuItem value="17" >Bangladesh</MenuItem>
                  <MenuItem value="18" >Barbados</MenuItem>
                  <MenuItem value="19" >Belarus</MenuItem>
                  <MenuItem value="20" >Belgium</MenuItem>
                  <MenuItem value="21" >Belize</MenuItem>
                  <MenuItem value="22" >Benin</MenuItem>
                  <MenuItem value="23" >Bermuda</MenuItem>
                  <MenuItem value="24" >Bhutan</MenuItem>
                  <MenuItem value="25" >Bolivia</MenuItem>
                  <MenuItem value="26" >Bosnia and Herzegovina</MenuItem>
                  <MenuItem value="27" >Botswana</MenuItem>
                  <MenuItem value="28" >Bouvet Island</MenuItem>
                  <MenuItem value="29" >Brazil</MenuItem>
                  <MenuItem value="30" >British Indian Ocean Territory</MenuItem>
                  <MenuItem value="31" >Brunei Darussalam</MenuItem>
                  <MenuItem value="32" >Bulgaria</MenuItem>
                  <MenuItem value="33" >Burkina Faso</MenuItem>
                  <MenuItem value="34" >Burundi</MenuItem>
                  <MenuItem value="35" >Cambodia</MenuItem>
                  <MenuItem value="36" >Cameroon</MenuItem>
                  <MenuItem value="37" >Canada</MenuItem>
                  <MenuItem value="38" >Cape Verde</MenuItem>
                  <MenuItem value="39" >Cayman Islands</MenuItem>
                  <MenuItem value="40" >Central African Republic</MenuItem>
                  <MenuItem value="41" >Chad</MenuItem>
                  <MenuItem value="42" >Chile</MenuItem>
                  <MenuItem value="43" >China</MenuItem>
                  <MenuItem value="44" >Christmas Island</MenuItem>
                  <MenuItem value="45" >Cocos (Keeling) Islands</MenuItem>
                  <MenuItem value="46" >Colombia</MenuItem>
                  <MenuItem value="47" >Comoros</MenuItem>
                  <MenuItem value="48" >Congo</MenuItem>
                  <MenuItem value="49" >Congo, The Democratic Republic of The</MenuItem>
                  <MenuItem value="50" >Cook Islands</MenuItem>
                  <MenuItem value="51" >Costa Rica</MenuItem>
                  <MenuItem value="52" >Cote D'ivoire</MenuItem>
                  <MenuItem value="53" >Croatia</MenuItem>
                  <MenuItem value="54" >Cuba</MenuItem>
                  <MenuItem value="55" >Cyprus</MenuItem>
                  <MenuItem value="56" >Czech Republic</MenuItem>
                  <MenuItem value="57" >Denmark</MenuItem>
                  <MenuItem value="58" >Djibouti</MenuItem>
                  <MenuItem value="59" >Dominica</MenuItem>
                  <MenuItem value="60" >Dominican Republic</MenuItem>
                  <MenuItem value="61" >Ecuador</MenuItem>
                  <MenuItem value="62" >Egypt</MenuItem>
                  <MenuItem value="63" >El Salvador</MenuItem>
                  <MenuItem value="64" >Equatorial Guinea</MenuItem>
                  <MenuItem value="65" >Eritrea</MenuItem>
                  <MenuItem value="66" >Estonia</MenuItem>
                  <MenuItem value="67" >Ethiopia</MenuItem>
                  <MenuItem value="68" >Falkland Islands (Malvinas)</MenuItem>
                  <MenuItem value="69" >Faroe Islands</MenuItem>
                  <MenuItem value="70" >Fiji</MenuItem>
                  <MenuItem value="71" >Finland</MenuItem>
                  <MenuItem value="72" >France</MenuItem>
                  <MenuItem value="73" >French Guiana</MenuItem>
                  <MenuItem value="74" >French Polynesia</MenuItem>
                  <MenuItem value="75" >French Southern Territories</MenuItem>
                  <MenuItem value="76" >Gabon</MenuItem>
                  <MenuItem value="77" >Gambia</MenuItem>
                  <MenuItem value="78" >Georgia</MenuItem>
                  <MenuItem value="79" >Germany</MenuItem>
                  <MenuItem value="80" >Ghana</MenuItem>
                  <MenuItem value="81" >Gibraltar</MenuItem>
                  <MenuItem value="82" >Greece</MenuItem>
                  <MenuItem value="83" >Greenland</MenuItem>
                  <MenuItem value="84" >Grenada</MenuItem>
                  <MenuItem value="85" >Guadeloupe</MenuItem>
                  <MenuItem value="86" >Guam</MenuItem>
                  <MenuItem value="87" >Guatemala</MenuItem>
                  <MenuItem value="88" >Guinea</MenuItem>
                  <MenuItem value="89" >Guinea-bissau</MenuItem>
                  <MenuItem value="90" >Guyana</MenuItem>
                  <MenuItem value="91" >Haiti</MenuItem>
                  <MenuItem value="92" >Heard Island and Mcdonald Islands</MenuItem>
                  <MenuItem value="93" >Holy See (Vatican City State)</MenuItem>
                  <MenuItem value="94" >Honduras</MenuItem>
                  <MenuItem value="95" >Hong Kong</MenuItem>
                  <MenuItem value="96" >Hungary</MenuItem>
                  <MenuItem value="97" >Iceland</MenuItem>
                  <MenuItem value="98" >India</MenuItem>
                  <MenuItem value="99" >Indonesia</MenuItem>
                  <MenuItem value="100" >Iran, Islamic Republic of</MenuItem>
                  <MenuItem value="101" >Iraq</MenuItem>
                  <MenuItem value="102" >Ireland</MenuItem>
                  <MenuItem value="103" >Israel</MenuItem>
                  <MenuItem value="104" >Italy</MenuItem>
                  <MenuItem value="105" >Jamaica</MenuItem>
                  <MenuItem value="106" >Japan</MenuItem>
                  <MenuItem value="107" >Jordan</MenuItem>
                  <MenuItem value="108" >Kazakhstan </MenuItem>
                  <MenuItem value="109" >Kenya</MenuItem>
                  <MenuItem value="110" >Kiribati</MenuItem>
                  <MenuItem value="111" >Korea, Democratic People's Republic of</MenuItem>
                  <MenuItem value="112" >Korea, Republic of</MenuItem>
                  <MenuItem value="113" >Kuwait</MenuItem>
                  <MenuItem value="114" >Kyrgyzstan</MenuItem>
                  <MenuItem value="115" >Lao People's Democratic Republic</MenuItem>
                  <MenuItem value="116" >Latvia</MenuItem>
                  <MenuItem value="117" >Lebanon</MenuItem>
                  <MenuItem value="118" >Lesotho</MenuItem>
                  <MenuItem value="119" >Liberia</MenuItem>
                  <MenuItem value="120" >Libyan Arab Jamahiriya</MenuItem>
                  <MenuItem value="121" >Liechtenstein</MenuItem>
                  <MenuItem value="122" >Lithuania</MenuItem>
                  <MenuItem value="123" >Luxembourg</MenuItem>
                  <MenuItem value="124" >Macao</MenuItem>
                  <MenuItem value="125" >Macedonia, The Former Yugoslav Republic of</MenuItem>
                  <MenuItem value="126" >Madagascar</MenuItem>
                  <MenuItem value="127" >Malawi</MenuItem>
                  <MenuItem value="128" >Malaysia</MenuItem>
                  <MenuItem value="129" >Maldives</MenuItem>
                  <MenuItem value="130" >Mali</MenuItem>
                  <MenuItem value="131" >Malta</MenuItem>
                  <MenuItem value="132" >Marshall Islands</MenuItem>
                  <MenuItem value="133" >Martinique</MenuItem>
                  <MenuItem value="134" >Mauritania</MenuItem>
                  <MenuItem value="135" >Mauritius</MenuItem>
                  <MenuItem value="136" >Mayotte</MenuItem>
                  <MenuItem value="137" >Mexico</MenuItem>
                  <MenuItem value="138" >Micronesia, Federated States of</MenuItem>
                  <MenuItem value="139" >Moldova, Republic of</MenuItem>
                  <MenuItem value="140" >Monaco</MenuItem>
                  <MenuItem value="141" >Mongolia</MenuItem>
                  <MenuItem value="142" >Montserrat</MenuItem>
                  <MenuItem value="143" >Morocco</MenuItem>
                  <MenuItem value="144" >Mozambique</MenuItem>
                  <MenuItem value="145" >Myanmar</MenuItem>
                  <MenuItem value="146" >Namibia</MenuItem>
                  <MenuItem value="147" >Nauru</MenuItem>
                  <MenuItem value="148" >Nepal</MenuItem>
                  <MenuItem value="149" >Netherlands</MenuItem>
                  <MenuItem value="150" >Netherlands Antilles</MenuItem>
                  <MenuItem value="151" >New Caledonia</MenuItem>
                  <MenuItem value="152" >New Zealand</MenuItem>
                  <MenuItem value="153" >Nicaragua</MenuItem>
                  <MenuItem value="154" >Niger</MenuItem>
                  <MenuItem value="155" >Nigeria</MenuItem>
                  <MenuItem value="156" >Niue</MenuItem>
                  <MenuItem value="157" >Norfolk Island</MenuItem>
                  <MenuItem value="158" >Northern Mariana Islands</MenuItem>
                  <MenuItem value="159" >Norway</MenuItem>
                  <MenuItem value="160" >Oman</MenuItem>
                  <MenuItem value="161" >Pakistan</MenuItem>
                  <MenuItem value="162" >Palau</MenuItem>
                  <MenuItem value="163" >Palestinian Territory, Occupied</MenuItem>
                  <MenuItem value="164" >Panama</MenuItem>
                  <MenuItem value="165" >Papua New Guinea</MenuItem>
                  <MenuItem value="166" >Paraguay</MenuItem>
                  <MenuItem value="167" >Peru</MenuItem>
                  <MenuItem value="168" >Philippines</MenuItem>
                  <MenuItem value="169" >Pitcairn</MenuItem>
                  <MenuItem value="170" >Poland</MenuItem>
                  <MenuItem value="171" >Portugal</MenuItem>
                  <MenuItem value="172" >Puerto Rico</MenuItem>
                  <MenuItem value="173" >Qatar</MenuItem>
                  <MenuItem value="174" >Reunion</MenuItem>
                  <MenuItem value="175" >Romania</MenuItem>
                  <MenuItem value="176" >Russian Federation</MenuItem>
                  <MenuItem value="177" >Rwanda</MenuItem>
                  <MenuItem value="178" >Saint Helena</MenuItem>
                  <MenuItem value="179" >Saint Kitts and Nevis</MenuItem>
                  <MenuItem value="180" >Saint Lucia</MenuItem>
                  <MenuItem value="181" >Saint Pierre and Miquelon</MenuItem>
                  <MenuItem value="182" >Saint Vincent and The Grenadines</MenuItem>
                  <MenuItem value="183" >Samoa</MenuItem>
                  <MenuItem value="184" >San Marino</MenuItem>
                  <MenuItem value="185" >Sao Tome and Principe</MenuItem>
                  <MenuItem value="186" >Saudi Arabia</MenuItem>
                  <MenuItem value="187" >Senegal</MenuItem>
                  <MenuItem value="188" >Serbia and Montenegro</MenuItem>
                  <MenuItem value="189" >Seychelles</MenuItem>
                  <MenuItem value="190" >Sierra Leone</MenuItem>
                  <MenuItem value="191" >Singapore</MenuItem>
                  <MenuItem value="192" >Slovakia</MenuItem>
                  <MenuItem value="193" >Slovenia</MenuItem>
                  <MenuItem value="194" >Solomon Islands</MenuItem>
                  <MenuItem value="195" >Somalia</MenuItem>
                  <MenuItem value="196" >South Africa</MenuItem>
                  <MenuItem value="197" >South Georgia and The South Sandwich Islands</MenuItem>
                  <MenuItem value="198" >Spain</MenuItem>
                  <MenuItem value="199" >Sri Lanka</MenuItem>
                  <MenuItem value="200" >Sudan</MenuItem>
                  <MenuItem value="201" >Suriname </MenuItem>
                  <MenuItem value="202" >Svalbard and Jan Mayen</MenuItem>
                  <MenuItem value="203" >Swaziland</MenuItem>
                  <MenuItem value="204" >Sweden</MenuItem>
                  <MenuItem value="205" >Switzerland</MenuItem>
                  <MenuItem value="206" >Syrian Arab Republic</MenuItem>
                  <MenuItem value="207" >Taiwan, Province of China</MenuItem>
                  <MenuItem value="208" >Tajikistan</MenuItem>
                  <MenuItem value="209" >Tanzania, United Republic of</MenuItem>
                  <MenuItem value="210" >Thailand</MenuItem>
                  <MenuItem value="211" >Timor-leste</MenuItem>
                  <MenuItem value="212" >Togo</MenuItem>
                  <MenuItem value="213" >Tokelau</MenuItem>
                  <MenuItem value="214" >Tonga</MenuItem>
                  <MenuItem value="215" >Trinidad and Tobago</MenuItem>
                  <MenuItem value="216" >Tunisia</MenuItem>
                  <MenuItem value="217" >Turkey</MenuItem>
                  <MenuItem value="218" >Turkmenistan</MenuItem>
                  <MenuItem value="219" >Turks and Caicos Islands</MenuItem>
                  <MenuItem value="220" >Tuvalu</MenuItem>
                  <MenuItem value="221" >Uganda</MenuItem>
                  <MenuItem value="222" >Ukraine</MenuItem>
                  <MenuItem value="223" >United Arab Emirates</MenuItem>
                  <MenuItem value="224" >United Kingdom</MenuItem>
                  <MenuItem value="225" >United States</MenuItem>
                  <MenuItem value="226" >United States Minor Outlying Islands</MenuItem>
                  <MenuItem value="227" >Uruguay</MenuItem>
                  <MenuItem value="228" >Uzbekistan</MenuItem>
                  <MenuItem value="229" >Vanuatu</MenuItem>
                  <MenuItem value="230" >Venezuela</MenuItem>
                  <MenuItem value="231" >Viet Nam</MenuItem>
                  <MenuItem value="232" >Virgin Islands, British</MenuItem>
                  <MenuItem value="233" >Virgin Islands, U.S.</MenuItem>
                  <MenuItem value="234" >Wallis and Futuna</MenuItem>
                  <MenuItem value="235" >Western Sahara</MenuItem>
                  <MenuItem value="236" >Yemen</MenuItem>
                  <MenuItem value="237" >Zambia</MenuItem>
                  <MenuItem value="238" >Zimbabwe</MenuItem>
                  <MenuItem value="240" >Australia</MenuItem>
              </Select>
          </FormControl>
          <FormControl variant="standard" sx={{paddingBottom: 1}}>
              <InputLabel id="pStateL">State or Province</InputLabel>
              <Select onChange={handleStateChange} labelId="pStateL" id="pState" label="State or Province">
                  <MenuItem value="1" >Alabama</MenuItem>
                  <MenuItem value="2" >Alaska</MenuItem>
                  <MenuItem value="3" >Arizona</MenuItem>
                  <MenuItem value="4" >Arkansas</MenuItem>
                  <MenuItem value="5" >California</MenuItem>
                  <MenuItem value="6" >Colorado</MenuItem>
                  <MenuItem value="7" >Connecticut</MenuItem>
                  <MenuItem value="8" >Delaware</MenuItem>
                  <MenuItem value="9" >District Of Columbia</MenuItem>
                  <MenuItem value="10" >Florida</MenuItem>
                  <MenuItem value="11" >Georgia</MenuItem>
                  <MenuItem value="12" >Hawaii</MenuItem>
                  <MenuItem value="13" >Idaho</MenuItem>
                  <MenuItem value="14" >Illinois</MenuItem>
                  <MenuItem value="15" >Indiana</MenuItem>
                  <MenuItem value="16" >Iowa</MenuItem>
                  <MenuItem value="17" >Kansas</MenuItem>
                  <MenuItem value="18" >Kentucky</MenuItem>
                  <MenuItem value="19" >Louisiana</MenuItem>
                  <MenuItem value="20" >Maine</MenuItem>
                  <MenuItem value="21" >Maryland</MenuItem>
                  <MenuItem value="22" >Massachusetts</MenuItem>
                  <MenuItem value="23" >Michigan</MenuItem>
                  <MenuItem value="24" >Minnesota</MenuItem>
                  <MenuItem value="25" >Mississippi</MenuItem>
                  <MenuItem value="26" >Missouri</MenuItem>
                  <MenuItem value="27" >Montana</MenuItem>
                  <MenuItem value="28" >Nebraska</MenuItem>
                  <MenuItem value="29" >Nevada</MenuItem>
                  <MenuItem value="30" >New Hampshire</MenuItem>
                  <MenuItem value="31" >New Jersey</MenuItem>
                  <MenuItem value="32" >New Mexico</MenuItem>
                  <MenuItem value="33" >New York</MenuItem>
                  <MenuItem value="34" >North Carolina</MenuItem>
                  <MenuItem value="35" >North Dakota</MenuItem>
                  <MenuItem value="36" >Ohio</MenuItem>
                  <MenuItem value="37" >Oklahoma</MenuItem>
                  <MenuItem value="38" >Oregon</MenuItem>
                  <MenuItem value="39" >Pennsylvania</MenuItem>
                  <MenuItem value="40" >Rhode Island</MenuItem>
                  <MenuItem value="41" >South Carolina</MenuItem>
                  <MenuItem value="42" >South Dakota</MenuItem>
                  <MenuItem value="43" >Tennessee</MenuItem>
                  <MenuItem value="44" >Texas</MenuItem>
                  <MenuItem value="45" >Utah</MenuItem>
                  <MenuItem value="46" >Vermont</MenuItem>
                  <MenuItem value="47" >Virginia</MenuItem>
                  <MenuItem value="48" >Washington</MenuItem>
                  <MenuItem value="49" >West Virginia</MenuItem>
                  <MenuItem value="50" >Wisconsin</MenuItem>
                  <MenuItem value="51" >Wyoming</MenuItem>
                  <MenuItem value="52" >Puerto Rico</MenuItem>
                  <MenuItem value="53" >Armed Forces</MenuItem>
                  <MenuItem value="54" >Alberta</MenuItem>
                  <MenuItem value="55" >British Columbia</MenuItem>
                  <MenuItem value="56" >Labrador</MenuItem>
                  <MenuItem value="57" >Manitoba</MenuItem>
                  <MenuItem value="58" >New Brunswick</MenuItem>
                  <MenuItem value="59" >Newfoundland</MenuItem>
                  <MenuItem value="60" >Northwest Territories</MenuItem>
                  <MenuItem value="61" >Nova Scotia</MenuItem>
                  <MenuItem value="62" >Ontario</MenuItem>
                  <MenuItem value="63" >Prince Edward Island</MenuItem>
                  <MenuItem value="64" >Quebec</MenuItem>
                  <MenuItem value="65" >Saskatchewan</MenuItem>
                  <MenuItem value="66" >Yukon Territory</MenuItem>
                  <MenuItem value="67" >Guam</MenuItem>
                  <MenuItem value="68" >Nunavut</MenuItem>
                  <MenuItem value="69" >n/a</MenuItem>
              </Select>
          </FormControl>
          <TextField id="fZip" onChange={handleZipChange} label="Zip / Postal Code" variant="standard" required sx={{paddingBottom: 1}}/>
          <TextField id="fPhone" onChange={handlePhoneChange} label="Phone Number" variant="standard" required sx={{paddingBottom: 1}}/>
          <TextField id="fEmail" onChange={handleEmailChange} label="E-mail Address" variant="standard" required sx={{paddingBottom: 1}}/>
          { /* <FormControlLabel control={<Switch defaultChecked />} label="HTML email format" /> */}
          <TextField id="fUname" onChange={handleUnameChange} label="User name" variant="standard" required sx={{paddingBottom: 1}}/>
          <FormControl sx={{paddingBottom: 1}} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                  onChange={handlePasswChange}
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                          >
                              {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                      </InputAdornment>
                  }
              />
          </FormControl>
          <FormControl sx={{paddingBottom: 1}} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
              <Input
                  onChange={handlePasswRepChange}
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                          >
                              {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                      </InputAdornment>
                  }
              />
          </FormControl>
          <Reaptcha
              sitekey="6LflPlIUAAAAAE6lK67Blwub9KCwodIWkgGsryVt"
              onVerify={onRecaptchaVerify}
          />
          <Button disabled={!values.allfilled} onClick={onSubscribe} style={{marginTop: 25}}>Create Profile</Button>
      </TrialRegistrationPageRenderer>
    </ResponsiveLayout>
  );
};

export default TrialRegistrationPage;
