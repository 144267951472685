import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { productInterface } from '@Store';
import { getProduct } from '@Api/product';

export const useHandleLoadProductInfo = (productId) => {
  const currentProductInfo = useSelector(productInterface.getCurrentProductInfo);
  const [isLoading, setDataLoading] = useState(false);
  const [currentPID, setCurrentPID] = useState(+productId);

  const loadData = useCallback(async ({ productId }) => {
    setDataLoading(true);
    const retData = await getProduct({
      productId,
    });
    const { product } = retData;
    productInterface.dispatch(productInterface.setCurrentProductInfo({ currentProductInfo: product }));
    setDataLoading(false);
  }, []);

  useEffect(() => {
    /** Here we clear store, otherwise we append new data to already loaded data */
    /** This also sets loadedCount to 0 and fires loadData effect */
    productInterface.dispatch(productInterface.clearData());
  }, []);

  useEffect(() => {
    if (!currentProductInfo || !currentProductInfo.ProductId || !(currentProductInfo.ProductId === currentPID)) loadData({ productId: currentPID });
  }, [currentProductInfo, loadData, currentPID]);

  return [loadData, isLoading, currentPID, setCurrentPID];
};
