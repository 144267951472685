import styled, { css } from 'styled-components';

const ButtonRenderer = styled.button`
  ${({ themeColor }) =>
    css`
      border: 2px solid ${themeColor};
      color: ${themeColor};
    `}
  ${({ backgroundColor }) =>
    css`
      border: 2px solid ${backgroundColor};
      background-color: ${backgroundColor};
    `}
  padding: 5px 7px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;

  ${({ fillSpace }) =>
    fillSpace &&
    css`
      width: 100%;
      height: 48px;
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: lightgray;
      border-color: lightgray;
    `}
  ${({ variant }) =>
    variant === 'plain' &&
    css`
      border: 0;
    `}
  ${({ variant }) =>
    variant === 'narrow' &&
    css`
      font-size: 16px;
      font-weight: 500;
      padding: 3px 7px;
    `}
  &:active {
    opacity: 0.5;
  }
`;

export default ButtonRenderer;
