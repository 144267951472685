import React from 'react';

import {Collapse} from "antd";
const { Panel } = Collapse;

const IssueAccordion = ({accData}) => {

    return (
        <>
            {accData && (
                <Collapse size="small" defaultActiveKey={['0']}>
                    {accData.Parents.map((value, ind) => (
                        <Panel size="small" header={value.Text} key={ind}>
                            {value.Children.map((child) => (
                                <div style={{margin: 15}}><a href={child.Link}>{child.Text}</a></div>
                            ))}
                        </Panel>
                    ))}
                </Collapse>
            )}
        </>
    )
}

export default IssueAccordion;