import React, {useCallback, useEffect, useState} from 'react';
import MainLogoArea from '@Components/sections/TopHeader/MainLogoArea';
import {Visible} from '@Components/containers/Grid';
import {TopMenuDesktopRenderer, TopMenuMenuAreaRenderer} from '@Components/sections/TopMenu/Renderers';
import Button from '@Components/controls/Button';
import images from '@Images';
import LoginArea from '@Components/sections/TopMenu/LoginArea';
import NavigationArea from '@Components/sections/TopMenu/NavigationArea';
import {appInterface} from '@Store';

const TopHeader = () => {
    const handleMenuOpenClick = useCallback(() => {
        appInterface.dispatch(appInterface.setSideMenuOpen({sideMenuOpen: true}));
    }, []);

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', processScroll);
        return () => {
            window.removeEventListener('scroll', processScroll);
        };
    });

    const processScroll = useCallback(() => {
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? setIsSticky(true) : setIsSticky(false);
    }, [setIsSticky]);

    return (
        <TopMenuDesktopRenderer>
            <TopMenuMenuAreaRenderer>
                <Visible xs sm md lg>
                    <Button variant="plain" onClick={handleMenuOpenClick}>
                        <img width={21} src={images.Menu} alt="Menu"/>
                    </Button>
                </Visible>
                <Visible xl xxl>
                    {isSticky && <MainLogoArea variant="TopMenu"/>}
                </Visible>
                <Visible xs sm md lg>
                    <MainLogoArea variant="TopMenu"/>
                </Visible>
            </TopMenuMenuAreaRenderer>
            <NavigationArea/>
            <Visible xl xxl>
                {isSticky && <LoginArea/>}
            </Visible>
            <Visible xs sm md lg>
                <LoginArea/>
            </Visible>
        </TopMenuDesktopRenderer>
    );
};

export default TopHeader;
