import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Popconfirm, Table, Upload } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import {appInterface, curIssuesInterface, topFeaturesInterface} from "@Store";
import { UploadOutlined } from '@ant-design/icons';

const EditableContext = React.createContext(null);

import {useHandleLoadTopFeatures} from "@Api/hooks/topFeatures";
import {useSelector} from "react-redux";
import {delTopFeatures, postTopFeatures, putTopFeatures, putTopFeaturesSorting} from "@Api/topFeatures";
import {fileUploadUrl, networkBaseUrl} from "@Utils/network";
import {useHandleLoadCurrentIssues} from "@Api/hooks/curIssues";
import {delCurIssue, postCurIssue, putCurIssue, putCurIssuesSorting} from "@Api/curIssues";
import {Checkbox} from "@mui/material";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if(!values?.cover?.file)
                handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    },[form, toggleEdit, handleSave]);

    const handleChange = useCallback((info) => {
        if (info.file.status === 'uploading') {
            const blaBla = info.file.status;
            return;
        }
        if (info.file.status === 'done') {
            const cover = networkBaseUrl + 'Content/Images/' + info.file.response.result;
            handleSave({...record, ItemImageUrl: cover});
            return;
        }
    },[handleSave]);

    let childNode = children;

    let editor;
    if(title === 'cover')
        editor = (
            <Upload
                action={fileUploadUrl}
                listType="picture"
                maxCount={1}
                onRemove={save}
                onChange={handleChange}
            >
                <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
        )
    else
        if(title === 'ColumnLayout' || title === 'AdBelow')
            editor = (<Checkbox onChange={save} ref={inputRef} />)
        else
            editor = (<Input ref={inputRef} name={dataIndex} onPressEnter={save} onBlur={save}/>)

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                valuePropName={title === 'cover' ? "file" : (title === 'ColumnLayout' || title === 'AdBelow') ? 'checked' : undefined}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {editor}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {title === 'cover' && (
                    <img height={80} src={children[1]} />
                )}
                {title !== 'cover' && (
                    <>{children}</>
                )}

            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const TopFeaturesEditor = () => {
    const [loadData, isLoading] = useHandleLoadTopFeatures(true);
    const dataSource = useSelector(topFeaturesInterface.getCurrentTopFeatures);

    useEffect(() => {
        appInterface.dispatch(appInterface.activeItem({ openItem: ['adm_top_categories'] }));
        // eslint-disable-next-line
    }, []);

    const onSortEnd = useCallback(async ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );

            await putTopFeaturesSorting(newData.map(x=>x.Id));
            await loadData();
        }
    },[putTopFeaturesSorting, loadData, dataSource]);

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();

        if(!dataSource) return (<></>);
        // function findIndex base on Table rowKey props and should always be a right array index
        const ind = dataSource.findIndex((x) => x.Id === props['data-row-key']);

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <SortableItem index={ind} {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const handleDelete = useCallback(async (Id) => {
        await delTopFeatures(Id);
        await loadData();
    },[delTopFeatures, loadData]);

    const defaultColumns = [
        {
            title: 'Sort',
            dataIndex: 'SortOrder',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
            editable: false,
        },
        {
            title: 'cover',
            dataIndex: 'ItemImageUrl',
            width: '10%',
            editable: true,
            className: 'drag-visible',
        },
        {
            title: 'PID',
            dataIndex: 'ItemPID',
            editable: true,
        },
        {
            title: 'Group',
            dataIndex: 'ItemGroup',
            editable: true,
        },
        {
            title: 'ColumnLayout',
            dataIndex: 'ColumnLayout',
            editable: true,
            render: (_, record) =>
                record.ColumnLayout === true ? (
                    <p>True</p>
                ) : (<p>False</p>),
        },
        {
            title: 'AdBelow',
            dataIndex: 'AdBelow',
            editable: true,
            render: (_, record) =>
                record.AdBelow === true ? (
                    <p>True</p>
                ) : (<p>False</p>),
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.Id)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleAdd = useCallback( async () => {
        await postTopFeatures();
        await loadData();
    }, [loadData, postTopFeatures]);

    const handleSave = useCallback(async (row) => {
        await putTopFeatures(row);
        await loadData();
    },[putTopFeatures, loadData]);

    const components = {
        body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Add a row
            </Button>
            <Table
                size={'small'}
                pagination={{
                    pageSize: 50,
                }}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                rowKey="Id"
            />
        </div>
    );
};

export default TopFeaturesEditor;
