import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Modal, notification, Popconfirm, Table, Upload} from 'antd';
import {arrayMoveImmutable} from 'array-move';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {
    MenuOutlined,
    RestOutlined,
    CloudUploadOutlined,
    FolderViewOutlined,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
    CarryOutOutlined,
    InfoOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import {
    appInterface,
    curIssuesInterface,
    searchResultsInterface,
    topFeaturesInterface, userInterface,
    userListsInterface
} from "@Store";
import {UploadOutlined} from '@ant-design/icons';

const EditableContext = React.createContext(null);
import {useSelector} from "react-redux";
import {fileUploadUrl, networkBaseUrl} from "@Utils/network";
import {useHandleLoadTopFeatures} from "@Api/hooks/topFeatures";
import {delTopFeatures, postTopFeatures, putTopFeatures, putTopFeaturesSorting} from "@Api/topFeatures";
import {useUserLists} from "@Api/hooks/userLists";
import {delUserLists, postUserLists, putUserLists, TransferList} from "@Api/userLists";
import moment from "moment";
import {ROUTES} from "@Utils/routing";
import {useNavigate} from "react-router-dom";
import {useHandleLoadUserProfile} from "@Api/hooks/userProfile";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if (!values?.cover?.file)
                handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    }, [form, toggleEdit, handleSave]);

    const handleChange = useCallback((info) => {
        if (info.file.status === 'uploading') {
            const blaBla = info.file.status;
            return;
        }
        if (info.file.status === 'done') {
            const cover = networkBaseUrl + 'Content/Images/' + info.file.response.result;
            handleSave({...record, ItemImageUrl: cover});
            return;
        }
    }, [handleSave]);

    let childNode = children;

    let editor;
    if (title === 'cover')
        editor = (
            <Upload
                action={fileUploadUrl}
                listType="picture"
                maxCount={1}
                onRemove={save}
                onChange={handleChange}
            >
                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
            </Upload>
        )
    else
        editor = (<Input ref={inputRef} name={dataIndex} onPressEnter={save} onBlur={save}/>)

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                valuePropName={title === 'cover' ? "file" : undefined}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {editor}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {title === 'cover' && (
                    <img height={80} src={children[1]}/>
                )}
                {title !== 'cover' && (
                    <>{children}</>
                )}

            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const UserListsEditor = ({viewMode, handleClose, pid}) => {
    const [loadData, isLoading] = useUserLists(true);
    const dataSource = useSelector(userListsInterface.getUserLists);
    const selectedIDs = useSelector(searchResultsInterface.getSelectedIDs);
    const navigate = useNavigate();

    const onSortEnd = useCallback(async ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );

            await putTopFeaturesSorting(newData.map(x => x.Id));
            await loadData();
        }
    }, [putTopFeaturesSorting, loadData, dataSource]);

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();

        if (!dataSource) return (<></>);
        // function findIndex base on Table rowKey props and should always be a right array index
        const ind = dataSource.findIndex((x) => x.Id === props['data-row-key']);

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <SortableItem index={ind} {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const handleDelete = useCallback(async (Id) => {
        await delUserLists(Id);
        await loadData();
    }, [delUserLists, loadData]);

    const defaultColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            editable: true
        },
        {
            title: 'Date Created',
            dataIndex: 'DateCreated',
            editable: false,
            render: (record) => {
                return (
                    <div>
                        <p>{moment(record.DateCreated).format("DD-MM-YYYY")}</p>
                    </div>
                );
            }
        },
        {
            title: 'Date Updated',
            dataIndex: 'DateUpdated',
            editable: false,
            render: (record) => {
                return (
                    <div>
                        <p>{moment(record.DateUpdated).format("DD-MM-YYYY")}</p>
                    </div>
                );
            }
        },
        {
            title: '# of Records',
            dataIndex: 'NumRecords',
            editable: false,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.Id)}>
                            <a><RestOutlined style={{fontSize: '18px', color: 'black'}}/></a>
                        </Popconfirm>
                        {viewMode === 1 && (
                            <Popconfirm title="Update the list?"
                                        onConfirm={() => handlePutRecords(record.Id, record.Name)}>
                                <a style={{marginLeft: 20}}><CloudUploadOutlined
                                    style={{fontSize: '18px', color: 'black'}}/></a>
                            </Popconfirm>
                        )}
                        {viewMode === 2 && (
                            <a onClick={() => handleViewRecords(record.Id)} style={{marginLeft: 20}}><FolderViewOutlined
                                style={{fontSize: '18px', color: 'black'}}/></a>
                        )}
                    </>
                ) : null,
        },
    ];

    if(viewMode === 2) {
        defaultColumns.push({
            title: 'transfer',
            dataIndex: 'operation',
            width: 200,
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <a onClick={() => handleTransferList(record.Id, true)}><CloudUploadOutlined
                            style={{fontSize: '18px', color: 'black'}}/> Ingram</a>
                        <br/>
                        <a onClick={() => handleTransferList(record.Id, false)}><CloudUploadOutlined
                            style={{fontSize: '18px', color: 'black'}}/> Baker &amp; Taylor</a>
                    </>
                ) : null,
        });
    }

    const handleViewRecords = useCallback(async (Id) => {
        handleClose();
        searchResultsInterface.dispatch(
            searchResultsInterface.setSearchOptions({searchOptions: {listId: Id}, searchType: 4, totalCount: null})
        );
        navigate(ROUTES.searchResutsLazy);
    }, [handleClose, navigate]);

    const handlePutRecords = useCallback(async (Id, name) => {
        await putUserLists({Id: Id, Name: name, Pids: pid ? [pid] : selectedIDs});
        await loadData();
        handleClose();
    }, [delTopFeatures, loadData, selectedIDs, pid]);

    const handleAdd = useCallback(async () => {
        const uid = window.localStorage.getItem("LSUID");
        if (uid) {
            await postUserLists({Name: 'New List', Pids: pid ? [pid] : selectedIDs, UserId: JSON.parse(uid)});
            await loadData();
        }
    }, [loadData, postUserLists, selectedIDs, pid]);

    const handleSave = useCallback(async (row) => {
        await putUserLists({Id: row.Id, Name: row.Name, Pids: []});
        await loadData();
    }, [putUserLists, loadData]);

    const components = {
        body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const [isTransferListOpen, setIsTransferListOpen] = useState(false);
    const [transferId, setTransferId] = useState(null);

    const [values, setValues] = React.useState({
        IsIngram: false,
        UserEmail: '',
        DestinationSystem: '',
        DestinationUserID: '',
        DestinationPW: '',
        CartName: '',
        GroupName: '',
        CartNote: '',
        SourceUserID: '',
        SourceUserName: '',
        ListID: -1
    });

    const handleTransferList = useCallback((Id, isIngram) => {
        setValues({
            ...values,
            ListID: Id,
            IsIngram: isIngram,
            DestinationSystem: isIngram ? 'INSTRTEST' : 'TS3TEST',
            // DestinationSystem: isIngram ? 'INSTRPROD' : 'TS3PROD',
            GroupName: isIngram ? 'Private' : 'Booklist',
            UserEmail: '',
            DestinationUserID: '',
            DestinationPW: '',
            CartName: '',
            CartNote: '',
        });
        setTransferId(Id);
        setIsTransferListOpen(!isTransferListOpen);
    }, [isTransferListOpen, setIsTransferListOpen, values, setValues]);


    const [isTransferValidationError, setIsTransferValidationError] = useState(false);
    const [loadUP, isLoadingUP] = useHandleLoadUserProfile(false);
    const userProfile = useSelector(userInterface.getUserProfile);

    useEffect(() => {
        if (!isLoadingUP && userProfile && !values.SourceUserID) {
            setValues({
                ...values,
                SourceUserID: userProfile.Profile.UserId,
                SourceUserName: `${userProfile.User.FirstName} ${userProfile.User.LastName}`
            });
        }
    }, [isLoadingUP, userProfile, values, setValues]);

    const handleUserEmailChange = useCallback((e) => {
        setValues({
            ...values,
            UserEmail: e.target.value,
        });
    }, [values]);

    const handleDestinationUserIDChange = useCallback((e) => {
        setValues({
            ...values,
            DestinationUserID: e.target.value,
        });
    }, [values]);

    const handleDestinationPWChange = useCallback((e) => {
        setValues({
            ...values,
            DestinationPW: e.target.value,
        });
    }, [values]);

    const handleCartNameChange = useCallback((e) => {
        setValues({
            ...values,
            CartName: e.target.value,
        });
    }, [values]);

    const handleCartNoteChange = useCallback((e) => {
        setValues({
            ...values,
            CartNote: e.target.value,
        });
    }, [values]);

    const handleGroupNameChange = useCallback((e) => {
        setValues({
            ...values,
            GroupName: e.target.value,
        });
    }, [values]);

    const [api, contextHolder] = notification.useNotification();
    const notifySuccess = (placement) => {
        api.success({
            message: `Your list has been added to the queue.`,
            placement,
        });
    };
    const notifyFail = (placement) => {
        api.error({
            message: `Your list cannot be transferred because it contains either Features and/or records with invalid ISBNs.`,
            placement,
        });
    };

    const handleTransfer = useCallback(async () => {
        if (values && values.CartName && values.GroupName && values.UserEmail && values.DestinationUserID && values.DestinationPW) {
            setIsTransferValidationError(false);
            const {data} = await TransferList(values);
            if(data.IsSuccess) {
                notifySuccess('top');
            }
            else {
                notifyFail('top');
            }
            await loadData();
            setIsTransferListOpen(!isTransferListOpen);
        }
        else {
            setIsTransferValidationError(true);
        }
    }, [values]);

    const handleRefresh = useCallback(async () => {
        await loadData();
    }, [values]);

    return (
        <>
            {contextHolder}
            <Modal
                closable={false}
                open={isTransferListOpen}
                title="Transfer list"
                okText="Close"
                width={800}
                onOk={handleTransferList}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <>
                    {values.IsIngram ? (
                        <>
                            <h3>
                                To: <b>Ingram</b>
                            </h3>
                            <br/>
                            <h5 style={isTransferValidationError ? { color: 'red'} : {}}>
                                <i>All fields are required except for the note field. Each list transfer will be subject to your TS3 cart size limit.</i>
                            </h5>
                        </>) : (
                        <>
                            <h3>
                                To: <b>Baker &amp; Taylor</b>
                            </h3>
                            <br/>
                            <h5 style={isTransferValidationError ? { color: 'red'} : {}}>
                                <i>All fields are required except for the note field. Each list transfer will be subject to
                                your TS3 cart size limit.</i>
                            </h5>
                        </>
                    )}

                    <TextField value={values.UserEmail} fullWidth={true} onChange={handleUserEmailChange} label="Email Address" variant="standard"
                               sx={{paddingBottom: 1}} required/>

                    <TextField value={values.DestinationUserID} fullWidth={true} onChange={handleDestinationUserIDChange} label={values.IsIngram ? "INGRAM Logon ID" : "TS3 Logon ID"} variant="standard"
                               sx={{paddingBottom: 1}} required/>

                    <TextField value={values.DestinationPW} fullWidth={true} onChange={handleDestinationPWChange} label={values.IsIngram ? "INGRAM Password" : "TS3 Password"} variant="standard"
                               sx={{paddingBottom: 1}} required/>

                    <TextField value={values.CartName} fullWidth={true} onChange={handleCartNameChange} label={values.IsIngram ? "INGRAM List Name" : "TS3 Cart Name"} variant="standard"
                               sx={{paddingBottom: 1}} required/>


                    <FormControl fullWidth={true} variant="standard">
                        <InputLabel id="pProfessionL">Group</InputLabel>
                        {values.IsIngram ? (
                            <Select labelId="pProfessionL" id="pProfession" value={values.GroupName}
                                    onChange={handleGroupNameChange} label="Group">
                                <MenuItem value={"Public"}>Public</MenuItem>
                                <MenuItem value={"Private"}>Private</MenuItem>
                                <MenuItem value={"Shared"}>Shared</MenuItem>
                            </Select>
                        ) : (
                            <Select labelId="pProfessionL" id="pProfession" value={values.GroupName}
                                    onChange={handleGroupNameChange} label="Group">
                                <MenuItem value={"Booklist"}>Booklist</MenuItem>
                            </Select>
                        )}
                    </FormControl>

                    <TextField value={values.CartNote} fullWidth={true} onChange={handleCartNoteChange} label={values.IsIngram ? "INGRAM List Note" : "TS3 Cart Note"} variant="standard"
                               sx={{paddingBottom: 5, paddingTop: 1}} required/>

                    <Button
                        onClick={handleTransfer}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        Transfer
                    </Button>
                </>
            </Modal>
            <div>
                <Button
                    onClick={handleAdd}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Add new List
                </Button>
                <Table
                    size={'small'}
                    pagination={{
                        pageSize: 5,
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <>
                                <div style={{display: "inline-block", marginRight: 15}}
                                    dangerouslySetInnerHTML={{
                                        __html: record.TransferStatusLine.m_StringValue,
                                    }}
                                />
                                <a onClick={() => handleRefresh()}><CloudSyncOutlined
                                    style={{fontSize: '18px', color: 'black'}}/></a>
                            </>


                        ),
                        rowExpandable: (record) => record.TransferStatusLine && record.TransferStatusLine.m_StringValue && record.TransferStatusLine.m_StringValue !== '',
                        expandIcon: ({ expanded, onExpand, record }) => {
                            if (record.TransferStatusLine && record.TransferStatusLine.m_StringValue && record.TransferStatusLine.m_StringValue !== '') {
                                return expanded ? (
                                    <InfoOutlined onClick={e => onExpand(record, e)}/>
                                ) : (
                                    <InfoCircleOutlined onClick={e => onExpand(record, e)}/>
                                )
                            }
                        }
                    }}
                    scroll={{y: 380}}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="Id"
                />
            </div>
        </>
    );
};

export default UserListsEditor;
