import React, {useCallback, useState} from 'react';
import images from '@Images';
import Button from '@Components/controls/Button';
import {
  LoginMenuRenderer, LoginMenuSubitemRenderer,
  LoginSearchAreaRenderer,
  LoginSearchAreaWrapper,
} from '@Components/sections/TopHeader/LoginSearchArea/Renderers';
import LinkButton from '@Components/controls/LinkButton';
import { appInterface } from '@Store';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {PermIdentity} from "@mui/icons-material";
import {ROUTES} from "@Utils/routing";
import {useLocalStorage} from "@Utils/localStorage";
import UserListsEditor from "@Components/Pages/SearchResults/UserLists";
import {Modal} from "antd";
import SavedSearchesEditor from "@Components/Pages/SavedSearches";
import SocialMediaLinks from "@Components/sections/TopHeader/SocialMediaLinks";
import SMBluesky from "@Images/SMBluesky.svg";
import {useCookies} from "react-cookie";

const deleteCookie = (name) => {
  document.cookie = name +'=; Path=/; domain=booklistonline.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name +'=; Path=/; domain=booklistonline.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const LoginSearchArea = () => {
  const isOpen = useSelector(appInterface.getQuickSearchOpen);
  const handleMenuOpenClick = useCallback(() => {
    appInterface.dispatch(appInterface.setQuickSearchOpen({ quickSearchOpen: !isOpen }));
  }, [isOpen]);

  const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
  const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
  const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
  const [menuOpened, setMenuOpened] = useState(false);
  const [isAddListOpen, setIsAddListOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['ASP.NET_SessionId']);

  const isUserAutherticated = (!!lsUserId && lsAuthModeId !== '2');
  const isAccountAutherticated = lsAuthModeId === '6' || lsAuthModeId === '7' || lsAuthModeId === '8'  || lsAuthModeId === '2';

  const navigate = useNavigate();
  const handleLoginClick = useCallback(() => {
    navigate(ROUTES.login);
  }, []);

  const handleMyBooklistClick = useCallback(() => {
    setMenuOpened(!menuOpened)
  }, [menuOpened]);

  const handleLogout = useCallback(() => {
    deleteCookie("betaJWT");
    setMenuOpened(false);
    setLSUserId('');
    setLSAccountId('');
    setLSAuthModeId('');
    removeCookie('ASP.NET_SessionId');
    window.location.reload();
  }, [menuOpened]);

  const handleAddList = useCallback(() => {
    setIsAddListOpen(!isAddListOpen);
  }, [isAddListOpen, setIsAddListOpen]);

  const [isAddSavedSearchOpen, setIsAddSavedSearchOpen] = useState(false);

  const handleLoadSearch = useCallback(() => {
    const uid = window.localStorage.getItem("LSUID");
    if(!uid) {
      Modal.error({
        title: 'Log In',
        content: 'Please log in to use saved searches',
      });
      return;
    }
    setIsAddSavedSearchOpen(!isAddSavedSearchOpen);
  }, [isAddSavedSearchOpen, setIsAddSavedSearchOpen]);

  return (
    <LoginSearchAreaWrapper>
      <Modal
          closable={false}
          open={isAddListOpen}
          title="Add to list"
          okText="Close"
          width={800}
          onOk={handleAddList}
          cancelButtonProps={{ style: { display: 'none' } }}
      >
        <UserListsEditor viewMode={2} handleClose={handleAddList} />
      </Modal>
      <Modal
          closable={false}
          open={isAddSavedSearchOpen}
          title="Save search"
          okText="Close"
          width={600}
          onOk={handleLoadSearch}
          cancelButtonProps={{ style: { display: 'none' } }}
      >
        <SavedSearchesEditor viewMode={2} handleClose={handleLoadSearch} />
      </Modal>
      <LoginSearchAreaRenderer className={menuOpened ? 'selected' : ''}>
        <a href="https://www.facebook.com/ALA.Booklist">
          <img width={21} style={{ marginTop: 5 }} src={images.SMFacebook} alt="logo" />
        </a>
        <a href="https://bsky.app/profile/ala-booklist.bsky.social">
          <img width={21} style={{ marginTop: 5 }} src={images.SMBluesky} alt="logo" />
        </a>
        <a href="https://www.youtube.com/user/BooklistOnline">
          <img width={21} style={{ marginTop: 5 }} src={images.SMYoutube} alt="logo" />
        </a>
        <a href="https://www.instagram.com/ala_booklist">
          <img width={21} style={{ marginTop: 3 }} src={images.SMInstagram} alt="logo" />
        </a>
        <a href="https://www.linkedin.com/company/booklistpublications" style={{marginRight: 10}}>
          <img width={21} style={{ marginTop: 3 }} src={images.SMLinkedIn} alt="logo" />
        </a>

        {(!isUserAutherticated  && !isAccountAutherticated) && (
            <Button onClick={handleLoginClick}>
              <PermIdentity />
              {/* <img width={21} src={images.Login} alt="Login" />*/}
              Log&nbsp;In
            </Button>
        )}
        {isUserAutherticated && (
            <>
              <Button onClick={handleMyBooklistClick}>
                <PermIdentity />
                My&nbsp;Booklist
              </Button>
              <LoginMenuRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`/user-profile`}>My Profile</Link>
                </LoginMenuSubitemRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`#`} onClick={handleAddList}>My Lists</Link>
                </LoginMenuSubitemRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`#`} onClick={handleLoadSearch}>My Searches</Link>
                </LoginMenuSubitemRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`#`} onClick={handleLogout}>Logout</Link>
                </LoginMenuSubitemRenderer>
              </LoginMenuRenderer>
            </>
        )}
        {isAccountAutherticated && (
            <>
              <Button onClick={handleMyBooklistClick}>
                <PermIdentity />
                My&nbsp;Booklist
              </Button>
              <LoginMenuRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`/login`}>Log in</Link>
                </LoginMenuSubitemRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`/user-profile`}>Create Profile</Link>
                </LoginMenuSubitemRenderer>
                <LoginMenuSubitemRenderer>
                  <Link to={`#`} onClick={handleLogout}>Logout</Link>
                </LoginMenuSubitemRenderer>
              </LoginMenuRenderer>
            </>
        )}
        {!isOpen && (
            <a href="#" onClick={handleMenuOpenClick}>
          <img width={21} src={images.Search} alt="search" />
        </a>
        )}
      </LoginSearchAreaRenderer>
      <SocialMediaLinks/>
    </LoginSearchAreaWrapper>
  );
};

export default LoginSearchArea;
