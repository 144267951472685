import React, { createContext, useState } from 'react';
import propTypes from 'prop-types';
import PageMessage from './PageMessage';
import PageContainerRenderer from './PageContainerRenderer';
import PageArea from './PageArea';

const PageContainerContext = createContext({ pageMessage: 'You have no provider!' });

const PageContainer = (props) => {
  const { children, fullScreen, bgColor } = props;
  const [pageMessage, setPageMessage] = useState('');
  return (
    <PageContainerContext.Provider value={{ pageMessage, setPageMessage }}>
      <PageContainerRenderer {...{ fullScreen, bgColor }}>
        <PageArea>{children}</PageArea>
        {!!pageMessage && <PageMessage message={pageMessage} />}
      </PageContainerRenderer>
    </PageContainerContext.Provider>
  );
};

PageContainer.propTypes = {
  children: propTypes.any,
  bgColor: propTypes.any,
};

const asPageContainerContextConsumer = (Component) => (props) => {
  return (
    <PageContainerContext.Consumer>
      {(context) => <Component {...{ ...context, ...props }} />}
    </PageContainerContext.Consumer>
  );
};

export default PageContainer;
export { asPageContainerContextConsumer };
