import React from 'react';
import {
  LogoSubtitleRenderer,
  LogoTitleRenderer,
  LogoWrapperRenderer,
} from '@Components/sections/TopHeader/MainLogoArea/Renderers';
import BOL from '@Images/BOL.svg';

const MainLogoArea = ({ variant = 'main' }) => {
  return (
    <LogoWrapperRenderer to={variant === 'SideMenu' ? "#" : "/"} variant={variant}>
      {variant !== 'TopMenu' && variant !== 'SideMenu' && (
          <img width={320} src={BOL} />
      )}

      {variant === 'SideMenu' && (
          <img width={220} src={BOL} />
      )}
      {variant === 'TopMenu' && (
          <img width={100} src={BOL} />
          /* <>
            <LogoTitleRenderer variant={variant}>Booklist</LogoTitleRenderer>
          </> */
      )}
    </LogoWrapperRenderer>
  );
};

export default MainLogoArea;
