import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { userInterface } from '@Store';
import {GetReaderToken, GetUserProfile} from '@Api/user';
import {useLocalStorage} from "@Utils/localStorage";

export const useHandleLoadReaderToken = (clearSelf) => {
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const currentReaderToken = useSelector(userInterface.getReaderToken);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const data = await GetReaderToken(lsAccountId);
        userInterface.dispatch(userInterface.setReaderToken({readerToken: data}));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) userInterface.dispatch(userInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentReaderToken) loadData();
    }, [currentReaderToken, loadData]);

    return [loadData, isLoading];
};
