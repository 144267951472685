import React from 'react';
import Container from '../../containers/Container';
import propTypes from 'prop-types';
import TopAdArea from '@Components/sections/TopAdArea';
import TopHeader from '@Components/sections/TopHeader';
import {Visible} from '@Components/containers/Grid';
import TopMenu from '@Components/sections/TopMenu';
import SideMenu from '@Components/sections/SideMenu';
import Footer from '@Components/sections/Footer';
import QuickSearch from '@Components/sections/QuickSearch';
import Overlay from '@Components/controls/Overlay';

const ResponsiveLayout = ({children, isAdmin}) => (
    <>
        <Visible xs sm md lg xl xxl>
            <Container flex flexy column>
                {!isAdmin && (
                    <>
                        <TopAdArea/>
                        <Visible xl xxl>
                            <TopHeader/>
                        </Visible>
                        <TopMenu/>
                        <QuickSearch/>
                    </>
                )}
                {children}
                {!isAdmin && (
                    <Footer/>
                )}
                <Overlay/>
            </Container>
            <SideMenu/>
        </Visible>
    </>
);

ResponsiveLayout.propTypes = {
    children: propTypes.any,
};

export default ResponsiveLayout;
