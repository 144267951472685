import React, {useCallback, useEffect, useState} from 'react';
import Button from '@Components/controls/Button';
import {
    AdvancedSearchColumn, AdvancedSearchHeaderRow,
    AdvancedSearchRow,
    AdvancedSearchRowColumn,
    AdvancedSearchRowShortColumn,
    QuickSearchRenderer,
    QuickSearchRow, QuickSearchSubTitleRenderer,
    QuickSearchTitleRenderer,
} from '@Components/sections/QuickSearch/Renderers';
import Typography from '@Components/controls/Typography';
import {useSelector} from 'react-redux';
import {appInterface, searchResultsInterface, siteInfoInterface} from '@Store';
import {navigateTo, ROUTES} from '@Utils/routing';
import {useNavigate} from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import styled from 'styled-components';
import {Box, Checkbox, Chip, FormControlLabel, ListItemText, OutlinedInput, Switch} from '@mui/material';
import {alpha, styled as StyledM} from '@mui/material/styles';
import {pink, blue} from '@mui/material/colors';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import {VerticalAlignTopOutlined} from '@ant-design/icons';
import {useLocalStorage} from "@Utils/localStorage";
import {Modal} from "antd";
import SavedSearchesEditor from "@Components/Pages/SavedSearches";

const StyledFC = styled(FormControl)`
    && {
        margin: 0px;
    }

    label {
        color: black !important;
    }
`;

const StyledSelect = styled(Select)`
    && {
        margin: 0px;
        height: 30px;
        color: black;
    }

    fieldset {
        border-color: darkblue !important;
        border-width: 2px !important;
    }
`;

const StyledSelectMM = styled(Select)`
    && {
        margin: 0px;
        color: black;
    }

    span {
        color: black;
    }

    fieldset {
        border-color: darkblue !important;
        border-width: 2px !important;
    }
`;

const GreenSwitch = StyledM(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: blue[900],
        '&:hover': {
            backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[900],
    },
}));

const StyledDropdownTreeSelect = styled(DropdownTreeSelect)`
    @font-face {
        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
    }

    .dropdown {
        z-index: 999;
        max-height: 200px;

        input {
            border-color: darkblue !important;
            border-width: 2px !important;
        }
    }

    .tag {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 0.8125rem;
        color: white;
        height: 22px;
        padding: 0px 13px;
        background-color: rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        white-space: nowrap;
        cursor: default;
        border: 0;
    }

    .tag-remove {
        font-size: 100%;
        margin-left: 10px;
    }

    .search {
        min-width: 360px;
    }

    .dropdown-trigger.arrow.bottom:after {
        font-size: 70%;
    }

    .dropdown-trigger {
        border-color: darkblue !important;
        border-width: 2px !important;
    }

    .dropdown-content {
        border-radius: 4px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        min-width: 398px;
        max-height: 200px;
        z-index: 999;
        color: black;

        label {
            color: black !important;
        }
    }

    .infinite-scroll-component {
        max-height: 200px;
    }

    .mdl-demo .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }

    .mdl-demo .toggle {
        font: normal normal normal 18px/1 'Material Icons';
        color: #555;
        white-space: pre;
        margin-right: 4px;
    }

    .mdl-demo .toggle.collapsed::after {
        cursor: pointer;
        content: '\\E5CF';
        vertical-align: middle;
    }

    .mdl-demo .toggle.expanded::after {
        cursor: pointer;
        content: '\\E5CE';
        vertical-align: middle;
    }

    /* checkbox styles */

    .mdl-demo .checkbox-item {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 0.75rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        vertical-align: middle;
    }

    .mdl-demo .checkbox-item:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 2px solid #aaa;
        transition: all 0.3s ease-in-out;
    }

    .mdl-demo .checkbox-item:checked:before {
        height: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top-style: none;
        border-right-style: none;
        border-color: #2196f3;
    }
`;

const awards = [
    {value: '1', name: 'Booklist Top of the List'},
    {value: '2', name: 'Booklist Editors’ Choice'},
    {value: '3', name: 'Newbery Medal'},
    {value: '4', name: 'Newbery Honor'},
    {value: '5', name: 'Caldecott Medal'},
    {value: '6', name: 'Caldecott Honor'},
    {value: '7', name: 'Printz Award'},
    {value: '8', name: 'Printz Honor'},
    {value: '28', name: 'Odyssey Award'},
    {value: '29', name: 'Odyssey Honor'},
    {value: '33', name: 'Morris Award'},
    {value: '34', name: 'Morris Honor'},
    {value: '9', name: 'Sibert Medal'},
    {value: '10', name: 'Sibert Honor'},
    {value: '35', name: 'Dartmouth Award'},
    {value: '36', name: 'Dartmouth Honor'},
    {value: '11', name: 'Coretta Scott King Award'},
    {value: '12', name: 'Coretta Scott King Honor'},
    {value: '22', name: 'Pura Belpre Award'},
    {value: '23', name: 'Pura Belpre Honor'},
    {value: '24', name: 'Stonewall Award'},
    {value: '25', name: 'Stonewall Honor'},
    {value: '13', name: 'Notable Books'},
    {value: '26', name: 'The Reading List'},
    {value: '14', name: 'Notable Children’s Books'},
    {value: '27', name: 'Amelia Bloomer'},
    {value: '21', name: 'Carnegie Medal'},
    {value: '20', name: 'Notable Media'},
    {value: '15', name: 'Best Books for Young Adults'},
    {value: '40', name: 'Best Fiction for Young Adults'},
    {value: '16', name: 'Alex Awards'},
    {value: '37', name: 'YALSA Award for Excellence in Nonfiction'},
    {value: '38', name: 'YALSA Award for Excellence in Nonfiction Finalists'},
    {value: '30', name: 'Rainbow List'},
    {value: '41', name: 'Over the Rainbow List'},
    {value: '31', name: 'Great Graphic Novels for Teens'},
    {value: '32', name: 'Quick Picks'},
    {value: '17', name: 'National Book Award'},
    {value: '18', name: 'National Book Critics Circle Award'},
    {value: '19', name: 'Pulitzer Prize'},
    {value: '39', name: 'Booker Prize'},
    {value: '42', name: 'Andrew Carnegie Medal for Excellence in Fiction Finalist'},
    {value: '43', name: 'Andrew Carnegie Medal for Excellence in Nonfiction Finalist'},
    {value: '44', name: 'Andrew Carnegie Medal for Excellence in Fiction'},
    {value: '45', name: 'Andrew Carnegie Medal for Excellence in Nonfiction'},
    {value: '47', name: 'American Indian Youth Literature Award'},
    {value: '48', name: 'Asian/Pacific American Award for Literature'},
    {value: '49', name: 'Mildred L. Batchelder Award and Honor'},
    {value: '50', name: 'Schneider Family Book Award and Honor'},
    {value: '51', name: 'Sydney Taylor Book Award'},
    {value: '52', name: 'American Indian Youth Literature Honor'},
    {value: '53', name: 'Asian/Pacific American Honor for Literature'},
    {value: '54', name: 'Sydney Taylor Book Honor'},
    {value: '55', name: 'Man Booker Prize'},
    {value: '56', name: 'The Listen List'},
    {value: '57', name: 'Amazing Audiobooks for Young Adults'},
    {value: '58', name: 'Notable Children’s Recordings'},
];

const taxonomy = [
    {
        value: '101',
        label: 'ADULT BOOKS',
        children: [
            {
                value: '102',
                label: '..ADULT NONFICTION',
                children: [
                    {
                        value: '103',
                        label: 'Adult Arts',
                        children: [
                            {value: '104', label: 'Adult Architecture'},
                            {value: '105', label: 'Adult Dance'},
                            {value: '106', label: 'Adult Movies & Television'},
                            {value: '107', label: 'Adult Music'},
                            {value: '108', label: 'Adult Photography'},
                            {value: '109', label: 'Adult Theater'},
                        ],
                    },
                    {value: '110', label: 'Adult Business'},
                    {value: '111', label: 'Adult Child Care'},
                    {value: '112', label: 'Adult Computer Science'},
                    {value: '113', label: 'Adult Cookery'},
                    {value: '114', label: 'Adult Crafts & Hobbies'},
                    {value: '115', label: 'Adult Customs & Holidays'},
                    {value: '116', label: 'Adult Folklore'},
                    {value: '117', label: 'Adult Gardening'},
                    {value: '118', label: 'Adult Genealogy'},
                    {value: '119', label: 'Adult Geography, Travel, & Culture'},
                    {value: '120', label: 'Adult Health & Medicine'},
                    {
                        value: '121',
                        label: 'Adult History',
                        children: [
                            {value: '122', label: 'Adult U.S. History'},
                            {value: '123', label: 'Adult World History'},
                        ],
                    },

                    {value: '124', label: 'Adult Journalism & Publishing'},
                    {value: '125', label: 'Adult Language'},
                    {value: '126', label: 'Adult Literature'},
                    {value: '127', label: 'Adult Paranormal/ Supernatural'},
                    {value: '128', label: 'Adult Pets'},
                    {value: '129', label: 'Adult Philosophy'},
                    {value: '130', label: 'Adult Poetry'},
                    {value: '131', label: 'Adult Psychology'},
                    {value: '132', label: 'Adult Religion'},
                    {
                        value: '133',
                        label: 'Adult Science',
                        children: [
                            {value: '134', label: 'Adult Animals'},
                            {value: '135', label: 'Adult Mathematics'},
                            {value: '136', label: 'Adult Plants'},
                        ],
                    },

                    {
                        value: '137',
                        label: 'Adult Social Sciences',
                        children: [
                            {value: '138', label: 'Adult Economics'},
                            {value: '139', label: 'Adult Education'},
                            {value: '140', label: 'Adult Law'},
                            {value: '141', label: 'Adult Politics & Government'},
                        ],
                    },

                    {value: '142', label: 'Adult Sports & Recreation'},
                    {value: '143', label: 'Adult Technology'},
                ],
            },

            {
                value: '144',
                label: '..ADULT FICTION',
                children: [
                    {value: '145', label: 'Adult General Fiction'},
                    {
                        value: '146',
                        label: 'Adult Crime Fiction',
                        children: [
                            {value: '147', label: 'Adult Mystery'},
                            {value: '148', label: 'Adult Spy/Espionage'},
                            {value: '149', label: 'Adult Thriller/Suspense'},
                        ],
                    },

                    {value: '150', label: 'Adult Graphic Novels'},
                    {value: '151', label: 'Adult Historical Fiction'},
                    {value: '152', label: 'Adult Horror Fiction'},
                    {
                        value: '153',
                        label: 'Adult Romance',
                        children: [
                            {value: '154', label: 'Adult Contemporary Romance'},
                            {value: '155', label: 'Adult Historical Romance'},
                            {value: '156', label: 'Adult Romantic Suspense'},
                        ],
                    },

                    {
                        value: '157',
                        label: 'Adult Science Fiction & Fantasy',
                        children: [
                            {value: '158', label: 'Adult Science Fiction'},
                            {value: '159', label: 'Adult Fantasy'},
                        ],
                    },

                    {value: '160', label: 'Adult Westerns'},
                ],
            },
        ],
    },
    {
        value: '161',
        label: 'BOOKS FOR YOUTH',
        children: [
            {
                value: '162',
                label: '..YOUTH NONFICTION',
                children: [
                    {
                        value: '163',
                        label: 'Youth Arts',
                        children: [
                            {value: '164', label: 'Youth Performing Arts'},
                            {value: '165', label: 'Youth Visual Arts'},
                        ],
                    },

                    {value: '166', label: 'Youth Computer Science'},
                    {value: '167', label: 'Youth Cookery'},
                    {value: '168', label: 'Youth Crafts & Hobbies'},
                    {value: '169', label: 'Youth Customs & Holidays'},
                    {value: '170', label: 'Youth Feelings & Behavior'},
                    {value: '171', label: 'Youth Folklore & Fairy Tales'},
                    {value: '172', label: 'Youth Geography, Travel, & Culture'},
                    {value: '173', label: 'Youth Health & Medicine'},
                    {
                        value: '174',
                        label: 'Youth History',
                        children: [
                            {value: '175', label: 'Youth U.S. History'},
                            {value: '176', label: 'Youth World History'},
                        ],
                    },

                    {value: '177', label: 'Youth Journalism & Publishing'},
                    {value: '178', label: 'Youth Language'},
                    {value: '179', label: 'Youth Literature'},
                    {value: '180', label: 'Youth Nursery Rhymes'},
                    {value: '181', label: 'Youth Paranormal / Supernatural'},
                    {value: '182', label: 'Youth Pets'},
                    {value: '183', label: 'Youth Philosophy'},
                    {value: '184', label: 'Youth Poetry'},
                    {value: '185', label: 'Youth Religion'},
                    {
                        value: '186',
                        label: 'Youth Science',
                        children: [
                            {value: '187', label: 'Youth Animals'},
                            {value: '188', label: 'Youth Mathematics'},
                            {value: '189', label: 'Youth Plants'},
                        ],
                    },

                    {
                        value: '190',
                        label: 'Youth Social Sciences',
                        children: [{value: '191', label: 'Youth Politics & Government'}],
                    },

                    {value: '192', label: 'Youth Sports & Recreation'},
                    {value: '193', label: 'Youth Technology'},
                ],
            },

            {
                value: '194',
                label: 'YOUTH FICTION',
                children: [
                    {value: '195', label: 'Youth General Fiction'},
                    {value: '196', label: 'Youth Action/Adventure'},
                    {value: '197', label: 'Youth Fantasy'},
                    {value: '198', label: 'Youth Graphic Novels'},
                    {value: '199', label: 'Youth Historical Fiction'},
                    {value: '200', label: 'Youth Mystery'},
                    {value: '201', label: 'Youth Romance'},
                    {value: '202', label: 'Youth Scary Stories'},
                    {value: '203', label: 'Youth Science Fiction'},
                    {value: '204', label: 'Youth Westerns'},
                ],
            },
        ],
    },

    {
        value: '205',
        label: 'MEDIA',
        children: [
            {
                value: '206',
                label: 'MEDIA ADULT NONFICTION',
                children: [
                    {value: '207', label: 'Media Arts'},
                    {value: '208', label: 'Media Business'},
                    {value: '209', label: 'Media Child Care'},
                    {value: '210', label: 'Media Computer Science'},
                    {value: '211', label: 'Media Cookery'},
                    {value: '212', label: 'Media Crafts & Hobbies'},
                    {value: '213', label: 'Media Customs & Holidays'},
                    {value: '214', label: 'Media Folklore'},
                    {value: '215', label: 'Media Gardening'},
                    {value: '216', label: 'Media Genealogy'},
                    {value: '217', label: 'Media Geography, Travel, & Culture'},
                    {value: '218', label: 'Media Health & Medicine'},
                    {value: '219', label: 'Media History'},
                    {value: '220', label: 'Media Journalism & Publishing'},
                    {value: '221', label: 'Media Language'},
                    {value: '222', label: 'Media Literature'},
                    {value: '223', label: 'Media Paranormal / Supernatural'},
                    {value: '224', label: 'Media Pets'},
                    {value: '225', label: 'Media Philosophy'},
                    {value: '226', label: 'Media Poetry'},
                    {value: '227', label: 'Media Psychology'},
                    {value: '228', label: 'Media Religion'},
                    {value: '229', label: 'Media Science'},
                    {value: '230', label: 'Media Social Sciences'},
                    {value: '231', label: 'Media Sports & Recreation'},
                    {value: '232', label: 'Media Technology'},
                ],
            },

            {
                value: '233',
                label: 'MEDIA ADULT FICTION',
                children: [
                    {value: '234', label: 'Media General Fiction'},
                    {
                        value: '235',
                        label: 'Media Crime Fiction',
                        children: [
                            {value: '236', label: 'Media Mystery'},
                            {value: '237', label: 'Media Spy / Espionage'},
                            {value: '238', label: 'Media Thriller / Suspense'},
                        ],
                    },

                    {value: '239', label: 'Media Historical Fiction'},
                    {value: '240', label: 'Media Horror Fiction'},
                    {
                        value: '241',
                        label: 'Media Romance',
                        children: [
                            {value: '242', label: 'Media Contemporary Fiction'},
                            {value: '243', label: 'Media Historical Fiction'},
                            {value: '244', label: 'Media Romantic Suspense'},
                        ],
                    },

                    {
                        value: '245',
                        label: 'Media Science Fiction & Fantasy',
                        children: [
                            {value: '246', label: 'Media Science Fiction'},
                            {value: '247', label: 'Media Fantasy'},
                        ],
                    },

                    {value: '248', label: 'Media Westerns'},
                ],
            },

            {
                value: '249',
                label: 'MEDIA YOUTH NONFICTION',
                children: [
                    {value: '250', label: 'Media Arts'},
                    {value: '251', label: 'Media Computer Science'},
                    {value: '252', label: 'Media Cookery'},
                    {value: '253', label: 'Media Crafts & Hobbies'},
                    {value: '254', label: 'Media Customs & Holidays'},
                    {value: '255', label: 'Media Feelings & Behavior'},
                    {value: '256', label: 'Media Folklore & Fairy Tales'},
                    {value: '257', label: 'Media Geography, Travel, & Culture'},
                    {value: '258', label: 'Media Health & Medicine'},
                    {value: '259', label: 'Media History'},
                    {value: '260', label: 'Media Journalism & Publishing'},
                    {value: '261', label: 'Media Language'},
                    {value: '262', label: 'Media Literature'},
                    {value: '263', label: 'Media Nursery Rhymes'},
                    {value: '264', label: 'Media Paranormal / Supernatural'},
                    {value: '265', label: 'Media Pets'},
                    {value: '266', label: 'Media Philosophy'},
                    {value: '267', label: 'Media Poetry'},
                    {value: '268', label: 'Media Religion'},
                    {value: '269', label: 'Media Science'},
                    {value: '270', label: 'Media Social Sciences'},
                    {value: '271', label: 'Media Sports & Recreation'},
                    {value: '272', label: 'Media Technology'},
                ],
            },

            {
                value: '273',
                label: 'MEDIA YOUTH FICTION',
                children: [
                    {value: '274', label: 'Media General Fiction'},
                    {value: '275', label: 'Media Action/Adventure'},
                    {value: '276', label: 'Media Fantasy'},
                    {value: '277', label: 'Media Historical Fiction'},
                    {value: '278', label: 'Media Mystery'},
                    {value: '279', label: 'Media Romance'},
                    {value: '280', label: 'Media Scary Stories'},
                    {value: '281', label: 'Media Science Fiction'},
                    {value: '282', label: 'Media Westerns'},
                ],
            },
        ],
    },

    {
        value: '283',
        label: 'REFERENCE',
        children: [
            {
                value: '284',
                label: 'Reference Arts',
                children: [
                    {value: '285', label: 'Reference Architecture'},
                    {value: '286', label: 'Reference Dance'},
                    {value: '287', label: 'Reference Movies & Television'},
                    {value: '288', label: 'Reference Music'},
                    {value: '289', label: 'Reference Photography'},
                    {value: '290', label: 'Reference Theater'},
                ],
            },

            {value: '291', label: 'Reference Business'},
            {value: '292', label: 'Reference Child Care'},
            {value: '293', label: 'Reference Computer Science'},
            {value: '294', label: 'Reference Cookery'},
            {value: '295', label: 'Reference Crafts & Hobbies'},
            {value: '296', label: 'Reference Customs & Holidays'},
            {value: '297', label: 'Reference Folklore'},
            {value: '298', label: 'Reference Gardening'},
            {value: '299', label: 'Reference Genealogy'},
            {value: '300', label: 'Reference Geography, Travel, & Culture'},
            {value: '301', label: 'Reference Health & Medicine'},
            {
                value: '302',
                label: 'Reference History',
                children: [
                    {value: '303', label: 'Reference U.S. History'},
                    {value: '304', label: 'Reference World History'},
                ],
            },

            {value: '305', label: 'Reference Journalism & Publishing'},
            {value: '306', label: 'Reference Language'},
            {value: '307', label: 'Reference Library Science'},
            {value: '308', label: 'Reference Literature'},
            {value: '309', label: 'Reference Paranormal / Supernatural'},
            {value: '310', label: 'Reference Pets'},
            {value: '311', label: 'Reference Philosophy'},
            {value: '312', label: 'Reference Psychology'},
            {value: '313', label: 'Reference Religion'},
            {
                value: '314',
                label: 'Reference Science',
                children: [
                    {value: '315', label: 'Reference Animals'},
                    {value: '316', label: 'Reference Mathematics'},
                    {value: '317', label: 'Reference Plants'},
                ],
            },

            {
                value: '318',
                label: 'Reference Social Sciences',
                children: [
                    {value: '319', label: 'Reference Economics'},
                    {value: '320', label: 'Reference Education'},
                    {value: '321', label: 'Reference Law'},
                    {value: '322', label: 'Reference Politics & Government'},
                ],
            },

            {value: '323', label: 'Reference Sports & Recreation'},
            {value: '324', label: 'Reference Technology'},
        ],
    },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const QuickSearch = () => {
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const keyword = useSelector(searchResultsInterface.getQSKeyword);
    const searchBy = useSelector(searchResultsInterface.getQSSearchBy);
    const searchFor = useSelector(searchResultsInterface.getQSSearchFor);
    const searchIn = useSelector(searchResultsInterface.getQSSearchIn);
    const [searchMode, setSearchMode] = useState(1);

    const isUserAutherticated = (!!lsUserId && lsAuthModeId !== '2');
    const isAccountAutherticated = lsAuthModeId === '6' || lsAuthModeId === '7' ||lsAuthModeId === '8'  || lsAuthModeId === '2';

    const handleSearchClick = useCallback(() => {
        searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
        if (searchMode === 1) {
            searchResultsInterface.dispatch(
                searchResultsInterface.setSearchOptions({
                    searchOptions: {
                        keyword: keyword,
                        searchby: searchBy,
                        searchIn: searchFor,
                        sections: searchIn
                    }, searchType: 1, totalCount: null
                })
            );
        }
        if (searchMode === 2) {
            setSearchMode(1);
            searchResultsInterface.dispatch(
                searchResultsInterface.setSearchOptions({searchOptions: {}, searchType: 3, totalCount: null})
            );
        }
        navigate(ROUTES.searchResutsLazy);
        // navigateTo(ROUTES.searchResuts, true);
        // history.push(ROUTES.searchResuts);
    }, [keyword, searchBy, searchMode, setSearchMode, searchFor, searchIn]);

    const handleEnterPressed = useCallback((e) => {
        if(e.keyCode === 13) {
            handleSearchClick();
        }
    }, [handleSearchClick]);

    function useInput({type, placeholder, className, valueSelector, handleChange}) {
        const input = (
            <input
                onKeyDown={handleEnterPressed}
                className={className}
                placeholder={placeholder}
                value={valueSelector}
                onChange={handleChange}
                type={type}
            />
        );
        return [input];
    }

    const isOpen = useSelector(appInterface.getQuickSearchOpen);
    const navigate = useNavigate();

    const handleMenuOpenClick = useCallback(() => {
        appInterface.dispatch(appInterface.setQuickSearchOpen({quickSearchOpen: !isOpen}));
    }, [isOpen]);

    const handleQSKeywordChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setQSKeyword(e.target.value));
    }, []);

    const [keywordInput] = useInput({
        type: 'text',
        placeholder: 'Keyword',
        valueSelector: keyword,
        handleChange: handleQSKeywordChange
    });

    const handleQSSearchByChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setQSSearchBy(e.target.value));
    }, []);

    const handleQSSearchForChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setQSSearchFor(e.target.value));
    }, []);

    const handleQSSearchInChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setQSSearchIn(e.target.value));
    }, []);

    // ---------------------------AS-------------------------------------

    const asSearchIn = useSelector(searchResultsInterface.getASSearchIn);
    const handleASSearchInChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchIn(e.target.value));
    }, []);

    const asSearchBy1 = useSelector(searchResultsInterface.getASSearchBy1);
    const handleASSearchBy1Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchBy1(e.target.value));
    }, []);
    const asSearchAnd1 = useSelector(searchResultsInterface.getASSearchAnd1);
    const handleASSearchAnd1Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchAnd1(e.target.value));
    }, []);
    const asKeyword1 = useSelector(searchResultsInterface.getASKeyword1);
    const handleASKeyword1Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASKeyword1(e.target.value));
    }, []);
    const [asKeyword1Input] = useInput(
        {
            type: 'text',
            placeholder: 'Keyword',
            className: 'keywordInput',
            valueSelector: asKeyword1,
            handleChange: handleASKeyword1Change
        });

    const asSearchBy2 = useSelector(searchResultsInterface.getASSearchBy2);
    const handleASSearchBy2Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchBy2(e.target.value));
    }, []);
    const asSearchAnd2 = useSelector(searchResultsInterface.getASSearchAnd2);
    const handleASSearchAnd2Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchAnd2(e.target.value));
    }, []);
    const asKeyword2 = useSelector(searchResultsInterface.getASKeyword2);
    const handleASKeyword2Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASKeyword2(e.target.value));
    }, []);
    const [asKeyword2Input] = useInput(
        {
            type: 'text',
            placeholder: 'Keyword',
            className: 'keywordInput',
            valueSelector: asKeyword2,
            handleChange: handleASKeyword2Change
        });

    const asSearchBy3 = useSelector(searchResultsInterface.getASSearchBy3);
    const handleASSearchBy3Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchBy3(e.target.value));
    }, []);
    const asSearchAnd3 = useSelector(searchResultsInterface.getASSearchAnd3);
    const handleASSearchAnd3Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchAnd3(e.target.value));
    }, []);
    const asKeyword3 = useSelector(searchResultsInterface.getASKeyword3);
    const handleASKeyword3Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASKeyword3(e.target.value));
    }, []);
    const [asKeyword3Input] = useInput(
        {
            type: 'text',
            placeholder: 'Keyword',
            className: 'keywordInput',
            valueSelector: asKeyword3,
            handleChange: handleASKeyword3Change
        });

    const asSearchBy4 = useSelector(searchResultsInterface.getASSearchBy4);
    const handleASSearchBy4Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchBy4(e.target.value));
    }, []);
    const asSearchAnd4 = useSelector(searchResultsInterface.getASSearchAnd4);
    const handleASSearchAnd4Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchAnd4(e.target.value));
    }, []);
    const asKeyword4 = useSelector(searchResultsInterface.getASKeyword4);
    const handleASKeyword4Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASKeyword4(e.target.value));
    }, []);
    const [asKeyword4Input] = useInput(
        {
            type: 'text',
            placeholder: 'Keyword',
            className: 'keywordInput',
            valueSelector: asKeyword4,
            handleChange: handleASKeyword4Change
        });

    const asSearchBy5 = useSelector(searchResultsInterface.getASSearchBy5);
    const handleASSearchBy5Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASSearchBy5(e.target.value));
    }, []);
    const asKeyword5 = useSelector(searchResultsInterface.getASKeyword5);
    const handleASKeyword5Change = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASKeyword5(e.target.value));
    }, []);
    const [asKeyword5Input] = useInput(
        {
            type: 'text',
            placeholder: 'Keyword',
            className: 'keywordInput',
            valueSelector: asKeyword5,
            handleChange: handleASKeyword5Change
        });

    const asDeweyFrom = useSelector(searchResultsInterface.getASDeweyFrom);
    const handleASDeweyFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASDeweyFrom(e.target.value));
    }, []);
    const [asDeweyFromInput] = useInput(
        {
            type: 'text',
            placeholder: '',
            className: 'deweyInput',
            valueSelector: asDeweyFrom,
            handleChange: handleASDeweyFromChange
        });

    const asDeweyTo = useSelector(searchResultsInterface.getASDeweyTo);
    const handleASDeweyToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASDeweyTo(e.target.value));
    }, []);
    const [asDeweyToInput] = useInput(
        {
            type: 'text',
            placeholder: '',
            className: 'deweyInput',
            valueSelector: asDeweyTo,
            handleChange: handleASDeweyToChange
        });

    // const [searchTax, setSearchTax] = useState('');
    const asTaxonomy = useSelector(searchResultsInterface.getASTaxonomy);
    const onChangeTax = (currentNode, selectedNodes) => {
        const tt = selectedNodes.filter(x => x).map(x => x.value).join(',');

        searchResultsInterface.dispatch(searchResultsInterface.setASTaxonomy(tt));
    };

    const asAudience = useSelector(searchResultsInterface.getASAudience);
    const handleASAudienceChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASAudience(e.target.value));
    }, []);

    const asAwards = useSelector(searchResultsInterface.getASAwards);
    const handleAwardsChange = useCallback((event) => {
        const {
            target: {value},
        } = event;
        searchResultsInterface.dispatch(searchResultsInterface.setASAwards(typeof value === 'string' ? value : value.join(',')));
    }, []);

    const asPIYearFrom = useSelector(searchResultsInterface.getASIssueYearFrom);
    const handleASIssueYearFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIssueYearFrom(e.target.value));
    }, []);

    const asPIYearTo = useSelector(searchResultsInterface.getASIssueYearTo);
    const handleASIssueYearToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIssueYearTo(e.target.value));
    }, []);

    const asPIMonthFrom = useSelector(searchResultsInterface.getASIssueMonthFrom);
    const handleASIssueMonthFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIssueMonthFrom(e.target.value));
    }, []);

    const asPIMonthTo = useSelector(searchResultsInterface.getASIssueMonthTo);
    const handleASIssueMonthToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIssueMonthTo(e.target.value));
    }, []);

    const asPDYearFrom = useSelector(searchResultsInterface.getASPublicationYearFrom);
    const handleASPublicationYearFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASPublicationYearFrom(e.target.value));
    }, []);

    const asPDYearTo = useSelector(searchResultsInterface.getASPublicationYearTo);
    const handleASPublicationYearToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASPublicationYearTo(e.target.value));
    }, []);

    const asPDMonthFrom = useSelector(searchResultsInterface.getASPublicationMonthFrom);
    const handleASPublicationMonthFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASPublicationMonthFrom(e.target.value));
    }, []);

    const asPDMonthTo = useSelector(searchResultsInterface.getASPublicationMonthTo);
    const handleASPublicationMonthToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASPublicationMonthTo(e.target.value));
    }, []);

    const asGradeFrom = useSelector(searchResultsInterface.getASGradeFrom);
    const handleASGradeFromChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASGradeFrom(e.target.value));
    }, []);

    const asGradeTo = useSelector(searchResultsInterface.getASGradeTo);
    const handleASGradeToChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASGradeTo(e.target.value));
    }, []);

    const asIsStarredReview = useSelector(searchResultsInterface.getASIsStarredReview);
    const handleASIsStarredReviewChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsStarredReview(e.currentTarget.checked ? true : false));
    }, []);

    const asIsOnlineOnly = useSelector(searchResultsInterface.getASIsOnlineOnly);
    const handleASIsOnlineOnlyChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsOnlineOnly(e.currentTarget.checked ? true : false));
    }, []);

    const asIsYaRecommended = useSelector(searchResultsInterface.getASIsYaRecommended);
    const handleASIsYaRecommendedChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsYaRecommended(e.currentTarget.checked ? true : false));
    }, []);

    const asIsFirstNovel = useSelector(searchResultsInterface.getASIsFirstNovel);
    const handleASIsFirstNovelChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsFirstNovel(e.currentTarget.checked ? true : false));
    }, []);

    const asIsPictureBook = useSelector(searchResultsInterface.getASIsPictureBook);
    const handleASIsPictureBookChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsPictureBook(e.currentTarget.checked ? true : false));
    }, []);

    const asIsEasyReader = useSelector(searchResultsInterface.getASIsEasyReader);
    const handleASIsEasyReaderChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsEasyReader(e.currentTarget.checked ? true : false));
    }, []);

    const asIsEbook = useSelector(searchResultsInterface.getASIsEbook);
    const handleASIsEbookChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsEbook(e.currentTarget.checked ? true : false));
    }, []);

    const asIsClassics = useSelector(searchResultsInterface.getASIsClassics);
    const handleASIsClassicsChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsClassics(e.currentTarget.checked ? true : false));
    }, []);

    const asIsPodcast = useSelector(searchResultsInterface.getASIsPodcast);
    const handleASIsPodcastChange = useCallback((e) => {
        searchResultsInterface.dispatch(searchResultsInterface.setASIsPodcast(e.currentTarget.checked ? true : false));
    }, []);



    const handleClearSearchClick = useCallback(() => {
        searchResultsInterface.dispatch(searchResultsInterface.setASClear());
    }, []);


    const assignObjectPaths = (obj, stack, selectChildren) => {
        Object.keys(obj).forEach((k) => {
            const node = obj[k];
            if (typeof node === 'object') {
                node.path = stack ? `${stack}.${k}` : k;
                if (asTaxonomy && asTaxonomy.split(',').includes(node.value) || selectChildren)
                    node.checked = true;
                else
                    node.checked = false;
                assignObjectPaths(node, node.path, node.checked === true ? true : false);
            }
        });
    };

    function deepFind(obj, path) {
        const paths = path.split(".");
        let current = obj;
        let i;
        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] == undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    useEffect(() => {
        if (!isOpen) setSearchMode(1);
        const scrollTop = window.scrollY;
        if (isOpen && !(searchMode === 1) && scrollTop >= 400) {
            window.scrollTo(0, 0);
        }
    }, [isOpen, searchMode, setSearchMode]);

    assignObjectPaths(taxonomy);

    const transferDataSource = [];

    function flatten(list = []) {
        list.forEach((item) => {
            transferDataSource.push(item);
            flatten(item.children);
        });
    }

    flatten(taxonomy);
    const rer = transferDataSource.filter(x => x.checked);
    if (asTaxonomy && asTaxonomy.split(',').length !== rer.length) {
        searchResultsInterface.dispatch(searchResultsInterface.setASTaxonomy(rer.map(x => x.value).join(',')));
    }

    const [isAddSavedSearchOpen, setIsAddSavedSearchOpen] = useState(false);
    const [savedSearchMode, setSavedSearchMode] = useState(1);
    const searchType = useSelector(searchResultsInterface.getSearchType);

    const handleAddSavedSearch = useCallback(() => {
        setSavedSearchMode(1);

        const uid = window.localStorage.getItem("LSUID");
        if (!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use saved searches',
            });
            return;
        }

        setIsAddSavedSearchOpen(!isAddSavedSearchOpen);
    }, [isAddSavedSearchOpen, setIsAddSavedSearchOpen, searchType, setSavedSearchMode, savedSearchMode]);

    const handleLoadSearch = useCallback(() => {
        setSavedSearchMode(3);

        const uid = window.localStorage.getItem("LSUID");
        if (!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use saved searches',
            });
            return;
        }
        setIsAddSavedSearchOpen(!isAddSavedSearchOpen);
    }, [isAddSavedSearchOpen, setIsAddSavedSearchOpen, searchType, setSavedSearchMode]);

    return (
        <>
            <Modal
                closable={false}
                open={isAddSavedSearchOpen}
                title="Save search"
                okText="Close"
                width={600}
                onOk={savedSearchMode === 1 ? handleAddSavedSearch : handleLoadSearch}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <SavedSearchesEditor viewMode={savedSearchMode} handleClose={handleAddSavedSearch}/>
            </Modal>
            <QuickSearchRenderer mode={searchMode} isOpen={isOpen}>
                <QuickSearchTitleRenderer mode={searchMode}>
                    <Typography
                        id="ttt"
                        font="XL"
                        fontWeight="600"
                        fontFamily="garamond-premier-pro"
                        fontStyle="italic"
                        textAlign="center"
                    >
                        {searchMode === 1 ? 'Quick Search' : 'Advanced Search'}
                    </Typography>
                    <Typography
                        font="SM"
                        fontWeight="600"
                        fontFamily="garamond-premier-pro"
                        fontStyle="italic"
                        textAlign="center"
                        onClick={() => setSearchMode(searchMode === 1 ? 2 : 1)}
                    >
                        {searchMode === 2 ? 'Quick Search' : 'Advanced Search'}
                    </Typography>
                </QuickSearchTitleRenderer>
                {searchMode === 1 ? (
                    <>
                        <QuickSearchRow>
                            {keywordInput}
                            <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                <InputLabel>Search By</InputLabel>
                                <StyledSelect value={searchBy} onChange={handleQSSearchByChange} label="Search By">
                                    <MenuItem value={23}>Title</MenuItem>
                                    <MenuItem value={15}>Author</MenuItem>
                                    <MenuItem value={81}>ISBN</MenuItem>
                                    <MenuItem value={5}>Keyword</MenuItem>
                                </StyledSelect>
                            </StyledFC>
                            <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                <InputLabel>Search For</InputLabel>
                                <StyledSelect value={searchFor} onChange={handleQSSearchForChange} label="Search For">
                                    <MenuItem value={-1}>All Content</MenuItem>
                                    <MenuItem value={0}>Reviews Only</MenuItem>
                                    <MenuItem value={1}>Feature Content Only</MenuItem>
                                    <MenuItem value={2}>Reader Content Only</MenuItem>
                                </StyledSelect>
                            </StyledFC>
                            <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                <InputLabel>Search In</InputLabel>
                                <StyledSelect value={searchIn} onChange={handleQSSearchInChange} label="Search In">
                                    <MenuItem value={-1}>All Sections</MenuItem>
                                    <MenuItem value={101}>Adult Books</MenuItem>
                                    <MenuItem value={161}>Books For Youth</MenuItem>
                                    <MenuItem value={205}>Media</MenuItem>
                                    <MenuItem value={283}>Reference Sources</MenuItem>
                                </StyledSelect>
                            </StyledFC>
                            <Button themeColor='darkblue' variant="narrow" onClick={handleSearchClick}>
                                Search
                            </Button>
                        </QuickSearchRow>
                        <QuickSearchSubTitleRenderer mode={searchMode}>
                            <Typography
                                font="SM"
                                fontWeight="600"
                                fontFamily="freight-sans-pro"
                                fontStyle="italic"
                                textAlign="right"
                                onClick={handleMenuOpenClick}
                            >
                                Hide <VerticalAlignTopOutlined/>
                            </Typography>
                        </QuickSearchSubTitleRenderer>
                    </>
                ) : (
                    <>
                        <AdvancedSearchHeaderRow>
                            <StyledFC sx={{m: 1, minWidth: 220}} size="small">
                                <InputLabel>Content</InputLabel>
                                <StyledSelect value={asSearchIn} onChange={handleASSearchInChange} label="Media class">
                                    <MenuItem value={1}>ALL</MenuItem>
                                    <MenuItem value={2}>Reviews</MenuItem>
                                    <MenuItem value={3}>Features</MenuItem>
                                    <MenuItem value={4}>Booklist blog</MenuItem>
                                </StyledSelect>
                            </StyledFC>
                        </AdvancedSearchHeaderRow>
                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Search terms:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 220}} size="small">
                                    <InputLabel>In</InputLabel>
                                    <StyledSelect value={asSearchBy1} onChange={handleASSearchBy1Change} label="In">
                                        <MenuItem value={"23"}>Title</MenuItem>
                                        <MenuItem value={"15"}>Author/Editor</MenuItem>
                                        <MenuItem value={"2"}>Illustrator</MenuItem>
                                        <MenuItem value={"3"}>Audiobook Reader</MenuItem>
                                        <MenuItem value={"4"}>Keyword in Subject</MenuItem>
                                        <MenuItem value={"10"}>Keyword in Review</MenuItem>
                                        <MenuItem value={"6"}>Publisher Name</MenuItem>
                                        <MenuItem value={"7"}>Reviewer Name</MenuItem>
                                        <MenuItem value={"81"}>ISBN</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                {asKeyword1Input}
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect value={asSearchAnd1} onChange={handleASSearchAnd1Change} label="">
                                        <MenuItem value={"1"}>And</MenuItem>
                                        <MenuItem value={"2"}>Or</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 220}} size="small">
                                    <InputLabel>In</InputLabel>
                                    <StyledSelect value={asSearchBy2} onChange={handleASSearchBy2Change} label="In">
                                        <MenuItem value={"23"}>Title</MenuItem>
                                        <MenuItem value={"15"}>Author/Editor</MenuItem>
                                        <MenuItem value={"2"}>Illustrator</MenuItem>
                                        <MenuItem value={"3"}>Audiobook Reader</MenuItem>
                                        <MenuItem value={"4"}>Keyword in Subject</MenuItem>
                                        <MenuItem value={"10"}>Keyword in Review</MenuItem>
                                        <MenuItem value={"6"}>Publisher Name</MenuItem>
                                        <MenuItem value={"7"}>Reviewer Name</MenuItem>
                                        <MenuItem value={"81"}>ISBN</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                {asKeyword2Input}
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect value={asSearchAnd2} onChange={handleASSearchAnd2Change} label="">
                                        <MenuItem value={"1"}>And</MenuItem>
                                        <MenuItem value={"2"}>Or</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 220}} size="small">
                                    <InputLabel>In</InputLabel>
                                    <StyledSelect value={asSearchBy3} onChange={handleASSearchBy3Change} label="In">
                                        <MenuItem value={"23"}>Title</MenuItem>
                                        <MenuItem value={"15"}>Author/Editor</MenuItem>
                                        <MenuItem value={"2"}>Illustrator</MenuItem>
                                        <MenuItem value={"3"}>Audiobook Reader</MenuItem>
                                        <MenuItem value={"4"}>Keyword in Subject</MenuItem>
                                        <MenuItem value={"10"}>Keyword in Review</MenuItem>
                                        <MenuItem value={"6"}>Publisher Name</MenuItem>
                                        <MenuItem value={"7"}>Reviewer Name</MenuItem>
                                        <MenuItem value={"81"}>ISBN</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                {asKeyword3Input}
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect value={asSearchAnd3} onChange={handleASSearchAnd3Change} label="">
                                        <MenuItem value={"1"}>And</MenuItem>
                                        <MenuItem value={"2"}>Or</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 220}} size="small">
                                    <InputLabel>In</InputLabel>
                                    <StyledSelect value={asSearchBy4} onChange={handleASSearchBy4Change} label="In">
                                        <MenuItem value={"23"}>Title</MenuItem>
                                        <MenuItem value={"15"}>Author/Editor</MenuItem>
                                        <MenuItem value={"2"}>Illustrator</MenuItem>
                                        <MenuItem value={"3"}>Audiobook Reader</MenuItem>
                                        <MenuItem value={"4"}>Keyword in Subject</MenuItem>
                                        <MenuItem value={"10"}>Keyword in Review</MenuItem>
                                        <MenuItem value={"6"}>Publisher Name</MenuItem>
                                        <MenuItem value={"7"}>Reviewer Name</MenuItem>
                                        <MenuItem value={"81"}>ISBN</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                {asKeyword4Input}
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect value={asSearchAnd4} onChange={handleASSearchAnd4Change} label="">
                                        <MenuItem value={"1"}>And</MenuItem>
                                        <MenuItem value={"2"}>Or</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel>In</InputLabel>
                                    <StyledSelect value={asSearchBy5} onChange={handleASSearchBy5Change} label="In">
                                        <MenuItem value={"23"}>Title</MenuItem>
                                        <MenuItem value={"15"}>Author/Editor</MenuItem>
                                        <MenuItem value={"2"}>Illustrator</MenuItem>
                                        <MenuItem value={"3"}>Audiobook Reader</MenuItem>
                                        <MenuItem value={"4"}>Keyword in Subject</MenuItem>
                                        <MenuItem value={"10"}>Keyword in Review</MenuItem>
                                        <MenuItem value={"6"}>Publisher Name</MenuItem>
                                        <MenuItem value={"7"}>Reviewer Name</MenuItem>
                                        <MenuItem value={"81"}>ISBN</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                {asKeyword5Input}
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>

                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Booklist Print Issue / Booklist Online Publication Date:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel>Month</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPIMonthFrom}
                                        onChange={handleASIssueMonthFromChange}
                                        label="Month"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="1">Jan 1 & 15</MenuItem>
                                        <MenuItem value="3">Feb 1</MenuItem>
                                        <MenuItem value="4">Feb 15</MenuItem>
                                        <MenuItem value="5">Mar 1</MenuItem>
                                        <MenuItem value="6">Mar 15</MenuItem>
                                        <MenuItem value="7">Apr 1</MenuItem>
                                        <MenuItem value="8">Apr 15</MenuItem>
                                        <MenuItem value="9">May 1</MenuItem>
                                        <MenuItem value="10">May 15</MenuItem>
                                        <MenuItem value="11">June 1 & 15</MenuItem>
                                        <MenuItem value="13">July</MenuItem>
                                        <MenuItem value="14">Aug</MenuItem>
                                        <MenuItem value="15">Sept 1</MenuItem>
                                        <MenuItem value="16">Sept 15</MenuItem>
                                        <MenuItem value="17">Oct 1</MenuItem>
                                        <MenuItem value="18">Oct 15</MenuItem>
                                        <MenuItem value="19">Nov 1</MenuItem>
                                        <MenuItem value="20">Nov 15</MenuItem>
                                        <MenuItem value="21">Dec 1</MenuItem>
                                        <MenuItem value="22">Dec 15</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <StyledFC sx={{m: 1, minWidth: 80}} size="small">
                                    <InputLabel>Year</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPIYearFrom}
                                        onChange={handleASIssueYearFromChange}
                                        label="Year"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="2025">2025</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2018">2018</MenuItem>
                                        <MenuItem value="2017">2017</MenuItem>
                                        <MenuItem value="2016">2016</MenuItem>
                                        <MenuItem value="2015">2015</MenuItem>
                                        <MenuItem value="2014">2014</MenuItem>
                                        <MenuItem value="2013">2013</MenuItem>
                                        <MenuItem value="2012">2012</MenuItem>
                                        <MenuItem value="2011">2011</MenuItem>
                                        <MenuItem value="2010">2010</MenuItem>
                                        <MenuItem value="2009">2009</MenuItem>
                                        <MenuItem value="2008">2008</MenuItem>
                                        <MenuItem value="2007">2007</MenuItem>
                                        <MenuItem value="2006">2006</MenuItem>
                                        <MenuItem value="2005">2005</MenuItem>
                                        <MenuItem value="2004">2004</MenuItem>
                                        <MenuItem value="2003">2003</MenuItem>
                                        <MenuItem value="2002">2002</MenuItem>
                                        <MenuItem value="2001">2001</MenuItem>
                                        <MenuItem value="2000">2000</MenuItem>
                                        <MenuItem value="1999">1999</MenuItem>
                                        <MenuItem value="1998">1998</MenuItem>
                                        <MenuItem value="1997">1997</MenuItem>
                                        <MenuItem value="1996">1996</MenuItem>
                                        <MenuItem value="1995">1995</MenuItem>
                                        <MenuItem value="1994">1994</MenuItem>
                                        <MenuItem value="1993">1993</MenuItem>
                                        <MenuItem value="1992">1992</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    to
                                </Typography>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel>Month</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPIMonthTo}
                                        onChange={handleASIssueMonthToChange}
                                        label="Month"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="1">Jan 1 & 15</MenuItem>
                                        <MenuItem value="3">Feb 1</MenuItem>
                                        <MenuItem value="4">Feb 15</MenuItem>
                                        <MenuItem value="5">Mar 1</MenuItem>
                                        <MenuItem value="6">Mar 15</MenuItem>
                                        <MenuItem value="7">Apr 1</MenuItem>
                                        <MenuItem value="8">Apr 15</MenuItem>
                                        <MenuItem value="9">May 1</MenuItem>
                                        <MenuItem value="10">May 15</MenuItem>
                                        <MenuItem value="11">June 1 & 15</MenuItem>
                                        <MenuItem value="13">July</MenuItem>
                                        <MenuItem value="14">Aug</MenuItem>
                                        <MenuItem value="15">Sept 1</MenuItem>
                                        <MenuItem value="16">Sept 15</MenuItem>
                                        <MenuItem value="17">Oct 1</MenuItem>
                                        <MenuItem value="18">Oct 15</MenuItem>
                                        <MenuItem value="19">Nov 1</MenuItem>
                                        <MenuItem value="20">Nov 15</MenuItem>
                                        <MenuItem value="21">Dec 1</MenuItem>
                                        <MenuItem value="22">Dec 15</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <StyledFC sx={{m: 1, minWidth: 80}} size="small">
                                    <InputLabel>Year</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPIYearTo}
                                        onChange={handleASIssueYearToChange}
                                        label="Year"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="2025">2025</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2018">2018</MenuItem>
                                        <MenuItem value="2017">2017</MenuItem>
                                        <MenuItem value="2016">2016</MenuItem>
                                        <MenuItem value="2015">2015</MenuItem>
                                        <MenuItem value="2014">2014</MenuItem>
                                        <MenuItem value="2013">2013</MenuItem>
                                        <MenuItem value="2012">2012</MenuItem>
                                        <MenuItem value="2011">2011</MenuItem>
                                        <MenuItem value="2010">2010</MenuItem>
                                        <MenuItem value="2009">2009</MenuItem>
                                        <MenuItem value="2008">2008</MenuItem>
                                        <MenuItem value="2007">2007</MenuItem>
                                        <MenuItem value="2006">2006</MenuItem>
                                        <MenuItem value="2005">2005</MenuItem>
                                        <MenuItem value="2004">2004</MenuItem>
                                        <MenuItem value="2003">2003</MenuItem>
                                        <MenuItem value="2002">2002</MenuItem>
                                        <MenuItem value="2001">2001</MenuItem>
                                        <MenuItem value="2000">2000</MenuItem>
                                        <MenuItem value="1999">1999</MenuItem>
                                        <MenuItem value="1998">1998</MenuItem>
                                        <MenuItem value="1997">1997</MenuItem>
                                        <MenuItem value="1996">1996</MenuItem>
                                        <MenuItem value="1995">1995</MenuItem>
                                        <MenuItem value="1994">1994</MenuItem>
                                        <MenuItem value="1993">1993</MenuItem>
                                        <MenuItem value="1992">1992</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <br/>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Publication Date:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel>Month</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPDMonthFrom}
                                        onChange={handleASPublicationMonthFromChange}
                                        label="Month"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="1">Jan</MenuItem>
                                        <MenuItem value="2">Feb</MenuItem>
                                        <MenuItem value="3">Mar</MenuItem>
                                        <MenuItem value="4">Apr</MenuItem>
                                        <MenuItem value="5">May</MenuItem>
                                        <MenuItem value="6">June</MenuItem>
                                        <MenuItem value="7">July</MenuItem>
                                        <MenuItem value="8">Aug</MenuItem>
                                        <MenuItem value="9">Sept</MenuItem>
                                        <MenuItem value="10">Oct</MenuItem>
                                        <MenuItem value="11">Nov</MenuItem>
                                        <MenuItem value="12">Dec</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <StyledFC sx={{m: 1, minWidth: 80}} size="small">
                                    <InputLabel>Year</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPDYearFrom}
                                        onChange={handleASPublicationYearFromChange}
                                        label="Year"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="2025">2025</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2018">2018</MenuItem>
                                        <MenuItem value="2017">2017</MenuItem>
                                        <MenuItem value="2016">2016</MenuItem>
                                        <MenuItem value="2015">2015</MenuItem>
                                        <MenuItem value="2014">2014</MenuItem>
                                        <MenuItem value="2013">2013</MenuItem>
                                        <MenuItem value="2012">2012</MenuItem>
                                        <MenuItem value="2011">2011</MenuItem>
                                        <MenuItem value="2010">2010</MenuItem>
                                        <MenuItem value="2009">2009</MenuItem>
                                        <MenuItem value="2008">2008</MenuItem>
                                        <MenuItem value="2007">2007</MenuItem>
                                        <MenuItem value="2006">2006</MenuItem>
                                        <MenuItem value="2005">2005</MenuItem>
                                        <MenuItem value="2004">2004</MenuItem>
                                        <MenuItem value="2003">2003</MenuItem>
                                        <MenuItem value="2002">2002</MenuItem>
                                        <MenuItem value="2001">2001</MenuItem>
                                        <MenuItem value="2000">2000</MenuItem>
                                        <MenuItem value="1999">1999</MenuItem>
                                        <MenuItem value="1998">1998</MenuItem>
                                        <MenuItem value="1997">1997</MenuItem>
                                        <MenuItem value="1996">1996</MenuItem>
                                        <MenuItem value="1995">1995</MenuItem>
                                        <MenuItem value="1994">1994</MenuItem>
                                        <MenuItem value="1993">1993</MenuItem>
                                        <MenuItem value="1992">1992</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    to
                                </Typography>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel>Month</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPDMonthTo}
                                        onChange={handleASPublicationMonthToChange}
                                        label="Month"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="1">Jan</MenuItem>
                                        <MenuItem value="2">Feb</MenuItem>
                                        <MenuItem value="3">Mar</MenuItem>
                                        <MenuItem value="4">Apr</MenuItem>
                                        <MenuItem value="5">May</MenuItem>
                                        <MenuItem value="6">June</MenuItem>
                                        <MenuItem value="7">July</MenuItem>
                                        <MenuItem value="8">Aug</MenuItem>
                                        <MenuItem value="9">Sept</MenuItem>
                                        <MenuItem value="10">Oct</MenuItem>
                                        <MenuItem value="11">Nov</MenuItem>
                                        <MenuItem value="12">Dec</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <StyledFC sx={{m: 1, minWidth: 80}} size="small">
                                    <InputLabel>Year</InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asPDYearTo}
                                        onChange={handleASPublicationYearToChange}
                                        label="Year"
                                    >
                                        <MenuItem selected="selected" value="-1">
                                            All
                                        </MenuItem>
                                        <MenuItem value="2025">2025</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2018">2018</MenuItem>
                                        <MenuItem value="2017">2017</MenuItem>
                                        <MenuItem value="2016">2016</MenuItem>
                                        <MenuItem value="2015">2015</MenuItem>
                                        <MenuItem value="2014">2014</MenuItem>
                                        <MenuItem value="2013">2013</MenuItem>
                                        <MenuItem value="2012">2012</MenuItem>
                                        <MenuItem value="2011">2011</MenuItem>
                                        <MenuItem value="2010">2010</MenuItem>
                                        <MenuItem value="2009">2009</MenuItem>
                                        <MenuItem value="2008">2008</MenuItem>
                                        <MenuItem value="2007">2007</MenuItem>
                                        <MenuItem value="2006">2006</MenuItem>
                                        <MenuItem value="2005">2005</MenuItem>
                                        <MenuItem value="2004">2004</MenuItem>
                                        <MenuItem value="2003">2003</MenuItem>
                                        <MenuItem value="2002">2002</MenuItem>
                                        <MenuItem value="2001">2001</MenuItem>
                                        <MenuItem value="2000">2000</MenuItem>
                                        <MenuItem value="1999">1999</MenuItem>
                                        <MenuItem value="1998">1998</MenuItem>
                                        <MenuItem value="1997">1997</MenuItem>
                                        <MenuItem value="1996">1996</MenuItem>
                                        <MenuItem value="1995">1995</MenuItem>
                                        <MenuItem value="1994">1994</MenuItem>
                                        <MenuItem value="1993">1993</MenuItem>
                                        <MenuItem value="1992">1992</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>
                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Dewey:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                {asDeweyFromInput}
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    to
                                </Typography>
                                {asDeweyToInput}
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <br/>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Grade:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asGradeFrom}
                                        onChange={handleASGradeFromChange}
                                        label=""
                                    >
                                        <MenuItem selected="selected" value="-2">
                                            All
                                        </MenuItem>
                                        <MenuItem value="-1">Preschool</MenuItem>
                                        <MenuItem value="0">Kindergarden</MenuItem>
                                        <MenuItem value="1">1st Grade</MenuItem>
                                        <MenuItem value="2">2nd Grade</MenuItem>
                                        <MenuItem value="3">3rd Grade</MenuItem>
                                        <MenuItem value="4">4th Grade</MenuItem>
                                        <MenuItem value="5">5th Grade</MenuItem>
                                        <MenuItem value="6">6th Grade</MenuItem>
                                        <MenuItem value="7">7th Grade</MenuItem>
                                        <MenuItem value="8">8th Grade</MenuItem>
                                        <MenuItem value="9">9th Grade</MenuItem>
                                        <MenuItem value="10">10th Grade</MenuItem>
                                        <MenuItem value="11">11th Grade</MenuItem>
                                        <MenuItem value="12">12th Grade</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    to
                                </Typography>
                                <StyledFC sx={{m: 1, minWidth: 120}} size="small">
                                    <InputLabel></InputLabel>
                                    <StyledSelect
                                        MenuProps={MenuProps}
                                        value={asGradeTo}
                                        onChange={handleASGradeToChange}
                                        label=""
                                    >
                                        <MenuItem selected="selected" value="-2">
                                            All
                                        </MenuItem>
                                        <MenuItem value="-1">Preschool</MenuItem>
                                        <MenuItem value="0">Kindergarden</MenuItem>
                                        <MenuItem value="1">1st Grade</MenuItem>
                                        <MenuItem value="2">2nd Grade</MenuItem>
                                        <MenuItem value="3">3rd Grade</MenuItem>
                                        <MenuItem value="4">4th Grade</MenuItem>
                                        <MenuItem value="5">5th Grade</MenuItem>
                                        <MenuItem value="6">6th Grade</MenuItem>
                                        <MenuItem value="7">7th Grade</MenuItem>
                                        <MenuItem value="8">8th Grade</MenuItem>
                                        <MenuItem value="9">9th Grade</MenuItem>
                                        <MenuItem value="10">10th Grade</MenuItem>
                                        <MenuItem value="11">11th Grade</MenuItem>
                                        <MenuItem value="12">12th Grade</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>
                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Booklist Taxonomy:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledDropdownTreeSelect data={taxonomy} onChange={onChangeTax} className="mdl-demo"/>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Audience / Format:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 400}} size="small">
                                    <InputLabel>Audience</InputLabel>
                                    <StyledSelect value={asAudience} onChange={handleASAudienceChange} label="Audience">
                                        <MenuItem selected="selected" value="0">
                                            All
                                        </MenuItem>
                                        <MenuItem value="1">Adult Books</MenuItem>
                                        <MenuItem value="2">Books for Youth</MenuItem>
                                        <MenuItem value="3">Video & DVD</MenuItem>
                                        <MenuItem value="4">Audio for Adults</MenuItem>
                                        <MenuItem value="5">Audio for Youth</MenuItem>
                                        <MenuItem value="6">Reference</MenuItem>
                                        <MenuItem value="7">Internet Resources</MenuItem>
                                    </StyledSelect>
                                </StyledFC>
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>
                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Awards:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <StyledFC sx={{m: 1, minWidth: 400, maxWidth: 400}} size="small">
                                    <InputLabel>Awards</InputLabel>
                                    <StyledSelectMM
                                        value={asAwards.split(',').filter(x => x)}
                                        onChange={handleAwardsChange}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => (
                                                    <Chip key={value}
                                                          label={awards[awards.findIndex(x => x.value === value)].name}/>
                                                ))}
                                            </Box>
                                        )}
                                        input={<OutlinedInput label="Award"/>}
                                        label="Audience"
                                        multiple
                                        MenuProps={MenuProps}
                                    >
                                        {awards.map((award) => (
                                            <MenuItem key={award.name} value={award.value}>
                                                <Checkbox checked={asAwards.split(',').indexOf(award.value) > -1}/>
                                                <ListItemText primary={award.name}/>
                                            </MenuItem>
                                        ))}
                                    </StyledSelectMM>
                                </StyledFC>
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>
                        <AdvancedSearchColumn>
                            <AdvancedSearchRow>
                                <Typography
                                    font="MD"
                                    fontWeight="600"
                                    fontFamily="freight-sans-pro"
                                    fontStyle="italic"
                                    textAlign="center"
                                >
                                    Limit search:
                                </Typography>
                            </AdvancedSearchRow>
                            <AdvancedSearchRow>
                                <AdvancedSearchRowColumn>
                                    <FormControlLabel control={<GreenSwitch checked={asIsStarredReview}
                                                                            onChange={handleASIsStarredReviewChange}
                                                                            size="small"/>} label="Starred Reviews"/>
                                    <FormControlLabel control={<GreenSwitch checked={asIsOnlineOnly}
                                                                            onChange={handleASIsOnlineOnlyChange}
                                                                            size="small"/>}
                                                      label="Online-Only Reviews"/>
                                    <FormControlLabel
                                        control={<GreenSwitch checked={asIsYaRecommended}
                                                              onChange={handleASIsYaRecommendedChange} size="small"/>}
                                        label="Adult Book Recommended for Young Adults"
                                    />
                                    <FormControlLabel control={<GreenSwitch checked={asIsFirstNovel}
                                                                            onChange={handleASIsFirstNovelChange}
                                                                            size="small"/>} label="First Novels"/>
                                    <FormControlLabel control={<GreenSwitch checked={asIsPictureBook}
                                                                            onChange={handleASIsPictureBookChange}
                                                                            size="small"/>} label="Picture Books"/>
                                </AdvancedSearchRowColumn>
                                <AdvancedSearchRowShortColumn>
                                    <FormControlLabel control={<GreenSwitch checked={asIsEasyReader}
                                                                            onChange={handleASIsEasyReaderChange}
                                                                            size="small"/>} label="Easy Readers"/>
                                    <FormControlLabel
                                        control={<GreenSwitch checked={asIsEbook} onChange={handleASIsEbookChange}
                                                              size="small"/>} label="E-books"/>
                                    <FormControlLabel
                                        control={<GreenSwitch checked={asIsClassics} onChange={handleASIsClassicsChange}
                                                              size="small"/>} label="Classics"/>
                                    <FormControlLabel
                                        control={<GreenSwitch checked={asIsPodcast} onChange={handleASIsPodcastChange}
                                                              size="small"/>} label="Podcast"/>
                                </AdvancedSearchRowShortColumn>
                            </AdvancedSearchRow>
                        </AdvancedSearchColumn>
                        <hr style={{width: '100%'}}/>
                        <Button themeColor="darkblue" variant="narrow" onClick={handleSearchClick} disabled={(!isUserAutherticated && !isAccountAutherticated)}>
                            Search
                        </Button>
                        <Button themeColor="darkblue" variant="narrow" onClick={handleClearSearchClick}
                                disabled={(!isUserAutherticated && !isAccountAutherticated)}>
                            Clear
                        </Button>
                        <div style={{flex: '1', display: 'flex', justifyContent: 'end', gap: '7px'}}>
                            <Button themeColor="darkblue" variant="narrow" onClick={handleAddSavedSearch}
                                    disabled={!lsUserId}>
                                Save Search
                            </Button>
                            <Button themeColor="darkblue" variant="narrow" onClick={handleLoadSearch}
                                    disabled={!lsUserId}>
                                LoadSearch
                            </Button>
                        </div>
                        {(!isUserAutherticated && !isAccountAutherticated) && (
                            <Typography
                                font="MD"
                                fontWeight="600"
                                fontFamily="freight-sans-pro"
                                fontStyle="italic"
                                textAlign="center"
                            >
                                Must be logged in to view advanced search result
                            </Typography>
                        )}
                    </>
                )}
            </QuickSearchRenderer>
        </>
    );
};

export default QuickSearch;
