import React from 'react';
import {useHandleLoadSiteInfo} from "@Api/hooks/siteInfo";
import {useSelector} from "react-redux";
import {siteInfoInterface} from "@Store";

const ReaderCoverPage = () => {
    const [loadData, isLoading] = useHandleLoadSiteInfo(110);
    const currentSiteInfo = useSelector(siteInfoInterface.getCurrentSiteInfo);

    return (
        <div dangerouslySetInnerHTML={{
                 __html: currentSiteInfo,
             }}
        />
    )
}
export default ReaderCoverPage;
