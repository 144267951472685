import { css } from 'styled-components';
import memoize from 'fast-memoize';
import get from 'lodash/get';
import {
  LG,
  MD,
  SM,
  XL,
  XXL,
  XS,
  SCREEN_CLASSES,
  SCREEN_CLASS_XS,
  SCREEN_CLASS_SM,
  SCREEN_CLASS_MD,
  SCREEN_CLASS_LG,
  SCREEN_CLASS_XL,
  SCREEN_CLASS_XXL,
} from '@Styles/breakpoints';
import { mergeTaggedLiteral } from '@Utils/strings';

const themed =
  (themePathStrings, ...elements) =>
  (props) => {
    const themePath = mergeTaggedLiteral(themePathStrings, ...elements);
    return get(props, `theme.${themePath}`);
  };

const buildAtMostBreakpointHandler =
  (breakpoint) =>
  (styleContent, ...expressions) =>
    css`
      @media only screen and (max-width: ${breakpoint - 1}px) {
        ${mergeTaggedLiteral(styleContent, ...expressions)}
      }
    `;

const buildAtLestBreakpointHandler =
    (breakpoint) =>
        (styleContent, ...expressions) =>
            css`
      @media only screen and (min-width: ${breakpoint + 1}px) {
        ${mergeTaggedLiteral(styleContent, ...expressions)}
      }
    `;

const atMostXS = buildAtMostBreakpointHandler(XS);
const atMostSM = buildAtMostBreakpointHandler(SM);
const atMostMD = buildAtMostBreakpointHandler(MD);
const atMostLG = buildAtMostBreakpointHandler(LG);
const atMostXL = buildAtMostBreakpointHandler(XL);
const atMostXXL = buildAtMostBreakpointHandler(XXL);

const atLestXS = buildAtLestBreakpointHandler(XS);
const atLestSM = buildAtLestBreakpointHandler(SM);
const atLestMD = buildAtLestBreakpointHandler(MD);
const atLestLG = buildAtLestBreakpointHandler(LG);
const atLestXL = buildAtLestBreakpointHandler(XL);
const atLestXXL = buildAtLestBreakpointHandler(XXL);

const screenClassNotHigherThan = memoize((breakpointToCompareWith) => {
  const indexToCompare = SCREEN_CLASSES.findIndex(equals(breakpointToCompareWith));
  return (breakpoint) => {
    const myIndex = SCREEN_CLASSES.findIndex(equals(breakpoint));

    return myIndex <= indexToCompare;
  };
});

const screenClassHigherThan = (breakpointToCompareWith) => (breakpoint) =>
  !screenClassNotHigherThan(breakpointToCompareWith)(breakpoint);

const screenClassHigherThanSM = screenClassHigherThan(SCREEN_CLASS_SM);

export default undefined;
export {
  themed,
  atMostXS,
  atMostLG,
  atMostMD,
  atMostSM,
  atMostXL,
  atMostXXL,
  atLestXS,
  atLestLG,
  atLestMD,
  atLestSM,
  atLestXL,
  atLestXXL,
  screenClassNotHigherThan,
  screenClassHigherThan,
  screenClassHigherThanSM,
  SCREEN_CLASS_LG,
  SCREEN_CLASS_MD,
  SCREEN_CLASS_SM,
  SCREEN_CLASS_XL,
  SCREEN_CLASS_XS,
  SCREEN_CLASS_XXL,
};
