import React from 'react';
// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';

import {
    BulbOutlined,
    ReadOutlined,
    ThunderboltOutlined,
    ExceptionOutlined,
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DashboardOutlined,
} from '@ant-design/icons';

// icons
const icons = {
    BulbOutlined,
    ReadOutlined,
    ThunderboltOutlined,
    ExceptionOutlined,
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DashboardOutlined
};

const menuItem = {
    items: [{
        id: 'group-announcement',
        title: 'Rich Content',
        type: 'group',
        children: [
            {
                id: 'adm_announcement',
                title: 'Top section',
                type: 'item',
                url: '/adm-announcement',
                icon: icons.BulbOutlined,
                breadcrumbs: false
            }
        ]
    },
        {
            id: 'group-landing-sections',
            title: 'Landing sections',
            type: 'group',
            children: [
                {
                    id: 'util-cur-issue',
                    title: 'Current Issues',
                    type: 'item',
                    url: '/adm-cur-issues',
                    icon: icons.ReadOutlined,
                },
                {
                    id: 'adm_high_demand',
                    title: 'High Demand titles',
                    type: 'item',
                    url: '/adm-high-demand',
                    icon: icons.ThunderboltOutlined
                },
                {
                    id: 'adm_top_categories',
                    title: 'Top categories',
                    type: 'item',
                    url: '/adm-top-categories',
                    icon: icons.ExceptionOutlined
                }
            ]
        }
        ]
};

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;