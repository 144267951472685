import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    savedSearches: null,
};

const savedSearchesSlice = createSlice({
    name: 'savedSearches',
    initialState,
    reducers: {
        setSavedSearches: (state, action) => {
            const { payload } = action;
            state.savedSearches = payload;
        },
        clearData: () => initialState,
    },
});

const savedSearchesSelectors = {
    getSavedSearches: (state) => state.savedSearches,
};
const savedSearchesActions = savedSearchesSlice.actions;

export default savedSearchesSlice;
export { savedSearchesSelectors, savedSearchesActions };