import styled, { css } from 'styled-components';
import { atMostSM } from '@Utils/themes';

const NavigationItemRenderer = styled.li`
  position: relative;
  margin-bottom: 0;
  ${atMostSM`
    margin-bottom: 8px;
  `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      ul {
        opacity: 1;
        visibility: visible;
        max-height: 1500px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    `}
  
  &:active {
    background-color: black;
    color: white;
  }

  & > a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 14px;
    ${atMostSM`
    font-size: 24px;
  `}
  }
`;

const NavigationSublistRenderer = styled.ul`
  background-color: white;
  color: black;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transition-property: opacity, visibility, transform, max-height, padding;
  transition-duration: 0.4s;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transform: scaleY(1);
`;

const NavigationSubitemRenderer = styled.li`
  margin-bottom: 16px;
  white-space: normal;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    line-height: 130%;
    transition-property: border-bottom;
    transition-duration: 0.4s;
  }

  &:hover {
    a {
      border-bottom: 3px dotted black;
    }
  }
`;

export { NavigationItemRenderer, NavigationSublistRenderer, NavigationSubitemRenderer };
