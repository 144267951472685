import React, { useCallback, useState } from 'react';
import {
  NavigationItemRenderer,
  NavigationSublistRenderer,
} from '@Components/sections/Footer/NavigationArea/NavigationItem/Renderers';
import {Link} from "react-router-dom";

const NavigationItem = ({ title, children, link }) => {
  return (
    <NavigationItemRenderer>
        {link && (
            <Link to={link}>{title}</Link>
        )}
        {!link && (
            <a>{title}</a>
        )}
        <NavigationSublistRenderer>{children}</NavigationSublistRenderer>
    </NavigationItemRenderer>
  );
};

export default NavigationItem;
