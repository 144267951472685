import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userLists: null,
};

const userListsSlice = createSlice({
    name: 'userLists',
    initialState,
    reducers: {
        setUserLists: (state, action) => {
            const { payload } = action;
            state.userLists = payload;
        },
        clearData: () => initialState,
    },
});

const userListsSelectors = {
    getUserLists: (state) => state.userLists,
};
const userListsActions =userListsSlice.actions;

export default userListsSlice;
export { userListsSelectors, userListsActions };