import {css} from "styled-components";
import {mergeTaggedLiteral} from "@Utils/strings";

const XS = 375;
const SM = 576;
const MD = 826;
const LG = 992;
const XL = 1200;
const XXL = 1600;

const SCREEN_CLASS_XS = 'xs';
const SCREEN_CLASS_SM = 'sm';
const SCREEN_CLASS_MD = 'md';
const SCREEN_CLASS_LG = 'lg';
const SCREEN_CLASS_XL = 'xl';
const SCREEN_CLASS_XXL = 'xxl';

const SCREEN_CLASSES = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const SCREEN_SIZE = {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
};

const buildAtMostBreakpointHandler = breakpoint => (
    styleContent,
    ...expressions
) => css`
  @media only screen and (max-width: ${breakpoint - 1}px) {
    ${mergeTaggedLiteral(styleContent, ...expressions)}
  }
`

const atMostXS = buildAtMostBreakpointHandler(XS)
const atMostSM = buildAtMostBreakpointHandler(SM)
const atMostMD = buildAtMostBreakpointHandler(MD)
const atMostLG = buildAtMostBreakpointHandler(LG)
const atMostXL = buildAtMostBreakpointHandler(XL)
const atMostXXL = buildAtMostBreakpointHandler(XXL)

export {
  LG,
  MD,
  SM,
  XL,
  XS,
  XXL,
  SCREEN_SIZE,
  SCREEN_CLASSES,
  SCREEN_CLASS_LG,
  SCREEN_CLASS_MD,
  SCREEN_CLASS_SM,
  SCREEN_CLASS_XL,
  SCREEN_CLASS_XS,
  SCREEN_CLASS_XXL,
  atMostXS,
  atMostSM,
  atMostMD,
  atMostLG,
  atMostXL,
  atMostXXL
};
