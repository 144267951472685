import styled from 'styled-components';

const TopMenuDesktopRenderer = styled.div`
  position: sticky;
  top: 0;
  z-index: 555;
  padding-bottom: 0;
  background-color: white;
  flex: 0 1 50px;
  justify-content: space-between;
  display: flex;
  gap: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const TopMenuMenuAreaRenderer = styled.div`
  flex: 0 1 0%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export { TopMenuDesktopRenderer, TopMenuMenuAreaRenderer };
