import { combineReducers } from 'redux';
import { configureStore, isPlain } from '@reduxjs/toolkit';
import { sliceToInterfaceBuilder } from '@Utils/store';

import notificationSlice, { notificationSelectors } from './slices/notification';
import userSlice, { userSelectors } from './slices/user';
import appSlice, { appSelectors } from './slices/app';
import searchResultsSlice, { searchResultsSelectors } from './slices/searchResults';
import productSlice, { productSelectors } from './slices/product';
import siteInfoSlice, { siteInfoSelectors } from './slices/siteInfo';
import curIssuesSlice, { curIssuesSelectors } from './slices/curIssues';
import highDemandsSlice, { highDemandsSelectors } from './slices/highDemands';
import topFeaturesSlice, { topFeaturesSelectors } from './slices/topFeatures';
import announcementSlice, { announcementSelectors } from './slices/announcement';
import userListsSlice, { userListsSelectors } from './slices/userLists';
import savedSearchesSlice, { savedSearchesSelectors } from './slices/savedSearches';
import siteInfoLoginSlice, {siteInfoLoginSelectors} from "@Store/slices/siteInfoLogin";

const reducer = combineReducers(
  [notificationSlice, userSlice, appSlice, searchResultsSlice, productSlice, siteInfoSlice, siteInfoLoginSlice, curIssuesSlice, highDemandsSlice, topFeaturesSlice, announcementSlice, userListsSlice, savedSearchesSlice].reduce(
    (acc, element) => ({
      ...acc,
      [element.name]: element.reducer,
    }),
    {}
  )
);

const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (value) => {
          return isPlain(value) || value instanceof Date;
        },
      },
    }),
});

const sliceToInterface = sliceToInterfaceBuilder(store);

const notificationInterface = sliceToInterface(notificationSlice, notificationSelectors);

const userInterface = sliceToInterface(userSlice, userSelectors);

const appInterface = sliceToInterface(appSlice, appSelectors);

const searchResultsInterface = sliceToInterface(searchResultsSlice, searchResultsSelectors);

const productInterface = sliceToInterface(productSlice, productSelectors);

const siteInfoInterface = sliceToInterface(siteInfoSlice, siteInfoSelectors);

const siteInfoLoginInterface = sliceToInterface(siteInfoLoginSlice, siteInfoLoginSelectors);

const curIssuesInterface = sliceToInterface(curIssuesSlice, curIssuesSelectors);

const highDemandsInterface = sliceToInterface(highDemandsSlice, highDemandsSelectors);

const topFeaturesInterface = sliceToInterface(topFeaturesSlice, topFeaturesSelectors);

const announcementInterface = sliceToInterface(announcementSlice, announcementSelectors);

const userListsInterface = sliceToInterface(userListsSlice, userListsSelectors);

const savedSearchesInterface = sliceToInterface(savedSearchesSlice, savedSearchesSelectors);

export default store;
export {
  notificationInterface,
  userInterface,
  appInterface,
  searchResultsInterface,
  productInterface,
  siteInfoInterface,
  curIssuesInterface,
  highDemandsInterface,
  topFeaturesInterface,
  announcementInterface,
  userListsInterface,
  savedSearchesInterface,
  siteInfoLoginInterface
};
