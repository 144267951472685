import React, {useCallback, useEffect, useRef, useState} from 'react';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import {
    EmptyResultsWrapper,
    ResultItem,
    ResultItemBody,
    ResultItemCitation,
    ResultItemHeroshot, ResultItemHeroshotPlaceholder, ResultItemHeroshotSmall,
    ResultItemMGPath,
    ResultItemSelect,
    ResultsWrapper,
    SearchResultsPageRenderer,
    SearchSort,
    SearchSummary,
    SearchToolsSelect,
    SearchToolsSend,
} from '@Components/Pages/SearchResultsLazy/Renderers';
import { useSelector } from 'react-redux';
import { searchResultsInterface } from '@Store';
import { useHandleLoadSearchResults } from '@Api/hooks/search';
import Loader from '@Components/controls/Loader';
import DOMPurify from 'dompurify';
import Typography from '@Components/controls/Typography';
import Select from 'react-select';
import SelectIcon from '@Images/Select.svg';
import { ReactComponent as ViewSelectedIcon } from '@Images/ViewSelected.svg';
import { ReactComponent as AddToListIcon } from '@Images/AddToList.svg';
import { ReactComponent as DownloadIcon } from '@Images/Download.svg';
import { ReactComponent as PrintIcon } from '@Images/print.svg';
import { ReactComponent as EmailIcon } from '@Images/Email.svg';
import CloseIcon from '@Images/Close.svg';
import SaveIcon from '@Images/Save.svg';
import LoadIcon from '@Images/Load.svg';
import CoverPlaceholder from '@Images/book-cover.svg';
import Cover from '@Images/Cover.png';
import {Link, useNavigate, useParams} from 'react-router-dom';

import Button from '@Components/controls/Button';
import {useHandleLoadSearchResultsV2} from "@Api/hooks/searchV2";
import { useIsVisible } from 'react-is-visible';
import {ROUTES} from "@Utils/routing";
import {Modal, notification} from "antd";
import UserListsEditor from "@Components/Pages/SearchResults/UserLists";
import { FloatButton, Popover } from 'antd';
import SavedSearchesEditor from "@Components/Pages/SavedSearches";
import {getSavedSearch, getSavedSearchByKey} from "@Api/savedSearches";
import {useLocalStorage} from "@Utils/localStorage";
import {FormControl, InputLabel, TextField} from "@mui/material";
import {DownloadList, EmailList} from "@Api/userLists";

const purifyConfig = {
    FORBID_TAGS: ['{{', '&nbsp;', 'img'],
    SAFE_FOR_TEMPLATES: true,
    RETURN_TRUSTED_TYPE: true,
    ALLOW_DATA_ATTR: false,
    KEEP_CONTENT: true,
};

const purifyConfig2 = {
    FORBID_TAGS: ['{{', '&nbsp;', 'img', 'span'],
    SAFE_FOR_TEMPLATES: true,
    RETURN_TRUSTED_TYPE: true,
    ALLOW_DATA_ATTR: false,
    KEEP_CONTENT: true,
};

const citationOptions = [
    { value: 'short', label: 'Citation' },
    { value: 'long', label: 'Citation + Partial text' },
];

const citationOptionsLoggedIn = [
    { value: 'short', label: 'Citation' },
    { value: 'long', label: 'Citation + Full text' },
];

const sortOptions = [
    { value: '0', label: 'Date' },
    { value: '1', label: 'Title' },
    { value: '2', label: 'Author/Editor' },
];

const sizeOptions = [
    { value: 10, label: '10' },
    { value: 30, label: '30' },
    { value: 100, label: '100' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
];

const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    spacing: {
        baseUnit: 2,
        controlHeight: 20,
        menuGutter: 4,
    },
    colors: {
        ...theme.colors,
        neutral10: 'black',
        neutral20: 'black',
        neutral30: 'black',
        neutral40: 'black',
        primary25: 'lightgrey',
        primary: 'black',
    },
});

const SearchResultsLazyPage = () => {
    const params = useParams();

    const searchResults = useSelector(searchResultsInterface.getSearchResults);
    const totalCount = useSelector(searchResultsInterface.getTotalCount);
    const page = useSelector(searchResultsInterface.getPage);
    const pageSize = useSelector(searchResultsInterface.getPageSize);
    const sortOrder = useSelector(searchResultsInterface.getSortOrder);
    const selectedIDs = useSelector(searchResultsInterface.getSelectedIDs);
    const searchOptions = useSelector(searchResultsInterface.getSearchOptions);
    const searchType = useSelector(searchResultsInterface.getSearchType);

    const loadedCount = searchResults.length;

    const navigate = useNavigate();

    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')

    const [loadData, isLoading, loadAllData] = useHandleLoadSearchResultsV2();
    const [isPrintOpen, setIsPrintOpen] = useState(false);
    const [sharedSearchLoaded, setSharedSearchLoaded] = useState(false);
    const [isAddListOpen, setIsAddListOpen] = useState(false);
    const [isAddSavedSearchOpen, setIsAddSavedSearchOpen] = useState(false);
    const [savedSearchMode, setSavedSearchMode] = useState(1);

    const [citationOption, setCitationOption] = useState('short');
    const [printEmailCitationOption, setPrintEmailCitationOption] = useState('long');

    const visibilityAnchorRef = useRef(null)
    const isVisible = useIsVisible(visibilityAnchorRef, { once: false })

    useEffect(() => {
        document.title = "Booklist Online: Leading Book Discovery";
    }, []);

    useEffect(async () => {
        if (params && params.searchKey && !sharedSearchLoaded) {
            const savedSearch = await getSavedSearchByKey(params.searchKey);
            searchResultsInterface.dispatch(searchResultsInterface.setQSKeyword(''));
            searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
            searchResultsInterface.dispatch(
                searchResultsInterface.setASCriteria(savedSearch.data)
            );
            searchResultsInterface.dispatch(searchResultsInterface.setSearchOptions({ searchOptions: { }, searchType: 3, totalCount: null }));
            setSharedSearchLoaded(true);
            window.scrollTo(0, 0);
        }
        else {
            if(!isLoading && searchType === 0) {
                navigate(ROUTES.root);
            }
        }
    }, [params, searchType, isLoading, sharedSearchLoaded, setSharedSearchLoaded])

    useEffect(() => {
        if (isVisible && totalCount && searchResults && searchResults.length > 0 && totalCount > searchResults.length && !isLoading) {
            searchResultsInterface.dispatch(
                searchResultsInterface.setNextPage()
            );
        }
    }, [isVisible, totalCount, searchResults, visibilityAnchorRef, isLoading])

    const handleTaxSearch = useCallback((mgid, mgName) => () => {
        searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
        searchResultsInterface.dispatch(searchResultsInterface.setSearchOptions({ searchOptions: { mgid, mgName }, searchType: 2, totalCount: null }));
        window.scrollTo(0, 0);
        // searchResultsInterface.dispatch(searchResultsInterface.setSearchResults({ totalCount: null, searchResults:[] }));
    }, []);

    const handleSelectDeselectPID = useCallback((pid) => () => {
        searchResultsInterface.dispatch(searchResultsInterface.setSelectedPID({ pid }));
    }, []);

    const handleSelectAll = useCallback(async () => {
        searchResultsInterface.dispatch(searchResultsInterface.setSelectAll({ }));
    }, [loadAllData]);

    const handleClearSelected = useCallback(() => {
        searchResultsInterface.dispatch(searchResultsInterface.setClearSelected({ }));
    }, []);

    const handleViewSelected = useCallback(() => {
        searchResultsInterface.dispatch(searchResultsInterface.setFirstViewIndex({ }));
        navigate('/products/');
    }, []);

    const handlePrint = useCallback(() => {
        setIsPrintOpen(!isPrintOpen);
    }, [isPrintOpen, setIsPrintOpen]);

    const handleAddList = useCallback(() => {
        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use user lists',
            });
            return;
        }

        if(!selectedIDs || selectedIDs.length === 0) {
            Modal.error({
                title: 'Select records',
                content: 'Please select at least one record to add to the list',
            });
            return;
        }

        setIsAddListOpen(!isAddListOpen);
    }, [isAddListOpen, setIsAddListOpen, selectedIDs]);

    const handleAddSavedSearch = useCallback(() => {
        setSavedSearchMode(1);

        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use saved searches',
            });
            return;
        }

        if(savedSearchMode === 1 && searchType !== 3) {
            Modal.error({
                title: 'Perform advanced search',
                content: 'Please perform advanced search in order to save the criteria.',
            });
            return;
        }

        setIsAddSavedSearchOpen(!isAddSavedSearchOpen);
    }, [isAddSavedSearchOpen, setIsAddSavedSearchOpen, searchType, setSavedSearchMode, savedSearchMode]);

    const handleLoadSearch = useCallback(() => {
        setSavedSearchMode(2);

        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use saved searches',
            });
            return;
        }
        setIsAddSavedSearchOpen(!isAddSavedSearchOpen);
    }, [isAddSavedSearchOpen, setIsAddSavedSearchOpen, searchType, setSavedSearchMode]);

    const TaxonomyRenderer = (taxonomy) => {
        const mgs = [];
        const myregexp = /{{(?<tag>MGID)=(?<mgId>[^}]*)}}(?<GroupName>[\w|\t|\r|\W]*?){{\/\k<tag>}}/g;
        let match = myregexp.exec(taxonomy);
        while (match != null) {
            mgs.push({
                MgName: match[3],
                MgId: match[2]
            });
            match = myregexp.exec(taxonomy);
        }
        return mgs.map((item, ind, arr)=>(<><a style={{textDecoration:'underline', cursor: 'pointer'}} onClick={handleTaxSearch(item.MgId, item.MgName)}>{item.MgName.replaceAll('&nbsp;','')}</a>{(ind+1)===arr.length ? '' : '→'}</>))
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = e => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(usersData.users),
            fileName: 'users.json',
            fileType: 'text/json',
        })
    }

    const exportToCsv = useCallback(async (e) => {
        e.preventDefault()

        // const res = selectedIDs.length === totalCount ? await loadAllData() : searchResults.filter(x=>selectedIDs.includes(x.Id));
        // Headers for each column
        // const headers = ['Title,PubDate,Format,Dewey,Grade,Issue']
        // const reviewsCsv = res.reduce((acc, review) => {
        //    const { TitleSubtitle, PubDate, Format, Dewey, Grade, Issue } = review
        //   acc.push([`"${TitleSubtitle}"`, `"${PubDate}"`, `"${Format}"`, `"${Dewey}"`, `"${Grade}"`, `"${Issue}"`].join(','))
        //    return acc
        // }, [])

        const { data } = await DownloadList(selectedIDs);

        downloadFile({
            data: data.Data, // [...headers, ...reviewsCsv].join('\n'),
            fileName: 'ALA Booklist Reviews.csv',
            fileType: 'text/csv',
        })

    }, [searchResults, selectedIDs, totalCount]);

    const [api, contextHolder] = notification.useNotification();
    const notifySuccess = (placement) => {
        api.success({
            message: `Your email has been sent.`,
            placement,
        });
    };
    const notifyFail = (placement) => {
        api.error({
            message: `Mail server is unavailable. Please try later.`,
            placement,
        });
    };

    const [isEmailListOpen, setIsEmailListOpen] = useState(false);
    const [emailTo, setEmailTo] = useState('');
    const [emailFrom, setEmailFrom] = useState('');
    const [comments, setComments] = useState('');
    const [subject, setSubject] = useState('');

    const handleEmailList = useCallback(() => {
        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use user lists',
            });
            return;
        }

        if(!selectedIDs || selectedIDs.length === 0) {
            Modal.error({
                title: 'Select records',
                content: 'Please select at least one record to add to the list',
            });
            return;
        }

        setIsEmailValidationError(false);

        setIsEmailListOpen(!isEmailListOpen);
    }, [isEmailListOpen, setIsEmailListOpen, selectedIDs]);

    const [isEmailValidationError, setIsEmailValidationError] = useState(false);

    const handleEmailSend = useCallback(async () => {
        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            Modal.error({
                title: 'Log In',
                content: 'Please log in to use user lists',
            });
            return;
        }

        if(!selectedIDs || selectedIDs.length === 0) {
            Modal.error({
                title: 'Select records',
                content: 'Please select at least one record to add to the list',
            });
            return;
        }

        if(!emailFrom || !emailTo || !subject) {
            setIsEmailValidationError(true);
            return;
        }

        const inputdata = {
            selectedIDs:selectedIDs,
            EmailFrom:emailFrom,
            EmailTo:emailTo,
            Comments:comments,
            Subject:subject,
            Cite:printEmailCitationOption,
        }
        const {data} = await EmailList(inputdata);
        if(data.IsSuccess) {
            notifySuccess('top');
        }
        else {
            notifyFail('top');
        }

        setIsEmailListOpen(!isEmailListOpen);
    }, [isEmailListOpen, setIsEmailListOpen, selectedIDs, emailFrom, emailTo, comments, subject, printEmailCitationOption]);

    const handleEmailToChange = useCallback((e) => {
        setEmailTo(e.target.value);
    }, [setEmailTo]);

    const handleEmailFromChange = useCallback((e) => {
        setEmailFrom(e.target.value);
    }, [setEmailFrom]);

    const handleCommentsChange = useCallback((e) => {
        setComments(e.target.value);
    }, [setComments]);

    const handleSubjectChange = useCallback((e) => {
        setSubject(e.target.value);
    }, [setSubject]);


    return (
        <ResponsiveLayout>
            {contextHolder}
            <Modal
                closable={false}
                open={isEmailListOpen}
                title="Email titles"
                okText="Email"
                width={600}
                onOk={handleEmailSend}
                onCancel={handleEmailList}
            >
                <h5 style={isEmailValidationError ? {color: 'red'} : {}}>
                    <i>Please fill required fields (*):</i>
                </h5>
                <br/>

                <InputLabel>Content:</InputLabel>
                <Select
                    labelId="pCitat" id="pCCitat" label="Content setting"
                    defaultValue={citationOptionsLoggedIn.filter((option) => option.value === printEmailCitationOption)}
                    options={citationOptionsLoggedIn}
                    onChange={(e) => {
                        setPrintEmailCitationOption(e.value);
                    }}
                />

                <TextField value={emailTo} fullWidth={true} onChange={handleEmailToChange} label="Email To address"
                           variant="standard"
                           sx={{paddingBottom: 5, paddingTop: 1}} required/>

                <TextField value={emailFrom} fullWidth={true} onChange={handleEmailFromChange}
                           label="Email From address" variant="standard"
                           sx={{paddingBottom: 5, paddingTop: 1}} required/>

                <TextField value={subject} fullWidth={true} onChange={handleSubjectChange} label="Subject"
                           variant="standard"
                           sx={{paddingBottom: 5, paddingTop: 1}} required/>

                <TextField value={comments} fullWidth={true} onChange={handleCommentsChange} label="Comments"
                           variant="standard"
                           sx={{paddingBottom: 5, paddingTop: 1}} multiline={true}/>


            </Modal>
            <Modal
                closable={false}
                open={isAddListOpen}
                title="Add to list"
                okText="Close"
                width={600}
                onOk={handleAddList}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <UserListsEditor viewMode={1} handleClose={handleAddList} />
            </Modal>
            <Modal
                closable={false}
                open={isAddSavedSearchOpen}
                title="Save search"
                okText="Close"
                width={600}
                onOk={savedSearchMode === 1 ? handleAddSavedSearch : handleLoadSearch}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <SavedSearchesEditor viewMode={savedSearchMode} handleClose={handleAddSavedSearch} />
            </Modal>
            <Modal
                open={isPrintOpen}
                title="Print content"
                okText="Print"
                cancelText="Cancel"
                width={600}
                bodyStyle={{height: 400, overflowY:'scroll'}}
                onCancel={handlePrint}
                onOk={() => {
                    const mywindow = window.open('', 'PRINT', 'height=400,width=600');

                    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
                    mywindow.document.write('</head><body >');
                    mywindow.document.write('<h1>' + document.title  + '</h1>');
                    mywindow.document.write(document.getElementById('printContent').innerHTML);
                    mywindow.document.write('</body></html>');

                    mywindow.document.close(); // necessary for IE >= 10
                    mywindow.focus(); // necessary for IE >= 10*/

                    mywindow.print();
                    // mywindow.close();

                    return true;
                }}
            >
                <Select
                    defaultValue={citationOptionsLoggedIn.filter((option) => option.value === printEmailCitationOption)}
                    theme={selectTheme}
                    sx={{width: '100px'}}
                    options={citationOptionsLoggedIn}
                    onChange={(e) => {
                        setPrintEmailCitationOption(e.value);
                    }}
                />
                <SearchResultsPageRenderer id='printContent'>
                    <ResultsWrapper>
                        {searchResults.filter(x=>selectedIDs.includes(x.Id)).map((item, i) => (
                            <ResultItem key={item.id}>
                                <ResultItemBody>
                                    <ResultItemCitation>
                                        <Link to={`/products/${item.Id}`}>
                                            <Typography font="XL" themeColor="lightBlue" fontWeight="600">
                                                {item.TitleSubtitle}
                                            </Typography>
                                            <br/>
                                        </Link>
                                        <Typography font="SM" themeColor="black" fontWeight="600">
                                            {item.PubDate} {item.Format} {item.Dewey} {item.Grade}
                                        </Typography>
                                        <br/>
                                        <Typography font="SM" themeColor="lightBlue" fontWeight="600">
                                            {item.MediaClassName}
                                        </Typography>
                                        <br/>
                                        <Typography font="SM" themeColor="black" fontWeight="600">
                                            {item.Issue}
                                        </Typography>
                                        <br/>
                                        {printEmailCitationOption === 'long' && (
                                            <Typography font="SM" themeColor="black" fontWeight="400">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(item.fullReview, purifyConfig),
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                    </ResultItemCitation>
                                </ResultItemBody>
                                <br/>
                                <br/>
                                <br/>
                            </ResultItem>
                        ))}
                    </ResultsWrapper>
                </SearchResultsPageRenderer>
            </Modal>
            <SearchResultsPageRenderer>
                {!isLoading && loadedCount === 0 && (
                    <EmptyResultsWrapper>
                        <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                            Results are empty
                        </Typography>
                    </EmptyResultsWrapper>
                )}
                {loadedCount > 0 && (
                    <>
                        <SearchSummary>
                            <Typography themeColor="lightBlue" font="XXXXL" fontWeight="600">
                                {totalCount} Results
                            </Typography>
                            {searchOptions.mgid && (
                                <Typography themeColor="lightBlue" font="LG">
                                    Category: {searchOptions.mgName}
                                </Typography>
                            )}
                            {searchOptions.keyword && (
                                <Typography themeColor="lightBlue" font="LG">

                                    Keyword: {searchOptions.keyword}, Keyword in: Title, All Content, All sections
                                </Typography>
                            )}
                        </SearchSummary>
                        <SearchSort>
                            <Typography font="LG" fontWeight="600">
                                Display:
                            </Typography>
                            <Select
                                defaultValue={citationOptions.filter((option) => option.value === citationOption)}
                                theme={selectTheme}
                                options={citationOptions}
                                onChange={(e) => {
                                    setCitationOption(e.value);
                                }}
                            />
                            <Typography font="LG" fontWeight="600">
                                Sort:
                            </Typography>
                            <Select
                                defaultValue={sortOptions.filter((option) => option.value === sortOrder)}
                                width="100px"
                                theme={selectTheme}
                                options={sortOptions}
                                onChange={(e) => {
                                    searchResultsInterface.dispatch(
                                        searchResultsInterface.setSearchResults({ totalCount: null, searchResults:[], searchResultsPIDs:[] })
                                    );
                                    searchResultsInterface.dispatch(
                                        searchResultsInterface.setSortOrder(e.value)
                                    );
                                }}
                            />
                            <Typography font="LG" fontWeight="600">
                                Size:
                            </Typography>
                            <Select
                                defaultValue={sizeOptions.filter((option) => option.value === pageSize)}
                                width="100px"
                                theme={selectTheme}
                                options={sizeOptions}
                                onChange={(e) => {
                                    searchResultsInterface.dispatch(
                                        searchResultsInterface.setSearchResults({ totalCount: null, searchResults:[], searchResultsPIDs:[] })
                                    );
                                    searchResultsInterface.dispatch(
                                        searchResultsInterface.setSearchSize({ pageSize: e.value, page:0 })
                                    );
                                }}
                            />
                        </SearchSort>
                        <SearchToolsSelect>
                            <Button variant="narrow" onClick={handleSelectAll}>
                                <img width={21} src={SelectIcon} alt="Select" />
                                Select All titles
                            </Button>
                            <Button variant="narrow" onClick={handleClearSelected}>
                                <img width={21} src={CloseIcon} alt="Select" />
                                Clear Selected
                            </Button>
                            <Button variant="narrow"  onClick={handleAddSavedSearch}>
                                <img width={21} src={SaveIcon} alt="Select" />
                                Save Search
                            </Button>
                            <Button variant="narrow" onClick={handleLoadSearch}>
                                <img width={21} src={LoadIcon} alt="Select" />
                                Load Search
                            </Button>
                        </SearchToolsSelect>
                        <SearchToolsSend>
                            <Button themeColor="white" backgroundColor="black" variant="narrow" onClick={handleViewSelected}>
                                <ViewSelectedIcon />
                                View selected items
                            </Button>
                            <Button themeColor="white" backgroundColor="black" variant="narrow" disabled={!lsUserId} onClick={handleAddList}>
                                <AddToListIcon />
                                Add To List
                            </Button>
                            <Button themeColor="white" backgroundColor="black" variant="narrow" disabled={!lsUserId} onClick={exportToCsv}>
                                <DownloadIcon />
                                Download
                            </Button>
                            <Button themeColor="white" backgroundColor="black" variant="narrow" disabled={!lsUserId} onClick={handlePrint}>
                                <PrintIcon />
                                Print
                            </Button>
                            <Button themeColor="white" backgroundColor="black" disabled={!lsUserId} variant="narrow" onClick={handleEmailList}>
                                <EmailIcon />
                                Email
                            </Button>
                        </SearchToolsSend>
                        <ResultsWrapper>
                            {searchResults.map((item, i) => (
                                <ResultItem key={item.id}>
                                    <ResultItemMGPath>
                                        <Typography font="XS" themeColor="grey1" fontFamily="freight-sans-pro" fontWeight="400">
                                            {TaxonomyRenderer(item.TaxonomyPath)}
                                        </Typography>
                                    </ResultItemMGPath>
                                    <ResultItemBody>
                                        <ResultItemSelect>
                                            <input type="checkbox" checked={selectedIDs.includes(item.Id)} onChange={handleSelectDeselectPID(item.Id)} />
                                            {item.Image && item.Image.indexOf('http') > -1 && (
                                                <Popover content={<img
                                                    src={item.Image && item.Image.indexOf('http') > -1 ? item.Image : Cover}/>}
                                                         title="Title">
                                                    <img src={CoverPlaceholder} width="14" height="14" style={{marginBottom: 5}}/>
                                                </Popover>
                                            )}
                                            {item.BookIcon && item.BookIcon.indexOf('{{BOOKIMAGE}}') > -1 && (
                                                <img src="https://www.booklistonline.com/images/book_image.gif"
                                                     width="14" height="14" style={{marginBottom: 5}}/>
                                            )}
                                            {item.Ebook && (
                                                <img src="https://www.booklistonline.com/images/ebook-66.gif" width="14" height="19" style={{marginBottom: 5}} />
                                            )}
                                            {item.BookIcon && item.BookIcon.indexOf('{{AUDIOIMAGE}}') > -1 &&  (
                                                <img src="https://www.booklistonline.com/images/headphones_image.gif" width="14" height="14" style={{marginBottom: 5}} />
                                            )}
                                            {item.BookIcon && item.BookIcon.indexOf('{{VIDEOIMAGE}}') > -1 &&  (
                                                <img src="https://www.booklistonline.com/images/camcorder_image.gif" width="14" height="14" style={{marginBottom: 5}} />
                                            )}
                                            {item.BookIcon && item.BookIcon.indexOf('{{INTERNETIMAGE}}') > -1 &&  (
                                                <img src="https://www.booklistonline.com/images/internet_image.gif" width="14" height="14" style={{marginBottom: 5}} />
                                            )}
                                            {item.Starred && (
                                                <img src="https://www.booklistonline.com/images/starred_review.gif" width="14" height="14" style={{marginBottom: 5}} />
                                            )}
                                            {item.HasAwards && (
                                                <img src="https://www.booklistonline.com/images/BlueRibbon.gif" style={{marginBottom: 5}} width="14" height="19" />
                                            )}
                                        </ResultItemSelect>
                                        {/*{citationOption === 'long' && (
                                            <>
                                                {item.Image && item.Image.indexOf('http') > -1 && (
                                                    <ResultItemHeroshot>
                                                        <img  src={item.Image && item.Image.indexOf('http') > -1 ? item.Image : Cover} />
                                                    </ResultItemHeroshot>
                                                )}
                                                {(!item.Image || item.Image.indexOf('http') === -1) && (
                                                    <ResultItemHeroshotPlaceholder>
                                                        <img  src={CoverPlaceholder} />
                                                    </ResultItemHeroshotPlaceholder>
                                                )}
                                            </>
                                        )}
                                        {citationOption === 'short' && (
                                            <>
                                                {item.Image && item.Image.indexOf('http') > -1 && (
                                                    <ResultItemHeroshotSmall>
                                                        <img  src={item.Image && item.Image.indexOf('http') > -1 ? item.Image : Cover} />
                                                    </ResultItemHeroshotSmall>
                                                )}
                                                {(!item.Image || item.Image.indexOf('http') === -1) && (
                                                    <ResultItemHeroshotPlaceholder>
                                                        <img  src={CoverPlaceholder} />
                                                    </ResultItemHeroshotPlaceholder>
                                                )}
                                            </>

                                        )}*/}
                                        <ResultItemCitation>
                                            <Link to={`/products/${item.Id}`} state={{fromSearchResults: true}}>
                                                <Typography font="MD" themeColor="lightBlue" fontFamily="freight-sans-pro" fontWeight="500">
                                                    {item.TitleSubtitle}
                                                </Typography>
                                            </Link>
                                            <Typography font="SM" fontFamily="freight-sans-pro" themeColor="black"
                                                        fontWeight="500">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(item.AuthoredBy, purifyConfig2),
                                                    }}
                                                />
                                            </Typography>
                                            <Typography font="XS" themeColor="black" fontFamily="freight-sans-pro"
                                                        fontWeight="500">
                                                {item.PubDate} {item.Format} {item.Dewey} {item.Grade}
                                            </Typography>
                                            <Typography font="XS" fontFamily="freight-sans-pro" themeColor="lightBlue" fontWeight="500">
                                                {item.MediaClassName}
                                            </Typography>
                                            <Typography font="XS" fontFamily="freight-sans-pro" themeColor="black" fontWeight="500">
                                                {item.Issue}
                                            </Typography>
                                            <Typography font="XS" fontFamily="freight-sans-pro" themeColor="black" fontWeight="500">
                                                {citationOption === 'long' && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(item.Review, purifyConfig),
                                                        }}
                                                        />
                                                )}
                                            </Typography>
                                        </ResultItemCitation>
                                    </ResultItemBody>
                                </ResultItem>
                            ))}
                        </ResultsWrapper>
                    </>
                )}
                <a ref={visibilityAnchorRef}>{isVisible ? `` : `Nope`}</a>
                {isLoading && <Loader height={400} centered />}
                <FloatButton.BackTop visibilityHeight={400} target={()=>document} />
            </SearchResultsPageRenderer>
        </ResponsiveLayout>
    );
};

export default SearchResultsLazyPage;
