import styled, { css } from 'styled-components';

const LinkButtonRenderer = styled.a`
  text-decoration: none;
  ${({ variant, themeColor, theme }) =>
    variant === 'static' &&
    css`
      border-bottom: 3px dotted ${theme.colors[themeColor]};
    `}

  ${({ variant, themeColor, theme }) =>
    variant === 'animated' &&
    css`
      transition-property: border-bottom;
      transition-duration: 0.4s;

      &:hover {
        border-bottom: 3px dotted ${theme.colors[themeColor]};
      }
      &:active {
        border-bottom: 3px dotted ${theme.colors[themeColor]};
      }
    `}
`;

export { LinkButtonRenderer };
