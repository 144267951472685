import styled from 'styled-components';
import {atMostLG} from "@Styles/breakpoints";
import {atLestLG, atLestXL} from "@Utils/themes";

const FullPageCurIssueRenderer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
`;

const CurrentIssueRenderer = styled.div`
  max-width: 1500px;
  flex: 1px;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const CurrentIssueHeaderXL = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 24px;
  margin-top: 24px;
  span {
    flex: 1;
    text-align: center;
    line-height: 50px;
  }
`;

const CurrentIssueHeader = styled.div`
  max-height: 50px;
  flex: 0 0 50px;
  display: flex;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  margin-top: 12px;
  margin-bottom: 24px;
  span {
    flex: 1;
    text-align: center;
    line-height: 50px;
  }
`;

const CurrentIssueColumnsWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  div:first-child {
  }
  div:not(:first-child) {
  }
`;

const CurrentIssueColumnRenderer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width:127px;
    ${atLestLG`
    width:240px;    
  `}
    ${atLestXL`
    width:300px;    
  `
    
  }
  
  & > span {
    margin-top: 15px;
  }
`;

const CurrentIssueItemRenderer = styled.div`
  flex: 1 1 0px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  text-align: center;
`;

export {
  CurrentIssueRenderer,
  CurrentIssueColumnRenderer,
  CurrentIssueItemRenderer,
  CurrentIssueHeader,
  CurrentIssueHeaderXL,
  CurrentIssueColumnsWrapper,
  FullPageCurIssueRenderer
};
