import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { userInterface } from '@Store';
import {AuthenticateIP, GetUserIP} from '@Api/user';
import {useLocalStorage} from "@Utils/localStorage";

export const useHandleLoadUserIp = (clearSelf) => {
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const currentUserIp = useSelector(userInterface.getUserIP);
    const [isLoading, setDataLoading] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const data =  // await GetUserIP(lsAccountId);
        userInterface.dispatch(userInterface.setUserIPAddress(data));
        const { Authenticated, accountId, account } = await AuthenticateIP({ipaddress: window.CLIENTPP});
        userInterface.dispatch(userInterface.setUserIPAuthenticated({ipAuthenticated: Authenticated}));
        if(Authenticated && !lsAccountId) {
            setShouldRefresh(true);
            setLSAccountId(accountId);
        }
        if(Authenticated && account) {
            userInterface.dispatch(userInterface.setUserAccount({account: account}));
        }
        if(Authenticated && !lsAuthModeId) {
            setShouldRefresh(true);
            setLSAuthModeId('6');
        }
        setDataLoading(false);
    }, [lsAccountId]);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) userInterface.dispatch(userInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentUserIp) loadData();
    }, [currentUserIp, loadData]);

    return [loadData, isLoading, shouldRefresh];
};
