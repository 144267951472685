import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { highDemandsInterface } from '@Store';
import {getHighDemands} from "@Api/highDemands";

export const useHandleLoadHighDemands = (clearSelf) => {
    const currentHighDemands = useSelector(highDemandsInterface.getCurrentHighDemands);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const {data} = await getHighDemands();
        highDemandsInterface.dispatch(highDemandsInterface.setCurrentHighDemands(data));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) highDemandsInterface.dispatch(highDemandsInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentHighDemands) loadData();
    }, [currentHighDemands, loadData]);

    return [loadData, isLoading];
};