import http from '@Utils/network';

const GET_CURISSUES_ENDPOINT = () => `api/EusAdmin/CurrentIssues`;
const PUT_ISSUES_SORT_ENDPOINT = () => `api/EusAdmin/UpdateCurrentIssuesSorting`;
const POST_CURISSUE_ENDPOINT = () => `api/EusAdmin/CreateCurrentIssue`;
const PUT_CURISSUE_ENDPOINT = () => `api/EusAdmin/UpdateCurrentIssue`;
const DEL_CURISSUE_ENDPOINT = (Id) => `api/EusAdmin/DeleteCurrentIssue/${Id}`;

const getCurIssues = async () => {
    const curIssues = await http.get(GET_CURISSUES_ENDPOINT());
    return curIssues;
};

const putCurIssuesSorting = async (data) => {
    const retVal = await http.put(PUT_ISSUES_SORT_ENDPOINT(), data);
    return retVal;
};

const postCurIssue = async () => {
    const retVal = await http.post(POST_CURISSUE_ENDPOINT());
    return retVal;
};

const putCurIssue = async (data) => {
    const retVal = await http.put(PUT_CURISSUE_ENDPOINT(), data);
    return retVal;
};

const delCurIssue = async (Id) => {
    const retVal = await http.delete(DEL_CURISSUE_ENDPOINT(Id));
    return retVal;
};

export { getCurIssues, putCurIssuesSorting, postCurIssue, putCurIssue, delCurIssue };