import styled, { css } from 'styled-components';
import { atMostMD, atMostSM } from '@Utils/themes';

const SideMenueRenderer = styled.div`
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  max-height: 100vh;
  background-color: white;
  transition-property: opacity, visibility, max-height, width;
  transition-duration: 0.4s;
  height: 100vh;
  border-bottom: 1px solid black;

  display: grid;
  grid-template-rows: min-content auto;

  ${atMostSM`
    height: 100%;
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      width: 270px;
      max-height: unset;
    `}

  ${({ isOpen }) =>
    isOpen &&
    atMostSM`
      width: 100%;
    `}}
`;

export { SideMenueRenderer };
