import http from '@Utils/network';

const GET_USERLISTS_ENDPOINT = (userId) => `api/EusAdmin/UserLists?userId=${userId}`;
const POST_USERLISTS_ENDPOINT = () => `api/EusAdmin/CreateUserList`;
const PUT_USERLISTS_ENDPOINT = () => `api/EusAdmin/UpdateUserList`;
const DEL_USERLISTS_ENDPOINT = (Id) => `api/EusAdmin/DeleteUserList/${Id}`;
const TRANSFERLIST_ENDPOINT = () => `api/Lists/TransferList`;
const EMAILLIST_ENDPOINT = () => `api/Lists/EmailList`;
const DOWNLOADLISTS_ENDPOINT = () => `api/Eusservice/ExportFile`;

const getUserLists = async (userId) => {
    const userLists = await http.get(GET_USERLISTS_ENDPOINT(userId));
    return userLists;
};

const postUserLists = async (data) => {
    const retVal = await http.post(POST_USERLISTS_ENDPOINT(), data);
    return retVal;
};

const putUserLists = async (data) => {
    const retVal = await http.put(PUT_USERLISTS_ENDPOINT(), data);
    return retVal;
};

const delUserLists = async (Id) => {
    const retVal = await http.delete(DEL_USERLISTS_ENDPOINT(Id));
    return retVal;
};

const TransferList = async (data) => {
    const retVal = await http.post(TRANSFERLIST_ENDPOINT(), data);
    return retVal;
};

const EmailList = async (data) => {
    const retVal = await http.post(EMAILLIST_ENDPOINT(), data);
    return retVal;
};

const DownloadList = async (data) => {
    const retVal = await http.post(DOWNLOADLISTS_ENDPOINT(), data);
    return retVal;
};

export { getUserLists, postUserLists, putUserLists, delUserLists, TransferList, EmailList, DownloadList };
