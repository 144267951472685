import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    visible: false,
    data: {
        variant: 'info',
        details: {}
    }
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setData: (state, action) => {
            const { payload } = action
            state.visible = true
            state.data = payload
        },
        clearData: () => initialState
    }
})

const notificationSelectors = {
    getVisibleState: state => state.visible,
    getData: state => state.data
}
const notificationActions = notificationSlice.actions

export default notificationSlice
export { notificationSelectors, notificationActions }
