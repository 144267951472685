import styled from 'styled-components';

const LoginAreaRenderer = styled.div`
    flex: 0 0 130px;
    display: flex;
    gap: 10px;
    margin-right: 16px;
    align-items: center;

    a {
        cursor: pointer;
    }

    button {
        font-size: 14px;
        padding: 2px 5px 2px 3px;
        gap: 2px;
    }

    &.selected {
        button {
            background-color: black;
            color: white;
        }

        ul {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
    }
`;

export { LoginAreaRenderer};
