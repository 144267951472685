import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentTopFeatures: null,
};

const topFeaturesSlice = createSlice({
    name: 'topFeatures',
    initialState,
    reducers: {
        setCurrentTopFeatures: (state, action) => {
            const { payload } = action;
            state.currentTopFeatures = payload;
        },
        clearData: () => initialState,
    },
});

const topFeaturesSelectors = {
    getCurrentTopFeatures: (state) => state.currentTopFeatures,
    getCurrentTopFeaturesWithRowLayout: (state) => state.currentTopFeatures ? state.currentTopFeatures.filter((option) => option.ColumnLayout === false) : undefined,
    getCurrentTopFeaturesWithColumnLayout: (state) => state.currentTopFeatures ? state.currentTopFeatures.filter((option) => option.ColumnLayout === true) : undefined,
};
const topFeaturesActions = topFeaturesSlice.actions;

export default topFeaturesSlice;
export { topFeaturesSelectors, topFeaturesActions };
