import http from '@Utils/network';

const GET_SAVEDSEARCHES_ENDPOINT = (userId) => `api/EusAdmin/SavedSearches?userId=${userId}`;
const GET_SAVEDSEARCH_ENDPOINT = (Id) => `api/EusAdmin/SavedSearchById/${Id}`;
const GET_SAVEDSEARCH_BYKEY_ENDPOINT = (Id) => `api/EusAdmin/SavedSearchByKey/${Id}`;
const POST_SAVEDSEARCH_ENDPOINT = () => `api/EusAdmin/CreateSavedSearch`;
const PUT_SAVEDSEARCH_ENDPOINT = () => `api/EusAdmin/UpdateSavedSearch`;
const DEL_SAVEDSEARCH_ENDPOINT = (Id) => `api/EusAdmin/DeleteSavedSearch/${Id}`;

const getSavedSearches = async (userId) => {
    const userLists = await http.get(GET_SAVEDSEARCHES_ENDPOINT(userId));
    return userLists;
};

const postSavedSearch = async (data) => {
    const retVal = await http.post(POST_SAVEDSEARCH_ENDPOINT(), data);
    return retVal;
};

const putSavedSearch = async (data) => {
    const retVal = await http.put(PUT_SAVEDSEARCH_ENDPOINT(), data);
    return retVal;
};

const delSavedSearch = async (Id) => {
    const retVal = await http.delete(DEL_SAVEDSEARCH_ENDPOINT(Id));
    return retVal;
};

const getSavedSearch = async (Id) => {
    const retVal = await http.get(GET_SAVEDSEARCH_ENDPOINT(Id));
    return retVal;
};

const getSavedSearchByKey = async (Id) => {
    const retVal = await http.get(GET_SAVEDSEARCH_BYKEY_ENDPOINT(Id));
    return retVal;
};

export { getSavedSearches, postSavedSearch, putSavedSearch, delSavedSearch, getSavedSearch, getSavedSearchByKey };