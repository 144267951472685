import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { topFeaturesInterface } from '@Store';
import {getTopFeatures} from "@Api/topFeatures";

export const useHandleLoadTopFeatures = (clearSelf) => {
    const currentHTopFeatures = useSelector(topFeaturesInterface.getCurrentTopFeatures);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const {data} = await getTopFeatures();
        topFeaturesInterface.dispatch(topFeaturesInterface.setCurrentTopFeatures(data));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) topFeaturesInterface.dispatch(topFeaturesInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentHTopFeatures) loadData();
    }, [currentHTopFeatures, loadData]);

    return [loadData, isLoading];
};