import styled, { css } from 'styled-components';
import { atMostLG, atMostMD, atMostSM } from '@Utils/themes';
import { Link } from 'react-router-dom';

const LogoWrapperRenderer = styled(Link)`
  flex: 1 1 0%;
  margin-top: 16px;
  margin-left: 16px;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  text-align: left;
  ${({ variant }) =>
    variant === 'main' &&
    atMostSM`
      margin-top: 12px;
    `}

  ${({ variant }) =>
    variant === 'SideMenu' &&
    css`
      margin-top: 32px;
      margin-bottom: 32px;
    `}

  ${({ variant }) =>
    variant === 'SideMenu' &&
    atMostSM`
      margin-top: 0px;
      &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 87px;
      display: block;
      width: 100%;
      border-bottom: 1px solid black;
    }      
    `}

  ${({ variant }) =>
    variant === 'Footer' &&
    atMostLG`
      margin-bottom: 32px;
    }      
    `}

  ${({ variant }) =>
    variant === 'TopMenu' &&
    css`
      margin-top: 0px;
    `}
`;

const LogoTitleRenderer = styled.span`
  display: block;
  font-family: 'Times New Roman', serif;
  font-style: italic;
  font-weight: 700;

  ${({ variant }) =>
    variant === 'main' &&
    css`
      font-size: 82px;
      line-height: 78px;
    `}
  ${({ variant }) =>
    variant === 'main' &&
    atMostSM`
      font-size: 50px;
      line-height: 45px;
    `}

  ${({ variant }) =>
    variant === 'SideMenu' &&
    css`
      font-size: 40px;
      line-height: 42px;
    `}
  ${({ variant }) =>
    variant === 'SideMenu' &&
    atMostSM`
      font-size: 50px;
      line-height: 45px;
    `}

  ${({ variant }) =>
    variant === 'Footer' &&
    css`
      font-size: 40px;
      line-height: 42px;
    `}
  ${({ variant }) =>
    variant === 'Footer' &&
    atMostSM`
      font-size: 50px;
      line-height: 45px;
    `}

  ${({ variant }) =>
    variant === 'TopMenu' &&
    css`
      font-size: 28px;
      line-height: 1;
    `}
`;

const LogoSubtitleRenderer = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 700;

  ${({ variant }) =>
    variant === 'SideMenu' &&
    css`
      font-size: 11px;
    `}
  ${({ variant }) =>
    variant === 'SideMenu' &&
    atMostSM`
      font-size: 10px;
    `}

  ${({ variant }) =>
    variant === 'Footer' &&
    css`
      font-size: 11px;
    `}
  ${({ variant }) =>
    variant === 'Footer' &&
    atMostSM`
      font-size: 10px;
    `}

  ${atMostLG`
    font-size: 11px;
  `}
`;

export { LogoWrapperRenderer, LogoTitleRenderer, LogoSubtitleRenderer };
