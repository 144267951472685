import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import {
    FormControl,
    FormControlLabel,
    FormHelperText, Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {ReCAPTCHA} from "react-google-recaptcha";
import Reaptcha from "reaptcha";
import {UserProfilePageRenderer} from "@Components/Pages/UserProfile/Renderers";
import {
    announcementInterface,
    curIssuesInterface,
    searchResultsInterface,
    siteInfoInterface,
    userInterface
} from "@Store";
import shareBooklistReader from '@Images/shareBooklistReader.svg';
import AccountNumGuide from '@Images/AccountNumGuide.png';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHandleLoadReaderToken} from "@Api/hooks/readerToken";
import {useHandleLoadSiteInfo} from "@Api/hooks/siteInfo";
import {ShareReaderPageRenderer} from "@Components/Pages/ShareReader/Renderer";
import {Tabs} from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";

const ShareReaderPage = () => {
    const [loadData, isLoading] = useHandleLoadReaderToken(true);
    const dataSource = useSelector(userInterface.getReaderToken);

    const [loadData2, isLoading2] = useHandleLoadSiteInfo(110);
    const currentSiteInfo = useSelector(siteInfoInterface.getCurrentSiteInfo);

    const code = '<a href="https://www.booklistonline.com/reader-issues?read=' + (dataSource ? dataSource.Token : "") + '" target="_blank">\n<script data-width="275" data-height="366" src="https://www.booklistonline.com/Scripts/readerWidget.js?token=b60ffcbc-34fa-4403-b4e2-e468bcba260e" ></script>\n</a>'

    useEffect(() => {
        document.title = "Booklist Online: Leading Book Discovery";
    }, []);

    const items = [
        {
            key: '1',
            label: 'Preview',
            children: (
                <a href={'/reader-issues?read=' + (dataSource ? dataSource.Token : "") }>
                    <div dangerouslySetInnerHTML={{
                             __html: currentSiteInfo,
                         }}
                    />
                </a>
            ),
        },
        {
            key: '2',
            label: 'Link',
            children: 'https://www.booklistonline.com/reader-issues?read='+ (dataSource ? dataSource.Token : ""),
        },
        {
            key: '3',
            label: 'Embed code',
            children:(
                <>
                    <SyntaxHighlighter language='html' wrapLines={true} wrapLongLines={true}>
                        {code}
                    </SyntaxHighlighter>
                </>
            ),
        },
    ];

    return(
        <ResponsiveLayout>
            <ShareReaderPageRenderer>
                <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                    Share Booklist Reader with your patrons
                </Typography>
                <Typography font="SM" fontStyle="italic">
                    Our newest publication, <i>Booklist Reader</i>, enriches your readers’ advisory program with patron-focused content from <i>Booklist</i>. <a href="/reader-issues"> Learn more about this new benefit for subscribing libraries.</a>
                    Below is your library’s unique, shareable link for your website and newsletters. Once you place the link or “embed code” into your website, it will automatically update to the current month’s issue. The size of the displayed image is adjustable.
                </Typography>
                <Tabs style={{minHeight: 300}} defaultActiveKey="1" items={items}/>
            </ShareReaderPageRenderer>
        </ResponsiveLayout>
    )
}

export default ShareReaderPage;
