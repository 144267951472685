import styled from 'styled-components';

const LoginPageRenderer = styled.div`
  flex: 1;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  background-color: rgb(232, 240, 254);
  min-width: 200px;
  justify-content: flex-start;  
`;

const LoginHeader = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: baseline;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 20px;
`;

const LoginHeaderLabel = styled.div`
  display: block;
  font-family: freight-sans-pro, serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: black;
  max-width: 150px;
`;

const LoginHeaderSep = styled.span`
  display: block;
  background-color: black;
  border-radius: 50%;
  font-family: freight-sans-pro, serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: white;
  padding: 30px;
  margin: 0px 50px;
`;

const LoginFormBlock = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: flex-start;
  padding-right: 30px;
  margin-top: 20px;
  & > div:first-child {
    border-right: 2px solid black;
  }
  margin-bottom: 40px;
`;

const LoginFormColumn = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  min-width: 400px;
`;

export { LoginPageRenderer, LoginContentBlock, LoginHeader, LoginHeaderSep, LoginHeaderLabel, LoginFormBlock, LoginFormColumn };
