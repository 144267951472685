import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import {
    FormControl,
    FormControlLabel,
    FormHelperText, Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {ReCAPTCHA} from "react-google-recaptcha";
import Reaptcha from "reaptcha";
import {UserProfilePageRenderer} from "@Components/Pages/UserProfile/Renderers";
import {announcementInterface, curIssuesInterface, searchResultsInterface, userInterface} from "@Store";
import shareBooklistReader from '@Images/shareBooklistReader.svg';
import AccountNumGuide from '@Images/AccountNumGuide.png';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHandleLoadUserProfile} from "@Api/hooks/userProfile";
import {notification} from "antd";
import {useLocalStorage} from "@Utils/localStorage";
import {PrintSubscription, SaveProfile} from "@Api/user";
import {lazySlidesOnRight} from "react-slick/lib/utils/innerSliderUtils";
import AccessRestricted from "@Components/sections/Common/AccessRestricted";

const UserProfilePage = () => {
    const [loadData, isLoading] = useHandleLoadUserProfile(true);
    const dataSource = useSelector(userInterface.getUserProfile);
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')

    const [api, contextHolder] = notification.useNotification();
    const notifySuccess = (placement, message) => {
        api.success({
            message: message,
            placement,
        });
    };
    const notifyFail = (placement, message) => {
        api.error({
            message: message,
            placement,
        });
    };

    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        password: '',
        passwordRepeat: '',
        email: '',
        zipCode: '',
        profession: '4',
        userName: '',
        printNumber: '',
        showPassword: false,
        AlertsFirstName1: '',
        AlertsLastName1: '',
        AlertsFirstName2: '',
        AlertsLastName2: '',
        AlertsFirstName3: '',
        AlertsLastName3: '',
        AlertsFirstName4: '',
        AlertsLastName4: '',
        AlertsFirstName5: '',
        AlertsLastName5: '',
        PreferredQuickSearchKeywordType: '23',
        PreferredQuickSearchScope: '-1',
        PreferredQuickSearchSections: '-1',
        PreferredQuickSearchSortOrder: '0',
        recap:false,
        allfilled: false
    });

    const validate = function(obj) {
        return obj &&
            obj.firstName &&
            obj.lastName &&
            obj.email &&
            obj.zipCode &&
            obj.userName &&
            obj.PreferredQuickSearchKeywordType &&
            obj.PreferredQuickSearchScope &&
            obj.PreferredQuickSearchSections &&
            obj.PreferredQuickSearchSortOrder &&
            (lsUserId || obj.password) &&
            (lsUserId || obj.passwordRepeat) &&
            (obj.passwordRepeat === obj.password) &&
            obj.recap;
    }

    useEffect(() => {
        if(!isLoading && dataSource) {
            setValues({
                ...values,
                firstName: dataSource.User.FirstName,
                lastName: dataSource.User.LastName,
                email: dataSource.User.Email,
                zipCode: dataSource.Profile.ZipCode,
                profession: dataSource.Profile.Profession,
                userName: dataSource.User.UserName,
                printNumber: dataSource.User.PrintSubscriberNumber,
                showPassword: false,
                AlertsFirstName1: dataSource.Profile.AlertsFirstName1,
                AlertsLastName1: dataSource.Profile.AlertsLastName1,
                AlertsFirstName2: dataSource.Profile.AlertsFirstName2,
                AlertsLastName2: dataSource.Profile.AlertsLastName2,
                AlertsFirstName3: dataSource.Profile.AlertsFirstName3,
                AlertsLastName3: dataSource.Profile.AlertsLastName3,
                AlertsFirstName4: dataSource.Profile.AlertsFirstName4,
                AlertsLastName4: dataSource.Profile.AlertsLastName4,
                AlertsFirstName5: dataSource.Profile.AlertsFirstName5,
                AlertsLastName5: dataSource.Profile.AlertsLastName5,
                PreferredQuickSearchKeywordType: dataSource.Profile.PreferredQuickSearchKeywordType,
                PreferredQuickSearchScope: dataSource.Profile.PreferredQuickSearchScope,
                PreferredQuickSearchSections: dataSource.Profile.PreferredQuickSearchSections,
                PreferredQuickSearchSortOrder: dataSource.Profile.PreferredQuickSearchSortOrder,
            });
        }
    }, [isLoading, dataSource]);

    const onRecaptchaVerify = useCallback((e) => {
        setValues({
            ...values,
            recap: true,
            allfilled: validate({
                ...values,
                recap: true,
            })
        });

    }, [values]);

    const onChangeTax = (currentNode, selectedNodes) => {
        const tt = selectedNodes.filter(x => x).map(x => x.value).join(',');
        setValues({
            ...values,
            taxonomy: tt,
        });
    };

    const onSubscribe = useCallback(async (e) => {
        if(lsUserId || ((lsAuthModeId === '8' || lsAuthModeId === '7' || lsAuthModeId === '6') && lsAccountId)) {
            const resp = await SaveProfile({
                ...values,
                lsUserId,
                lsAccountId
            });

            if(resp.Success) {
                notifySuccess('top', resp.Message)
            }
            else
            {
                notifyFail('top', resp.ErrorMessage)
            }
        }
        else {
            notifyFail('top', "You are not authenticated, please refresh the page")
        }

    }, [values, notifySuccess, notifyFail, lsUserId, lsAccountId, lsAuthModeId]);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleFNameChange = useCallback((e) => {
        setValues({
            ...values,
            firstName: e.target.value,
            allfilled: validate({
                ...values,
                firstName: e.target.value,
            })
        });
    }, [values]);

    const handleLNameChange = useCallback((e) => {
        setValues({
            ...values,
            lastName: e.target.value,
            allfilled: validate({
                ...values,
                lastName: e.target.value,
            })
        });
    }, [values]);

    const handleEmailChange = useCallback((e) => {
        setValues({
            ...values,
            email: e.target.value,
            allfilled: validate({
                ...values,
                email: e.target.value,
            })
        });
    }, [values]);

    const handleZipChange = useCallback((e) => {
        setValues({
            ...values,
            zipCode: e.target.value,
            allfilled: validate({
                ...values,
                zipCode: e.target.value,
            })
        });
    }, [values]);

    const handleProfessionChange = useCallback((e) => {
        setValues({
            ...values,
            profession: e.target.value,
            allfilled: validate({
                ...values,
                profession: e.target.value,
            })
        });
    }, [values]);

    const handleUserNameChange = useCallback((e) => {
        setValues({
            ...values,
            userName: e.target.value,
            allfilled: validate({
                ...values,
                userName: e.target.value,
            })
        });
    }, [values]);

    const handlePrintNumChange = useCallback((e) => {
        setValues({
            ...values,
            printNumber: e.target.value,
            allfilled: validate({
                ...values,
                printNumber: e.target.value,
            })
        });
    }, [values]);

    const handlePasswordChange = useCallback((e) => {
        setValues({
            ...values,
            password: e.target.value,
            allfilled: validate({
                ...values,
                password: e.target.value,
            })
        });
    }, [values]);

    const handlePasswRepChange = useCallback((e) => {
        setValues({
            ...values,
            passwordRepeat: e.target.value,
            allfilled: validate({
                ...values,
                passwordRepeat: e.target.value,
            })
        });
    }, [values]);


    const handleAlertsFirstName1 = useCallback((e) => {
        setValues({
            ...values,
            AlertsFirstName1: e.target.value,
            allfilled: validate({
                ...values,
                AlertsFirstName1: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsFirstName2 = useCallback((e) => {
        setValues({
            ...values,
            AlertsFirstName2: e.target.value,
            allfilled: validate({
                ...values,
                AlertsFirstName2: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsFirstName3 = useCallback((e) => {
        setValues({
            ...values,
            AlertsFirstName3: e.target.value,
            allfilled: validate({
                ...values,
                AlertsFirstName3: e.target.value,
            })
        });
    }, [values]);


    const handleAlertsFirstName4 = useCallback((e) => {
        setValues({
            ...values,
            AlertsFirstName4: e.target.value,
            allfilled: validate({
                ...values,
                AlertsFirstName4: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsFirstName5 = useCallback((e) => {
        setValues({
            ...values,
            AlertsFirstName5: e.target.value,
            allfilled: validate({
                ...values,
                AlertsFirstName5: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsLastName1 = useCallback((e) => {
        setValues({
            ...values,
            AlertsLastName1: e.target.value,
            allfilled: validate({
                ...values,
                AlertsLastName1: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsLastName2 = useCallback((e) => {
        setValues({
            ...values,
            AlertsLastName2: e.target.value,
            allfilled: validate({
                ...values,
                AlertsLastName2: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsLastName3 = useCallback((e) => {
        setValues({
            ...values,
            AlertsLastName3: e.target.value,
            allfilled: validate({
                ...values,
                AlertsLastName3: e.target.value,
            })
        });
    }, [values]);


    const handleAlertsLastName4 = useCallback((e) => {
        setValues({
            ...values,
            AlertsLastName4: e.target.value,
            allfilled: validate({
                ...values,
                AlertsLastName4: e.target.value,
            })
        });
    }, [values]);

    const handleAlertsLastName5 = useCallback((e) => {
        setValues({
            ...values,
            AlertsLastName5: e.target.value,
            allfilled: validate({
                ...values,
                AlertsLastName5: e.target.value,
            })
        });
    }, [values]);

    const handleKeywordTypeChange = useCallback((e) => {
        setValues({
            ...values,
            PreferredQuickSearchKeywordType: e.target.value,
            allfilled: validate({
                ...values,
                PreferredQuickSearchKeywordType: e.target.value,
            })
        });
    }, [values]);
    const handleScopeChange = useCallback((e) => {
        setValues({
            ...values,
            PreferredQuickSearchScope: e.target.value,
            allfilled: validate({
                ...values,
                PreferredQuickSearchScope: e.target.value,
            })
        });
    }, [values]);
    const handleSectionsChange = useCallback((e) => {
        setValues({
            ...values,
            PreferredQuickSearchSections: e.target.value,
            allfilled: validate({
                ...values,
                PreferredQuickSearchSections: e.target.value,
            })
        });
    }, [values]);
    const handleSortChange = useCallback((e) => {
        setValues({
            ...values,
            PreferredQuickSearchSortOrder: e.target.value,
            allfilled: validate({
                ...values,
                PreferredQuickSearchSortOrder: e.target.value,
            })
        });
    }, [values]);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const authenticatedToView = lsAuthModeId === '1' || lsAuthModeId === '8' || lsAuthModeId === '7' || lsAuthModeId === '6';

    return (
        <ResponsiveLayout>
            {contextHolder}
            <UserProfilePageRenderer>
                {!authenticatedToView && (<AccessRestricted />)}
                {authenticatedToView && (
                    <>
                        <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                            Booklist Online Profile Page
                        </Typography>
                        <Typography font="SM" fontStyle="italic">
                            Create or update your Booklist Online profile here. Unlimited-use account subscribers do not need to
                            enter subscription numbers into their profiles. Unsure if you need an account number? Send us an
                            email at <a href="mailto:info@booklistonline.com">info@booklistonline.com</a>.
                        </Typography>

                        <Link to="/manage-reader-access" style={{marginBottom: 15}}>
                            <img width={200} src={shareBooklistReader}></img>
                        </Link>

                        <Typography font="SM" fontWeight="600">
                            Please tell us:
                        </Typography>
                        <TextField value={values.firstName} onChange={handleFNameChange} name="bol-f-name" autoComplete="new-f-name" label="First name" variant="standard"
                                   sx={{paddingBottom: 1}}/>
                        <TextField value={values.lastName} onChange={handleLNameChange} name="bol-l-name" autoComplete="new-l-name" label="Last name" variant="standard"
                                   sx={{paddingBottom: 1}}/>
                        <TextField value={values.email} onChange={handleEmailChange} name="bol-l-email" autoComplete="new-l-email" label="Your Email Address: (required)"
                                   variant="standard" required sx={{paddingBottom: 1}}/>
                        <TextField value={values.zipCode} onChange={handleZipChange} name="bol-l-zip" autoComplete="new-l-zip" label="Zip code" variant="standard"
                                   sx={{paddingBottom: 5}}/>

                        <Typography font="SM" fontWeight="600">
                            What type of work do you do?
                        </Typography>

                        <FormControl variant="standard" sx={{paddingBottom: 5, paddingTop: 1}}>
                            <InputLabel id="pProfessionL">Your profession</InputLabel>
                            <Select labelId="pProfessionL" id="pProfession" value={values.profession}
                                    onChange={handleProfessionChange} label="Your profession">
                                <MenuItem value={"1"}>Academic Librarian</MenuItem>
                                <MenuItem value={"2"}>Public Librarian</MenuItem>
                                <MenuItem value={"3"}>School Librarian</MenuItem>
                                <MenuItem value={"4"}>Publisher</MenuItem>
                                <MenuItem value={"5"}>Individual Reader</MenuItem>
                            </Select>
                        </FormControl>

                        <Typography font="SM" fontWeight="600">
                            Create or change Username and Password:
                        </Typography>

                        <TextField value={values.userName} onChange={handleUserNameChange} name="bol-l-uname" autoComplete="new-l-uname" label="User name" variant="standard"
                                   sx={{paddingBottom: 1}} required/>
                        <TextField value={values.printNumber} onChange={handlePrintNumChange} name="bol-l-print" autoComplete="new-l-print" label="Print subscription #" variant="standard"
                                   sx={{paddingBottom: 1}}/>

                        <img src={AccountNumGuide} width={200} />

                        <FormControl sx={{paddingBottom: 1}} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                onChange={handlePasswordChange}
                                autoComplete="new-password"
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl sx={{paddingBottom: 5}} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                            <Input
                                onChange={handlePasswRepChange}
                                autoComplete="new-password"
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <Typography font="SM" fontWeight="600">
                            Get Alerts:
                        </Typography>
                        <Typography font="SM" fontStyle="italic">
                            Updates about new reviews and features in your selected interest areas will be posted under your "My Alerts" tab. Select your interests from the list below.
                        </Typography>

                        <Typography font="SM" fontWeight="600" style={{marginTop: 15}}>
                            Who are your five favorite authors?
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid container item xs={6} direction="column">
                                <TextField value={values.AlertsFirstName1} autoComplete="off" onChange={handleAlertsFirstName1} label="First name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsFirstName2} autoComplete="off" onChange={handleAlertsFirstName2} label="First name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsFirstName3} autoComplete="off" onChange={handleAlertsFirstName3} label="First name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsFirstName4} autoComplete="off" onChange={handleAlertsFirstName4} label="First name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsFirstName5} autoComplete="off" onChange={handleAlertsFirstName5} label="First name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <TextField value={values.AlertsLastName1} autoComplete="off" onChange={handleAlertsLastName1} label="Last name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsLastName2} autoComplete="off" onChange={handleAlertsLastName2} label="Last name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsLastName3} autoComplete="off" onChange={handleAlertsLastName3} label="Last name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsLastName4} autoComplete="off" onChange={handleAlertsLastName4} label="Last name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                                <TextField value={values.AlertsLastName5} autoComplete="off" onChange={handleAlertsLastName5} label="Last name" variant="standard"
                                           sx={{paddingBottom: 5}}/>
                            </Grid>
                        </Grid>

                        <Typography font="SM" fontWeight="600">
                            Select your preferences for Booklist Online:
                        </Typography>
                        <Typography font="SM" fontStyle="italic">
                            Default quick search:
                        </Typography>
                        <FormControl variant="standard" sx={{paddingBottom: 5, paddingTop: 1}}>
                            <InputLabel id="keywordType">Keyword Type</InputLabel>
                            <Select labelId="keywordType" value={values.PreferredQuickSearchKeywordType}
                                    onChange={handleKeywordTypeChange} label="Keyword Type">
                                <MenuItem value={'23'}>Title</MenuItem>
                                <MenuItem value={'15'}>Author</MenuItem>
                                <MenuItem value={'81'}>ISBN</MenuItem>
                                <MenuItem value={'5'}>Keyword</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{paddingBottom: 5, paddingTop: 1}}>
                            <InputLabel id="qsScope">Scope</InputLabel>
                            <Select labelId="qsScope" value={values.PreferredQuickSearchScope}
                                    onChange={handleScopeChange} label="Scope">
                                <MenuItem value={'-1'}>All Content</MenuItem>
                                <MenuItem value={'0'}>Reviews Only</MenuItem>
                                <MenuItem value={'1'}>Feature Content Only</MenuItem>
                                <MenuItem value={'2'}>Reader Content Only</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{paddingBottom: 5, paddingTop: 1}}>
                            <InputLabel id="qsSections">Sections</InputLabel>
                            <Select labelId="qsSections" value={values.PreferredQuickSearchScope}
                                    onChange={handleSectionsChange} label="Sections">
                                <MenuItem value={'-1'}>All Sections</MenuItem>
                                <MenuItem value={'101'}>Adult Books</MenuItem>
                                <MenuItem value={'161'}>Books For Youth</MenuItem>
                                <MenuItem value={'205'}>Media</MenuItem>
                                <MenuItem value={'283'}>Reference Sources</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{paddingBottom: 5, paddingTop: 1}}>
                            <InputLabel id="qsSort">Sort Order</InputLabel>
                            <Select labelId="qsSort" value={values.PreferredQuickSearchSortOrder}
                                    onChange={handleSortChange} label="Sort Order">
                                <MenuItem value={'0'}>Date</MenuItem>
                                <MenuItem value={'1'}>Title</MenuItem>
                                <MenuItem value={'2'}>Author/Editor</MenuItem>
                            </Select>
                        </FormControl>
                        <Reaptcha
                            sitekey="6LflPlIUAAAAAE6lK67Blwub9KCwodIWkgGsryVt"
                            onVerify={onRecaptchaVerify}
                        />
                        <Button disabled={!values.allfilled} onClick={onSubscribe} style={{marginTop: 25}}>{lsUserId ? "Save profile" : "Create profile"}</Button>
                    </>
                )}
            </UserProfilePageRenderer>
        </ResponsiveLayout>
    )
}

export default UserProfilePage;
