import React, { useCallback, useState } from 'react';
import identity from 'lodash/identity';
import ButtonRenderer from '@Components/controls/Button/Renderers';
import HGroup from '@Components/containers/HGroup';
import Loader from '@Components/controls/Loader';
import Typography from '@Components/controls/Typography';
import omit from 'lodash/omit';
import styled, { css } from 'styled-components';
import { atMostSM } from '@Utils/themes';
import { useIsMounted } from '@Utils/hooks';

const ButtonOverlayPositioner = styled.div`
  display: inline-block;
  position: relative;
  ${atMostSM`
    display: block;
  `}
`;
const DisabledButtonOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Button = (props) => {
  const {
    onClick = identity,
    onDisabledClick = identity,
    disabled,
    loading = false,
    type = 'button',
    variant = 'button',
    fillSpace = false,
    title,
    children,
    themeColor,
    backgroundColor,
  } = props;

  const [isLoadingPromise, setIsLoadingPromise] = useState(false);

  const isMounted = useIsMounted();
  const isLoading = loading || isLoadingPromise;
  const isDisabled = isLoading || disabled;

  const clickHandler = useCallback(
    (...events) => {
      if (isDisabled) {
        return;
      }

      const result = onClick(...events);

      if (result instanceof Promise) {
        setIsLoadingPromise(true);
        result.finally(() => isMounted() && setIsLoadingPromise(false));
      }

      return result;
    },
    [onClick, isDisabled, isMounted, setIsLoadingPromise]
  );

  const rendererProps = omit(props, ['onDisabledClick', 'loading']);

  return (
    <ButtonOverlayPositioner>
      <ButtonRenderer
        {...rendererProps}
        type={type}
        onClick={clickHandler}
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={variant}
        fillSpace={fillSpace}
        themeColor={themeColor}
        backgroundColor={backgroundColor}
        title={isLoading ? 'Loading' : title}
      >
        {isLoading ? (
          <HGroup>
            <Loader height={16} width={16} type="plain" fill={variant==="narrow" ? "black" : "white"} />
            <Typography>Loading</Typography>
          </HGroup>
        ) : (
          children
        )}
      </ButtonRenderer>
      {disabled && <DisabledButtonOverlay title={title} onClick={onDisabledClick} />}
    </ButtonOverlayPositioner>
  );
};

export default Button;
