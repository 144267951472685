import React, {useCallback, useState} from 'react';
import images from '@Images';
import Button from '@Components/controls/Button';
import {
    LoginAreaRenderer
} from '@Components/sections/TopMenu/LoginArea/Renderers';
import { useSelector } from 'react-redux';
import { appInterface } from '@Store';
import {NavigationSubitemRenderer} from "@Components/sections/TopMenu/NavigationArea/NavigationItem/Renderers";
import {Link, useNavigate} from "react-router-dom";
import {useLocalStorage} from "@Utils/localStorage";
import {ROUTES} from "@Utils/routing";
import {PermIdentity} from "@mui/icons-material";
import {LoginMenuRenderer, LoginMenuSubitemRenderer} from "@Components/sections/TopHeader/LoginSearchArea/Renderers";
import UserListsEditor from "@Components/Pages/SearchResults/UserLists";
import {Modal} from "antd";
import {useCookies} from "react-cookie";

const deleteCookie = (name) => {
    document.cookie = name +'=; Path=/; domain=booklistonline.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
const LoginArea = () => {
  const isOpen = useSelector(appInterface.getQuickSearchOpen);
  const handleMenuOpenClick = useCallback(() => {
    appInterface.dispatch(appInterface.setQuickSearchOpen({ quickSearchOpen: !isOpen }));
  }, [isOpen]);

    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
    const [menuOpened, setMenuOpened] = useState(false);
    const [isAddListOpen, setIsAddListOpen] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['ASP.NET_SessionId']);

    const isUserAutherticated = (!!lsUserId && lsAuthModeId !== '2');
    const isAccountAutherticated = lsAuthModeId === '6' || lsAuthModeId === '7' ||lsAuthModeId === '8'  || lsAuthModeId === '2';

    const navigate = useNavigate();
    const handleLoginClick = useCallback(() => {
        navigate(ROUTES.login);
    }, []);

    const handleMyBooklistClick = useCallback(() => {
        setMenuOpened(!menuOpened)
    }, [menuOpened]);

    const handleLogout = useCallback(() => {
        deleteCookie("betaJWT");
        setMenuOpened(false);
        setLSUserId('');
        setLSAccountId('');
        setLSAuthModeId('');
        removeCookie('ASP.NET_SessionId');
        window.location.reload();
    }, [menuOpened]);

    const handleAddList = useCallback(() => {
        setIsAddListOpen(!isAddListOpen);
    }, [isAddListOpen, setIsAddListOpen]);

  return (
    <LoginAreaRenderer className={menuOpened ? 'selected' : ''}>
        <Modal
            closable={false}
            open={isAddListOpen}
            title="Add to list"
            okText="Close"
            width={900}
            onOk={handleAddList}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <UserListsEditor viewMode={2} handleClose={handleAddList} />
        </Modal>

        {(!isUserAutherticated  && !isAccountAutherticated) && (
            <>
                <Button variant="plain" onClick={handleLoginClick}>
                    <img width={21} src={images.Login} alt="Login" />
                    Log in
                </Button>
            </>
        )}
        {!isOpen && (
            <a onClick={handleMenuOpenClick}>
                <img width={21} src={images.Search} alt="search" />
            </a>
        )}
        {isUserAutherticated && (
            <>
                <Button onClick={handleMyBooklistClick}>
                    <PermIdentity />
                    My&nbsp;Booklist
                </Button>
                <LoginMenuRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`/user-profile`}>My Profile</Link>
                    </LoginMenuSubitemRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`#`} onClick={handleAddList}>My Lists</Link>
                    </LoginMenuSubitemRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`#`} onClick={handleLogout}>Logout</Link>
                    </LoginMenuSubitemRenderer>
                </LoginMenuRenderer>
            </>
        )}
        {isAccountAutherticated && (
            <>
                <Button onClick={handleMyBooklistClick}>
                    <PermIdentity />
                    My&nbsp;Booklist
                </Button>
                <LoginMenuRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`/login`}>Log in</Link>
                    </LoginMenuSubitemRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`/user-profile`}>Create Profile</Link>
                    </LoginMenuSubitemRenderer>
                    <LoginMenuSubitemRenderer>
                        <Link to={`#`} onClick={handleLogout}>Logout</Link>
                    </LoginMenuSubitemRenderer>
                </LoginMenuRenderer>
            </>
        )}
    </LoginAreaRenderer>
  );
};

export default LoginArea;
