import styled, { css } from 'styled-components';

const ContainerRenderer = styled.div`
  min-height: min-content;
  ${({ flexy }) =>
    flexy &&
    css`
      flex: 1;
    `}

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow: auto;
    `}
  
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}
  ${({ column, flex }) =>
    flex &&
    column &&
    css`
      flex-direction: column;
    `}
  ${({ justify, flex }) =>
    flex &&
    justify &&
    css`
      justify-content: ${justify};
    `}
  ${({ align, flex }) =>
    flex &&
    align &&
    css`
      align-items: ${align};
    `}
`;

export default ContainerRenderer;
