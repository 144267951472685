import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { userListsInterface } from '@Store';
import {getUserLists} from "@Api/userLists";


export const useUserLists = (clearSelf) => {
    const currentUserLists = useSelector(userListsInterface.getUserLists);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const uid = window.localStorage.getItem("LSUID");
        if(!uid) {
            userListsInterface.dispatch(userListsInterface.setUserLists([]));
        } else {
            const {data} = await getUserLists(JSON.parse(uid));
            userListsInterface.dispatch(userListsInterface.setUserLists(data));
        }
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) userListsInterface.dispatch(userListsInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentUserLists) loadData();
    }, [currentUserLists, loadData]);

    return [loadData, isLoading];
};