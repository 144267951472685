import styled from 'styled-components';
import { atMostLG, atMostSM } from '@Utils/themes';

const NavigationAreaRenderer = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content auto;
`;

export { NavigationAreaRenderer };
