import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSiteInfo: false,
  accordionData: null
};

const siteInfoSlice = createSlice({
  name: 'siteInfo',
  initialState,
  reducers: {
    setCurrentSiteInfo: (state, action) => {
      const { payload } = action;
      state.currentSiteInfo = payload.currentSiteInfo;
    },
    setCurrentAccordionData: (state, action) => {
      const { payload } = action;
      state.accordionData = payload.currentAccordionData;
    },
    clearData: () => initialState,
  },
});

const siteInfoSelectors = {
  getCurrentSiteInfo: (state) => state.currentSiteInfo,
  getCurrentAccordionData: (state) => state.accordionData,
};
const siteInfoActions = siteInfoSlice.actions;

export default siteInfoSlice;
export { siteInfoSelectors, siteInfoActions };
