import React, {useCallback} from 'react';
import SocialMediaLinksRenderer from '@Components/sections/TopHeader/SocialMediaLinks/Renderers';
import images from '@Images';
import Button from '@Components/controls/Button';
import {appInterface, userInterface} from '@Store';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '@Utils/routing';
import {useLocalStorage} from "@Utils/localStorage";
import {useSelector} from "react-redux";
import Typography from "@Components/controls/Typography";

const SocialMediaLinks = () => {
    const navigate = useNavigate();
    const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
    const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
    const [lsAccountName, setLSAccountName] = useLocalStorage("LSANM", '')
    const currentUserAccount = useSelector(userInterface.getUserAccount);
    const handleSubscribeClick = useCallback(() => {
        navigate(ROUTES.subscriptions);
    }, []);

    const handleActivateClick = useCallback(() => {
        navigate(ROUTES.printreg);
    }, []);

    return (
        <>
            <SocialMediaLinksRenderer>
                <Button themeColor='blue' onClick={handleSubscribeClick}>Subscribe</Button>
                {(!lsUserId && !lsAccountId) && (<Button onClick={handleActivateClick}>Activate&nbsp;profile</Button>)}
            </SocialMediaLinksRenderer>
            {(lsAccountId && (currentUserAccount || lsAccountName)) && (
                <SocialMediaLinksRenderer>
                    <Typography font="SM" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                        Account: {(currentUserAccount && currentUserAccount.AccountName) ? currentUserAccount.AccountName : lsAccountName}
                    </Typography>
                </SocialMediaLinksRenderer>
            )}
        </>
    );
};

export default SocialMediaLinks;
