import styled, { keyframes, css } from 'styled-components';
import propTypes from 'prop-types';
const animation = (strokeWidth) => keyframes`
  from {
    opacity: 1;
    stroke-width: ${strokeWidth};
  }
  to {
    stroke-width: 1;
    opacity: 0.1;
  }
`;
const LoaderSVGRenderer = styled.svg.attrs(({ width, height }) => ({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 40 40',
  width,
  height,
}))`
  fill: none;

  path {
    stroke: ${({ stroke, theme }) => (!stroke ? theme.colors.loader.stroke : stroke)};
    stroke-width: ${({ strokeWidth }) => strokeWidth};
    opacity: 1;
    animation: ${({ strokeWidth }) =>
      css`
        ${animation(strokeWidth)} 0.7s linear infinite;
      `};
  }

  path:nth-child(2) {
    animation-delay: 0.1s;
  }
  path:nth-child(3) {
    animation-delay: 0.2s;
  }
  path:nth-child(4) {
    animation-delay: 0.3s;
  }
  path:nth-child(5) {
    animation-delay: 0.4s;
  }
  path:nth-child(6) {
    animation-delay: 0.5s;
  }
  path:nth-child(7) {
    animation-delay: 0.6s;
  }
  path:nth-child(8) {
    animation-delay: 0.7s;
  }
`;

LoaderSVGRenderer.propTypes = {
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  height: propTypes.oneOfType([propTypes.string, propTypes.number]),
  stroke: propTypes.string,
  strokeWidth: propTypes.number,
};

LoaderSVGRenderer.defaultProps = {
  width: 40,
  height: 40,
  strokeWidth: 3,
};

export default LoaderSVGRenderer;
