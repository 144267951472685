import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, notification, Popconfirm, Table, Upload} from 'antd';
import {arrayMoveImmutable} from 'array-move';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {MenuOutlined, RestOutlined, CloudUploadOutlined, FolderViewOutlined, CopyOutlined} from '@ant-design/icons';
import {
    savedSearchesInterface,
    searchResultsInterface
} from "@Store";
import {UploadOutlined} from '@ant-design/icons';

const EditableContext = React.createContext(null);
import {useSelector} from "react-redux";
import {fileUploadUrl, networkBaseUrl} from "@Utils/network";
import {useHandleLoadTopFeatures} from "@Api/hooks/topFeatures";
import {delTopFeatures, postTopFeatures, putTopFeatures, putTopFeaturesSorting} from "@Api/topFeatures";
import {useUserLists} from "@Api/hooks/userLists";
import {delUserLists, postUserLists, putUserLists} from "@Api/userLists";
import moment from "moment";
import {ROUTES} from "@Utils/routing";
import {useNavigate} from "react-router-dom";
import {useSavedSearches} from "@Api/hooks/savedSearches";
import {delSavedSearch, getSavedSearch, postSavedSearch, putSavedSearch} from "@Api/savedSearches";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = useCallback(async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if (!values?.cover?.file)
                handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    }, [form, toggleEdit, handleSave]);

    const handleChange = useCallback((info) => {
        if (info.file.status === 'uploading') {
            const blaBla = info.file.status;
            return;
        }
        if (info.file.status === 'done') {
            const cover = networkBaseUrl + 'Content/Images/' + info.file.response.result;
            handleSave({...record, ItemImageUrl: cover});
            return;
        }
    }, [handleSave]);

    let childNode = children;

    let editor;
    if (title === 'cover')
        editor = (
            <Upload
                action={fileUploadUrl}
                listType="picture"
                maxCount={1}
                onRemove={save}
                onChange={handleChange}
            >
                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
            </Upload>
        )
    else
        editor = (<Input ref={inputRef} name={dataIndex} onPressEnter={save} onBlur={save}/>)

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                valuePropName={title === 'cover' ? "file" : undefined}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {editor}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {title === 'cover' && (
                    <img height={80} src={children[1]}/>
                )}
                {title !== 'cover' && (
                    <>{children}</>
                )}

            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const SavedSearchesEditor = ({viewMode, handleClose, pid}) => {
    const [loadData, isLoading] = useSavedSearches(true);
    const dataSource = useSelector(savedSearchesInterface.getSavedSearches);
    const asSearchCriteria = useSelector(searchResultsInterface.getASSearchCriteria);
    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();
    const notifyCopyLink = (placement) => {
        api.info({
            message: `Link has been copied`,
            description:
                'You can paste the copied link using Ctrl-V key combination',
            placement,
        });
    };

    const onSortEnd = useCallback(async ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );

            await putTopFeaturesSorting(newData.map(x => x.Id));
            await loadData();
        }
    }, [putTopFeaturesSorting, loadData, dataSource]);

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();

        if (!dataSource) return (<></>);
        // function findIndex base on Table rowKey props and should always be a right array index
        const ind = dataSource.findIndex((x) => x.Id === props['data-row-key']);

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <SortableItem index={ind} {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const handleDelete = useCallback(async (Id) => {
        await delSavedSearch(Id);
        await loadData();
    }, [delSavedSearch, loadData]);

    const defaultColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            editable: true
        },
        {
            title: 'Date Created',
            dataIndex: 'DateCreated',
            editable: false,
            render: (record) => {
                return (
                    <div>
                        <p>{moment(record.DateCreated).format("DD-MM-YYYY")}</p>
                    </div>
                );
            }
        },
        {
            title: 'Date Updated',
            dataIndex: 'DateUpdated',
            editable: false,
            render: (record) => {
                return (
                    <div>
                        <p>{moment(record.DateUpdated).format("DD-MM-YYYY")}</p>
                    </div>
                );
            }
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        {contextHolder}
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.Id)}>
                            <a title='Delete the search'><RestOutlined style={{fontSize: '18px', color: 'black'}}/></a>
                        </Popconfirm>
                        {viewMode === 1 && (
                            <Popconfirm title="Update the search?"
                                        onConfirm={() => handlePutRecords(record.Id, record.Name)}>
                                <a title='Update the search' style={{marginLeft: 20}}><CloudUploadOutlined
                                    style={{fontSize: '18px', color: 'black'}}/></a>
                            </Popconfirm>
                        )}
                        {(viewMode === 2 || viewMode === 3) && (
                            <a title='View the search' onClick={() => handleViewRecords(record.Id)}
                               style={{marginLeft: 20}}><FolderViewOutlined style={{fontSize: '18px', color: 'black'}}/></a>
                        )}
                        {(viewMode === 2 || viewMode === 3) && (
                            <a title='Copy search link' onClick={() => {
                                navigator.clipboard.writeText(window.location.origin + '/search-results/' + record.Uid);
                                notifyCopyLink('top');
                            }} style={{marginLeft: 20}}><CopyOutlined style={{fontSize: '18px', color: 'black'}}/></a>
                        )}
                    </>
                ) : null,
        },
    ];

    const handleViewRecords = useCallback(async (Id) => {
        handleClose();
        const savedSearch = await getSavedSearch(Id);
        searchResultsInterface.dispatch(searchResultsInterface.setQSKeyword(''));
        searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
        searchResultsInterface.dispatch(
            searchResultsInterface.setASCriteria(savedSearch.data)
        );
        searchResultsInterface.dispatch(searchResultsInterface.setSearchOptions({
            searchOptions: {},
            searchType: 3,
            totalCount: null
        }));
        if (viewMode !== 3) navigate(ROUTES.searchResutsLazy);
    }, [handleClose, navigate, viewMode]);

    const handlePutRecords = useCallback(async (Id, name) => {
        await putSavedSearch({Id: Id, Name: name, Criteria: asSearchCriteria});
        await loadData();
        handleClose();
    }, [delTopFeatures, loadData, asSearchCriteria]);

    const handleAdd = useCallback(async () => {
        const uid = window.localStorage.getItem("LSUID");
        if (uid) {
            const data = await postSavedSearch({Name: 'New Saved Search', Criteria: asSearchCriteria, UserId: JSON.parse(uid)});
            if(data && data.data && data.data.CreatedList) {
                await putSavedSearch({Id: data.data.CreatedList.Id, Name: data.data.CreatedList.Name, Criteria: asSearchCriteria});
            }
            await loadData();
        }
    }, [loadData, postSavedSearch, asSearchCriteria, handleClose]);

    const handleSave = useCallback(async (row) => {
        await putSavedSearch({Id: row.Id, Name: row.Name, Criteria: asSearchCriteria});
        await loadData();
    }, [putSavedSearch, loadData, asSearchCriteria]);

    const components = {
        body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            {viewMode === 1 && (
                <Button
                    onClick={handleAdd}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Create new saved search
                </Button>
            )}
            <Table
                size={'small'}
                pagination={{
                    pageSize: 5,
                }}

                scroll={{y: 380}}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                rowKey="Id"
            />
        </div>
    );
};

export default SavedSearchesEditor;