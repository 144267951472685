import styled from 'styled-components';
import { atMostMD } from '@Utils/themes';
import {atMostLG} from "@Styles/breakpoints";

const FullPageContainerRenderer = styled.div`
  flex: 1;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  margin-left: 12px;
  margin-right: 12px;
`;

const ADContainerRenderer = styled.div`
  flex: 0 0 200px;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SiteInfoPageContainerRenderer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SiteInfoPageRenderer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  color: black;
  padding: 20px;
  * {
    color: inherit;
  }
  p {
    padding-top: 20px;
    clear: both;
  }
  div {
    clear: both;
  }

  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .exclusives-item {
    display: inline-block;
    padding: 0 0 10px 0;
    min-width: 175px;
    text-align: center;
  }  
  .reader-search {
    img {
      max-height: 200px;
    }
  }
  .digitalArchiveItem {
    display: inline-block;
    width: 180px;
    float: left;
    height: 290px;
    clear: none;
  }
`;

const MiddleADAreaRenderer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 600px;
  }
`;

const EmptyPageWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    flex: 0;
    line-height: 10;
  }
`;

export {
  FullPageContainerRenderer,
  ADContainerRenderer,
  SiteInfoPageContainerRenderer,
  SiteInfoPageRenderer,
  MiddleADAreaRenderer,
  EmptyPageWrapper,
};
