import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentSiteInfo: false,
    accordionData: null
};

const siteInfoLoginSlice = createSlice({
    name: 'siteInfoLogin',
    initialState,
    reducers: {
        setCurrentSiteInfo: (state, action) => {
            const { payload } = action;
            state.currentSiteInfo = payload.currentSiteInfo;
        },
        setCurrentAccordionData: (state, action) => {
            const { payload } = action;
            state.accordionData = payload.currentAccordionData;
        },
        clearData: () => initialState,
    },
});

const siteInfoLoginSelectors = {
    getCurrentSiteInfo: (state) => state.currentSiteInfo,
    getCurrentAccordionData: (state) => state.accordionData,
};
const siteInfoLoginActions = siteInfoLoginSlice.actions;

export default siteInfoLoginSlice;
export { siteInfoLoginSelectors, siteInfoLoginActions };
