import styled from 'styled-components';
import { atMostMD } from '@Utils/themes';

const SignUpAreaRenderer = styled.div`
  flex: 1 1 0px;
  flex-direction: column;
  display: flex;
  background-color: #eee;
  text-align: center;
  img {
    max-width: 800px;
    flex: 0;
    align-self: center;
  }
`;

const SignUpFooter = styled.div`
  background-color: #2f5733;
  flex: 1 1 0px;
  display: flex;
`;
const SignUpFooterLeft = styled.div`
  flex: 2 1 0px;
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  padding-left: 100px;
  padding-bottom: 35px;
  ${atMostMD`
    padding-left: 20px;
  `}
`;
const SignUpFooterRight = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export { SignUpAreaRenderer, SignUpFooter, SignUpFooterLeft, SignUpFooterRight };
