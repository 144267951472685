import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import omit from 'lodash/omit';
import * as GridSystem from 'react-grid-system';
import { LG, MD, SM, XL, XS, XXL, SCREEN_SIZE } from '../../../styles/breakpoints';

const GUTTER_WIDTH = 22;

GridSystem.setConfiguration({
  gridColumns: 24,
  gutterWidth: GUTTER_WIDTH,
  breakpoints: [XS, SM, MD, LG, XL],
});

const Grid = styled((props) => {
  const { flex, flexy, noMargin } = props;

  const style = {
    ...props.style,
    ...(flex ? { display: 'flex', flexDirection: 'column' } : ''),
    ...(flexy ? { flex: 1 } : {}),
    /* eslint-disable indent */
    // we have conflict between eslint's and prettier's opinions here.
    ...(noMargin
      ? {
          paddingLeft: `-${GUTTER_WIDTH / 2}px`,
          paddingRight: `-${GUTTER_WIDTH / 2}px`,
          overflowX: 'hidden', // row will expand half a gutter, thus this is to clip it.
        }
      : {}),
    /* eslint-enable */
  };

  const gridSystemProps = omit(props, ['noMargin', 'flex', 'flexy', 'loose']);

  return <GridSystem.Container {...{ fluid: true, ...gridSystemProps, style }} />;
})`
  ${({ loose }) =>
    loose
      ? css`
          & > .grid-row-marker {
            xx-margin-bottom: 12px;
            min-height: 36px;
          }
          & > form > .grid-row-marker {
            xx-margin-bottom: 12px;
            min-height: 36px;
          }
        `
      : ''}
`;

const Row = (props) => {
  const { flex, flexy } = props;
  const style = {
    ...props.style,
    ...(flexy ? { flex: 1 } : {}),
    ...(flex ? { display: 'flex' } : {}),
  };
  return (
    <GridSystem.Row
      {...{
        ...props,
        className: 'grid-row-marker',
        style,
        flexy: undefined,
        flex: undefined,
      }}
    />
  );
};

const Col = (props) => {
  const { flex, flexy } = props;
  const style = {
    ...props.style,
    ...(flexy ? { flex: 1 } : {}),
    ...(flex ? { display: 'flex' } : {}),
    ...{ paddingBottom: '10px' },
  };
  return <GridSystem.Col {...{ ...props, style, flexy: undefined, flex: undefined }} />;
};

// TODO: Ensure we need SimplyVisible component
const Visible = (props) => <GridSystem.Visible {...props} />;
const SimplyVisible = ({ visible, children, ...props }) => (visible ? children : null);

Grid.propTypes = {
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.arrayOf(propTypes.oneOfType([propTypes.element, propTypes.bool])),
    propTypes.bool,
  ]),
  fluid: propTypes.bool,
  noMargin: propTypes.bool,
  flex: propTypes.bool,
  flexy: propTypes.bool,
  style: propTypes.object,
};
Row.propTypes = {
  flex: propTypes.bool,
  flexy: propTypes.bool,
  style: propTypes.object,
};

Col.propTypes = {
  flex: propTypes.bool,
  flexy: propTypes.bool,
  style: propTypes.object,
};

Visible.propTypes = {
  visible: propTypes.bool,
};
SimplyVisible.propTypes = {
  visible: propTypes.bool,
};
const ScreenSizeProvider = GridSystem.ScreenClassProvider;
const { useScreenClass } = GridSystem;
export default Grid;
export { Row, Col, Grid, ScreenSizeProvider, useScreenClass, Visible, SimplyVisible };
