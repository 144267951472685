import styled from 'styled-components';
import { atMostMD } from '@Utils/themes';

const ShareReaderPageRenderer = styled.div`
  flex: 1;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 800px;
  align-self: center;
  padding: 25px;
  a {
    color: black;
  }
  & > span:nth-child(-n + 2) {
    padding-bottom: 20px;
  }
`;

export { ShareReaderPageRenderer };
