import styled from 'styled-components';

const EmptyResultsWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    flex: 0;
    line-height: 10;
  }
`;

const SearchResultsPageRenderer = styled.div`
  flex: 1;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 950px;
  align-self: center;
`;

const SearchSummary = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const SearchSort = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchToolsSelect = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

const SearchToolsSend = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  flex-wrap: wrap;
`;

const ResultsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ResultItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const ResultItemMGPath = styled.div`
  flex: 1;
  display: flex;
  padding-left: 30px;
  padding-bottom: 5px;
`;
const ResultItemBody = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`;
const ResultItemSelect = styled.div`
  flex: 0 0 30px;
  display: flex;
  max-width: 30px;
`;
const ResultItemHeroshot = styled.div`
  flex: 0 0 130px;
  margin-top: 3px;
  display: flex;
  max-width: 130px;
  img {
    width: 100%;
  }
`;
const ResultItemCitation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
  a {
    text-decoration: none;
  }
`;

export {
  SearchResultsPageRenderer,
  EmptyResultsWrapper,
  SearchSummary,
  SearchSort,
  SearchToolsSelect,
  SearchToolsSend,
  ResultsWrapper,
  ResultItem,
  ResultItemMGPath,
  ResultItemBody,
  ResultItemSelect,
  ResultItemHeroshot,
  ResultItemCitation,
};
