import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@Components/controls/Typography';
import Button from '@Components/controls/Button';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import { Visible } from 'react-grid-system';
import {
    Autocomplete,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {ReCAPTCHA} from "react-google-recaptcha";
import {PrintRegistrationPageRenderer} from "@Components/Pages/RegisterPrintSubscriber/Renderers";
import LabelNew from '@Images/label_new.png';
import RenewalNew from '@Images/renewal_new.png';
import Reaptcha from "reaptcha";
import {PrintSubscription} from "@Api/user";
import {notification} from "antd";

const PrintRegistrationPage = () => {
    function useInput({ type, placeholder }) {
        const [value, setValue] = useState('');
        const input = (
            <input placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} type={type} />
        );
        return [value, input];
    }

    useEffect(() => {
        document.title = "Booklist Online: Leading Book Discovery";
    }, []);

    const [values, setValues] = React.useState({
        accountNumber: '',
        firstName: '',
        lastName: '',
        profession: '',
        institution: '',
        institutionName: '',
        job: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        username: '',
        password: '',
        passwordRepeat: '',
        recap:false,
        showPassword: false,
        allfilled: false
    });

    const [api, contextHolder] = notification.useNotification();
    const notifySuccess = (placement, message) => {
        api.success({
            message: message,
            placement,
        });
    };
    const notifyFail = (placement, message) => {
        api.error({
            message: message,
            placement,
        });
    };

    const onSubscribe = useCallback(async (e) => {
        const resp = await PrintSubscription(values);
        if(resp.Success) {
            notifySuccess('top', resp.Message)
        }
        else
        {
            notifyFail('top', resp.ErrorMessage)
        }
    }, [values, notifySuccess, notifyFail]);

    const validate = function(obj) {
        return obj && obj.accountNumber && obj.firstName && obj.lastName && obj.profession && obj.institution && obj.institutionName && obj.job && obj.address1 && obj.city && obj.country && obj.state && obj.zip && obj.phone && obj.email && obj.username && obj.password && obj.passwordRepeat && (obj.passwordRepeat === obj.password) && obj.recap;
    }

    const onRecaptchaVerify = useCallback((e) => {
        setValues({
            ...values,
            recap: true,
            allfilled: validate({
                ...values,
                recap: true,
            })
        });

    }, [values]);

    const handleAccountNumberChange = useCallback((e) => {
        setValues({
            ...values,
            accountNumber: e.target.value,
            allfilled: validate({
                ...values,
                accountNumber: e.target.value,
            })
        });

    }, [values]);

    const handleFNameChange = useCallback((e) => {
        setValues({
            ...values,
            firstName: e.target.value,
            allfilled: validate({
                ...values,
                firstName: e.target.value,
            })
        });
    }, [values]);
    const handleLNameChange = useCallback((e) => {
        setValues({
            ...values,
            lastName: e.target.value,
            allfilled: validate({
                ...values,
                lastName: e.target.value,
            })
        });
    }, [values]);
    const handleProfChange = useCallback((e) => {
        setValues({
            ...values,
            profession: e.target.value,
            allfilled: validate({
                ...values,
                profession: e.target.value,
            })
        });
    }, [values]);
    const handleInstChange = useCallback((e) => {
        setValues({
            ...values,
            institution: e.target.value,
            allfilled: validate({
                ...values,
                institution: e.target.value,
            })
        });
    }, [values]);
    const handleInstNameChange = useCallback((e) => {
        setValues({
            ...values,
            institutionName: e.target.value,
            allfilled: validate({
                ...values,
                institutionName: e.target.value,
            })
        });
    }, [values]);
    const handleJobChange = useCallback((e) => {
        setValues({
            ...values,
            job: e.target.value,
            allfilled: validate({
                ...values,
                job: e.target.value,
            })
        });
    }, [values]);
    const handleAddr1Change = useCallback((e) => {
        setValues({
            ...values,
            address1: e.target.value,
            allfilled: validate({
                ...values,
                address1: e.target.value,
            })
        });
    }, [values]);
    const handleAddr2Change = useCallback((e) => {
        setValues({
            ...values,
            address2: e.target.value,
            allfilled: validate({
                ...values,
                address2: e.target.value,
            })
        });
    }, [values]);
    const handleCityChange = useCallback((e) => {
        setValues({
            ...values,
            city: e.target.value,
            allfilled: validate({
                ...values,
                city: e.target.value,
            })
        });
    }, [values]);
    const handleStateChange = useCallback((e) => {
        setValues({
            ...values,
            state: e.target.value,
            allfilled: validate({
                ...values,
                state: e.target.value,
            })
        });
    }, [values]);
    const handleZipChange = useCallback((e) => {
        setValues({
            ...values,
            zip: e.target.value,
            allfilled: validate({
                ...values,
                zip: e.target.value,
            })
        });
    }, [values]);
    const handlePhoneChange = useCallback((e) => {
        setValues({
            ...values,
            phone: e.target.value,
            allfilled: validate({
                ...values,
                phone: e.target.value,
            })
        });
    }, [values]);
    const handleEmailChange = useCallback((e) => {
        setValues({
            ...values,
            email: e.target.value,
            allfilled: validate({
                ...values,
                email: e.target.value,
            })
        });
    }, [values]);
    const handleUnameChange = useCallback((e) => {
        setValues({
            ...values,
            username: e.target.value,
            allfilled: validate({
                ...values,
                username: e.target.value,
            })
        });
    }, [values]);
    const handlePasswChange = useCallback((e) => {
        setValues({
            ...values,
            password: e.target.value,
            allfilled: validate({
                ...values,
                password: e.target.value,
            })
        });
    }, [values]);
    const handlePasswRepChange = useCallback((e) => {
        setValues({
            ...values,
            passwordRepeat: e.target.value,
            allfilled: validate({
                ...values,
                passwordRepeat: e.target.value,
            })
        });
    }, [values]);

    const handleClickShowPassword = useCallback((e) => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
            allfilled: validate({
                ...values,
                accountNumber: e.target.value,
            })
        });
    }, [values]);

    const handleCountryChange = useCallback((e) => {
        setValues({
            ...values,
            country: e.target.value,
            allfilled: validate({
                ...values,
                country: e.target.value,
            })
        });
    }, [values]);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function onCaptchaChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <ResponsiveLayout>
            {contextHolder}
            <PrintRegistrationPageRenderer>
                <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                    Subscribers—connect your print subscription to Booklist Online and get access to a host of online content, including the digital version of the magazine.
                </Typography>
                <Typography font="SM" fontStyle="italic">
                    One registration per subscription. Problems registering? Send us an email at <a href="info@booklistonline.com">info@booklistonline.com</a>
                </Typography>
                <Typography font="SM" fontWeight="600">
                    First, enter your account number (found on your Booklist label or by clicking <a href="https://bls.pcdfusion.com/pcd/CustomerSupport/App/10283">here</a>), e-mail address, and preferred user name and password:
                </Typography>
                <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
                    <TextField onChange={handleAccountNumberChange} id="fAccNum" label="Account #" variant="standard" required sx={{ paddingBottom: 1 }} />
                    <img width={300} src={LabelNew} />
                    <img width={300} src={RenewalNew} />
                </FormControl>

                <TextField id="fName" onChange={handleFNameChange} label="First name" variant="standard" required sx={{ paddingBottom: 1 }} />
                <TextField id="lName" onChange={handleLNameChange} label="Last name" variant="standard" required sx={{ paddingBottom: 1 }} />
                <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
                    <InputLabel id="pProfessionL">Your profession</InputLabel>
                    <Select onChange={handleProfChange} labelId="pProfessionL" id="pProfession" label="Your profession">
                        <MenuItem value={10}>Academic Librarian</MenuItem>
                        <MenuItem value={20}>Public Librarian</MenuItem>
                        <MenuItem value={30}>School Librarian</MenuItem>
                        <MenuItem value={40}>Publisher</MenuItem>
                        <MenuItem value={40}>Individual Reader</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="fJob" onChange={handleJobChange} label="Job title" variant="standard" required sx={{ paddingBottom: 1 }} />
                <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
                    <InputLabel id="pInstitutionL">Your profession</InputLabel>
                    <Select onChange={handleInstChange} labelId="pInstitutionL" id="pInstitution" label="Institution">
                        <MenuItem value={1}>Other</MenuItem>
                        <MenuItem value={2}>Elementary School</MenuItem>
                        <MenuItem value={3}>Middle School</MenuItem>
                        <MenuItem value={4}>High School</MenuItem>
                        <MenuItem value={5}>Combined Middle and High School</MenuItem>
                        <MenuItem value={6}>School District</MenuItem>
                        <MenuItem value={7}>Public Library</MenuItem>
                        <MenuItem value={8}>Library System</MenuItem>
                        <MenuItem value={9}>Statewide Department</MenuItem>
                        <MenuItem value={10}>Community College</MenuItem>
                        <MenuItem value={11}>College/University</MenuItem>
                        <MenuItem value={12}>Business</MenuItem>
                        <MenuItem value={13}>Nonprofit</MenuItem>
                        <MenuItem value={14}>Individual</MenuItem>
                        <MenuItem value={15}>Combined Middle and High School</MenuItem>
                        <MenuItem value={16}>Middle School</MenuItem>
                        <MenuItem value={17}>Combined K-12 School</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="fInstitution" onChange={handleInstNameChange} label="Institution name" variant="standard" required sx={{ paddingBottom: 1 }} />
                <TextField id="fAddress1" onChange={handleAddr1Change} label="Address 1" variant="standard" required sx={{ paddingBottom: 1 }} />
                <TextField id="fAddress2" onChange={handleAddr2Change} label="Address 2" variant="standard" required sx={{ paddingBottom: 1 }} />
                <TextField id="fCity" onChange={handleCityChange} label="City" variant="standard" required sx={{ paddingBottom: 1 }} />
                <FormControl variant="standard" sx={{ paddingBottom: 1 }} required>
                    <InputLabel id="pCountryL">Country</InputLabel>
                    <Select onChange={handleCountryChange} labelId="pCountryL" onChange={handleCountryChange} id="pCountry" label="Country">
                        <MenuItem value="NONE" >NONE</MenuItem>
                        <MenuItem value="Afghanistan" >Afghanistan</MenuItem>
                        <MenuItem value="Albania" >Albania</MenuItem>
                        <MenuItem value="Algeria" >Algeria</MenuItem>
                        <MenuItem value="American Samoa" >American Samoa</MenuItem>
                        <MenuItem value="Andorra" >Andorra</MenuItem>
                        <MenuItem value="Angola" >Angola</MenuItem>
                        <MenuItem value="Anguilla" >Anguilla</MenuItem>
                        <MenuItem value="Antarctica" >Antarctica</MenuItem>
                        <MenuItem value="Antigua and Barbuda" >Antigua and Barbuda</MenuItem>
                        <MenuItem value="Argentina" >Argentina</MenuItem>
                        <MenuItem value="Armenia" >Armenia</MenuItem>
                        <MenuItem value="Aruba" >Aruba</MenuItem>
                        <MenuItem value="Austria" >Austria</MenuItem>
                        <MenuItem value="Azerbaijan" >Azerbaijan</MenuItem>
                        <MenuItem value="Bahamas" >Bahamas</MenuItem>
                        <MenuItem value="Bahrain" >Bahrain</MenuItem>
                        <MenuItem value="Bangladesh" >Bangladesh</MenuItem>
                        <MenuItem value="Barbados" >Barbados</MenuItem>
                        <MenuItem value="Belarus" >Belarus</MenuItem>
                        <MenuItem value="Belgium" >Belgium</MenuItem>
                        <MenuItem value="Belize" >Belize</MenuItem>
                        <MenuItem value="Benin" >Benin</MenuItem>
                        <MenuItem value="Bermuda" >Bermuda</MenuItem>
                        <MenuItem value="Bhutan" >Bhutan</MenuItem>
                        <MenuItem value="Bolivia" >Bolivia</MenuItem>
                        <MenuItem value="Bosnia and Herzegovina" >Bosnia and Herzegovina</MenuItem>
                        <MenuItem value="Botswana" >Botswana</MenuItem>
                        <MenuItem value="Bouvet Island" >Bouvet Island</MenuItem>
                        <MenuItem value="Brazil" >Brazil</MenuItem>
                        <MenuItem value="British Indian Ocean Territory" >British Indian Ocean Territory</MenuItem>
                        <MenuItem value="Brunei Darussalam" >Brunei Darussalam</MenuItem>
                        <MenuItem value="Bulgaria" >Bulgaria</MenuItem>
                        <MenuItem value="Burkina Faso" >Burkina Faso</MenuItem>
                        <MenuItem value="Burundi" >Burundi</MenuItem>
                        <MenuItem value="Cambodia" >Cambodia</MenuItem>
                        <MenuItem value="Cameroon" >Cameroon</MenuItem>
                        <MenuItem value="Canada" >Canada</MenuItem>
                        <MenuItem value="Cape Verde" >Cape Verde</MenuItem>
                        <MenuItem value="Cayman Islands" >Cayman Islands</MenuItem>
                        <MenuItem value="Central African Republic" >Central African Republic</MenuItem>
                        <MenuItem value="Chad" >Chad</MenuItem>
                        <MenuItem value="Chile" >Chile</MenuItem>
                        <MenuItem value="China" >China</MenuItem>
                        <MenuItem value="Christmas Island" >Christmas Island</MenuItem>
                        <MenuItem value="Cocos (Keeling) Islands" >Cocos (Keeling) Islands</MenuItem>
                        <MenuItem value="Colombia" >Colombia</MenuItem>
                        <MenuItem value="Comoros" >Comoros</MenuItem>
                        <MenuItem value="Congo" >Congo</MenuItem>
                        <MenuItem value="Congo, The Democratic Republic of The" >Congo, The Democratic Republic of The</MenuItem>
                        <MenuItem value="Cook Islands" >Cook Islands</MenuItem>
                        <MenuItem value="Costa Rica" >Costa Rica</MenuItem>
                        <MenuItem value="Cote D'ivoire" >Cote D'ivoire</MenuItem>
                        <MenuItem value="Croatia" >Croatia</MenuItem>
                        <MenuItem value="Cuba" >Cuba</MenuItem>
                        <MenuItem value="Cyprus" >Cyprus</MenuItem>
                        <MenuItem value="Czech Republic" >Czech Republic</MenuItem>
                        <MenuItem value="Denmark" >Denmark</MenuItem>
                        <MenuItem value="Djibouti" >Djibouti</MenuItem>
                        <MenuItem value="Dominica" >Dominica</MenuItem>
                        <MenuItem value="Dominican Republic" >Dominican Republic</MenuItem>
                        <MenuItem value="Ecuador" >Ecuador</MenuItem>
                        <MenuItem value="Egypt" >Egypt</MenuItem>
                        <MenuItem value="El Salvador" >El Salvador</MenuItem>
                        <MenuItem value="Equatorial Guinea" >Equatorial Guinea</MenuItem>
                        <MenuItem value="Eritrea" >Eritrea</MenuItem>
                        <MenuItem value="Estonia" >Estonia</MenuItem>
                        <MenuItem value="Ethiopia" >Ethiopia</MenuItem>
                        <MenuItem value="Falkland Islands (Malvinas)" >Falkland Islands (Malvinas)</MenuItem>
                        <MenuItem value="Faroe Islands" >Faroe Islands</MenuItem>
                        <MenuItem value="Fiji" >Fiji</MenuItem>
                        <MenuItem value="Finland" >Finland</MenuItem>
                        <MenuItem value="France" >France</MenuItem>
                        <MenuItem value="French Guiana" >French Guiana</MenuItem>
                        <MenuItem value="French Polynesia" >French Polynesia</MenuItem>
                        <MenuItem value="French Southern Territories" >French Southern Territories</MenuItem>
                        <MenuItem value="Gabon" >Gabon</MenuItem>
                        <MenuItem value="Gambia" >Gambia</MenuItem>
                        <MenuItem value="Georgia" >Georgia</MenuItem>
                        <MenuItem value="Germany" >Germany</MenuItem>
                        <MenuItem value="Ghana" >Ghana</MenuItem>
                        <MenuItem value="Gibraltar" >Gibraltar</MenuItem>
                        <MenuItem value="Greece" >Greece</MenuItem>
                        <MenuItem value="Greenland" >Greenland</MenuItem>
                        <MenuItem value="Grenada" >Grenada</MenuItem>
                        <MenuItem value="Guadeloupe" >Guadeloupe</MenuItem>
                        <MenuItem value="Guam" >Guam</MenuItem>
                        <MenuItem value="Guatemala" >Guatemala</MenuItem>
                        <MenuItem value="Guinea" >Guinea</MenuItem>
                        <MenuItem value="Guinea-bissau" >Guinea-bissau</MenuItem>
                        <MenuItem value="Guyana" >Guyana</MenuItem>
                        <MenuItem value="Haiti" >Haiti</MenuItem>
                        <MenuItem value="Heard Island and Mcdonald Islands" >Heard Island and Mcdonald Islands</MenuItem>
                        <MenuItem value="Holy See (Vatican City State)" >Holy See (Vatican City State)</MenuItem>
                        <MenuItem value="Honduras" >Honduras</MenuItem>
                        <MenuItem value="Hong Kong" >Hong Kong</MenuItem>
                        <MenuItem value="Hungary" >Hungary</MenuItem>
                        <MenuItem value="Iceland" >Iceland</MenuItem>
                        <MenuItem value="India" >India</MenuItem>
                        <MenuItem value="Indonesia" >Indonesia</MenuItem>
                        <MenuItem value="Iran, Islamic Republic of" >Iran, Islamic Republic of</MenuItem>
                        <MenuItem value="Iraq" >Iraq</MenuItem>
                        <MenuItem value="Ireland" >Ireland</MenuItem>
                        <MenuItem value="Israel" >Israel</MenuItem>
                        <MenuItem value="Italy" >Italy</MenuItem>
                        <MenuItem value="Jamaica" >Jamaica</MenuItem>
                        <MenuItem value="Japan" >Japan</MenuItem>
                        <MenuItem value="Jordan" >Jordan</MenuItem>
                        <MenuItem value="Kazakhstan " >Kazakhstan </MenuItem>
                        <MenuItem value="Kenya" >Kenya</MenuItem>
                        <MenuItem value="Kiribati" >Kiribati</MenuItem>
                        <MenuItem value="Korea, Democratic People's Republic of" >Korea, Democratic People's Republic of</MenuItem>
                        <MenuItem value="Korea, Republic of" >Korea, Republic of</MenuItem>
                        <MenuItem value="Kuwait" >Kuwait</MenuItem>
                        <MenuItem value="Kyrgyzstan" >Kyrgyzstan</MenuItem>
                        <MenuItem value="Lao People's Democratic Republic" >Lao People's Democratic Republic</MenuItem>
                        <MenuItem value="Latvia" >Latvia</MenuItem>
                        <MenuItem value="Lebanon" >Lebanon</MenuItem>
                        <MenuItem value="Lesotho" >Lesotho</MenuItem>
                        <MenuItem value="Liberia" >Liberia</MenuItem>
                        <MenuItem value="Libyan Arab Jamahiriya" >Libyan Arab Jamahiriya</MenuItem>
                        <MenuItem value="Liechtenstein" >Liechtenstein</MenuItem>
                        <MenuItem value="Lithuania" >Lithuania</MenuItem>
                        <MenuItem value="Luxembourg" >Luxembourg</MenuItem>
                        <MenuItem value="Macao" >Macao</MenuItem>
                        <MenuItem value="Macedonia, The Former Yugoslav Republic of" >Macedonia, The Former Yugoslav Republic of</MenuItem>
                        <MenuItem value="Madagascar" >Madagascar</MenuItem>
                        <MenuItem value="Malawi" >Malawi</MenuItem>
                        <MenuItem value="Malaysia" >Malaysia</MenuItem>
                        <MenuItem value="Maldives" >Maldives</MenuItem>
                        <MenuItem value="Mali" >Mali</MenuItem>
                        <MenuItem value="Malta" >Malta</MenuItem>
                        <MenuItem value="Marshall Islands" >Marshall Islands</MenuItem>
                        <MenuItem value="Martinique" >Martinique</MenuItem>
                        <MenuItem value="Mauritania" >Mauritania</MenuItem>
                        <MenuItem value="Mauritius" >Mauritius</MenuItem>
                        <MenuItem value="Mayotte" >Mayotte</MenuItem>
                        <MenuItem value="Mexico" >Mexico</MenuItem>
                        <MenuItem value="Micronesia, Federated States of" >Micronesia, Federated States of</MenuItem>
                        <MenuItem value="Moldova, Republic of" >Moldova, Republic of</MenuItem>
                        <MenuItem value="Monaco" >Monaco</MenuItem>
                        <MenuItem value="Mongolia" >Mongolia</MenuItem>
                        <MenuItem value="Montserrat" >Montserrat</MenuItem>
                        <MenuItem value="Morocco" >Morocco</MenuItem>
                        <MenuItem value="Mozambique" >Mozambique</MenuItem>
                        <MenuItem value="Myanmar" >Myanmar</MenuItem>
                        <MenuItem value="Namibia" >Namibia</MenuItem>
                        <MenuItem value="Nauru" >Nauru</MenuItem>
                        <MenuItem value="Nepal" >Nepal</MenuItem>
                        <MenuItem value="Netherlands" >Netherlands</MenuItem>
                        <MenuItem value="Netherlands Antilles" >Netherlands Antilles</MenuItem>
                        <MenuItem value="New Caledonia" >New Caledonia</MenuItem>
                        <MenuItem value="New Zealand" >New Zealand</MenuItem>
                        <MenuItem value="Nicaragua" >Nicaragua</MenuItem>
                        <MenuItem value="Niger" >Niger</MenuItem>
                        <MenuItem value="Nigeria" >Nigeria</MenuItem>
                        <MenuItem value="Niue" >Niue</MenuItem>
                        <MenuItem value="Norfolk Island" >Norfolk Island</MenuItem>
                        <MenuItem value="Northern Mariana Islands" >Northern Mariana Islands</MenuItem>
                        <MenuItem value="Norway" >Norway</MenuItem>
                        <MenuItem value="Oman" >Oman</MenuItem>
                        <MenuItem value="Pakistan" >Pakistan</MenuItem>
                        <MenuItem value="Palau" >Palau</MenuItem>
                        <MenuItem value="Palestinian Territory, Occupied" >Palestinian Territory, Occupied</MenuItem>
                        <MenuItem value="Panama" >Panama</MenuItem>
                        <MenuItem value="Papua New Guinea" >Papua New Guinea</MenuItem>
                        <MenuItem value="Paraguay" >Paraguay</MenuItem>
                        <MenuItem value="Peru" >Peru</MenuItem>
                        <MenuItem value="Philippines" >Philippines</MenuItem>
                        <MenuItem value="Pitcairn" >Pitcairn</MenuItem>
                        <MenuItem value="Poland" >Poland</MenuItem>
                        <MenuItem value="Portugal" >Portugal</MenuItem>
                        <MenuItem value="Puerto Rico" >Puerto Rico</MenuItem>
                        <MenuItem value="Qatar" >Qatar</MenuItem>
                        <MenuItem value="Reunion" >Reunion</MenuItem>
                        <MenuItem value="Romania" >Romania</MenuItem>
                        <MenuItem value="Russian Federation" >Russian Federation</MenuItem>
                        <MenuItem value="Rwanda" >Rwanda</MenuItem>
                        <MenuItem value="Saint Helena" >Saint Helena</MenuItem>
                        <MenuItem value="Saint Kitts and Nevis" >Saint Kitts and Nevis</MenuItem>
                        <MenuItem value="Saint Lucia" >Saint Lucia</MenuItem>
                        <MenuItem value="Saint Pierre and Miquelon" >Saint Pierre and Miquelon</MenuItem>
                        <MenuItem value="Saint Vincent and The Grenadines" >Saint Vincent and The Grenadines</MenuItem>
                        <MenuItem value="Samoa" >Samoa</MenuItem>
                        <MenuItem value="San Marino" >San Marino</MenuItem>
                        <MenuItem value="Sao Tome and Principe" >Sao Tome and Principe</MenuItem>
                        <MenuItem value="Saudi Arabia" >Saudi Arabia</MenuItem>
                        <MenuItem value="Senegal" >Senegal</MenuItem>
                        <MenuItem value="Serbia and Montenegro" >Serbia and Montenegro</MenuItem>
                        <MenuItem value="Seychelles" >Seychelles</MenuItem>
                        <MenuItem value="Sierra Leone" >Sierra Leone</MenuItem>
                        <MenuItem value="Singapore" >Singapore</MenuItem>
                        <MenuItem value="Slovakia" >Slovakia</MenuItem>
                        <MenuItem value="Slovenia" >Slovenia</MenuItem>
                        <MenuItem value="Solomon Islands" >Solomon Islands</MenuItem>
                        <MenuItem value="Somalia" >Somalia</MenuItem>
                        <MenuItem value="South Africa" >South Africa</MenuItem>
                        <MenuItem value="South Georgia and The South Sandwich Islands" >South Georgia and The South Sandwich Islands</MenuItem>
                        <MenuItem value="Spain" >Spain</MenuItem>
                        <MenuItem value="Sri Lanka" >Sri Lanka</MenuItem>
                        <MenuItem value="Sudan" >Sudan</MenuItem>
                        <MenuItem value="Suriname " >Suriname </MenuItem>
                        <MenuItem value="Svalbard and Jan Mayen" >Svalbard and Jan Mayen</MenuItem>
                        <MenuItem value="Swaziland" >Swaziland</MenuItem>
                        <MenuItem value="Sweden" >Sweden</MenuItem>
                        <MenuItem value="Switzerland" >Switzerland</MenuItem>
                        <MenuItem value="Syrian Arab Republic" >Syrian Arab Republic</MenuItem>
                        <MenuItem value="Taiwan, Province of China" >Taiwan, Province of China</MenuItem>
                        <MenuItem value="Tajikistan" >Tajikistan</MenuItem>
                        <MenuItem value="Tanzania, United Republic of" >Tanzania, United Republic of</MenuItem>
                        <MenuItem value="Thailand" >Thailand</MenuItem>
                        <MenuItem value="Timor-leste" >Timor-leste</MenuItem>
                        <MenuItem value="Togo" >Togo</MenuItem>
                        <MenuItem value="Tokelau" >Tokelau</MenuItem>
                        <MenuItem value="Tonga" >Tonga</MenuItem>
                        <MenuItem value="Trinidad and Tobago" >Trinidad and Tobago</MenuItem>
                        <MenuItem value="Tunisia" >Tunisia</MenuItem>
                        <MenuItem value="Turkey" >Turkey</MenuItem>
                        <MenuItem value="Turkmenistan" >Turkmenistan</MenuItem>
                        <MenuItem value="Turks and Caicos Islands" >Turks and Caicos Islands</MenuItem>
                        <MenuItem value="Tuvalu" >Tuvalu</MenuItem>
                        <MenuItem value="Uganda" >Uganda</MenuItem>
                        <MenuItem value="Ukraine" >Ukraine</MenuItem>
                        <MenuItem value="United Arab Emirates" >United Arab Emirates</MenuItem>
                        <MenuItem value="United Kingdom" >United Kingdom</MenuItem>
                        <MenuItem value="United States" >United States</MenuItem>
                        <MenuItem value="United States Minor Outlying Islands" >United States Minor Outlying Islands</MenuItem>
                        <MenuItem value="Uruguay" >Uruguay</MenuItem>
                        <MenuItem value="Uzbekistan" >Uzbekistan</MenuItem>
                        <MenuItem value="Vanuatu" >Vanuatu</MenuItem>
                        <MenuItem value="Venezuela" >Venezuela</MenuItem>
                        <MenuItem value="Viet Nam" >Viet Nam</MenuItem>
                        <MenuItem value="Virgin Islands, British" >Virgin Islands, British</MenuItem>
                        <MenuItem value="Virgin Islands, U.S." >Virgin Islands, U.S.</MenuItem>
                        <MenuItem value="Wallis and Futuna" >Wallis and Futuna</MenuItem>
                        <MenuItem value="Western Sahara" >Western Sahara</MenuItem>
                        <MenuItem value="Yemen" >Yemen</MenuItem>
                        <MenuItem value="Zambia" >Zambia</MenuItem>
                        <MenuItem value="Zimbabwe" >Zimbabwe</MenuItem>
                        <MenuItem value="Australia" >Australia</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{paddingBottom: 1}}>
                    <InputLabel id="pStateL">State or Province</InputLabel>
                    <Select onChange={handleStateChange} labelId="pStateL" id="pState" label="State or Province">
                        <MenuItem value="Alabama" >Alabama</MenuItem>
                        <MenuItem value="Alaska" >Alaska</MenuItem>
                        <MenuItem value="Arizona" >Arizona</MenuItem>
                        <MenuItem value="Arkansas" >Arkansas</MenuItem>
                        <MenuItem value="California" >California</MenuItem>
                        <MenuItem value="Colorado" >Colorado</MenuItem>
                        <MenuItem value="Connecticut" >Connecticut</MenuItem>
                        <MenuItem value="Delaware" >Delaware</MenuItem>
                        <MenuItem value="District Of Columbia" >District Of Columbia</MenuItem>
                        <MenuItem value="Florida" >Florida</MenuItem>
                        <MenuItem value="Georgia" >Georgia</MenuItem>
                        <MenuItem value="Hawaii" >Hawaii</MenuItem>
                        <MenuItem value="Idaho" >Idaho</MenuItem>
                        <MenuItem value="Illinois" >Illinois</MenuItem>
                        <MenuItem value="Indiana" >Indiana</MenuItem>
                        <MenuItem value="Iowa" >Iowa</MenuItem>
                        <MenuItem value="Kansas" >Kansas</MenuItem>
                        <MenuItem value="Kentucky" >Kentucky</MenuItem>
                        <MenuItem value="Louisiana" >Louisiana</MenuItem>
                        <MenuItem value="Maine" >Maine</MenuItem>
                        <MenuItem value="Maryland" >Maryland</MenuItem>
                        <MenuItem value="Massachusetts" >Massachusetts</MenuItem>
                        <MenuItem value="Michigan" >Michigan</MenuItem>
                        <MenuItem value="Minnesota" >Minnesota</MenuItem>
                        <MenuItem value="Mississippi" >Mississippi</MenuItem>
                        <MenuItem value="Missouri" >Missouri</MenuItem>
                        <MenuItem value="Montana" >Montana</MenuItem>
                        <MenuItem value="Nebraska" >Nebraska</MenuItem>
                        <MenuItem value="Nevada" >Nevada</MenuItem>
                        <MenuItem value="New Hampshire" >New Hampshire</MenuItem>
                        <MenuItem value="New Jersey" >New Jersey</MenuItem>
                        <MenuItem value="New Mexico" >New Mexico</MenuItem>
                        <MenuItem value="New York" >New York</MenuItem>
                        <MenuItem value="North Carolina" >North Carolina</MenuItem>
                        <MenuItem value="North Dakota" >North Dakota</MenuItem>
                        <MenuItem value="Ohio" >Ohio</MenuItem>
                        <MenuItem value="Oklahoma" >Oklahoma</MenuItem>
                        <MenuItem value="Oregon" >Oregon</MenuItem>
                        <MenuItem value="Pennsylvania" >Pennsylvania</MenuItem>
                        <MenuItem value="Rhode Island" >Rhode Island</MenuItem>
                        <MenuItem value="South Carolina" >South Carolina</MenuItem>
                        <MenuItem value="South Dakota" >South Dakota</MenuItem>
                        <MenuItem value="Tennessee" >Tennessee</MenuItem>
                        <MenuItem value="Texas" >Texas</MenuItem>
                        <MenuItem value="Utah" >Utah</MenuItem>
                        <MenuItem value="Vermont" >Vermont</MenuItem>
                        <MenuItem value="Virginia" >Virginia</MenuItem>
                        <MenuItem value="Washington" >Washington</MenuItem>
                        <MenuItem value="West Virginia" >West Virginia</MenuItem>
                        <MenuItem value="Wisconsin" >Wisconsin</MenuItem>
                        <MenuItem value="Wyoming" >Wyoming</MenuItem>
                        <MenuItem value="Puerto Rico" >Puerto Rico</MenuItem>
                        <MenuItem value="Armed Forces" >Armed Forces</MenuItem>
                        <MenuItem value="Alberta" >Alberta</MenuItem>
                        <MenuItem value="British Columbia" >British Columbia</MenuItem>
                        <MenuItem value="Labrador" >Labrador</MenuItem>
                        <MenuItem value="Manitoba" >Manitoba</MenuItem>
                        <MenuItem value="New Brunswick" >New Brunswick</MenuItem>
                        <MenuItem value="Newfoundland" >Newfoundland</MenuItem>
                        <MenuItem value="Northwest Territories" >Northwest Territories</MenuItem>
                        <MenuItem value="Nova Scotia" >Nova Scotia</MenuItem>
                        <MenuItem value="Ontario" >Ontario</MenuItem>
                        <MenuItem value="Prince Edward Island" >Prince Edward Island</MenuItem>
                        <MenuItem value="Quebec" >Quebec</MenuItem>
                        <MenuItem value="Saskatchewan" >Saskatchewan</MenuItem>
                        <MenuItem value="Yukon Territory" >Yukon Territory</MenuItem>
                        <MenuItem value="Guam" >Guam</MenuItem>
                        <MenuItem value="Nunavut" >Nunavut</MenuItem>
                        <MenuItem value="n/a" >n/a</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="fZip" onChange={handleZipChange} label="Zip / Postal Code" variant="standard" required sx={{paddingBottom: 1}}/>
                <TextField id="fPhone" onChange={handlePhoneChange} label="Phone Number" variant="standard" required sx={{paddingBottom: 1}}/>
                <TextField id="fEmail" onChange={handleEmailChange} label="E-mail Address" variant="standard" required sx={{paddingBottom: 1}}/>
                { /* <FormControlLabel control={<Switch defaultChecked />} label="HTML email format" /> */}
                <TextField id="fUname" onChange={handleUnameChange} label="User name" variant="standard" required sx={{paddingBottom: 1}}/>
                <FormControl sx={{paddingBottom: 1}} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                        onChange={handlePasswChange}
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl sx={{paddingBottom: 1}} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                        onChange={handlePasswRepChange}
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Reaptcha
                    sitekey="6LflPlIUAAAAAE6lK67Blwub9KCwodIWkgGsryVt"
                    onVerify={onRecaptchaVerify}
                />
                <Button disabled={!values.allfilled} onClick={onSubscribe} style={{marginTop: 25}}>Create Profile</Button>
            </PrintRegistrationPageRenderer>
        </ResponsiveLayout>
    );
};

export default PrintRegistrationPage;
