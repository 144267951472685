import React, {useEffect, useRef, useState} from 'react';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import { Visible } from 'react-grid-system';
import {
    ADContainerRenderer,
    FullPageContainerRenderer,
    MiddleADAreaRenderer,
    SiteInfoPageContainerRenderer,
} from '@Components/Pages/SiteInfoPage/Renderers';

import MiddleAD1 from '@Images/Samples/MiddleAD1.png';
import {EmptyPageWrapper, NotFoundPageWrapper} from '@Components/Pages/ProductInfo/Renderers';
import Typography from '@Components/controls/Typography';
import {Link} from "react-router-dom";


const NotFound = () => {
    useEffect(() => {
        document.title = "Booklist Online: Leading Book Discovery";
    }, []);

    return (
        <ResponsiveLayout>
            <FullPageContainerRenderer>
                <Visible lg xl xxl>
                    <ADContainerRenderer></ADContainerRenderer>
                </Visible>
                <SiteInfoPageContainerRenderer>
                    <Visible xs sm md><MiddleADAreaRenderer>
                            <img src={MiddleAD1} />
                        </MiddleADAreaRenderer>
                    </Visible>
                    <NotFoundPageWrapper>
                        <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                            We're sorry, but we couldn't find the page you were looking for.
                        </Typography>
                        <br/>
                        <div style={{maxWidth: '600px'}}>
                            <h1>What happened?</h1>
                            <ul>
                                <li><span  style={{fontSize: '13px'}} >If you clicked on a link to get here, the link may be outdated or could contain errors.</span></li>
                                <li><span  style={{fontSize: '13px'}} >If you typed the URL directly into the address bar, you might have mis-keyed something.</span></li>
                                <li><span  style={{fontSize: '13px'}}>If you’re looking for a review or feature article, search our site using the Quick Search box at the top left. For more search options, switch to our Advanced Search.</span></li>
                                <li><span  style={{fontSize: '13px'}}>If you’re looking for information about <i>Booklist, Booklist Online</i>, or our staff or procedures, please browse the navigation bar at the top or bottom of this page and follow the links there.</span></li>
                            </ul>
                            <h1>Do you still need help?</h1>
                            <span style={{fontSize: '13px'}} ><Link to='/contact-us'>Contact Us!</Link>
                        </span>
                        </div>

                    </NotFoundPageWrapper>
                </SiteInfoPageContainerRenderer>
                <Visible lg xl xxl>
                    <ADContainerRenderer>
                        <broadstreet-zone zone-id="72504"></broadstreet-zone>
                        <broadstreet-zone zone-id="72505"></broadstreet-zone>
                        <broadstreet-zone zone-id="72506"></broadstreet-zone>
                    </ADContainerRenderer>
                </Visible>
            </FullPageContainerRenderer>
        </ResponsiveLayout>
    );
};

export default NotFound;
