const bolTheme = {
  colors: {
    loader: {
      stroke: '#191919',
    },
    plainLoader: {
      fillColor: '#C6CACC',
      textColor: '#C6CACC',
    },
    powderWhite: '#FFFDF9',
    persianGreen: '#06B49A',
    blue: '#0078d7',
    lightBlue: '#1c8eec',
    onyx: '#36313D',
    black: '#000000',
    grey1: '#949699',
    white: '#FFFFFF',
    darkblue: 'darkblue',
    red: 'red'
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    XS: '12px',
    SM: '14px',
    MD: '16px',
    MMD: '17px',
    LG: '18px',
    XL: '20px',
    XXL: '22px',
    XXXL: '24px',
    XXXXL: '26px',
    XXXXXL: '28px',
    XXXXXXL: '36px',
  },
};

export default bolTheme;
