import React from 'react';
import TopHeaderDesktopRenderer from '@Components/sections/TopHeader/Renderers';
import SocialMediaLinks from '@Components/sections/TopHeader/SocialMediaLinks';
import MainLogoArea from '@Components/sections/TopHeader/MainLogoArea';
import LoginSearchArea from '@Components/sections/TopHeader/LoginSearchArea';
import {Visible} from '@Components/containers/Grid';

const TopHeader = () => {
    return (
        <TopHeaderDesktopRenderer>
            <Visible xl xxl>
                <MainLogoArea/>
                <LoginSearchArea/>
            </Visible>
        </TopHeaderDesktopRenderer>
    );
};

export default TopHeader;
