import React, {useCallback} from 'react';
import {
    SocialMediaButtonRenderer,
    SocialMediaLinksRenderer
} from '@Components/sections/Footer/SocialMediaArea/Renderers';
import { ReactComponent as SMFacebookSvgComponent } from '@Images/SMFacebook.svg';
import { ReactComponent as SMInstagramSvgComponent } from '@Images/SMInstagram.svg';
import { ReactComponent as SMBlueskySvgComponent } from '@Images/SMBluesky.svg';
import { ReactComponent as SMLinkedInSvgComponent } from '@Images/SMLinkedIn.svg';
import { ReactComponent as SMYoutubeSvgComponent } from '@Images/SMYoutube.svg';
import styled from "styled-components";
import Button from "@Components/controls/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "@Utils/routing";

const FooterSubscribeButton = styled(Button)``;

const SocialMediaArea = () => {
    const navigate = useNavigate();
    const handleSubscribeClick = useCallback(() => {
        navigate(ROUTES.subscriptions);
    }, []);


  return (
      <>
          <SocialMediaLinksRenderer>
              <a href="https://www.facebook.com/ALA.Booklist">
                  <SMFacebookSvgComponent style={{ color: 'white' }} />
              </a>
              <a href="https://bsky.app/profile/ala-booklist.bsky.social">
                  <SMBlueskySvgComponent style={{ color: 'white' }} />
              </a>
              <a href="https://www.linkedin.com/company/booklistpublications">
                  <SMLinkedInSvgComponent style={{ color: 'white' }} />
              </a>
              <a href="https://www.youtube.com/user/BooklistOnline">
                  <SMYoutubeSvgComponent style={{ color: 'white' }} />
              </a>
              <a href="https://www.instagram.com/ala_booklist">
                  <SMInstagramSvgComponent style={{ color: 'white' }} />
              </a>
          </SocialMediaLinksRenderer>
          <SocialMediaButtonRenderer>
              <FooterSubscribeButton themeColor="white" onClick={handleSubscribeClick}>Subscribe</FooterSubscribeButton>
          </SocialMediaButtonRenderer>
      </>
  );
};

export default SocialMediaArea;
