import http from '@Utils/network';

const QUICK_SEARCH_ENDPOINT = `api/Eusservice/QuickSearch`;

const QUICK_SEARCH_V2_ENDPOINT = `api/Eusservice/QuickSearchN`;
const BROWSE_SEARCH_V2_ENDPOINT = `api/Eusservice/BrowseSearchN`;
const BCPI_SEARCH_V2_ENDPOINT = `api/Eusservice/BcpiSearchN`;
const ADVANCED_SEARCH_V2_ENDPOINT = `api/Eusservice/AdvancedSearchN`;
const LIST_SEARCH_V2_ENDPOINT = `api/Eusservice/ListsSearchN`;
const PREPROGRAMMED_SEARCH_V2_ENDPOINT = `api/Eusservice/PreprogrammedSearchN`;
const AUTHOR_SEARCH_V2_ENDPOINT = `api/Eusservice/AuthorSearchN`;
const ISSUE_SEARCH_V2_ENDPOINT = `api/Eusservice/IssueSearchN`;
const READER_SEARCH_V2_ENDPOINT = `api/Eusservice/ReaderSearchN`;

const quickSearch = async (searchOptions) => {
  const { page, pageSize, sortOrder, keyword, searchBy, scope, sections } = searchOptions;
  const requestParams = {
    page,
    pageSize,
    sortOrder,
    keyword,
    searchBy,
    scope,
    sections,
  };

  const { data } = await http.post(QUICK_SEARCH_ENDPOINT, requestParams);
  return data;
};

const quickSearchV2 = async (searchOptions) => {
  const { skip, take, sort, keyword, searchby, scope, sections, searchIn } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    keyword,
    searchby: String(searchby),
    searchin: String(searchIn),
    sections: String(sections),
    scope,
  };

  const { data } = await http.post(QUICK_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const browseSearchV2 = async (searchOptions) => {
  const { skip, take, sort, mgid } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    mgid
  };

  const { data } = await http.post(BROWSE_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const bcpiSearchV2 = async (searchOptions) => {
  const { skip, take, sort, mgid, mgtype, issue } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    mgid,
    mgtype,
    issue
  };

  const { data } = await http.post(BCPI_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const issueSearchV2 = async (searchOptions) => {
  const { skip, take, sort, pid } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    pid
  };

  const { data } = await http.post(ISSUE_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const advancedSearchV2 = async (searchOptions) => {
  const {
    skip,
    take,
    sort
  } = searchOptions;
  const requestParams = {
    PageNumber: skip,
    ItemsPerPage: take,
    SortOrder: sort,
    ...searchOptions
  };

  const { data } = await http.post(ADVANCED_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const listSearchV2 = async (searchOptions) => {
  const { skip, take, sort, listId } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    listId
  };

  const { data } = await http.post(LIST_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const authorSearchV2 = async (searchOptions) => {
  const { skip, take, sort, author } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    author
  };

  const { data } = await http.post(AUTHOR_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const preprogrammedSearchV2 = async (searchOptions) => {
  const { skip, take, sort, mgid } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    mgid
  };

  const { data } = await http.post(PREPROGRAMMED_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

const readerSearchV2 = async (searchOptions) => {
  const { skip, take, sort, mgid } = searchOptions;
  const requestParams = {
    skip,
    take,
    sort,
    mgid
  };

  const { data } = await http.post(READER_SEARCH_V2_ENDPOINT, requestParams);
  return data;
};

export { quickSearch, quickSearchV2, browseSearchV2, advancedSearchV2, listSearchV2, preprogrammedSearchV2, authorSearchV2, bcpiSearchV2, issueSearchV2, readerSearchV2 };
