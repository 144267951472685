import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentProductInfo: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCurrentProductInfo: (state, action) => {
      const { payload } = action;
      state.currentProductInfo = payload.currentProductInfo;
    },
    clearData: () => initialState,
  },
});

const productSelectors = {
  getCurrentProductInfo: (state) => state.currentProductInfo,
};
const productActions = productSlice.actions;

export default productSlice;
export { productSelectors, productActions };
