import React from 'react';
import images from '@Images';
import Button from '@Components/controls/Button';
import LinkButton from '@Components/controls/LinkButton';
import { ToolBarRenderer, SocialMediaLinksRenderer } from '@Components/sections/SideMenu/ToolBar/Renderers';
import SMBluesky from "@Images/SMBluesky.svg";

const ToolBar = () => {
  return (
    <>
      <ToolBarRenderer>
        <Button style={{ marginBottom: 15 }}>Subscribe</Button>
        <Button>
          <img width={21} src={images.Login} alt="Login" />
          Log In
        </Button>
        <SocialMediaLinksRenderer>
          <a href="https://www.facebook.com/ALA.Booklist">
            <img width={21} style={{ marginTop: 5 }} src={images.SMFacebook} alt="logo" />
          </a>
          <a href="https://bsky.app/profile/ala-booklist.bsky.social">
            <img width={21} style={{ marginTop: 5 }} src={images.SMBluesky} alt="logo" />
          </a>
          <a href="https://www.youtube.com/user/BooklistOnline">
            <img width={21} style={{ marginTop: 5 }} src={images.SMYoutube} alt="logo" />
          </a>
          <a href="https://www.instagram.com/ala_booklist">
            <img width={21} style={{ marginTop: 3 }} src={images.SMInstagram} alt="logo" />
          </a>
          <a href="https://www.linkedin.com/company/booklistpublications">
            <img width={21} style={{ marginTop: 3 }} src={images.SMLinkedIn} alt="logo" />
          </a>
        </SocialMediaLinksRenderer>
      </ToolBarRenderer>
    </>
  );
};

export default ToolBar;
