import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { siteInfoInterface } from '@Store';
import { getSiteInfo } from '@Api/siteInfo';

export const useHandleLoadSiteInfo = (siteInfoId) => {
  const currentSiteInfo = useSelector(siteInfoInterface.getCurrentSiteInfo);
  const [isLoading, setDataLoading] = useState(false);

  const loadData = useCallback(
    async ({ siteInfoId }) => {
      setDataLoading(true);
      const retData = await getSiteInfo({
        siteInfoId,
      });
      const { siteInfo, accordionData } = retData;
      siteInfoInterface.dispatch(siteInfoInterface.setCurrentSiteInfo({ currentSiteInfo: siteInfo }));
      siteInfoInterface.dispatch(siteInfoInterface.setCurrentAccordionData({ currentAccordionData: accordionData }));
      setDataLoading(false);
    },
    [siteInfoId]
  );

  useEffect(() => {
    /** Here we clear store, otherwise we append new data to already loaded data */
    /** This also sets loadedCount to 0 and fires loadData effect */
    siteInfoInterface.dispatch(siteInfoInterface.clearData());
  }, [siteInfoId]);

  useEffect(() => {
    if (!currentSiteInfo) loadData({ siteInfoId });
  }, [currentSiteInfo, loadData, siteInfoId]);

  return [loadData, isLoading];
};
