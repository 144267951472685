import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { curIssuesInterface } from '@Store';
import { getCurIssues } from '@Api/curIssues';

export const useHandleLoadCurrentIssues = (clearSelf) => {
    const currentCurIssues = useSelector(curIssuesInterface.getCurrentCurIssues);
    const [isLoading, setDataLoading] = useState(false);

    const loadData = useCallback(async () => {
        setDataLoading(true);
        const {data} = await getCurIssues();
        curIssuesInterface.dispatch(curIssuesInterface.setCurrentCurIssues(data));
        setDataLoading(false);
    }, []);

    useEffect(() => {
        /** Here we clear store, otherwise we append new data to already loaded data */
        /** This also sets loadedCount to 0 and fires loadData effect */
        if(clearSelf) curIssuesInterface.dispatch(curIssuesInterface.clearData());
    }, [clearSelf]);

    useEffect(() => {
        if (!currentCurIssues) loadData();
    }, [currentCurIssues, loadData]);

    return [loadData, isLoading];
};