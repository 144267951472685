import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import {searchResultsInterface, siteInfoInterface} from '@Store';
import { useHandleLoadSiteInfo } from '@Api/hooks/siteInfo';
import {useNavigate, useParams} from 'react-router-dom';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import { Visible } from 'react-grid-system';
import {
  ADContainerRenderer,
  FullPageContainerRenderer,
  MiddleADAreaRenderer,
  SiteInfoPageContainerRenderer,
  SiteInfoPageRenderer,
} from '@Components/Pages/SiteInfoPage/Renderers';
import ProductInfoPage from '@Components/Pages/ProductInfo';
import SideAD1 from '@Images/Samples/SideAD1.png';
import SideAD2 from '@Images/Samples/SideAD2.png';
import MiddleAD1 from '@Images/Samples/MiddleAD1.png';
import { EmptyPageWrapper } from '@Components/Pages/ProductInfo/Renderers';
import Typography from '@Components/controls/Typography';
import Loader from '@Components/controls/Loader';
import DOMPurify from 'dompurify';
import {ROUTES} from "@Utils/routing";
import {useLocalStorage} from "@Utils/localStorage";
import IssueAccordion from "@Components/Pages/SiteInfoPage/IssueAccordion";
import ReactDOM from "react-dom";
import { useSearchParams } from 'react-router-dom';


const purifyConfig = {
  FORBID_TAGS: ['{{', '&nbsp;'],
  SAFE_FOR_TEMPLATES: true,
  RETURN_TRUSTED_TYPE: true,
  ALLOW_DATA_ATTR: false,
  KEEP_CONTENT: true,
};

const SiteInfoPage = ({ siteInfoId }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [lsUserId, setLSUserId] = useLocalStorage("LSUID", '')
  const [lsAuthModeId, setLSAuthModeId] = useLocalStorage("LSAMDID", '')
  const [lsAccountId, setLSAccountId] = useLocalStorage("LSAID", '')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if(siteInfoId === 102) document.title = "Booklist Online Exclusive Reviews";
    else if(siteInfoId === 83) document.title = "Booklist Online: Leading Book Discovery";
    else document.title = "Booklist Online: Leading Book Discovery";
  }, []);

  useEffect(() => {
    if(siteInfoId === 86 && !lsUserId) {
      navigate(ROUTES.issuesLogin);
    }
  }, [lsUserId, siteInfoId, navigate]);

  useEffect(() => {
    if(siteInfoId === 83 && !lsUserId) {
      navigate(ROUTES.root);
    }
  }, [lsUserId, siteInfoId, navigate]);

  useEffect(() => {
    if(siteInfoId === 108 && searchParams.get('read')) {
      if(!lsAuthModeId) {
        setLSAuthModeId('5');
      }
    }

  }, [lsAuthModeId, setLSAuthModeId, searchParams, siteInfoId]);

  useEffect(() => {
    if(siteInfoId === 108 && !lsAccountId && !searchParams.get('read') && !(lsAuthModeId === '5')) {
      navigate(ROUTES.readerIssuesLogin);
    }
  }, [lsAuthModeId, searchParams, siteInfoId, lsAccountId, navigate]);

  const [loadData, isLoading] = useHandleLoadSiteInfo(siteInfoId || +searchParams.get('id'));
  const currentSiteInfo = useSelector(siteInfoInterface.getCurrentSiteInfo);
  const currentAccordionData = useSelector(siteInfoInterface.getCurrentAccordionData);
  const accordReference = useRef(null);

  useEffect(() => {
    const element = accordReference && accordReference.current;

    if(element) {
      const content = document.getElementById("issueaccordion");
      if(content) {
        ReactDOM.render(<IssueAccordion accData={currentAccordionData} />, content);
      }
    }
  }, [accordReference, currentSiteInfo, currentAccordionData, isLoading]);

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');

    if(targetLink && targetLink.pathname.startsWith('/IssueBrowseContents')) {
      e.preventDefault();
      const searchParams = targetLink.pathname.split("$");
      navigate('/products/'+ searchParams[1]);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/IssueBrowse')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { mgid: searchParams[1], mgtype: 0, issue: searchParams[2]}, searchType: 7, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/readerCategory')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { mgid: searchParams[1]}, searchType: 9, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/IssueBrowseSpec')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { mgid: searchParams[1], mgtype: 1, issue: searchParams[2]}, searchType: 7, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/IssueBrowseStar')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { mgid: searchParams[1], mgtype: 2, issue: searchParams[2]}, searchType: 7, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/IssueBrowseHot')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { mgid: searchParams[1], mgtype: 3, issue: searchParams[2]}, searchType: 7, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.pathname.startsWith('/PreProgrammedSearch')) {
      e.preventDefault();

      const searchParams = targetLink.pathname.split("$");
      searchResultsInterface.dispatch(searchResultsInterface.clearSearchResults());
      searchResultsInterface.dispatch(
          searchResultsInterface.setSearchOptions({ searchOptions: { preprogram: searchParams[1] }, searchType: 5, totalCount: null })
      );
      navigate(ROUTES.searchResutsLazy);
      return;
    }

    if(targetLink && targetLink.href.indexOf('/products/') === -1 && targetLink.href.indexOf('pid=') > -1) {
      e.preventDefault();
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    }

    if(targetLink && targetLink.host === 'www.booklistonline.com') {
      e.preventDefault();
      navigate(targetLink.pathname + targetLink.search);
    }

    if(!targetLink || targetLink.host !== window.location.host) return;
    e.preventDefault();

    if(targetLink.href.indexOf('pid=') > -1) {
      navigate('/products/'+ targetLink.href.substring(targetLink.href.indexOf('pid=') + 4));
    } else {
      navigate(targetLink.href.replace(/^(?:\/\/|[^/]+)*\//, '/'));
    }
  };

  return (
    <ResponsiveLayout>
      <FullPageContainerRenderer>
        <Visible lg xl xxl>
          <ADContainerRenderer></ADContainerRenderer>
        </Visible>
        <SiteInfoPageContainerRenderer>
          <Visible xs sm md>
            <MiddleADAreaRenderer>
              <img src={MiddleAD1} />
            </MiddleADAreaRenderer>
          </Visible>
          {!isLoading && !currentSiteInfo && (
            <EmptyPageWrapper>
              <Typography font="XXXXL" fontWeight="600" fontFamily="freight-sans-pro" fontStyle="italic">
                Page Not Found
              </Typography>
            </EmptyPageWrapper>
          )}
          {isLoading && <Loader centered />}
          {!isLoading && currentSiteInfo && (
            <SiteInfoPageRenderer>
              <div ref={accordReference}
                   onClick={contentClickHandler}
                   dangerouslySetInnerHTML={{
                     __html: currentSiteInfo,
                   }}
              />
            </SiteInfoPageRenderer>
          )}
        </SiteInfoPageContainerRenderer>
        <Visible lg xl xxl>
          <ADContainerRenderer>
            <broadstreet-zone zone-id="72504"></broadstreet-zone>
            <broadstreet-zone zone-id="72505"></broadstreet-zone>
            <broadstreet-zone zone-id="72506"></broadstreet-zone>
          </ADContainerRenderer>
        </Visible>
      </FullPageContainerRenderer>
    </ResponsiveLayout>
  );
};

export default SiteInfoPage;
