import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import 'antd/dist/antd.css';
import {announcementInterface, appInterface, topFeaturesInterface} from "@Store";
import {Button, Checkbox, Divider, Input, message} from "antd";
import {putAnnouncement} from "@Api/announcement";
import {useSelector} from "react-redux";
import {useHandleLoadAnnouncement} from "@Api/hooks/announcement";

const AnnouncementEditor = () => {
    const [loadData, isLoading] = useHandleLoadAnnouncement(false);
    const dataSource = useSelector(announcementInterface.getCurrentAnnouncement);

    const [announcementVisible, setAnnouncementVisible] = useState(true);
    const [announcementHeader, setAnnouncementHeader] = useState('');
    const [announcementColumnWidth, setAnnouncementColumnWidth] = useState('');

    const [reviewVisible, setReviewVisible] = useState(true);
    const [reviewPID, setReviewPID] = useState('');
    const [reviewHeader, setReviewHeader] = useState('');

    const [feature1Visible, setFeature1Visible] = useState(true);
    const [feature1PID, setFeature1PID] = useState('');
    const [feature1Header, setFeature1Header] = useState('');

    const [feature2Visible, setFeature2Visible] = useState(true);
    const [feature2PID, setFeature2PID] = useState('');
    const [feature2Header, setFeature2Header] = useState('');

    useEffect(() => {
        appInterface.dispatch(appInterface.activeItem({ openItem: ['adm_announcement'] }));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(dataSource && dataSource.Announcement) {
            setAnnouncementVisible(dataSource.Announcement.ItemStatus);
            setAnnouncementHeader(dataSource.Announcement.Header);
            setAnnouncementColumnWidth(dataSource.Announcement.ColumnWidth)
        }
        if(dataSource && dataSource.ReviewOfTheDay) {
            setReviewVisible(dataSource.ReviewOfTheDay.ItemStatus);
            setReviewHeader(dataSource.ReviewOfTheDay.Header);
        }
        if(dataSource && dataSource.ReviewOfTheDay) {
            setReviewPID(dataSource.ReviewOfTheDay.ItemPID);
        }

        if(dataSource && dataSource.Feature1) {
            setFeature1Visible(dataSource.Feature1.ItemStatus);
            setFeature1Header(dataSource.Feature1.Header);
        }
        if(dataSource && dataSource.Feature1) {
            setFeature1PID(dataSource.Feature1.ItemPID);
        }

        if(dataSource && dataSource.Feature2) {
            setFeature2Visible(dataSource.Feature2.ItemStatus);
            setFeature2Header(dataSource.Feature2.Header);
        }
        if(dataSource && dataSource.Feature2) {
            setFeature2PID(dataSource.Feature2.ItemPID);
        }
    }, [dataSource]);

    const editorRef = useRef(null);
    const editorRevRef = useRef(null);
    const editorFeat1Ref = useRef(null);
    const editorFeat2Ref = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const handleSave = useCallback( async () => {
        if (editorRef.current) {
            await putAnnouncement({...dataSource?.Announcement, ItemStatus:announcementVisible, ItemBody: editorRef.current.getContent(), Header: announcementHeader, ColumnWidth: announcementColumnWidth});
            await loadData();
            message.open({
                type: 'success',
                content: 'Entity has been saved successfully!',
                style: {
                    marginTop: '65px',
                },
            });
        }
    }, [loadData, putAnnouncement, editorRef, dataSource, announcementVisible, announcementHeader, announcementColumnWidth]);

    const handleSaveROD = useCallback( async () => {
        if (editorRevRef.current) {
            await putAnnouncement({...dataSource?.ReviewOfTheDay, ItemPID: reviewPID ? +reviewPID : null, ItemStatus:reviewVisible, Header:reviewHeader, ItemBody: editorRevRef.current.getContent()});
            await loadData();
            message.open({
                type: 'success',
                content: 'Entity has been saved successfully!',
                style: {
                    marginTop: '65px',
                },
            });
        }
    }, [loadData, putAnnouncement, editorRevRef, dataSource, reviewVisible, reviewPID, reviewHeader]);

    const handleSaveFeature1 = useCallback( async () => {
        if (editorFeat1Ref.current) {
            await putAnnouncement({...dataSource?.Feature1, ItemPID: feature1PID ? +feature1PID : null, ItemStatus:feature1Visible, Header: feature1Header, ItemBody: editorFeat1Ref.current.getContent()});
            await loadData();
            message.open({
                type: 'success',
                content: 'Entity has been saved successfully!',
                style: {
                    marginTop: '65px',
                },
            });
        }
    }, [loadData, putAnnouncement, editorFeat1Ref, dataSource, feature1Visible, feature1PID, feature1Header]);

    const handleSaveFeature2 = useCallback( async () => {
        if (editorFeat2Ref.current) {
            await putAnnouncement({...dataSource?.Feature2, ItemPID: feature2PID ? +feature2PID : null, ItemStatus:feature2Visible, Header: feature2Header, ItemBody: editorFeat2Ref.current.getContent()});
            await loadData();
            message.open({
                type: 'success',
                content: 'Entity has been saved successfully!',
                style: {
                    marginTop: '65px',
                },
            });
        }
    }, [loadData, putAnnouncement, editorFeat2Ref, dataSource, feature2Visible, feature2PID, feature2Header]);


    return (
        <div>
            <Divider orientation="left">Announcement</Divider>
            <Divider orientation="left" orientationMargin="0" plain>Announcement Header</Divider>
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Input placeholder="Header text" value={announcementHeader} onChange={(e)=> {setAnnouncementHeader(e.target.value); }} onBlur={(e)=> {setAnnouncementHeader(e.target.value); }} />
            </p>
            <Divider orientation="left" orientationMargin="0" plain>Announcement Column Width (%)</Divider>
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Input placeholder="Header text" value={announcementColumnWidth} onChange={(e)=> {setAnnouncementColumnWidth(e.target.value); }} onBlur={(e)=> {setAnnouncementColumnWidth(e.target.value); }} />
            </p>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                    invalid_elements: "",
                    valid_elements: '*[*]',
                    height: 500,
                    menubar: false,
                    plugins: 'image autolink lists advlist media table code link preview searchreplace wordcount',
                    toolbar1: 'code | fullscreen preview print wordcount | searchreplace | undo redo | bold italic underline strikethrough | link anchor | formatselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons',
                    toolbar2: 'insertfile image media codesample | ltr rtl | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    image_advtab: true
                }}
                initialValue={dataSource?.Announcement.ItemBody}
            />
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Checkbox checked={announcementVisible} onChange={(e)=> {setAnnouncementVisible(e.target.checked); }} >
                    Announcement visible
                </Checkbox>
            </p>
            <p>
                <Button
                    onClick={handleSave}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Save Announcement
                </Button>
            </p>

            <Divider orientation="left">Review of the day</Divider>
            <Divider orientation="left" orientationMargin="0" plain>Review of the day Header</Divider>
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Input placeholder="Header text" value={reviewHeader} onChange={(e)=> {setReviewHeader(e.target.value); }} onBlur={(e)=> {setReviewHeader(e.target.value); }} />
            </p>
            <Divider orientation="left" orientationMargin="0" plain>Review of the day PID</Divider>
            <Input placeholder="PID" value={reviewPID} onChange={(e)=> {setReviewPID(e.target.value); }} onBlur={(e)=> {setReviewPID(e.target.value); }} />

            <Divider orientation="left" orientationMargin="0" plain>Alternative text</Divider>
            <Editor
                onInit={(evt, editor) => editorRevRef.current = editor}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: 'image autolink lists advlist media table code link preview searchreplace wordcount',
                    toolbar1: 'code | fullscreen preview print wordcount | searchreplace | undo redo | bold italic underline strikethrough | link anchor | formatselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons',
                    toolbar2: 'insertfile image media codesample | ltr rtl | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    image_advtab: true
                }}
                initialValue={dataSource?.ReviewOfTheDay.ItemBody}
            />
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Checkbox checked={reviewVisible} onChange={(e)=> {setReviewVisible(e.target.checked); }} >
                    Review of the day visible
                </Checkbox>
            </p>
            <p>
                <Button
                    onClick={handleSaveROD}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Save Review of the day
                </Button>
            </p>
            <Divider orientation="left">Feature 1</Divider>
            <Divider orientation="left" orientationMargin="0" plain>Feature 1 Header</Divider>
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Input placeholder="Header text" value={feature1Header} onChange={(e)=> {setFeature1Header(e.target.value); }} onBlur={(e)=> {setFeature1Header(e.target.value); }} />
            </p>
            <Divider orientation="left" orientationMargin="0" plain>Feature 1 PID</Divider>
            <Input placeholder="PID" value={feature1PID} onChange={(e)=> {setFeature1PID(e.target.value); }} onBlur={(e)=> {setFeature1PID(e.target.value); }} />

            <Divider orientation="left" orientationMargin="0" plain>Alternative text</Divider>
            <Editor
                onInit={(evt, editor) => editorFeat1Ref.current = editor}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: 'image autolink lists advlist media table code link preview searchreplace wordcount',
                    toolbar1: 'code | fullscreen preview print wordcount | searchreplace | undo redo | bold italic underline strikethrough | link anchor | formatselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons',
                    toolbar2: 'insertfile image media codesample | ltr rtl | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    image_advtab: true
                }}
                initialValue={dataSource?.Feature1.ItemBody}
            />
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Checkbox checked={feature1Visible} onChange={(e)=> {setFeature1Visible(e.target.checked); }} >
                    Feature 1 visible
                </Checkbox>
            </p>
            <p>
                <Button
                    onClick={handleSaveFeature1}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Save Feature 1
                </Button>
            </p>
            <Divider orientation="left">Feature 2</Divider>
            <Divider orientation="left" orientationMargin="0" plain>Feature 2 Header</Divider>
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Input placeholder="Header text" value={feature2Header} onChange={(e)=> {setFeature2Header(e.target.value); }} onBlur={(e)=> {setFeature2Header(e.target.value); }} />
            </p>
            <Divider orientation="left" orientationMargin="0" plain>Feature 2 PID</Divider>
            <Input placeholder="PID" value={feature2PID} onChange={(e)=> {setFeature2PID(e.target.value); }} onBlur={(e)=> {setFeature2PID(e.target.value); }} />

            <Divider orientation="left" orientationMargin="0" plain>Alternative text</Divider>
            <Editor
                onInit={(evt, editor) => editorFeat2Ref.current = editor}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: 'image autolink lists advlist media table code link preview searchreplace wordcount',
                    toolbar1: 'code | fullscreen preview print wordcount | searchreplace | undo redo | bold italic underline strikethrough | link anchor | formatselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons',
                    toolbar2: 'insertfile image media codesample | ltr rtl | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    image_advtab: true
                }}
                initialValue={dataSource?.Feature2.ItemBody}
            />
            <p
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                }}
            >
                <Checkbox checked={feature2Visible} onChange={(e)=> {setFeature2Visible(e.target.checked); }} >
                    Feature 2 visible
                </Checkbox>
            </p>
            <p>
                <Button
                    onClick={handleSaveFeature2}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Save Feature 2
                </Button>
            </p>
        </div>
    )
};

export default AnnouncementEditor;
